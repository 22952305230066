<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
  <p class="work-sans-font text-size-14 line-height-2 m-1 text-white">
    Permite consultar los incidentes relacionados con la movilidad presentados
    en la ciudad, con su ubicación. Haga clic en un punto sobre el mapa para
    consultar la información.
  </p>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.location_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="icono puntero localización"></sit-icon>
    <div class="d-flex d-row full-width justify-space-between">
      <h3 class="text-color-white m-0 text-size-18 text-weight-500">
        {{ accidentAddress | uppercase }} - SECTOR {{ accidentSector }}
      </h3>
    </div>
  </div>
  <div class="d-flex fd-row justify-space-around align-items-center">
    <div class="d-flex fd-col justify-center align-items-center text-white">
      <sit-icon [icon]="Icon.AccidentWazeM" [sizeClass]="'icon-size-78'" class="mr-1 ml-16"
        alt="automóvil choque icono indicador Total Accidentes Registrados"></sit-icon>
      <span>{{ totalAccidents }}</span>
      <label>Total Accidentes Registrados</label>
    </div>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="automóvil choque icono título lista de Total Accidentes Registrados"></sit-icon>
    <h3 class="text-color-white m-0 text-size-18 text-weight-500">
      ACCIDENTES REGISTRADOS
    </h3>
  </div>
  <div>
    <table class="table-agents">
      <thead class="text-white text-size-12">
        <th>Radicado</th>
        <th>Tipo de Accidente</th>
        <th>Gravedad</th>
      </thead>
      <tbody class="p-0 text-weight-400 work-sans-font text-size-16 text-white">
        <ng-container *ngFor="let item of accidents">
          <tr>
            <td>{{ item.RADICADO }}</td>
            <td>{{ item.TIPO_ACCIDENTE }}</td>
            <td>{{ item.GRAVEDAD }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>