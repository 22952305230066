import {Component, Input, OnInit} from "@angular/core";
import {IModalTrafficModel} from "@types-custom/models/ui/modal-traffic-model";
import {IModalTrafficDataWazeModel} from "@types-custom/models/ui/modal-trafficdatawaze-model";
import {ITimeLineGraphicModel} from "@types-custom/models/ui/chart-line-color-model";
import {DrawerPanelService} from "@ui-core/services/drawer-panel/drawer-panel.service";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {IModalAlertsWazeModel} from "@types-custom/models/ui/modal.alerts.waze.model";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-waze-alerts-modal",
  templateUrl: "./waze-alerts-modal.component.html",
  styleUrls: ["./waze-alerts-modal.component.scss"],
})
export class WazeAlertsModalComponent implements OnInit{
  @Input() properties: IModalAlertsWazeModel;

  accidentTypes = {
    'ACCIDENT' : 'Accidente',
    'WEATHERHAZARD': 'Riesgo Clímatico',
    'ROAD_CLOSED' : 'Vía Cerrada'
  }
  accidentSubTypes = {
    'ROAD_CLOSED_CONSTRUCTION' : 'Vía en obra',
    'HAZARD_WEATHER_FLOOD': 'Inundación',
    'HAZARD_ON_ROAD_CAR_STOPPED' : 'Coche Detenido',
    'HAZARD_ON_SHOULDER_CAR_STOPPED' : 'Coche Detenido',
    'HAZARD_ON_ROAD_POT_HOLE' : 'Agujero en vía',
    'ACCIDENT_MINOR' : 'Accidente Menor',
    'ACCIDENT_MAJOR' : 'Accidente Mayor',
    'HAZARD_ON_ROAD_ROAD_KILL' : 'Muerte en vía',
    'ROAD_CLOSED_EVENT' : 'Evento en vía',
    'HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT' : 'Semáforo dañado',
  }

  accidentType: string = '';
  accidentSubType: string = '';
  date : string = '';
  time : string = '';

  constructor(private drawerPanelService: DrawerPanelService) {}
  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
    // @ts-ignore
    this.accidentType = this.accidentTypes[this.properties.type] || undefined;
    // @ts-ignore
    this.accidentSubType = this.accidentSubTypes[this.properties.subType] || this.accidentType;

    this.date = this.convertDate(new Date(this.properties.pubTime))
    this.time = this.convertTime(new Date(this.properties.pubTime))

  }

  convertDate(date : Date) : any {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }

  convertTime(date : Date): any {
    const hour = date.getHours();
    const minutes = date.getMinutes();

    const sub = hour >= 12 ? 'PM' : 'AM';

    const hour12 = hour % 12 === 0 ? 12 : hour % 12;

    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${sub}`;
  }

  protected readonly Icon = Icon;
}
