<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar px-24-px py-05-rem" tabindex="0">

    <div class="bg-color-background-1 d-flex mt-2">
        <div class="left-subtitle-block bg-color-primary-1"></div>
        <div class="d-flex full-width py-05">
            <sit-icon [icon]="Icon.chronometer_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-20 ml-16 text-md-size-18 text-white align-self-center">
                Proyección por tipo de recurso
            </h3>
        </div>
    </div>

    <div class="gap-10-px justify-center align-items-center mt-1-em mb-1-em content-title">
        <sit-dynamic-kpi [type]="3" [data]="KPIAgents"></sit-dynamic-kpi>
        <sit-dynamic-kpi [type]="3" [data]="KPICranes"></sit-dynamic-kpi>
    </div>

    <div class="bg-color-background-1 d-flex">
        <div class="left-subtitle-block bg-color-primary-1"></div>
        <div class="d-flex full-width py-05">
            <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="camino icono título rutas principales"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-20 ml-16 text-md-size-18 lh-32-px text-white align-self-center">
                Distribución proyectada por localidad
            </h3>
        </div>
    </div>

    <div class="list-cards gap-10-px py-24-px" [scrollHandler]="panelActionsDispatcher">
        <ng-container *ngFor="let card of organizedData">
            <sit-listpredictive-resources [Data]="card"></sit-listpredictive-resources>
        </ng-container>
    </div>
</div>
