import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IMapEvent } from '@types-custom/models/ui/map-viewer-model';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IAddressModel, AddressComponent, GeoJson, Feature, Bound } from '@types-custom/models/business/google-api.model';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  private apiKey = this.environment.geoLocations?.apiKey;
  private bounds = this.environment.geoLocations?.bounds;
  pointerData$ = new BehaviorSubject<IMapEvent | undefined>(undefined);
  clickInteractionDispatcher: BehaviorSubject<any>;
  clickEventsDispatcher: BehaviorSubject<any>;

  getBounds(): Bound {
    return this.bounds;
  }

  private localitiesMap: {
    [locality: string]: { localityName: string, localityId: string };
  } = {
      "santafe": { localityName: "Santa Fe", localityId: "Santa Fe" },
      "sancristobal": { localityName: "San Cristóbal", localityId: "San Cristóbal" },
      "usme": { localityName: "Usme", localityId: "Usme" },
      "tunjuelito": { localityName: "Tunjuelito", localityId: "Tunjuelito" },
      "bosa": { localityName: "Bosa", localityId: "Bosa" },
      "kennedy": { localityName: "Kennedy", localityId: "Kennedy" },
      "suba": { localityName: "Suba", localityId: "Suba" },
      "barriosunidos": { localityName: "Barrios Unidos", localityId: "Barrios Unidos" },
      "teusaquillo": { localityName: "Teusaquillo", localityId: "Teusaquillo" },
      "antonionarino": { localityName: "Antonio Nariño", localityId: "Antonio Nariño" },
      "puentearanda": { localityName: "Puente Aranda", localityId: "Puente Aranda" },
      "lacandelaria": { localityName: "La Candelaria", localityId: "La Candelaria" },
      "sumapaz": { localityName: "Sumapaz", localityId: "Sumapaz" },
      "usaquen": { localityName: "Usaquen", localityId: "Usaquen" },
      "fontibon": { localityName: "Fontibon", localityId: "Fontibon" },
      "engativa": { localityName: "Engativa", localityId: "Engativa" },
      "losmartires": { localityName: "Los Martires", localityId: "Los Martires" },
      "rafaeluribeuribe": { localityName: "Rafael Uribe Uribe", localityId: "Rafael Uribe Uribe" },
      "ciudadbolivar": { localityName: "Ciudad Bolivar", localityId: "Ciudad Bolivar" },
      "chapinero": { localityName: "Chapinero", localityId: "Chapinero" }
    };

  constructor(
    @Inject('environment') private environment: any, // Consider using a ConfigurationService
    private httpClient: HttpClient
  ) { }

  getAddress2(pointerData: IMapEvent): Observable<IAddressModel> {
    if (!pointerData) {
      return of(`Carrera 0 #0-0` as unknown as IAddressModel);
    }

    const { lat, lng } = pointerData;
    const url = `${this.environment.geoLocations.getAddressUrl}/json?latlng=${lat},${lng}&key=${this.apiKey}`;

    return this.httpClient.get(url).pipe(
      map(apiResponse => this.googleAPIModelMapper(apiResponse, lat, lng)),
      switchMap(addressModel => {
        return this.getClosestFeature(lat, lng).pipe(
          map(closestFeature => {
            if (closestFeature) {
              addressModel.closestFeature = closestFeature
              // addressModel.brokerId = this.highwaysMap[closestFeature.properties.composed_name];
              // addressModel.brokerName = closestFeature.properties.composed_name;
            }
            return addressModel;
          })
        );
      }),
      catchError(error => {
        console.error('Failed to get address model:', error);
        return throwError(() => error);
      })
    );
  }

  private fetchCorredores(): Observable<GeoJson> {
    return this.httpClient.get<GeoJson>(this.environment.corredores)
      .pipe(
        catchError(error => {
          console.error('Failed to fetch corredores:', error);
          return throwError(() => error);
        })
      );
  }

  public mapOrientation(corredor: string): string { // it ends with the orientation
    const mappedOrientations: { [corredor: string]: string } = {
      'SN': 'Sur - Norte',
      'NS': 'Norte - Sur',
      'EW': 'Oriente - Occidente',
      'WE': 'Occidente - Oriente',
    }
    return mappedOrientations[corredor] ?? '';
  }

  public getClosestFeature(lat: number, lng: number): Observable<Feature> {
    const point = turf.point([lng, lat]);
    const minDistanceToBroker = 50;
    let minDistance = Infinity;
    let closestFeature: any = null;

    return this.fetchCorredores().pipe(
      map(data => {
        data.features.forEach((feature: Feature) => {
          if (feature.geometry.type === 'MultiLineString') {
            feature.geometry.coordinates.forEach((line: any) => {
              const lineString = turf.lineString(line);
              const distance = turf.pointToLineDistance(point, lineString, { units: 'meters' });
              if (distance < minDistanceToBroker && distance < minDistance) {
                minDistance = distance;
                closestFeature = feature;
              }
            });
          }
        });
        return closestFeature;
      })
    );
  }

  public getLocalityByName(localityName: string): { localityName: string, localityId: string } {
    if (!localityName) return undefined;
    const normalizedLocality = this.normalizeName(localityName);
    const foundLocality = Object.keys(this.localitiesMap).find(locality => normalizedLocality.includes(locality));
    return foundLocality ? this.localitiesMap[foundLocality] : undefined;
  }

  public normalizeName(name: string): string {
    if (!name) return '';
    return name.normalize("NFD").replace(/[\u0300-\u036f ]/g, "").toLowerCase();
  }

  private googleAPIModelMapper(
    apiResponse: any, // Consider using a type/interface for the Google API response
    lat: number,
    lng: number
  ): IAddressModel {
    const { results = [] } = apiResponse;

    const addressComponents = [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'country',
      'neighborhood',
      'route',
      'street_number',
      'sublocality_level_1',
    ];

    if (results.length) {
      const { address_components = [] } = results[0];
      const model: { [key: string]: any } = {};

      addressComponents.forEach(addressComponent => {
        const foundComponent = address_components.find((component: AddressComponent) => component.types.includes(addressComponent));
        model[addressComponent] = foundComponent?.long_name;
      });
      // const localityInfo = this.getLocalityByName(model.sublocality_level_1);
      const localityInfo = this.normalizeName(model.sublocality_level_1);

      const result: IAddressModel = {
        ...model,
        response: apiResponse,
        lat,
        lng,
        // localityId: localityInfo?.localityId || '',
        localityDescriptor: localityInfo || 'localidad',
      };

      const bestAddressFull = results[0];
      result.addressStr = bestAddressFull.formatted_address.split(',')[0];

      return result;
    }

    return { response: apiResponse, lat, lng };
  }
}
