import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-modal-confirmation",
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ["./modal-confirmation.component.scss"],
})
export class ModalConfirmationComponent implements OnInit {
  @Input() titleMessage = '';
  @Input() infoMessage = 'No se ha encontrado la información solicitada';
  @Input() icon = Icon.check_circle_green;
  infoIcon = Icon.info;
  checkCircle = this.icon;

  maxWidth = 640;
  modalWidth = 'auto';
  bottom = '70px';
  left = '10px';
  right = '10px';

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    const elementStyle = (<HTMLStyleElement>(
      document.querySelector('.modal-box-msg')
    )).style;

    elementStyle.width = this.modalWidth;
    elementStyle.bottom = this.bottom;
    elementStyle.left = this.left;
    elementStyle.right = this.right;
  }
}
