import { Component, OnInit, Input } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { SameValueValidator } from '@types-custom/models/ui/generic-form.model';
import { BehaviorSubject, tap } from 'rxjs';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '../modal-success/modal-success.component';

@Component({
  selector: 'modal-forgot-password',
  templateUrl: './modal-forgot-password.component.html',
  styleUrls: ['./modal-forgot-password.component.scss'],
  providers: [LoginAuthService],
})
export class ModalForgotPasswordComponent implements OnInit {
  @Input() properties: any;

  Icon = Icon;
  infoIcon = Icon.info;
  cancelbuttonProps: IButtonModel | undefined = undefined;
  sendButtonProps: IButtonModel | undefined;
  showPass = false;

  fb = new UntypedFormBuilder();

  emailFormGroup: FormGroup;
  verificationFormGroup: FormGroup;

  private email = '';
  get Email() {
    return this.email;
  }

  get newPasswordErrorMessage(): string {
    const form: FormControl = this.verificationFormGroup.get(
      'newPassword'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('minlength')
      ? 'Longitud mínima requerida (8)'
      : form.hasError('pattern')
      ? 'Contraseña no válida'
      : '';
  }

  forgotPassAction = {
    emailForm: 'email',
    verificationForm: 'verification',
    bannerConfirmation: 'banner',
  };

  currentAction = this.forgotPassAction.emailForm;

  ngOnInit(): void {
    this.initializeProps();
  }

  private initializeProps(): void {
    this.emailFormGroup = this.fb.group({
      email: [
        { value: '', disabled: false },
        [Validators.required, Validators.email],
      ],
    });

    this.verificationFormGroup = this.fb.group(
      {
        code: [
          { value: '', disabled: false },
          [Validators.required, Validators.pattern('[0-9]{1,}')],
        ],
        newPassword: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).*/),
          ],
        ],
        confirmNewPassword: [
          { value: '', disabled: false },
          [Validators.required],
        ],
      },
      { validator: SameValueValidator('newPassword', 'confirmNewPassword') }
    );

    this.cancelbuttonProps = {
      label: 'CANCELAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button_primary,
        spanClass: IconClassesEnum.text_white_primary,
      },
    };

    this.sendButtonProps = {
      label: 'ACEPTAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.primary_1_button,
        spanClass: IconClassesEnum.text_complementary_2,
      },
    };
  }

  changeAction(action: string) {
    this.currentAction = action;
  }

  handleCancel() {
    this.properties.cancel();
  }

  handleSendEmailForm() {
    this.email = this.emailFormGroup.value.email;
    this.properties.loginAuthService
      .emailForgotPass(this.emailFormGroup.value)
      .pipe(tap((res) => console.log(res)))
      .subscribe({
        next: this.handleSubmitedEmailForm.bind(this),
        error: this.handleError.bind(this),
      });
  }

  handleSendVerificationForm() {
    this.verificationFormGroup.value.email = this.email;
    this.properties.loginAuthService
      .resetPassword(this.verificationFormGroup.value)
      .subscribe({
        next: (res: any) => {
          if (res.status !== 'PASSWORD_UPDATED') {
            this.handleError(res);
            return;
          }
          this.handleSuccess('La contraseña se ha actualizado con éxito');
        },
        error: this.handleError.bind(this),
      });
  }

  handleSubmitedEmailForm(res: any) {
    if (res.status === 'CODE_SENT') {
      this.changeAction(this.forgotPassAction.bannerConfirmation);
    } else {
      this.handleError(res);
    }
  }

  handleVerificationForm(res: any) {
    if (res.status === 'CODE_SENT') {
      this.handleSuccess();
    } else {
      this.handleCancel();
    }
  }

  handleBannerAccept() {
    this.changeAction(this.forgotPassAction.verificationForm);
  }

  handleSuccess(message?: string) {
    this.handleCancel();
    const modalSuccess: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };

    setTimeout(() => {
      this.properties.modalService.confirmationModal(modalSuccess);
    }, 300);
  }

  handleError(response: any) {
    let message;

    switch (response.status) {
      case 'WRONG_CODE':
        message = 'Código de confirmación incorrecto';
        break;
      case 'EMAIL_NOT_FOUND':
        message =
          'Correo electrónico no existe en la base de datos, por favor verifique';
        break;
    }

    this.handleCancel();

    const modalError: IUniqueModalModel = {
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };

    setTimeout(() => {
      this.properties.modalService.confirmationModal(modalError);
    }, 300);
  }

  validateFormGroup(formGroup: FormGroup): void {
    formGroup.markAllAsTouched();
  }
}
