import { Component, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardCo2Model } from '@types-custom/models/ui/card-co2-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';

@Component({
  selector: 'citizen-panel-co2',
  templateUrl: './panel-co2.component.html',
  styleUrls: ['./panel-co2.component.scss']
})
export class PanelCo2Component implements OnInit {
  Icon = Icon;
  cards: ICardCo2Model[] = [];
  avgCo2 = 0;
  avgPressure = 0;
  avgTemperature = 0;
  avgHumidity = 0;

  constructor(private drawerPanelService: DrawerPanelService) { }

  ngOnInit(): void {
    this.setIndicators(this.cards);
  }

  openModal(card: ICardCo2Model): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(
      card.cardMapLink.markerModel
    );
  }

  setIndicators(cards: ICardCo2Model[]) {
    let sumCo2 = 0;
    let sumPressure = 0;
    let sumTemperature = 0;
    let sumHumidity = 0;
    const count = cards.length;

    cards.map((card: ICardCo2Model) => {
      if (!isNaN(+card.carbonDioxide)) {
        sumCo2 = sumCo2 + (+card.carbonDioxide);
      }
      if (!isNaN(+card.pressure)) {
        sumPressure = sumPressure + (+card.pressure);
      }
      if (!isNaN(+card.temperature)) {
        sumTemperature = sumTemperature + (+card.temperature);
      }
      if (!isNaN(+card.humidity)) {
        sumHumidity = sumHumidity + (+card.humidity);
      }
    });

    this.avgCo2 = (sumCo2/count);
    this.avgPressure = (sumPressure/count);
    this.avgTemperature = (sumTemperature/count);
    this.avgHumidity = (sumHumidity/count);
  }

}
