<div class="modal-privacy-box">
  <div
    class="modal-header d-flex fd-col bg-color-primary-2 pl-16 justify-center"
  >
    <div class="image-title">
      <img
        src="assets/img/head-cgm.png"
        alt="header imagen de cgm"
        class="w-auto full-height"
      />
    </div>
  </div>
  <div class="modal-body d-flex fd-col bg-white">
    <h2 class="text-color-primary-2 pl-16 text-size-30 text-weight-800">
      Política de Protección de Datos
    </h2>
    <div
      class="text-info mb-16 pl-16 pr-24 work-sans-font text-color-background-2 scrollbar-modal"
    >
      <!-- <div [innerHtml]="htmlSnippet"></div> -->
      <p>
        Con el diligenciamiento de este formato y el registro de mis datos
        personales, estoy autorizando a la Alcaldía de Manizales para procesar,
        recolectar, almacenar y usar mi información personal, con respecto de
        las condiciones de seguridad y privacidad y de acuerdo con los términos
        de su manual de políticas y procedimiento de protección de datos
        vigente. Como titular de los datos tengo derecho a acceder gratuitamente
        a ellos, actualizarlos, rectificarlos, cancelarlos en los casos que sea
        procedente, revocar el consentimiento, oponerme a su tratamiento,
        presentar quejas, reclamos y acciones. El manual de políticas y
        procedimiento de protección de datos vigente, se encuentra publicado en
        la página web de la Alcaldía de Manizales
        <a href="https://manizales.gov.co/" target="_black"
          >www.manizales.gov.co</a
        >
      </p>
    </div>
    <div class="modal-footer">
      <div class="d-flex fd-row pl-16">
        <img
          src="assets/img/logo-alcaldia.svg"
          alt="logo-alcaldia"
          class="full-width full-height"
        />
        <img
          src="assets/img/logo-cgm-azul.png"
          alt="logo-cgm-azul"
          class="full-width full-height pl-16 w-75"
        />
      </div>
    </div>
  </div>
</div>
