import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsRoadRunPathModel {
  id: string;
  nombreVia: string;
  ancho: string;
  capacidad: string;
  largo: string;
  velocidad: string;
  tipo: string;
  sentido: string;
  title: string;
  markerPopupText: string;
}

export class RoadRunPathModel extends MarkerModelBase {
  static className = 'RoadRunPathModel';
  classProperties: IPropsRoadRunPathModel;

  constructor(
    props: IPropsRoadRunPathModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, RoadRunPathModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
