import { Component, OnInit, Input } from '@angular/core';
import { ICardRoadSituationsModel } from '@types-custom/models/ui/card-road-situations-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeSeriesGraphicModel } from '@types-custom/models/ui/chart-line-color-model';

@Component({
  selector: 'panel-road-situations-official',
  templateUrl: './panel-road-situations-official.component.html',
  styleUrls: ['./panel-road-situations-official.component.scss'],
})
export class PanelRoadSituationsOfficialComponent implements OnInit {
  situationsList: Array<any> = [];
  Icon = Icon;
  cards: ICardRoadSituationsModel[] | undefined = [];
  orderCards: ICardRoadSituationsModel[] | undefined;
  dataGraphicList: ITimeSeriesGraphicModel;
  colorScale: any = [{}];
  @Input() serviceDataSource: any;

  ngOnInit(): void {
    this.serviceDataSource.getIncidentsByHour().subscribe((res: any) => {
      this.setGraphicIncidents(res);
    });
  }
  constructor(private drawerPanelService: DrawerPanelService) {}

  openModal(card: ICardRoadSituationsModel): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }

  setGraphicIncidents(data: any): void {
    this.dataGraphicList = {
      data: data,
      label: [
        'Estado Semáforo',
        'Horarios',
        'Estados de las vías',
        'Señales de Tránsito',
        'Fallidos de Grúas',
        'Accidentes conciliados',
        'Inmovilizaciones',
        'Atención de quejas',
      ],
      labelX: 'Hora',
      labelY: 'Cantidad',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#92FDF6' }];
  }
}
