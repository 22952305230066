import { Component, OnInit } from "@angular/core";
import { Subject } from 'rxjs';

import { IncidentParametersManagementService } from "@official/services/data-grid-service/incident-parameters-management/incident-parameters-management.service";
import { Icon } from "@types-custom/models/ui/icon-model";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalCreateParameterComponent } from "../modal-create-parameter/modal-create-parameter.component";
import { GridPageActions, IGridPageModel } from "@types-custom/models/ui/grid-page-model";
import { IncidentsManageService } from "@official/services/data-grid-service/incidents-manage-service/incidents-manage.service";
import { IIncidentParameter } from '@shared/models/incident-parameter.model'
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { IDynamicFilterModel } from "@types-custom/models/ui/dynamic-filter";
import { GridTypeEnum, gridTableheadersMap } from "@official/utils/mappers/grid-mapper";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { SdkDynamicFiltersService } from "@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service";
import { ExportExcelService } from "@official/services/data-grid-service/export-excel-service/export-excel.service";

@Component({
  selector: "sit-incident-parameters",
  templateUrl: "./incident-parameters.component.html",
  styleUrls: ["./incident-parameters.component.scss"],
  providers: [
    IncidentParametersManagementService,
    IncidentsManageService,
    SdkDynamicFiltersService
  ]
})
export class IncidentParametersComponent implements OnInit {


  icon = Icon;

  constructor(
    public incidentParametersManagementService: IncidentParametersManagementService,
    public incidentsManagementService: IncidentsManageService,
    private exportExcelService: ExportExcelService,
    private service: IncidentsManageService,
    private modalService: ModalService,
    private sdkDynamicFiltersService: SdkDynamicFiltersService
  ) {
    this.sdkDynamicFiltersService.setFilters(this.incidentParametersManagementService, 0);
    incidentParametersManagementService.init()
  }

  ngOnInit(): void {
    this.editRowDispatcher.subscribe((val) => {
    })
  }

  dynamicFiltersModel: IDynamicFilterModel = this.incidentParametersManagementService.generateDynamicFiltersModel();

  editRowDispatcher = new Subject<IGridTableCellDispatcher>();

  get GridPageActions() {
    return GridPageActions;
  }

  gridPageProps: IGridPageModel = {
    headers: gridTableheadersMap([this.editRowDispatcher])[GridTypeEnum.ManageIncidentParameters],
    dataSource: this.incidentParametersManagementService,
    isAllowDownload: true,
    // dispatcher: this.editRowDispatcher
  };

  createButtonProps: IButtonModel = {
    label: 'CREAR PARÁMETRO',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  setParam(param: IIncidentParameter
    // , options:{success: (name: string)=>string, error: (name?: string)=>string}
  ) {
    this.service.setParam(param).subscribe({
      next: (response) => this.handleSuccess(response.name),
      error: (e) => {
        console.error('e :>> ', e);
        param.state = !param.state;
        this.handleError();
      }
    })
  }


  handleCreate() {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalCreateParameterComponent,
        data: {
          onComplete: () => this.incidentParametersManagementService.init()
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
      // modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
  }

  handleDownloadGridAction(action: GridPageActions): void {

    const fileProps = {
      url: this.incidentsManagementService.buildExcelPdfUrl(`${action}`, true),
      filters: this.incidentsManagementService?.formFiltersValue,
    };
    delete fileProps.filters.beginDate;
    const dynamicFilters = this.sdkDynamicFiltersService.getModel();

    Object.keys(dynamicFilters).forEach(k => {
      if (dynamicFilters[k])
        fileProps.filters[k] = dynamicFilters[k];
    })

    this.exportExcelService.downloadFile(
      fileProps.url,
      'parametros_de_incidentes',
      // GridTypeEnum.ManageIncidentParameters,
      `${action}`,
      fileProps.filters
    );
  }


  handleSuccess(name?: string) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: `Éxito al actualizar ${name}` },
      },
      headerBackgroundClass: 'bg-color-background-1',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };
    this.modalService.confirmationModal(modal);
  }
}
