import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  PLATFORM_ID,
  Inject,
  NgZone,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
  ITimeLineGraphicModel,
  IPointOnTimeLine,
} from '@types-custom/models/ui/chart-line-color-model';

/*
 ** Sit Chart Line Color
 ** Render a line chart
 */
@Component({
  selector: 'sit-chart-line',
  templateUrl: './sit-chart-line-color.component.html',
  styleUrls: ['./sit-chart-line-color.component.scss'],
})
export class SitChartLineColorComponent
  implements OnDestroy, AfterViewInit, OnChanges
{
  @ViewChild('lineColor') lineColor!: ElementRef;
  @Input() properties: ITimeLineGraphicModel;

  colorscaleDefault = [
    { minValue: 0, maxValue: 10, color: '#00C479' },
    { minValue: 10, maxValue: 20, color: '#FF9901' },
    { minValue: 20, maxValue: 30, color: '#FF3232' },
  ];
  @Input() colorScale = this.colorscaleDefault;
  private chart!: am4charts.XYChart;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.properties.currentValue) {
      this.drawHistoryTimeGraph();
    }
  }

  ngAfterViewInit(): void {
    if (this.properties) {
      this.drawHistoryTimeGraph();
    }
  }

  drawHistoryTimeGraph() {
    if (this.properties.data.length) {
      const itemsWithColor = this.addColorToItems(this.properties.data);
      this.createChart(itemsWithColor);
    }
    //TODO:Implement fetching data from url (depending on backend data model)
  }

  addColorToItems(graphicItems: IPointOnTimeLine[]) {
    return graphicItems.map((item: IPointOnTimeLine) => ({
      ...item,
      lineColor: this.getAmchartColors(item.value),
    }));
  }

  getAmchartColors(value: number) {
    return am4core.color(
      this.colorScale.find(
        (colorRange) =>
          value >= colorRange.minValue && value < colorRange.maxValue
      ).color
    );
  }

  createChart(data: IPointOnTimeLine[]) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(
        this.lineColor?.nativeElement,
        am4charts.XYChart
      );
      chart.data = data;
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.title.text = this.properties.labelX;
      categoryAxis.title.fill = am4core.color('#ffffff');
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.ticks.template.disabled = true;
      categoryAxis.renderer.line.opacity = 0;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.minGridDistance = 40;
      categoryAxis.dataFields.category = 'time';
      categoryAxis.startLocation = 0.4;
      categoryAxis.endLocation = 0.6;
      categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = this.properties.labelY;
      valueAxis.title.fill = am4core.color('#ffffff');
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.line.opacity = 0;
      valueAxis.renderer.ticks.template.disabled = true;
      valueAxis.min = 0;
      valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');
      valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');

      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.categoryX = 'time';
      lineSeries.dataFields.valueY = 'value';
      lineSeries.tooltipText = `${this.properties.label ? this.properties.label + ':' : ''} {valueY.value}`;
      lineSeries.fillOpacity = 0.5;
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.fill = 'lineColor';
      lineSeries.propertyFields.stroke = 'lineColor';
      
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'panX';
      chart.cursor.lineX.opacity = 0;
      chart.cursor.lineY.opacity = 0;

      // chart.scrollbarX = new am4core.Scrollbar();
      // chart.scrollbarX.parent = chart.bottomAxesContainer;
      this.chart = chart;
    });
  }


  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
