import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "sit-resources-list-locality",
  templateUrl: "./resources-list-locality.component.html",
  styleUrls: ["./resources-list-locality.component.scss"],
})
export class ResourcesListLocalityComponent implements OnInit{
  @Input() card : any

  ngOnInit() {
  }
}
