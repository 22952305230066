import { Component, Input } from '@angular/core';
import { IModalImageModel } from '@types-custom/models/ui/modal-image.model';

/*
** Modal Image
** Render an Image Modal that displays an image.
*/
@Component({
  selector: 'modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent {
  @Input() properties: IModalImageModel | undefined;

}
