import {Component, Input, OnInit} from "@angular/core";
import {IIncidentsCardFieldResourceModel} from "@types-custom/models/ui/card-incidents-field-resources";
import {Icon} from "@types-custom/models/ui/icon-model";
import {ICardRoadSituationsModel} from "@types-custom/models/ui/card-road-situations-model";
import {DrawerPanelService} from "@ui-core/services/drawer-panel/drawer-panel.service";

@Component({
  selector: "sit-incidents-kpi",
  templateUrl: "./incidents-kpi.component.html",
  styleUrls: ["./incidents-kpi.component.scss"],
})
export class IncidentsKPIComponent implements OnInit{
  @Input() DataItem : IIncidentsCardFieldResourceModel | undefined;
  @Input() KPIData : any | undefined

  public stacks = {
    classIncident          : {
      text : '',
      title : 'Clase'
    },
    typeIncident  : {
      text : '',
      title : 'Tipo'
    },
    priorityIncident   : {
      text : '',
      title : 'Prioridad'
    }
  }

  protected readonly Icon = Icon;

  constructor(private drawerPanelService : DrawerPanelService) {
  }

  ngOnInit() {
    this.stacks.classIncident.text = this.DataItem?.incidentClassName
    this.stacks.typeIncident.text = this.DataItem?.incidentTypeName
    this.stacks.priorityIncident.text = this.DataItem?.incidentPriorityName
  }

  public convertDateFormat(originalDate: string): string {
    const date = new Date(originalDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  public convertTimeFormat(originalDate: string): string {
    const date = new Date(originalDate);

    // Extract hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Format the time as HH:MM AM/PM
    const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

    return formattedTime;
  }

  public openModal(card: IIncidentsCardFieldResourceModel): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }
}
