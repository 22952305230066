import { ILocationOption } from "@types-custom/models/business/location.model";

export interface IFieldModel {
    id: number;
    name: string;
    type: string;
    minCharacterSize: number;
    maxCharacterSize: string;
    enabled: number;
    options: any[];
    hasAssociatedRecords: number;
    nameType?: string;
    stateParameter?: IStateParemter;
};


export interface IFieldManagmentModel {
    id?: number;
    name?: string;
    type?: string;
    nameType?: string;
    minCharacterSize?: number;
    maxCharacterSize?: number;
    enabled?: boolean;
    options?: any[];
    hasAssociatedRecords?: boolean;
    stateParameter?: IStateParemter;
}

export interface IFieldCreateModel {
    name: string;
    type: string;
    enabled: boolean;
    minCharacterSize?: number;
    maxCharacterSize?: number;
    options?: any[]
}

export interface IFieldEditModel extends IFieldCreateModel {
    id: number;
}

export enum IFieldState {
    ACTIVE = 'Activo',
    INACTIVE = 'Inactivo',
}

export enum IFieldType {
    TEXT = 'Texto',
    LIST = 'Selección única',
    BINARY = 'Binario (Si o No)'
}

export const fieldStateList = [
    {
      label: IFieldState.ACTIVE,
      value: 'true',
    } as ILocationOption,
  
    {
      label: IFieldState.INACTIVE,
      value: 'false',
    } as ILocationOption,
];

export const fieldTypeList = [
    {
      label: IFieldType.TEXT,
      value: 'text',
    } as ILocationOption,  
    {
      label: IFieldType.LIST,
      value: 'list',
    } as ILocationOption,
    {
        label: IFieldType.BINARY,
        value: 'binary',
      } as ILocationOption,
];

export interface IStateParemter {
    id: number;
    enabled: boolean;
    name?: string;
}