import { Injectable } from '@angular/core';
import { IMenuEvent } from '@types-custom/models/ui/menu.model';
import { BehaviorSubject, filter, Subject } from 'rxjs';

export interface IAction {
  event: IMenuEvent;
  dispatcher: BehaviorSubject<any>;
}
export interface IActionHandler {
  setService?(service: any): void;
  canHandle(event: IAction): boolean;
  handle(event: IAction): void;
}

@Injectable()
export class ActionService {
  private actionHandlers: IActionHandler[] = [];

  private actionSubject = new Subject<IAction>();

  dispatch(event: IMenuEvent, dispatcher: any): void {
    this.actionSubject.next({ event, dispatcher });
  }

  registerActionHandler(handler: IActionHandler): void {
    this.actionHandlers.push(handler);
  }

  listenToActions(): void {
    this.actionSubject
      .pipe(filter((action) => !!action.event))
      .subscribe((action) => {
        const handler = this.actionHandlers.find((h) => h.canHandle(action));

        if (handler) {
          handler.handle(action);
        }
      });
  }
}
