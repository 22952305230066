<ng-container *ngIf="
    properties.chartType === 1 ||
      properties.chartType === 2 ||
      properties.chartType === 3;
    else basicChart
  ">
  <div>
    <div id="{{ properties.divId }}" class="full-width full-height"></div>
    <div class="speedometer-labels">
      <span class="label-unit">{{ properties.value }} {{ properties.unitLabel }}</span>
      <span class="label-description">{{ properties.descriptionLabel }}</span>
    </div>
  </div>
</ng-container>

<ng-template #basicChart>
  <div class="full-width full-height modal">
    <img [attr.src]="
        properties.chartType === 4
          ? '/assets/charts/tacometro.svg'
          : '/assets/charts/concordans.svg'
      " alt="Velocimetro" class="icon-velocimeter" />
    <div class="hand-clock" [style.transform]="
        'rotate(' +
        (properties.handClockRotation !== undefined
          ? properties.handClockRotation
          : properties.value) +
        'deg)'
      "></div>
    <div class="speedometer-labels text-white" [ngClass]="
        properties.labelsClass !== undefined ? properties.labelsClass : ''
      ">
      <span class="label-unit text-md-size-18" [ngClass]="
          properties.unitLabelClass !== undefined
            ? properties.unitLabelClass
            : ''
        ">{{ properties.value }} {{ properties.unitLabel }}</span>
      <ng-container *ngIf="
          properties.descriptionLabel !== '' &&
          properties.descriptionLabel !== undefined
        ">
        <label class="label-description text-md-size-18">{{
          properties.descriptionLabel
          }}</label>
      </ng-container>
    </div>
  </div>
</ng-template>