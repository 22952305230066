export const generateColombiaDateObject = (): Date => {
  const date = new Date();
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/Bogota',
  };
  const optionsDate: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'America/Bogota',
  };

  const dateObject = new Date(
    date.toLocaleString('fr-CA', optionsDate) +
    'T' +
    date.toLocaleString('en-GB', optionsTime)
  );

  return dateObject;
};

export const isoDate = (_date?: Date) => {
  const date = _date ?? generateColombiaDateObject();
  return date.toISOString();
}

export const isoFormatDate = (_dateObject?: Date) => {
  const now = _dateObject ?? generateColombiaDateObject();

  // Ajustar la hora actual a la zona horaria de Colombia (UTC-5)
  const colombiaTimeInMs = now.getTime() - (5 * 60 * 60000);

  const colombiaDate = new Date(colombiaTimeInMs);
  const isoString = colombiaDate.toISOString();

  const [formatedDate, formatedTimeWithMs] = isoString.split('T');
  const [formatedTime] = formatedTimeWithMs.split('.');

  const obj = {
    isoDate: isoString,
    zeroTimeDate: `${formatedDate}T00:00:00.000z`,
    fullDate: `${formatedDate}T${formatedTime}`,
    date: formatedDate,
    time: formatedTime,
  };
  return obj;
};


export const formatDate = (unfromattedDate: string | Date): string => {
  if (!unfromattedDate) return '--/--/-- __:__';
  let date = new Date();

  const formatter = new Intl.DateTimeFormat('es-CO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // Use 24-hour format
  });

  switch (typeof unfromattedDate) {
    case "string":
      date = new Date(unfromattedDate);
      break;
    default:
      break;
  }
  return formatter.format(date);
}