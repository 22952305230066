import { Component, Input } from '@angular/core';
import {
  IGridTableCellDispatcher,
  IGridTableHeaderModel,
} from '@types-custom/models/ui/grid-table-model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { Observable, Subject } from 'rxjs';

/*
** Grid Table
** Render a table that load data from a specified datasource.
*/

@Component({
  selector: 'sit-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss'],
})
export class GridTableComponent {
  @Input() headers: IGridTableHeaderModel[] = [];
  @Input() dataSource: Observable<any> | undefined;
  @Input() dataSourceService: any | undefined;
  @Input() dispatcherRow?: Subject<IGridTableCellDispatcher> | undefined;

  dataCellType = {
    isNumber: (data: any) => typeof data === 'number',
  };

  handleClick(
    dispatcher: Subject<IGridTableCellDispatcher> | undefined,
    row: any,
    key: string | undefined,
    index: number,
    todo?: any
  ): void {
    if (dispatcher) {
      const data = {
        row,
        index,
        key,
      };

      dispatcher.next(data);
    }
  }

  handleClickRow(
    dispatcher: Subject<IGridTableCellDispatcher> | undefined,
    row: any,
    index: number,
  ): void { //console.log(index, todo);
    if (dispatcher) {
      const data = {
        row,
        index
      };

      dispatcher.next(data);
    }
  }

  resolveRenderData(
    toRender: IRendererModel,
    cellData: any,
    key: string,
    item?: any,
  ): IRendererModel {
    const { component, data } = toRender;
    const renderModel = {
      component,
      data: data ? { ...data, dataSource: this.dataSourceService, [key]: cellData ?? item } : { [key]: cellData ?? item },
    };
    return renderModel;
  }
}

