import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {IModalBikeModel} from "@types-custom/models/ui/modal-bikes.model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IcardConvention} from "@types-custom/models/ui/map-conventions-model";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "cdm-manizales-modal-bikes",
  templateUrl: "./modal-bikes.component.html",
  styleUrls: ["./modal-bikes.component.scss"],
  encapsulation : ViewEncapsulation.None})

export class ModalBikesComponent implements OnInit {
  @Input() properties : IModalBikeModel | undefined;

  isAllSelected = false;
  isNorteSelected = false;
  isSurSelected = false;

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  levelOfService : IcardConvention[] = [{
    icon : Icon.serva,
    label : "Nivel de Servicio",
    alt : "Nivel de Servicio"
  }]

  flow : IcardConvention[] = [
    {
      icon : Icon.bicycles_w,
      label : "108",
      alt : "Flujo"
    }
  ]

  ngOnInit() {
    this.properties.trafficBikesDataSource.getLastBikesMeasurements(this.properties.id.toString()).subscribe((res : any) => {
      this.avgSpeedProps.value = res.speed
      this.levelOfService[0].icon =
          res.levelOfService === 'C' ? Icon.servc :
              res.levelOfService === "A" ? Icon.serva :
                  res.levelOfService === "B" ? Icon.servb :
                      res.levelOfService === "D" ? Icon.servd :
                          Icon.serve;
      this.flow[0].label = res.flow.toString();
    })
  }

  toggleSelection(option: string): void {
    if (option === 'all') {
      this.isAllSelected = !this.isAllSelected;
      this.isNorteSelected = this.isAllSelected;
      this.isSurSelected = this.isAllSelected;
    } else if (option === 'norte') {
      this.isNorteSelected = !this.isNorteSelected;
      this.checkAllSelection();
    } else if (option === 'sur') {
      this.isSurSelected = !this.isSurSelected;
      this.checkAllSelection();
    }
  }

  checkAllSelection(): void {
    this.isAllSelected = this.isNorteSelected && this.isSurSelected;
  }
}
