import { Component } from '@angular/core';
import { LoadingOverlayService } from '@ui-core/services/loading-overlay/loading-overlay.service';
import { BehaviorSubject, Observable } from 'rxjs';

/*
** Loader Overlay
** Render a spinner loading that is visible when isLoaderVisibleSubject is true.
*/
@Component({
  selector: 'loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss'],
})
export class LoaderOverlayComponent {
  isLoaderVisibleSubject = new BehaviorSubject<boolean>(false);

  constructor(private loadingOverlayService: LoadingOverlayService) {
    this.loadingOverlayService.loadingDisplayObservable.subscribe(
      this.handleLoadingStatus.bind(this)
    );
  }

  private handleLoadingStatus(isVisible: boolean): void {
    this.isLoaderVisibleSubject.next(isVisible);
  }
}
