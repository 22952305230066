<div class="text-blue-1 text-weight-700 sit-card" (click)="openModal()">
  <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-16 text-weight-500">
      <span> {{ card.street }}</span>
  </div>
  <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
      <div class="container-info separator-line-info indicator-container">
          <div class="element">
            <span class="title">
                  {{card.class}}
            </span>
            <span class="subject">
                Clase
            </span>
          </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
              {{card.type}}
          </span>
          <span class="subject">
              Tipo
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
          <div class="element">
              <span class="title">
              {{card.priority}}
          </span>
              <span class="subject">
              Prioridad
          </span>
          </div>
      </div>
  </div>
  <div class="d-flex footer-box">
          <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white"
                                          [sizeClass]="'icon-size-15'"
                                          alt="icono persona"></sit-icon> {{this.date}}</span>

      <span class="d-flex"> <sit-icon [icon]="Icon.time"
                                      [sizeClass]="'icon-size-15'"
                                      alt="icono persona"></sit-icon> {{this.time}}</span>

  </div>
</div>