import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsBicyclePathModel {
  id: string;
  type: string;
  distance: string;
  width: string;
  shapeLength: string;
  editor: string;
  date: string;
  status: string;
  speed: string;
  slope: string;
  title: string;
  markerPopupText: string;
}

export class BicyclePathModel extends MarkerModelBase {
  static className = 'BicyclePathModel';
  classProperties: IPropsBicyclePathModel;

  constructor(
    props: IPropsBicyclePathModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, BicyclePathModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
