import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Icon } from './icon-model';

export enum IncidentFormEnum {
  localidad = '',
  latitude = 'latitude',
  longitude = 'longitude',
  incidentTime = 'incidentTime',
  beginDate = 'beginDate',
  endDate = 'endDate',
  orientId = 'orientId',
  brokerId = 'brokerId',
}

export interface IncidentsModel {
  divId: string;
  value: number;
  descriptionLabel: string;
  labelsClass: string;
}

export interface IncidentsCardModel {
  className: string
  incidentId: number
  stateName: string
  typeName: string
}

export interface IncidentStateStatModel {
  stateId: number,
  stateName: string,
  count: number
}

export interface Anotation {
  id: number,
  incidentId: number,
  observation: string,
  authorId: string,
  authorName: string,
  date: string,
  type: RoleMapper,
}

export enum RoleMapper {
  CGT = 'Operador CGT',
  COORDINADOR = 'Coordinador de recurso en campo',
  // POLICIA = 'Policia de tránsito',
  CATT = 'CATT',
  CIENPIES = 'Cienpiés',
  COLEGIOENBICI = 'Al colegio en bici',
  GOGEV = 'GOGEV',
  // RECURSO = 'Recurso en campo',
}

export function RoleIcon(
  role: RoleMapper
): { icon: Icon, background: string } | null {
  switch (role) {
    case RoleMapper.CGT:
      return { icon: Icon.role_cgt, background: '#FC7F8F' };
    case RoleMapper.COORDINADOR:
      return { icon: Icon.role_coordinador, background: '#62BAAC' };
    // case RoleMapper.POLICIA:
    //   return { icon: Icon.role_policia, background: '#00C479' };
    case RoleMapper.CATT:
      return { icon: Icon.role_policia, background: '#5C77FF' };
    case RoleMapper.CIENPIES:
      return { icon: Icon.role_cienpies, background: '#FF6839' };
    case RoleMapper.COLEGIOENBICI:
      return { icon: Icon.role_colegio_en_bici, background: '#AD5CFF' };
    case RoleMapper.GOGEV:
      return { icon: Icon.role_gogev, background: '#FF8A8E' };
    default:
      return null;
    // return { icon: Icon.role_recurso, background: '#fac' };
  }
}


export function maxCurrentDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentDate = new Date();
    const inputDate = new Date(control.value);
    return inputDate > currentDate ? { maxCurrentDate: true } : null;
  };
}
