import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  GridPageActions
} from '@types-custom/models/ui/grid-page-model';
import { Icon } from '@types-custom/models/ui/icon-model';

import {
  IGridTableCellDispatcher,
  IGridTableHeaderModel,
} from '@types-custom/models/ui/grid-table-model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'grid-button-icon',
  templateUrl: './grid-button-icon.component.html',
  styleUrls: ['./grid-button-icon.component.scss']
})
export class GridButtonIconComponent {
  readonly GridPageActions = GridPageActions;

  icon = Icon;
  @Input() headers: IGridTableHeaderModel[] = [];
  @Input() dataSource: Observable<any> | undefined;
  @Input() icons: Icon[];
  @Output() gridAction = new EventEmitter<GridPageActions>();
  
  dataCellType = {
    isNumber: (data: any) => typeof data === 'number',
  };

  handleClick(
    dispatcher: Subject<IGridTableCellDispatcher> | undefined,
    row: any,
    key: string | undefined,
    index: number
  ): void {
    if (dispatcher) {
      const data = {
        row,
        index,
        key,
      };

      dispatcher.next(data);
    }
  }

  resolveRenderData(
    toRender: IRendererModel,
    cellData: any,
    key: string
  ): IRendererModel {
    const { component, data } = toRender;
    const renderModel = {
      component,
      data: data ? { ...data, [key]: cellData } : { [key]: cellData },
    };
    return renderModel;
  }

  dispatchAction(action: GridPageActions): void {
    this.gridAction.emit(action);
  }

}
