<!-- Botón para desplegar el menú (basado en chips.svg) -->
<button (click)="toggleDropdown()" class="dropdown-button" [ngClass]="selectedDateRange ? 'active' : ''">
    <span class="text-white">{{ _onChangeDatesFormat(selectedDateRange) || label }}</span>
    <!-- Ícono o representación del SVG "Chips" -->
    <div class="icon-chips"></div>
</button>

<!-- Menú desplegable (basado en roles.svg) -->
<div [ngClass]="{'show': showDropdown}" class="dropdown-menu">
    <!-- Aquí puedes colocar las opciones del menú -->
    <!-- <ng-container *ngFor="let item of _dataSource$ | async as items">
        <div class="dropdown-item" (click)="selectItem(item)">
            {{ item.label }}
        </div>
    </ng-container> -->

    <mat-card class="demo-inline-calendar-card">
        <mat-calendar (selectedChange)="_onSelectedChange($event)"
                      [selected]="selectedDateRange">
        </mat-calendar>
    </mat-card>


</div>
