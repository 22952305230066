import {Component, Input, OnInit} from "@angular/core";
import {ICardRoadCorridorsModel} from "@types-custom/models/ui/card-road-corridors.model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {ICardTrafficDataWazeModel} from "@types-custom/models/ui/card-traffic-data-waze-model";
import {IModalTrafficModel} from "@types-custom/models/ui/modal-traffic-model";
import {IModalTrafficDataWazeModel} from "@types-custom/models/ui/modal-trafficdatawaze-model";

@Component({
  selector: "sit-list-traffic-waze-cards",
  templateUrl: "./sit-list-traffic-waze-cards.component.html",
  styleUrls: ["./sit-list-traffic-waze-cards.component.scss"],
})
export class SitListTrafficWazeCardsComponent  implements OnInit{
  @Input() card:
      | ICardTrafficDataWazeModel
      | undefined;

  @Input() number : number

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  actMinTimeIndicator = '-';
  actSecTimeIndicator = '-';
  expMinTimeIndicator = '-';
  expSecTimeIndicator = '-';

  ngOnInit() {
    this.avgSpeedProps.value = Math.round(Number(this.card.speed));
    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
    this.setTimeIndicator(this.card)
  }

  openModal(): void {
    this.card?.cardMapLink?.markerDispatcher.next(
      this.card.cardMapLink.markerModel
    );
  }

  setTimeIndicator(prop: ICardTrafficDataWazeModel): void {
    const timeAct = this.convertTime(prop.elapsedTime);
    const timeExp = this.convertTime(prop.expectedValue);

    this.actMinTimeIndicator = timeAct.minute;
    this.actSecTimeIndicator = timeAct.second;
    this.expMinTimeIndicator = timeExp.minute;
    this.expSecTimeIndicator = timeExp.second;
  }

  convertTime(minutes: number): any {
    const hour = Math.floor(minutes / 60);
    const outHour = hour < 10 ? '0' + hour : hour;

    const minute = Math.floor(minutes);
    const outMinute = minute < 10 ? '0' + minute : minute;

    const second = Math.floor((minutes / 60) % 60);
    const outSecond = second < 10 ? '0' + second : second;

    return {
      hour: outHour,
      minute: outMinute,
      second: outSecond,
    };
  }
}
