<div class="d-flex gap-10-px p-1-em" *ngIf="dataLocality && dataPercentage">
    <sit-dynamic-kpi [type]="0" [barLoader]="true" [data]="dataPercentage"></sit-dynamic-kpi>
    <sit-dynamic-kpi [type]="0" [barLoader]="false" [data]="dataLocality"></sit-dynamic-kpi>
</div>

<span class="text-white fw-bold d-flex gap-10-px align-items-center">
    <sit-icon
            [icon]="Icon.Tool"
            [sizeClass]="'icon-size-24'"
            alt="icono de herrameinta"
    ></sit-icon>
    Recursos Asignados
</span>
<div class="resources_containers" [scrollHandler]="panelActionsDispatcher" *ngIf="listResources.length > 0">
    <sit-dynamic-kpi *ngFor="let item of listResources" [type]="1" [data]="item"></sit-dynamic-kpi>
</div>
