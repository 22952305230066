import { AppComponent } from "@official/app.component";
import { AppRoutingModule } from "@official/routes/app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "@official-env/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InterceptorOfficial } from "@shared/interceptors/interceptor-official";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoadingOverlayService } from "@ui-core/services/loading-overlay/loading-overlay.service";
import { LocalStorageManagementService } from "@shared/services/local-storage-management/local-storage-management.service";
import { LoginComponent } from "@official/pages/login/login.component";
import { MaterialModule } from "@ui-core/shared/material.module";
import { MenuService } from "@official/services/menu/menu.service";
import { NgModule } from "@angular/core";
import { NotFoundComponent } from "@official/pages/not-found/not-found.component";
import { SdkModule } from "@sdk/sdk.module";
import { UiCoreModule } from "@ui-core/ui-core.module";
import { UserManagementComponent } from "@official/pages/user-management/user-management.component";
import { LoginFormComponent } from "@official/pages/login/login-form/login-form.component";
import { LoginAuthService } from "@shared/services/login-auth/login-auth.service";
import { RolesManagementComponent } from "@official/pages/roles-management/roles-management.component";
import { ActionService } from "@shared/services/action-service/action.service";
import { ModalActionHandler } from "@shared/services/action-service/handlers/modal-action-handler";
import { MapActionHandler } from "@shared/services/action-service/handlers/map-action-handler";
import { PanelActionHandler } from "@shared/services/action-service/handlers/panel-action-handler";
import { RouterActionHandler } from "@shared/services/action-service/handlers/router-action-handler";
import { OAuthConfigModule } from "@official/config/oauth/oauth.module";
import { PhysicalResourcesManagementComponent } from "./pages/physical-resources-management/physical-resources-management.component";
import { ScheduleActivitiesComponent } from "@official/pages/schedule-activities/schedule-activities.component";
import { IncidentParametersManagementComponent } from "./pages/incident-parameters-management/incident-parameters-management.component";
import { ManagementGroundResourceComponent } from "./pages/management-ground-resource/management-ground-resource.component";
import { ManageIncidentsComponent } from "./pages/manage-incidents/manage-incidents.component";
import { IncidentConfigurationComponent } from "@official/pages/incident-configuration/incident-configuration.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    NotFoundComponent,
    UserManagementComponent,
    RolesManagementComponent,
    IncidentParametersManagementComponent,
    ManagementGroundResourceComponent,
    PhysicalResourcesManagementComponent,
    ScheduleActivitiesComponent,
    ManageIncidentsComponent,
    IncidentConfigurationComponent,
  ],
  imports: [
    OAuthConfigModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    SdkModule,
    UiCoreModule,
  ],
  providers: [
    LoginAuthService,
    LocalStorageManagementService,
    MenuService,
    ActionService,
    ModalActionHandler,
    MapActionHandler,
    PanelActionHandler,
    RouterActionHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorOfficial,
      multi: true,
    },
    {
      provide: "environment",
      useValue: environment,
    },
    LoadingOverlayService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
