import { Component, Input, OnInit } from '@angular/core';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
  IClassesAttributes,
} from '@types-custom/models/ui/button-model';
import { GeoApiService } from '@shared/services/geo-api-service/geo-api.service';
import { GeoAPITypeEnum } from '@shared/models/geo-api-type.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { SituationTypeFilterEnum,
  SituationPriorityFilterEnum,
} from '@shared/models/situations.model';
// import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { GeometryTypeEnum, MarkerModelBase } from '@types-custom/models/business/marker.model';
import {forEach} from "lodash";

@Component({
  selector: "sit-incidents-field-resources-filter",
  templateUrl: "./incidents-field-resources-filter.component.html",
  styleUrls: ["./incidents-field-resources-filter.component.scss"],
})
export class IncidentsFieldResourcesFilterComponent {
  @Input() properties: any;
  situationTypeFilterEnum = SituationTypeFilterEnum;
  situationPriorityFilterEnum = SituationPriorityFilterEnum;

  incidentTypeControl = new FormControl('');
  resourceControl = new FormControl('');
  locationNameControl = new FormControl('');

  locations: any[] = [];

  ResourcesGroupTypeList : any[] = [
    {
      name : "Todos",
      value : -1
    },
    {
      name : "Grupo Guía",
      value : 20
    },
    {
      name : "Ciempiés",
      value : 19
    },
    {
      name : "Al Colegio En Bici",
      value : 17
    },
    {
      name : "Agentes Civiles",
      value : 18
    }
  ]

  incidentTypeList: any[] = [
    {
      name: 'Todos',
      value: -1
    },
    {
      name: SituationTypeFilterEnum.Incidente_en_via.replace('"','').replace('"',''),
      value: 5
    },
    {
      name: SituationTypeFilterEnum.Manifestacion.replace('"','').replace('"',''),
      value: 4
    },
    {
      name: SituationTypeFilterEnum.Semaforización.replace('"','').replace('"',''),
      value: 3
    },
    {
      name: SituationTypeFilterEnum.Siniestro.replace('"','').replace('"',''),
      value: 1
    },
    {
      name: SituationTypeFilterEnum.Transporte_masivo.replace('"','').replace('"',''),
      value: 173
    },
    {
      name: SituationTypeFilterEnum.Varado.replace('"','').replace('"',''),
      value: 2
    }
  ];


  resetFilter(){
    this.incidentTypeControl= new FormControl('');
    this.resourceControl = new FormControl('');
    this.locationNameControl = new FormControl('');
  }

  defaultDateStart = (new Date(new Date().setHours(0,0,0,0))).toISOString();
  defaultDateEnd = (new Date().toISOString());

  Icon=Icon;
  incidentForm: FormGroup;

  constructor(
      private geoApiService: GeoApiService,
  ) {}

  ngOnInit() {
    this.loadDataLocations();
  }

  get endDate() {
    return this.incidentForm.get('dateEnd');
  }

  displayFn(alert: any): string {
    return alert && alert.LocNombre ? alert.LocNombre : '';
  }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const IncidentsFieldResourcesLayer = response;

    IncidentsFieldResourcesLayer.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        className : (mark as any).className,

      };
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer,
      filter: undefined,
      source: points.dataFeatures
    });

    document.querySelector<HTMLElement>('#closeFilter').click();
    setTimeout(() => {
      document.querySelector<HTMLElement>('#closeFilter').parentElement.classList.add('applied-filter');
      document.querySelector('.marker-active-situations-layer')?.classList.remove('marker-active-situations-layer');
    }, 10);
  }

  applyFilter() {
    const initialFilterValue: {[key: string]: any} = {};
    const url = this.generateURL();
    //console.log(url)
    this.geoApiService.getIncidentsFieldResources(GeoAPITypeEnum.IncidentsHeatMap, true, url)
        .subscribe({
          next: this.reloadData.bind(this),
        });
  }

  private generateURL() {
    let url = this.defaultDateStart ? `incidentBeginDate=${new Date(this.defaultDateStart).toISOString().replace('Z','')}` : '';
    url += this.defaultDateEnd? `&incidentEndDate=${new Date(this.defaultDateEnd).toISOString().replace('Z','')}` : ''
    // @ts-ignore
    url += this.locationNameControl.value ? `&incidentLocationName=${this.locationNameControl.value['LocNombre']}` : '';
    if(this.incidentTypeControl.value) {
      // @ts-ignore
      let data : any[] = this.incidentTypeControl.value
      if(data.some((e : any) => e === -1)) {
        data = data.filter((e : any) =>  {return e !== -1});
        this.incidentTypeList.forEach((e : any) => {
          data.push(e.value)
        })
      }
      data.forEach((item : any) => {
        url += `&incidentClassIds=${item}`
      } )
    }
    if(this.resourceControl.value) {
      // @ts-ignore
      let data : any[] = this.resourceControl.value
      if(data.some((e : any) => e === -1)) {
        data = data.filter((e : any) => {return e !== -1});
        this.ResourcesGroupTypeList.forEach((e : any) => {
          data.push(e.value)
        })
      }
      data.forEach((item : any) => {
        url += `&resourceGroups=${item}`
      } )
    }
    url += '&resourceHours=1'

    return url;

  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    let selectedItems = selectedItemsControl?.value || [];

    if (event.target.checked) {
      if(item.value === -1){
        selectedItems = [-1]
      }
      else {
        selectedItems = selectedItems.filter((e: any) => e != -1);
        selectedItems.push(item.value);
      }
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }

  loadDataLocations(){
    this.locations = [
      {
        "LocNombre": "ANTONIO NARIÑO"
      },
      {
        "LocNombre": "BARRIOS UNIDOS"
      },
      {
        "LocNombre": "BOSA"
      },
      {
        "LocNombre": "CANDELARIA"
      },
      {
        "LocNombre": "CHAPINERO"
      },
      {
        "LocNombre": "CIUDAD BOLÍVAR"
      },
      {
        "LocNombre": "ENGATIVÁ"
      },
      {
        "LocNombre": "FONTIBÓN"
      },
      {
        "LocNombre": "KENNEDY"
      },
      {
        "LocNombre": "LOS MÁRTIRES"
      },
      {
        "LocNombre": "PUENTE ARANDA"
      },
      {
        "LocNombre": "RAFAEL URIBE URIBE"
      },
      {
        "LocNombre": "SAN CRISTÓBAL"
      },
      {
        "LocNombre": "SANTA FE"
      },
      {
        "LocNombre": "SUBA"
      },
      {
        "LocNombre": "SUMAPAZ"
      },
      {
        "LocNombre": "TEUSAQUILLO"
      },
      {
        "LocNombre": "TUNJUELITO"
      },
      {
        "LocNombre": "USAQUÉN"
      },

      {
        "LocNombre": "USME"
      }
    ];
  }
}
