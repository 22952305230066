import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ManageIncidentsCoordinatorService } from "@official/services/data-grid-service/manage-incidents-coordinator/manage-incidents-coordinator.service";
import { ButtonClassesEnum, IButtonModel } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { ModalService } from "@ui-core/services/modal/modal.service";

@Component({
  selector: "sit-modal-view-assigned-resources",
  templateUrl: "./modal-view-assigned-resources.component.html",
  styleUrls: ["./modal-view-assigned-resources.component.scss"],
})
export class ModalViewAssignedResourcesComponent implements OnInit {
  @Input() properties: any | undefined;
  @Input() id: any | undefined;
  Icon = Icon;

  data!: any;
  humanResources!: any;

  displayedColumns: string[] = ['names', 'phone'];

  dataSource!: MatTableDataSource<any>;

  buttonCancelAttributes: IButtonModel = {
    label: 'CERRAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };

  constructor(
    public manageIncidentsCoordinator: ManageIncidentsCoordinatorService,
    private modalService: ModalService
  ){

  }

  ngOnInit(): void {
    this.humanResourceById();
  }


  humanResourceById(){
    this.manageIncidentsCoordinator.getHumanResourceById(this.id).subscribe((res:any)=>{
      this.humanResources = res;
      this.dataSource = new MatTableDataSource(this.humanResources);
    })
  }

  onCancel(): void {
    this.modalService.cleanModals$.next([]);
  }

}
