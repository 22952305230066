import {Component, Input, OnInit} from "@angular/core";
import {Icon} from "@types-custom/models/ui/icon-model";
import {IModalFieldResourcesModel} from "@types-custom/models/ui/modal-field-resources-model";
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";

@Component({
  selector: "sit-modal-field-resources",
  templateUrl: "./modal-field-resources.component.html",
  styleUrls: ["./modal-field-resources.component.scss"],
})
export class ModalFieldResourcesComponent implements OnInit {
  @Input() properties: IModalFieldResourcesModel | undefined;
  Icon = Icon;

  public dataPercentage : any
  public dataLocality : any

  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;

  public listResources : any[] = []

  ngOnInit() {
    this.properties.resourcesDataSource.getDataOfResource(this.properties.id).subscribe((res : any) => {
      this.dataPercentage = {
        title: res.executionPercentage,
        subtitle : 'Ejecución'
      };
      this.dataLocality = {
        title : this.properties.localityName,
        subtitle : 'Localidad'
      }
      this.listResources = res.resources
    })
  }
}
