import { Component, Input, OnInit } from '@angular/core';
import { IModalCo2Model } from '@types-custom/models/ui/modal-co2-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';

/*
 ** Modal Co2
 ** Render a modal that displays information about co2.
 */

@Component({
/*   selector: 'citizen-modal-co2', */
  templateUrl: './modal-co2.component.html',
  styleUrls: ['./modal-co2.component.scss'],
})
export class ModalCo2Component implements OnInit {
  @Input() properties: IModalCo2Model | undefined;
  Icon = Icon;
  dataGraphicList: ITimeLineGraphicModel;

  colorScale: any = [{}];
  dataSource: any = {};

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
    this.properties.sensorDataSource
      .getLastMeasurement(this.properties.id)
      .subscribe((res) => {
        this.dataSource = res;
        this.graphicCo2(this.dataSource);
      });
  }

  graphicCo2(dataSource: any): void {
    const dataGraphic = dataSource.sensorizedPointsAvg.map((element: any) => {
      return {
        time: element.hour,
        value: element.avgCo2,
      };
    });
    this.dataGraphicList = {
      data: dataGraphic,
      label: 'ppm',
      labelX: 'Horas',
      labelY: 'Nivel Co2  ppm',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#FF9901' }];
  }

  graphicPressure(dataSource: any): void {
    const dataGraphic = dataSource.sensorizedPointsAvg.map((element: any) => {
      return {
        time: element.hour,
        value: element.avgPressure,
      };
    });
    this.dataGraphicList = {
      data: dataGraphic,
      label: 'hPa',
      labelX: 'Horas',
      labelY: 'Presión hPa',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#00c479' }];
  }

  graphicTemperature(dataSource: any): void {
    const dataGraphic = dataSource.sensorizedPointsAvg.map((element: any) => {
      return {
        time: element.hour,
        value: element.avgTemperature,
      };
    });
    this.dataGraphicList = {
      data: dataGraphic,
      label: '°C',
      labelX: 'Horas',
      labelY: 'Temperatura °C',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#FF3232' }];
  }

  graphicHumidity(dataSource: any): void {
    const dataGraphic = dataSource.sensorizedPointsAvg.map((element: any) => {
      return {
        time: element.hour,
        value: element.avgHumidity,
      };
    });
    this.dataGraphicList = {
      data: dataGraphic,
      label: '%',
      labelX: 'Horas',
      labelY: 'Humedad %',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#2461B6' }];
  }
}
