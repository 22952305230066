import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  tap,
} from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IDynamicSideMenuModel } from '@types-custom/models/ui/dynamic-side-menu';
import {
  IMapEvent,
  IMapViewerPropsModel,
  MapEventTypeEnum,
} from '@types-custom/models/ui/map-viewer-model';
import { IMenuEvent } from '@types-custom/models/ui/menu.model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { MapboxDataService } from '@sdk/services/map-box-data/mapbox-data.service';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import { MapboxLayoutService } from '@sdk/services/map-box-layout/mapbox-layout.service';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { MapMouseEvent, Marker } from 'mapbox-gl';
import {
  GeometryTypeEnum,
  IGeometryModel,
  ILayerModel,
  isILayerModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IRightPanelData,
  RightPanelEvents,
} from '@types-custom/models/ui/right-panel-model';
import { ScrollHandlingEventsEnum } from '@types-custom/models/ui/scroll-model';
import { ModalCameraComponent } from '../modal-camera/modal-camera.component';
import { ModalNoInfoComponent } from '../modal-no-info/modal-no-info.component';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { GeoLocationService } from '@shared/services/geo-location/geo-location.service';
import { IMapFilterModel } from '@types-custom/models/ui/map-filter.model';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import { GeoAPITypeEnum } from '@shared/models/geo-api-type.model';

/*
 ** Map Viewer
 ** Render a side menu, a dynamic filter and a map with markers if it has data
 */

@Component({
  selector: 'map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss'],
})
export class MapViewerComponent implements OnInit, AfterViewInit {
  @Output() mapClick = new EventEmitter<IMapEvent>();
  @Input() props: IMapViewerPropsModel<any, any> = {
    containerId: 'map',
    // Mappers
    layersDataMap: {},
    menuRightPanelMap: {},
    markerModalMap: {},
    geoModalMap: {},
    mapEventsMap: {},
    // DS
    dataSource: {
      getAPIList: (listType: any): Observable<any[]> => of([]),
    },
    // LeftMenu
    leftMenuEvents$: new Subject<any | undefined>(),
    rightMenuEvents$: new Subject<any | undefined>(),
    panelContentDispatcher$: new BehaviorSubject<any>(undefined),
    iconInfo: [],
    logoText: undefined,
    // Conventions Component
    mapConventionsProps: undefined,
    // Footer
    currentLayer$: new BehaviorSubject<any | undefined>(undefined),
    footerDynamicEvents$: new Subject<any | undefined>(),
    footerStaticEvents$: new Subject<any | undefined>(),
    footerModel: [],
    refreshDispatcher: undefined,
    mapFilter: undefined,
    mapIcons: [],
    markerRightPanelMap: {},
    autoUpdatedLayers: [],
  };
  isvisible = true;
  isVisibleIcon = false;
  //Props
  unsubscribier$ = new Subject<void>();
  currentLayers = new Array<any>();

  @ViewChild('mapboxContainer') mapboxContainer: ElementRef<HTMLElement>;

  // Markers
  markersDispatcher$ = new BehaviorSubject<any>(undefined);
  markersActivePanel$ = new BehaviorSubject<any>(undefined);
  markersDispatcherPanel$ = new BehaviorSubject<any>(undefined);
  linesDispatcher$ = new BehaviorSubject<any>(undefined);
  private focusMarkerSubject = new BehaviorSubject<MarkerModelBase | undefined>(
    undefined
  );
  focusMarker$ = this.focusMarkerSubject.asObservable();

  markerActive$ = new BehaviorSubject<Marker>(new Marker());
  lineActive$ = new BehaviorSubject<MarkerModelBase | undefined>(undefined);
  previousMarkerActive = new Marker();
  // Right menu
  toggleDynamicMenu$ = new BehaviorSubject<boolean>(true);
  dynamicMenuModel = new BehaviorSubject<IDynamicSideMenuModel>({});
  mapFilterDispatcher$ = new BehaviorSubject<IMapFilterModel>({});
  modalDispatcher$ = new BehaviorSubject<IMapFilterModel>({});
  mapFilterModel = new BehaviorSubject<any>([]);

  Icon = Icon;

  private isClearingFilters = false;

  readonly actionLayer = 'action';

  constructor(
    private mapboxInstanceService: MapboxInstanceService,
    private mapboxDataService: MapboxDataService,
    private mapboxLayoutService: MapboxLayoutService,
    private modalService: ModalService,
    private drawerPanelService: DrawerPanelService,
    private geoLocationService: GeoLocationService,
    private incidentsService: IncidentsService
  ) { }

  ngOnInit(): void {
    this.initWatchers();
    this.panelWatchers();
    this.autoRefreshCurrentLayers();
    this.incidentsService.incidentFilterNotifier$?.subscribe((data) => {
      this.handleDataLoaded({
        answers: [data],
        layer: 'Situations',
        isCallingRightMenu: true,
      });
      const points = {
        type: GeometryTypeEnum.Point,
        markers: [] as unknown as MarkerModelBase[],
        dataFeatures: [] as Array<any>,
      };

      const markers = data as MarkerModelBase[];

      markers.forEach((mark) => {
        const feature = {
          properties: (mark as any).classProperties,
          geometry: {
            type: (mark as any).geometry.type,
            coordinates: (mark as any).geometry.coordinates,
          },
          type: 'Feature',
        };
        points.markers.push(mark);
        points.dataFeatures.push(feature as never);
      });
      this.mapboxDataService.setLayerSource(points.dataFeatures, 'Situations');
    });
  }

  private initWatchers(): void {
    this.mapboxDataService.mapClickEmitter.subscribe(
      this.handleClickEvent.bind(this)
    );
    this.props.refreshDispatcher?.subscribe(
      this.handleRefreshDispatcher.bind(this)
    );
    this.props.footerDynamicEvents$.subscribe(
      this.handleFooterEvents.bind(this)
    );
    this.props.footerStaticEvents$.subscribe(
      this.handleFooterEvents.bind(this)
    );
    this.props.leftMenuEvents$.subscribe(this.handleLeftMenuEvents.bind(this));
    this.props.rightMenuEvents$?.subscribe(this.handleRightDispatcherEvents.bind(this));
    this.props.leftMenuEvents$.subscribe(this.cleanModals.bind(this));
    this.props.rightMenuEvents$?.subscribe(this.cleanModals.bind(this));
    this.props.clickInteractionDispatcher?.subscribe(
      this.handleMapClickInteraction.bind(this)
    );

    this.markersDispatcher$
      .pipe(
        filter((evt) => !!evt),
        tap((marker: any) => {
          this.markerActive$.next(marker.markerInstance); //TODO: formalize object models
        })
      )
      .subscribe(this.handleMarkerEvents.bind(this));

    this.markersDispatcherPanel$
      .pipe(
        filter((evt) => !!evt),
        tap(this.markerDispatcherStep.bind(this))
      )
      .subscribe(this.handleMarkerEvents.bind(this));

    this.markersActivePanel$
      .pipe(filter((evt) => !!evt))
      .subscribe(this.handleMarkerEvents.bind(this));

    this.focusMarker$
      .pipe(filter((marker) => !!marker))
      .subscribe(this.handleFocusMarker.bind(this));

    this.linesDispatcher$
      .pipe(filter((marker) => !!marker))
      .subscribe(this.handleLineClick.bind(this));

    this.markerActive$.subscribe(this.handleActiveMarker.bind(this));
    this.lineActive$.subscribe(this.handleActiveLine.bind(this));
    this.mapClick.subscribe(this.handleMapClick.bind(this));
    this.mapFilterDispatcher$.subscribe(this.handleMapFilter.bind(this));
  }

  ngAfterViewInit(): void {
    this.initializeMap();
    this.unsetTabIndexOnMapboxElements();
  }

  initializeMap(): void {
    this.mapboxInstanceService.initializeMap(
      this.props.containerId,
      this.props.mapIcons
    );
  }

  private markerDispatcherStep(marker: any): void {
    if (marker.markerInstance) {
      this.markerActive$.next(marker.markerInstance); //TODO: formalize object models
      this.focusMarkerSubject.next(marker);
      return;
    }
    this.lineActive$.next(marker);
  }

  private handleFocusMarker(marker: MarkerModelBase | undefined): void {
    const geometry = marker?.geometry as IGeometryModel;
    if (geometry?.coordinates) {
      this.mapboxInstanceService.setFocus(
        geometry.coordinates[1],
        geometry.coordinates[0]
      );
      this.mapboxDataService.markPoint(geometry.coordinates[1], geometry.coordinates[0], marker.className);
      return;
    }
    const lat = marker?.lat || 0;
    const lng = marker?.lng || 0;
    this.mapboxInstanceService.setFocus(lat, lng);
    this.mapboxDataService.markPoint(lat, lng, marker.className);
  }

  private handleRefreshDispatcher(): void {
    if (!this.isClearingFilters) {
      this.refresh(true);
    }
  }

  private handleRightDispatcherEvents(
    event: IMenuEvent<any, IRendererModel> | undefined
  ): void {
    if (!event) return;
    this.props.clickInteractionDispatcher?.next(undefined);
    this.mapboxDataService.clearMap();
    // this.toggleDynamicMenu$.next(false);
    const { action } = event;
    this.props.currentLayer$.next(action);
    const dataElements = this.props.layersDataMap[action];
    const observables = dataElements.map((dataType: any) =>
      this.props.dataSource.getAPIList(dataType, false, false)
    );
    forkJoin(observables)
      .pipe(
        map((data: any) => {
          return {
            answers: data,
            layer: action,
            isCallingRightMenu: false,
          };
        })
      )
      .subscribe(this.handleDataLoaded.bind(this));
  }

  private handleLeftMenuEvents(
    event: IMenuEvent<any, IRendererModel> | undefined
  ): void {
    if (event) {
      if (this.props.clickInteractionDispatcher?.value?.includes('manage')) {
        this.props.clickInteractionDispatcher?.next(undefined);
        this.toggleDynamicMenu$.next(false);
        this.mapboxDataService.clearMap();
      }
      this.mapboxDataService.clearMap();
      this.clearFooterItems();
      setTimeout(() => {
        this.props.clickInteractionDispatcher?.next(undefined);
      }, 3000);
      this.mapFilterModel.next(undefined);
      this.dynamicMenuModel.next({});
      const { action } = event;
      this.props.currentLayer$.next(action);
      const dataElements = this.props.layersDataMap[action];
      const observables = dataElements.map((dataType: any) =>
        this.props.dataSource.getAPIList(dataType, false, false)
      );
      forkJoin(observables)
        .pipe(
          map((data: any) => {
            return {
              answers: data,
              layer: action,
              isCallingRightMenu: true,
            };
          })
        )
        .subscribe(this.handleDataLoaded.bind(this));
    }
  }

  private handleDataLoaded({
    answers,
    layer,
    isCallingRightMenu,
  }: {
    answers: any[][];
    layer: any;
    isCallingRightMenu: boolean;
  }): void {
    const mapFilter = this.props.mapFilter
      ? this.props.mapFilter[layer]
      : undefined;

      if (mapFilter) {
      this.displayMapFilter(mapFilter, answers, layer);
    }

    if (answers[0].length === 0) return;
    answers?.forEach((answer) => {
      this.handleDataToDisplay(answer, layer);
    });

    if (isCallingRightMenu) {
      this.displayRightMenu(answers, layer, false);
    }
  }

  private handleDataToDisplay(
    markers: ILayerModel[] | MarkerModelBase[],
    layer: string
  ): void {


    if (isILayerModel(markers)) {
      this.addMultipleLayers(markers as ILayerModel[], layer);
      return;
    }
    this.addLayer(markers as MarkerModelBase[], layer);
  }

  private addMultipleLayers(markers: ILayerModel[], layer: string) {
    markers.forEach((e: ILayerModel) => {
      this.addLayer(e.marker, layer, e.visibility, e.geometryType);
    });
  }

  private addLayer(
    markers: MarkerModelBase[],
    layer: string,
    layerVisibility = true,
    geometryType?: GeometryTypeEnum
  ) {

    //this.mapboxDataService.clearMap()

    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const lines = {
      type: GeometryTypeEnum.LineString,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };

    markers.forEach((mark) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type: (mark.className === "AgentsNumberModel" || mark.className === "CranesNumberModel") ? GeometryTypeEnum.LineString : (mark as any).geometry.type,
          coordinates: (mark.className === "AgentsNumberModel" || mark.className === "CranesNumberModel") ? (mark as any).geometry.coordinates[0][0] : (mark as any).geometry.coordinates,
        },
        type: 'Feature',

      };

      if (mark.geometry.type === GeometryTypeEnum.Point) {
        points.markers.push(mark);

        points.dataFeatures.push(feature as never);
      }

      if (
        mark.geometry.type === GeometryTypeEnum.LineString ||
        mark.geometry.type === GeometryTypeEnum.MultiLineString
      ) {
        lines.markers.push(mark);
        lines.dataFeatures.push(feature as never);
      }
    });

    if (
      layer === 'Traffic' ||
      layer === 'TrafficSpeedRange' ||
      layer === 'TrafficConcordance' ||
      layer === 'TrafficConcordanceCorridors' ||
      layer === 'TrafficSpeedRangeCorridors' ||
      layer === 'BicyclePath' ||
      layer === 'CoosLine' ||
      layer === 'RoadRun' ||
      layer === 'RoadCorridors' ||
      layer === 'TrafficJamWaze' ||
      layer === 'Coiline' ||
      layer === 'ExodusAndReturnWaze' ||
      layer === 'WazeDataCorridors' ||
      layer === 'WazeDataSpeedRange' ||
      geometryType === GeometryTypeEnum.LineString ||
      geometryType === GeometryTypeEnum.MultiLineString
    ) {
      this.mapboxDataService.addLines(
        lines,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
      return;
    }


    if (geometryType === GeometryTypeEnum.HeatMap) {
      this.mapboxDataService.addHeatLayer(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
      return;
    }

    if (layer.includes('IncidentsHeatMap')) {
      this.mapboxDataService.addHeatWeightLayer(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
      return;
    }

    if (geometryType === GeometryTypeEnum.MultiPolygon) {

      this.mapboxDataService.addStatesLayer(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
      return;
    }

    if (layer === 'Accident') {
      this.mapboxDataService.addClusterLayer(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
    }

    if (layer.includes('AgentsCluster') || layer.includes('CranesCluster')) {
      this.mapboxDataService.addClusterLayer(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
    }

    if (layer === 'LastHour') {
      this.mapboxDataService.addClusterLayerChart(
        points,
        layer,
        this.linesDispatcher$,
        layerVisibility
      );
    }

    this.mapboxDataService.addPointLayer(
      points,
      layer,
      this.linesDispatcher$,
      layerVisibility
    );
  }

  private displayRightMenu(
    data: any,
    layerName: string,
    displayFromMarker: boolean
  ): void {
    if (this.props.currentLayer$.value) {
      const rightPanel =
        this.props.menuRightPanelMap[this.props.currentLayer$.value];
      if (rightPanel?.showPanelFromMarker && !displayFromMarker) return;

      const panelData = rightPanel?.mapperFn?.(data) ?? undefined;
      if (panelData) {
        panelData.cards.forEach((card: any, index: number) => {
          if (
            layerName === 'TrafficSpeedRange' ||
            layerName === 'TrafficConcordance' ||
            layerName === 'RoadCorridors' ||
            layerName === 'TrafficJamWaze'
          ) {
            card.cardMapLink = {
              instanceDispatcher: this.markerActive$,
              markerDispatcher: this.markersActivePanel$,
              markerModel: data[0]?.[index],
            };
          } else {
            card.cardMapLink = {
              instanceDispatcher: this.markerActive$,
              markerDispatcher: this.markersDispatcherPanel$,
              markerModel: data[0]?.[index],
            };
          }
        });
        panelData.panelActionsDispatcher
          ?.pipe(filter((value) => !!value))
          .subscribe(this.handleRightPanelEvents.bind(this));
      }

      const viewPanel = this.statusDataFilter(panelData);
      if (rightPanel) {
        this.dynamicMenuModel.next({
          title: rightPanel.title,
          subtitle: rightPanel.subtitle,
          titleIcon: rightPanel.titleIcon,
          alt: rightPanel.alt,
          toggleDispatcher$: this.toggleDynamicMenu$,
          content: {
            component: rightPanel.component,
            data: panelData,
          },
        });
        this.toggleDynamicMenu$.next(viewPanel);
      }
    }
  }

  private handleClickEvent(data: MapMouseEvent): void {
    if (this.props.clickInteractionDispatcher?.value) {
      const evt: IMapEvent = {
        type: MapEventTypeEnum.CLICK,
        event: data,
        lat: data.lngLat.lat,
        lng: data.lngLat.lng,
      };
      this.mapClick.next(evt);
    }
  }

  private handleMapClick(data: IMapEvent): void {
    this.geoLocationService.pointerData$.next(data);

    const point = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataCoordinates: [] as Array<any>,
      dataFeatures: [] as Array<any>,
    };

    const feature = {
      geometry: {
        type: 'Point',
        coordinates: [data.lng, data.lat],
      },
      type: 'Feature',
    };

    point.dataFeatures.push(feature as never);
    this.mapboxDataService.clearLayer(this.actionLayer);
    this.mapboxDataService.addPointLayer(point, this.actionLayer, undefined);
    this.mapboxInstanceService.setFocus(data.lat, data.lng);
  }

  private handleMapClickInteraction(isClickActionsEnabled: MapEventTypeEnum): void {
    const evtData = this.props?.mapEventsMap[isClickActionsEnabled];
    this.handleDynamicSideMenu(evtData)
  }

  private handleDynamicSideMenu(model: IDynamicSideMenuModel, data?: any) {
    if (model?.content?.component) {
      const sideMenuModel: IDynamicSideMenuModel = {
        ...model,
        toggleDispatcher$: this.toggleDynamicMenu$,
        content: {
          component: model.content?.component,
          data: {
            ...(model.content?.data ?? {}),
            data: this.props.panelContentDispatcher$,
            properties: {
              clickEventsDispatcher: this.mapClick,
              clickInteractionDispatcher: this.props.clickInteractionDispatcher,
            },
          },
        },
      };
      this.props.currentLayer$.next(undefined);
      this.mapFilterModel.next(undefined);
      this.mapboxDataService.clearMap();
      this.clearFooterItems();
      this.dynamicMenuModel.next(sideMenuModel);
      this.toggleDynamicMenu$.next(true);
      // this.props.panelContentDispatcher$.next(undefined);
    } else {
      if (model?.content?.data.layerDispatcher$) {
        model?.content?.data.layerDispatcher$.next(model?.content?.data.event);
      }
    }
  }

  private handleFooterEvents(event: IMenuEvent): void {
    if (!event) return;
    if (this.props.clickInteractionDispatcher?.value?.includes('manage')) {
      this.props.clickInteractionDispatcher?.next(undefined);
      this.toggleDynamicMenu$.next(false);
      this.mapboxDataService.clearMap();
    }

    const { action } = event;
    const itemIndex = this.currentLayers.findIndex(
      (layer) => layer === action
    );

    if (itemIndex > -1) {
      this.mapboxDataService.clearLayer(action);
      this.currentLayers.splice(itemIndex, 1);
    } else {
      this.currentLayers.push(action);
      const dataElements = this.props.layersDataMap[action];
      const observables = dataElements?.map((dataType: any) =>
        this.props.dataSource.getAPIList(dataType, true, true)
      );
      forkJoin(observables)
        .pipe(
          map((data: any) => ({
            answers: data,
            layer: action,
            isCallingRightMenu: false,
          }))
        )
        .subscribe(this.handleDataLoaded.bind(this));
    }
  }

  private handleMarkerEvents(data: (MarkerModelBase | { className: string, classProperties?: any })): void {

    const geoModalMap = this.props.geoModalMap[data.className];
    // console.log('this is the called event?', data, geoModalMap)
    if (geoModalMap) {

      const toRender: IRendererModel = {
        component: undefined,
        data: { modalDispatcher: this.modalDispatcher$, ...geoModalMap(data) },
      };

      if (typeof this.props.markerModalMap[data.className] === 'object') {
        toRender.component = this.props.markerModalMap[data.className][data.className](data)
      } else {
        toRender.component = this.props.markerModalMap[data.className];
      }

      const isThisModalShowing = this.verifyRepeatedModal(toRender);
      if (!isThisModalShowing) {
        this.modalService.addModal(toRender);
      }
    }

    if (this.props.markerRightPanelMap[data.className]) {
      this.displayRightMenu(data, this.props.currentLayer$.value, true);
    }
  }

  private verifyRepeatedModal(toRender: IRendererModel): boolean {
    const isShowingModal = !!this.modalService.currentModals.find((modal) => {
      return (
        JSON.stringify(modal.render.data.properties.title) ===
        JSON.stringify(toRender.data.properties.title)
      );
    });
    return isShowingModal;
  }

  private handleLineClick(marker: MarkerModelBase): void {
    this.handleMarkerEvents(marker);
  }

  private handleActiveMarker(marker: Marker): void {
    const previousMarkerActiveHtml = this.previousMarkerActive.getElement();
    previousMarkerActiveHtml.classList.remove('marker-active');

    const markerWrapperHtml = marker.getElement();
    if (!markerWrapperHtml.className.includes('marker-active')) {
      markerWrapperHtml.classList.add('marker-active');
      this.previousMarkerActive = marker;
    }
  }

  private handleActiveLine(data: any): void {
    const previousMarkerActiveHtml = this.previousMarkerActive?.getElement();
    if (previousMarkerActiveHtml) {
      previousMarkerActiveHtml.classList.remove('marker-active');
    }
    this.focusMarkerSubject.next(data);
  }

  private handleRightPanelEvents(event: RightPanelEvents): void {
    if (event === ScrollHandlingEventsEnum.AT_BOTTOM) {
      this.refresh(false);
    }
  }

  refresh(isEmptyingCache: boolean): void {
    this.mapboxDataService.clearMap();
    const layers = [this.props.currentLayer$.value, ...this.currentLayers];
    this.loadData(layers, isEmptyingCache);
  }

  private loadData(layers: string[], isEmptyingCache: boolean): void {
    const observables: Observable<any>[] = [];
    const actions: string[] = [];
    layers.forEach((layer) => {
      const obs = this.props.layersDataMap[layer];
      obs?.forEach((ob: any) => {
        observables.push(
          this.props.dataSource.getAPIList(ob, isEmptyingCache, false)
        );
        actions.push(layer);
      });
    });

    if (observables.length) {
      forkJoin(observables).subscribe((responses) => {
        responses.forEach((response, index) => {
          const data = {
            answers: [response],
            layer: actions[index],
            isCallingRightMenu: index === 0,
          };

          this.handleDataLoaded(data);
        });
      });
    }
  }

  private clearFooterItems(): void {
    this.currentLayers = [];
    this.props?.footerModel.forEach((footerItem) => {
      footerItem?.items?.forEach((itemButton) => {
        itemButton.isSelected = false;
      });
    });
  }

  public cleanFiltersValue() {
    this.isClearingFilters = true;
    this.dynamicMenuModel.next({});
    // TODO: Improve, too deep
    // this.props?.dynamicFiltersModel?.sdkDynamicFiltersService.cleanFilters();
    this.props?.dynamicFiltersModel?.filtersDispatcher.next({});
    this.isClearingFilters = false;
  }

  cleanModals() {
    this.modalService.cleanModals$.next([]);
  }

  public async toggleSideMenu(isSideMenuVisible: boolean) {
    if (this.props.isSideMenuVisible instanceof BehaviorSubject) {
      this.props.isSideMenuVisible.next(isSideMenuVisible);
    }
  }

  toggleFilterIncident() {
    this.isvisible = !this.isvisible;
    if (this.isvisible) {
      (<HTMLStyleElement>(
        document.querySelector('.filter-incident')
      )).style.display = 'block';
    } else {
      (<HTMLStyleElement>(
        document.querySelector('.filter-incident')
      )).style.display = 'none';
    }
  }

  toggleFilter(): void {
    if (this.props?.dynamicFiltersModel?.isActiveFilter) {
      const {
        props: {
          dynamicFiltersModel: { isActiveFilter },
        },
      } = this;
      const isVisible = isActiveFilter.value;
      isActiveFilter.next(!isVisible);
    }
  }

  private statusDataFilter(data: IRightPanelData<any> | undefined): boolean {
    if (data?.cards.length == 0) {
      this.emptySearchFilter();
      return false;
    }
    return true;
  }

  private emptySearchFilter(): void {
    const modal: IRendererModel = {
      component: ModalNoInfoComponent,
    };
    this.modalService.addModal(modal);
  }

  private panelWatchers(): void {
    const filter$ =
      this.props?.dynamicFiltersModel?.isActiveFilter?.asObservable();
    filter$?.subscribe(() => {
      this.drawerPanelService.showFilterPanel(true);
    });

    const dynamicMenu$ = this.dynamicMenuModel.asObservable();
    dynamicMenu$.subscribe(() => {
      this.drawerPanelService.showFilterPanel(false);
    });

    this.drawerPanelService.showFilterPanel$.subscribe(
      this.showFilterPanelDrawer.bind(this)
    );
  }

  private showFilterPanelDrawer(showPanel: boolean): void {
    if (showPanel) {
      this.toggleDynamicMenu$.next(false);
    } else {
      if (this.props?.dynamicFiltersModel?.isActiveFilter) {
        const {
          props: {
            dynamicFiltersModel: { isActiveFilter },
          },
        } = this;
        isActiveFilter.next(false);
      }
    }
  }

  private displayMapFilter(
    mapFilter: any,
    markers: any[],
    layer: string
  ): void {
    const mapFilterModel: any[] = [];
    mapFilter.forEach((element: any) => {
      const filterModel = {
        ...element,
        content: {
          component: element.content?.component,
          data: {
            properties: {
              mapFilterDispatcher$: this.mapFilterDispatcher$,
              data: markers,
              layer: layer,
            },
          },
        },
      };
      mapFilterModel.push(filterModel);
    });
    this.mapFilterModel.next(mapFilterModel);
  }

  private handleMapFilter(mapFilterModel: IMapFilterModel): void {
    if (mapFilterModel.source) { // No todos los filtros cargan source, es esta opción o dejarla al final para que no moleste cuando traigo los campos :filter
      this.handleDataLoaded({
        answers: [mapFilterModel.source],
        layer: mapFilterModel.layer,
        isCallingRightMenu: true,
      });
    }

    let marker = document.getElementsByClassName('marker-active mapboxgl-marker mapboxgl-marker-anchor-center' || 'marker-active');
    if (marker.length > 0) {
      marker[0].remove();
    }

    if (mapFilterModel.source) {
      this.mapboxDataService.setLayerSource(
        mapFilterModel.source,
        mapFilterModel.layer
      );
    }

    if (mapFilterModel.filter) {
      this.mapboxDataService.setFilter(
        mapFilterModel.layer
          ? mapFilterModel.layer
          : this.props.currentLayer$.value,
        mapFilterModel.filter
      );
    }

    if (mapFilterModel.visibility !== undefined) {
      this.mapboxDataService.setLayerVisibility(
        mapFilterModel.layer,
        mapFilterModel.visibility
      );
    }
  }

  autoRefreshCurrentLayers() {
    const timeToUpdate = 300000;
    setInterval(() => {
      const layersToUpdate = [
        this.props.currentLayer$.value,
        ...this.currentLayers,
      ];

      const allowRefresh = this.props.autoUpdatedLayers.some(
        (autoUpdatedLayer: string) => layersToUpdate.includes(autoUpdatedLayer)
      );

      if (allowRefresh) {
        this.refresh(true);
      }
    }, timeToUpdate);
  }

  unsetTabIndexOnMapboxElements() {
    setTimeout(() => {
      const unsetTabIndexRecursively = (
        childrenMapboxElements: HTMLCollection
      ) => {
        for (const childrenMapboxElement of childrenMapboxElements as unknown as HTMLElement[]) {
          childrenMapboxElement.tabIndex = -1;
          if (childrenMapboxElement.children.length > 0) {
            unsetTabIndexRecursively(childrenMapboxElement.children);
          }
        }
      };

      const childrenMapboxElements =
        this.mapboxContainer.nativeElement.children;
      unsetTabIndexRecursively(childrenMapboxElements);
    }, 2000);
  }
}
