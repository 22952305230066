import { Component, Input } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
** Page Title
** Render a page title
*/
@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() icon: Icon | undefined;
  @Input() name: string | undefined;
}
