<div class="grid-container text-white text-weight-600">
  <div class="card">
    <span class="text-size-16 text-res">ANCHO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.street_width_w"
      alt="icono indicador ancho de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.ancho }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">LONGITUD</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.street_lenght_w"
      alt="icono indicador longitud de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.largo }}</span>
  </div>

  <div class="card">
    <span class="text-size-16 text-res">SENTIDO DE CIRCULACIÓN</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.exodo_w"
      alt="icono indicador sentido de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ roadDirection || '' }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">CAPACIDAD DE LA VÍA</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.traffic_jams_w"
      alt="icono indicador capacidad de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.capacidad }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">TÍPO DE VÍA POT</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono indicador tipo de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.tipo }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">VELOCIDAD PROMEDIO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.road_speed_w"
      alt="icono indicador velocidad de tramo"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.velocidad }}</span>
  </div>
</div>
