<div
  *ngIf="dataList$ | async as dataList"
  class="main-box full-width full-height"
>
  <ng-container *ngFor="let item of dataList">
    <div
      class="
        bttn
        bg-blue-3
        full-width full-height
        d-flex
        align-items-center
        justify-center
      "
    >
      <a class="text-blue text-weight-600">{{ item.title }}</a>
    </div>

    <div
      class="
        list
        bttn
        bg-gray-2
        full-width full-height
        d-flex
        fd-col
        align-items-center
        justify-center
      "
      *ngFor="let bttn of item.data"
    >
      <a [href]="bttn.url" target="_blank" class="text-gray text-weight-600">{{
        bttn.service
      }}</a>
    </div>
  </ng-container>
</div>
