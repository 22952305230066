<div class="modal-success bg-color-background-3">
  <sit-icon
    [icon]="checkCircle"
    alt="icono confirmación exitosa"
    [sizeClass]="'icon-size-58 icon-sm-size-46'"
  ></sit-icon>

  <span class="text-white text-size-20 text-sm-size-14 text-weight-600 p-1">{{
    message
  }}</span>
</div>
