import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { IModalTrafficSpeedRangeModel } from '@types-custom/models/ui/modal-traffic-speed-range-model';

@Component({
  selector: 'modal-traffic-speedrange',
  templateUrl: './modal-traffic-speedrange.component.html',
  styleUrls: ['./modal-traffic-speedrange.component.scss'],
})
export class ModalTrafficSpeedrangeComponent implements OnInit, AfterViewInit {
  @Input() properties: IModalTrafficSpeedRangeModel | undefined;
  dataHistory: [] = [];
  dataHistoryTravel: [] = [];

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
  }

  ngAfterViewInit() {
    this.dataHistory = this.properties.travelTimes;
    this.dataHistoryTravel = this.properties.historyTravel;
  }
}
