<div class="modal-privacy-box modal">
  <div
    class="modal-header d-flex fd-col bg-color-primary-2 pl-16 justify-center"
  >
    <div class="image-title">
      <img
        src="assets/img/head-cgm.png"
        alt="imagen header cgm"
        class="w-auto full-height"
      />
    </div>
  </div>
  <div class="modal-body d-flex fd-col bg-white">
    <h2 class="text-color-primary-2 pl-16 text-size-30 text-weight-800">
      Términos y Condiciones
    </h2>
    <div
      class="text-info mb-16 pl-16 pr-24 work-sans-font text-color-background-2 scrollbar-modal"
    >
      <!-- <div [innerHtml]="htmlSnippet"></div> -->
      <p>
        El acceso al Portal implica una completa aceptación de la presente
        Política de Privacidad y Condiciones de Uso. En general la información
        alojada en el Portal es de carácter público, excepto en los casos en que
        exista información de carácter restringido la cual es claramente
        evidenciable ya que se requiere de un usuario y contraseña válido para
        tener acceso a ella. Los usuarios se comprometen a darle un uso
        apropiado al Portal y sus elementos, esto implica evitar acciones tales
        como, intento de accesos no autorizados ciertos sitios restringidos,
        modificación del sitio web, intentos de Dos (denegación de Servicios).
        Ningún contenido web del Portal se puede copiar para distribución
        comercial, ni se puede modificar ni publicar en otros sitios. El Portal
        puede contener enlaces y links con otras entidades sobre las cuales no
        tiene ningún tipo de control o jurisdicción, lo que implica que no
        existe ningún tipo de responsabilidad hacia el Municipio de Manizales
        por su uso. El Portal es un servicio gratuito a la ciudadanía, razón por
        la cual no garantiza ningún tipo de disponibilidad del servicio.
        Municipio de Manizales tiene derecho a negar, restringir o controlar el
        acceso a determinado usuario al Portal total o parcialmente en completa
        disposición, así como también modificar su contenido sin necesidad de
        previo aviso. Cualquier usuario que ingrese al Portal está aceptando
        intercambiar información a través de los Cookies que se generaron para
        estos sitios web. Lo anterior, se encuentra enmarcado en el Manual de
        Políticas y Procedimiento de protección de datos personales de la
        Alcaldía de Manizales V2.0. vigente, que se encuentra publicado en la
        página web de la Alcaldía de Manizales
        <a href="https://manizales.gov.co/" target="_black"
          >www.manizales.gov.co</a
        >
      </p>
    </div>
    <div class="modal-footer">
      <div class="d-flex fd-row pl-16">
        <img
          src="assets/img/logo-alcaldia.svg"
          alt="logo-alcaldia"
          class="full-width full-height"
        />
        <img
          src="assets/img/logo-cgm-azul.png"
          alt="logo-cgm-azul"
          class="full-width full-height pl-16 w-75"
        />
      </div>
    </div>
  </div>
</div>
