import { Component, Input, OnInit } from "@angular/core";
import { Subject } from 'rxjs'
import { INoveltyVehicle } from "@shared/models/novelty-vehicle.model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { NoveltyVehicleComponent } from "../novelty-vehicle-component/novelty-vehicle.component";
import { ModalNoInfoComponent } from "@ui-core/components/modal-no-info/modal-no-info.component";
import { VehicleNoveltyService } from "@official/services/data-grid-service/vehicle-novelty-service/vehicle-novelty.service";
import { SdkDynamicFiltersService } from "@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { ServiceModal } from "@official/pages/physical-resources-management/services/serviceModal.service";




@Component({
  selector: "sit-actions-novelty-vehicle",
  templateUrl: "./actions-novelty-vehicle.component.html",
  styleUrls: ["./actions-novelty-vehicle.component.scss"],
  providers: [
    VehicleNoveltyService,
    SdkDynamicFiltersService,
    ServiceModal
  ]
})
export class ActionsNoveltyVevhicleComponent implements OnInit {
  @Input() parameter: INoveltyVehicle;
  @Input() dispatchers: Subject<IGridTableCellDispatcher>[]; //primero editar, segundo gruo, tercer ver


  constructor(
    public modalService: ModalService,
    private service: NoveltyVehicleComponent
  ) { }


  ngOnInit(): void {
    // console.log('action dispatchers:', this.dispatchers)
    // console.log('parameter:', this.parameter)
  }

  get Icon() {
    return Icon;
  }

  handleEdit() {
    const element: IGridTableCellDispatcher = {
      index: 1,
      row: this.parameter,
    }
    this.dispatchers[0]//en primera posicion editar
      .next(element)
  }
  handleVer() {
    const element: IGridTableCellDispatcher = {
      index: 1,
      row: this.parameter,
    }
    this.dispatchers[2]//en tercer posicion ver
      .next(element)
  }
  handleGrupo() {
    const element: IGridTableCellDispatcher = {
      index: 1,
      row: this.parameter,
    }
    this.dispatchers[1]//en segunda posicion grupo
      .next(element)
  }
}
