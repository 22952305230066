import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IBarChartModel } from '@types-custom/models/ui/bar-chart-model';

/*
** Sit Bar Chart
** Render a bar chart
*/

@Component({
  selector: 'sit-bar-chart',
  templateUrl: './sit-bar-chart.component.html',
  styleUrls: ['./sit-bar-chart.component.css']
})
export class SitBarChartComponent implements OnInit, OnChanges {

  @Input() properties: IBarChartModel = {
    tid: 0,
    dataAll: [],
    type: 1
  }

  chartData = [
    {name:"Bueno", value:0, color: "#00C479", bcolor: "rgba(0,196,121,0.59)", bstyle: "solid", bwidth: "1px"} ,
    {name:"medio", value:0, color: "#FF9901", bcolor: "rgba(255,153,1,0.63)", bstyle: "solid", bwidth: "1px"} ,
    {name:"lento", value:0, color: "#FF3232", bcolor: "rgba(255,50,50,0.68)", bstyle: "solid", bwidth: "1px"}
  ];

  colorChartMap: { [key: string]: any } = {
    ['green']: () => {
      return {
        id: 1,
        name: "Bueno",
        color: "rgba(0,196,121,0.59)"
      }
    },
    ['yellow']: () => {
      return {
        id: 2,
        name: "medio",
        color: "rgba(255,153,1,0.63)"
      }
    },
    ['red']: () => {
      return {
        id: 3,
        name: "lento",
        color: "rgba(255,50,50,0.68)"
      }
    },
  }


  constructor() {
    //
   }

  ngOnInit(): void {
    this.FillData(this.properties.dataAll);
  }

  ngOnChanges(changes: SimpleChanges){

    if(changes.properties) {
      if (this.properties.type == 1){

        this.properties.dataSource?.gettraveltimesAvgTid(this.properties.tid.toString())
        .subscribe((list: any) => {

          this.FillData(list);

        });

      }
      else {
        if (this.properties.dataAll !== undefined) {
          const items = this.properties.dataAll.filter(( x: any )=> x.routeId == changes.properties.currentValue.tid);
          this.FillData(items);
        } else {
          this.FillData([]);
        }
      }

    }

  }

  FillData(list: any){
    let totTimes = 0;

    list.forEach((element: any) => {
      totTimes = totTimes + element.count;
    });


    this.chartData = list.map((item: any) => {
      let d: any = new Object();

      d = this.colorChartMap[item.levelOfService](item);

      d['value'] = ((100*item.count)/totTimes).toFixed(2);

      return d;

    });

    this.chartData.sort((a: any, b: any) => {
      if (a["id"] > b["id"]) {
        return 1;
      }
      if (a["id"] < b["id"]) {
        return -1;
      }

      return 0;
    });
  }

}
