<div class="d-flex align-items-center dropdown__selector">
  <ng-container *ngFor="let location of properties?.locationDataSource">
    <sit-dropdown
      [classesAttributes]="dropdownAttributes"
      [label]="location.label"
      [dataSource$]="location.dataSource$"
      [selection$]="location.selection$"
    >
    </sit-dropdown>
  </ng-container>
  <div class="flex-grow"></div>
  <sit-button
    (click)="search()"
    [buttonProperties]="buttonAttributes"
  ></sit-button>
  <sit-button
    (click)="cleanFiltersValue()"
    [buttonProperties]="cleanbuttonAttributes"
  ></sit-button>
</div>
