import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import { IIncidentParameter } from '@shared/models/incident-parameter.model';
import { ILocationOption, ILocationPoint } from '@types-custom/models/business/location.model';
import { AbstractSdkDynamicFilterDataSource, DynamicFilterTypeEnum, IDynamicFilterModel, ISdkDynamicFilterModel } from '@types-custom/models/ui/dynamic-filter';
import { AbstractPaginatorDataSource, IPaginatorModel } from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

@Injectable()
export class DatexCategoriesService implements
  AbstractPaginatorDataSource<any>
  , AbstractSdkDynamicFilterDataSource
// , AbstractPanelManagementDataSource
// , OnInit 
{
  constructor(
    public sdkDynamicFiltersService: SdkDynamicFiltersService,
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) { }
  options: { [key: string]: BehaviorSubject<ILocationOption[] | undefined> } = {
    type: new BehaviorSubject<ILocationOption[] | undefined>(undefined),
    state: new BehaviorSubject<ILocationOption[] | undefined>(undefined),
  };
  // states$ = new BehaviorSubject<{tipo: ILocationOption[], state: ILocationOption[]} | undefined>(undefined);

  filterList: ISdkDynamicFilterModel<any, any>[] = [
    {
      key: 'searchValue',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'Busqueda',
      value: new BehaviorSubject(undefined),
      searchChange: true
    },
  ]

  private dataSubject = new BehaviorSubject<IIncidentParameter[]>([]);
  data$: Observable<any> = this.dataSubject.asObservable();
  pageInfo: BehaviorSubject<IPaginatorModel> = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10, totalCount: 0 });


  selected$ = new BehaviorSubject<any | undefined>(undefined);
  selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;

  getUrlParams(pageInfo: IPaginatorModel): HttpParams {
    const { pageSize, data } = pageInfo;
    let params: { [key: string]: any }
      = { ...pageInfo, recordsPerPage: pageSize, searchValue: '' };
    if (data) {
      params = { ...params, ...data };
    }

    let paramsRet = new HttpParams();
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) { // Solo añadir parámetros que no son `undefined`
        paramsRet = paramsRet.append(key, params[key]);
      }
    });
    return paramsRet;
  }

  fetchPageData(
    pageInfo: IPaginatorModel
  ): Observable<IPaginatorModel> {
    if (this.selectedData.value) {
      pageInfo.data = this.selectedData.value; //TODO: review approach, copied from user-management
    }
    const BaseURL = `${this.environment.incidentUrl}/incident-categories-datex/pagination/data`;
    return this.httpClient
      .get<any>(BaseURL,
        { params: this.getUrlParams(pageInfo) })
      .pipe(
        tap((resp: any) => {
          const _pageInfo: IPaginatorModel = {
            page: resp.currentPage ?? 0,
            pageSize: pageInfo.pageSize ?? 0,
            totalCount: resp.totalRecords,
          };
          this.pageInfo.next(_pageInfo);
          this.dataSubject.next(resp.records ?? []);
        }),
      );
  }

  fetchCategories() {
    const URL = `${this.environment.incidentUrl}/incident-categories-datex/`;
    return this.httpClient.get<any>(URL);
  }

  fetchSubcategories(categoryId: number): Observable<any[]> {
    const url = `${this.environment.incidentUrl}/incident-categories-datex/${categoryId}/subcategories`;
    return this.httpClient.get<any>(url);
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
  }


  generateDynamicFiltersModel(): IDynamicFilterModel {
    const dynamicFiltersModel: IDynamicFilterModel = {
      sdkDynamicFiltersService: this.sdkDynamicFiltersService,
      dataSource: this,
      filtersDispatcher: this.selected$,
      showBttnConventions: false,
      hiddenButtonsFilters: true
    };
    return dynamicFiltersModel;
  }
  dataMap: { [key: number]: any } = {
  };

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const path = this.dataMap[index]?.();
    if (!path) return of({});
    return this.httpClient.get(path);
  }

  clearData(): void {
    this.filterList.forEach((filter) => {
      filter.value.next(undefined);
    });
  }
}
