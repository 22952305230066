import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { INoveltyVehicleModel } from '@types-custom/models/ui/modal-edit-novelty-vehicle';

@Component({
  selector: "sit-modal-detail-novelty-vehicle",
  templateUrl: "./modal-detail-novelty-vehicle.component.html",
  styleUrls: ["./modal-detail-novelty-vehicle.component.scss"],
})
export class ModalDetailNoveltyVehicleComponent implements OnInit {
  @Input() properties: INoveltyVehicleModel | undefined;
  // @Input() properties: INoveltyVehicleModel | undefined = {
  ngOnInit(): void {
  
  }
  //   icon: Icon.rudder_blue,
  //   id: 1,
  //   typeEventId: 101,
  //   typeEventName: "Tipo de evento",
  //   humanResourceId: 201,
  //   humanResourceNames: "Nombre del recurso humano",
  //   humanResourceLastNames: "Apellidos del recurso humano",
  //   physicalResourceId: 301,
  //   physicalResourceName: "Nombre del recurso físico",
  //   physicalResourceTypeName: "Tipo de recurso físico",
  //   stateId: 401,
  //   creationDate: "2023-12-04",
  //   comment: "Comentario sobre el evento",
  //   userName: "Nombre de usuario",
  // }; 
  Icon = Icon;
}