import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private loginAuthService: LoginAuthService,
    private localStorageManagementService: LocalStorageManagementService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token =
      this.localStorageManagementService.getUserLocalStorage();

    if (token) {
      return true;
    } else {
      this.loginAuthService.logout();
      return false;
    }
  }
}
