import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {IcoiModel } from '@types-custom/models/ui/modal-coi';
import { IcoiProp } from '@shared/models/coi.model';
/*
 ** Modal Coi
 ** Render a modal that displays information about Coi.
 */

@Component({
  selector: 'citizen-modal-coi', 
  templateUrl: './modal-coi.component.html',
  styleUrls: ['./modal-coi.component.scss'],
})
export class ModalCoiComponent {
  @Input() properties: IcoiModel | undefined
  Icon = Icon;
}