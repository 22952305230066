<divalt
  class="d-flex d-md-flex full-md-width h-100"
  [ngClass]="properties?.styleClass ?? ''"
>
  <div class="d-flex horizontal-menu-container flex-md-column full-md-width">
    <div class="menu-button d-md-none"
    [ngClass]="{'active': isPressed}"
    (click)="toggleColor()"
    >
      <sit-icon-button
        [alt]="properties?.buttonTooltip"
        (click)="toggleCollapse()"
        [icon]=" isPressed ?
                 properties?.menuIconSelect:properties?.menuIcon  "
        sizeClass="icon-size-30"
      ></sit-icon-button>
    </div>
    <section class="d-none d-md-block">
      <h6 class="text-weight-600 text-md-size-18 my-20">{{properties?.buttonTooltip}}</h6>
    </section>
    <div
      class="d-flex menu-items full-md-width flex-md-row flex-md-wrap container-chips"
      [ngClass]="{ collapsed: isCollapsed }"
    >
      <ng-container *ngIf="properties?.items as items">
        <ng-container *ngFor="let item of items">
          <ng-container
            *ngIf="
              item.id &&
              properties?.hiddenItem &&
              item.id !== (properties?.hiddenItem | async)
            "
          >
            <sit-icon-button
              class="d-block d-md-none"
              alt="{{ item?.alt }}"
              [ngClass]="{ active: item?.isSelected || item.isHover }"
              (click)="dispatchAction(item.event, item.dispatcher, item)"
              (mouseover)="item.isHover = true"
              (mouseout)="item.isHover = false"
              [tabIndex]="isCollapsed ? -1 : 0"
              [icon]="
                item?.isSelected || item.isHover
                  ? item.itemIconSelected
                  : item.itemIcon
              "
              sizeClass="icon-size-30"
            >
            </sit-icon-button>

            <!-- duplicate botton for responsive properties-->
            <div
              [ngClass]="{ active: item?.isSelected || item.isHover }"
              class="chip"
              (click)="dispatchAction(item.event, item.dispatcher, item)"
            >
              <sit-icon-button
                class="icon-pos d-none d-md-block d-sm-block"
                alt="{{ item?.alt }}"
                (mouseover)="item.isHover = true"
                (mouseout)="item.isHover = false"
                [icon]="item.itemIcon"
                sizeClass="icon-size-30"
              >
              </sit-icon-button>
              <div class="chip-content d-none d-md-block d-sm-block">
                {{ item.alt }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</divalt>