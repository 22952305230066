import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PredictiveService {

  private $PanelDataCardsDispatcher : BehaviorSubject<any> = new BehaviorSubject({} as any);
  public readonly $cards: Observable<any> = this.$PanelDataCardsDispatcher.asObservable();

  constructor(private httpClient: HttpClient,
              @Inject('environment') private environment: any) { }

  getResourcesPredictiveLocations(queryParams: string) {
    let url = ''
    if(queryParams){
      url = `${this.environment.predictiveUrl}/distributions/format-geo-json?${queryParams}`
    } else {
      const days  : string[] = ["sunday","monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
      const hoursOfDay: string[] = [
        "00:00-01:00", "01:00-02:00", "02:00-03:00", "03:00-04:00",
        "04:00-05:00", "05:00-06:00", "06:00-07:00", "07:00-08:00",
        "08:00-09:00", "09:00-10:00", "10:00-11:00", "11:00-12:00",
        "12:00-13:00", "13:00-14:00", "14:00-15:00", "15:00-16:00",
        "16:00-17:00", "17:00-18:00", "18:00-19:00", "19:00-20:00",
        "20:00-21:00", "21:00-22:00", "22:00-23:00", "23:00-00:00"
      ];
      url = `${this.environment.predictiveUrl}/distributions/format-geo-json?hour=${hoursOfDay[new Date().getHours()]}&day=${days[new Date().getDay()]}&type=ALL`
    }
    return this.httpClient.get<any>(
        url
    );
  }

  public setCards(cards : any) {
    this.$PanelDataCardsDispatcher.next(cards)
  }

    public getStacksInitials(queryParams? : string) {
      let url = ''
      if(queryParams){
        url = `${this.environment.incidentUrl}/incidents/geo-json/incidents-and-resources?${queryParams}`
      } else {
        url = `${this.environment.incidentUrl}/incidents/geo-json/incidents-and-resources`
      }
      url = `assets/mock/StacksPredictiveModel-mock.json`; //TODO mocked data for cammeras
      return this.httpClient.get<any>(
          url
      );
    }
}
