import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IModalZonesPathModel } from '@types-custom/models/ui/modal-zones-path';
import * as e from 'express';

@Component({
  selector: 'citizen-modal-zones',
  templateUrl: './modal-zones.component.html',
  styleUrls: ['./modal-zones.component.scss'],
})
export class ModalZonesComponent {
  @Input() properties: IModalZonesPathModel | undefined;
  Icon = Icon;
  statusZone: string;
  statusType: { [id: string]: string } = {
    0: 'MALA',
    1: 'REGULAR',
    2: 'BUENO',
    3: 'EXCELENTE',
  };
  ngOnInit() {
    this.checkEstado();
  }

  checkEstado() {
    this.statusZone = this.statusType[this.properties.estado];
  }
}
