<div class="modal-image">
  <div class="d-flex fd-row">
    <sit-icon alt="imagen" [icon]="properties?.icon"></sit-icon>
    <div class="title-text fd-col text-blue">
      <h2 class="mb-05 text-weight-600 font-xl">
        Cámara {{ properties?.camaraId }}
      </h2>
      <span class="text-weight-500 font-l">{{ properties?.location }}</span>
    </div>
  </div>

  <div class="date-time d-flex fd-row mt-1 mb-1">
    <div class="date mr-2">
      <label class="font-s">Fecha: </label>
      <span class="text-blue-1 font-s">{{ properties?.date }}</span>
    </div>
  </div>
  <div class="video-player">
    <img-loader [source]="properties?.ImageSource"></img-loader>
  </div>
</div>
