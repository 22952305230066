import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IModalModel,
  IUniqueModalModel,
} from '@types-custom/models/ui/modal.model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

/*
 ** Modal
 ** Render a confirm modal with a body that can be custom with a sit-renderer.
 */
@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  readonly ButtonClassesEnum = ButtonClassesEnum;
  readonly Icon = Icon;
  modals: IModalModel[] = [];
  activeModalIndex = 0;

  confirmButtonProps: IButtonModel = {
    label: 'ACEPTAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  modalWidth = 400;
  modalHeight = 200;
  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;
  xInitialPosition = this.modalWidth * 4;
  yInitialPosition = this.innerHeight / 3 - this.modalHeight;
  xPixelsIncrement = 35;
  yPixelsIncrement = 35;
  yCounter = 0;
  xCounter = 0;
  windowBoundaries = {
    xTop: 0,
    yTop: 0,
    xBottom: this.innerWidth,
    yBottom: this.innerHeight,
  };

  uniqueModal$: BehaviorSubject<IUniqueModalModel | undefined> =
    this.modalService.uniqueModal$;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.currentModal$
      .pipe(filter((toRender) => !!toRender))
      .subscribe(this.handleNewModal.bind(this));
    this.modalService.cleanModals$.subscribe(this.cleanModals.bind(this));
  }

  modalSelect(index: number): void {
    this.activeModalIndex = index;
  }

  private handleNewModal(toRender?: IRendererModel): void {
    this.activeModalIndex = this.modals.length;
    const increment = this.modals.length - 1;
    const xPoint = this.calculateXPoint(increment);
    const yPoint = this.calculateYPoint(increment);

    if (toRender) {
      const newModal: IModalModel = {
        x: xPoint,
        y: yPoint,
        render: toRender,
      };

      if (this.activeModalIndex <= 10) {
        this.modals.push(newModal);
        this.modalService.currentModals = this.modals;
      }
    }
  }

  private calculateXPoint(increment: number): number {
    if (this.isMobileOrTablet()) {
      return (
        (window.innerWidth - (window.innerWidth * 0.85)) / 2 +
        increment * 0
      );
    }
    return this.xBoundaryCalc(increment);
  }

  private calculateYPoint(increment: number): number {
    if (this.isMobileOrTablet()) {
      return (
        (window.innerHeight - this.modalHeight) / 4 +
        increment * this.yPixelsIncrement
      );
    }
    return this.yBoundaryCalc(increment);
  }

  xBoundaryCalc(increment: number): number {
    const xPoint =
      this.xInitialPosition -
      this.xInitialPosition * 0.72 +
      increment * this.xPixelsIncrement;
    if (
      xPoint > this.windowBoundaries.xBottom - this.modalWidth ||
      xPoint < this.windowBoundaries.xTop
    ) {
      this.xCounter += 1;
      return this.xInitialPosition;
    }
    return xPoint;
  }

  yBoundaryCalc(increment: number): number {
    let yPoint = this.yInitialPosition + increment * this.yPixelsIncrement;

    if (
      yPoint > this.windowBoundaries.yBottom - this.modalWidth ||
      yPoint < this.windowBoundaries.yTop
    ) {
      yPoint = Math.abs(yPoint - this.yCounter * this.modalHeight);
    }
    return yPoint;
  }

  isMobileOrTablet(): boolean {
    const mobileWidthThreshold = 1000;
    return window.innerWidth < mobileWidthThreshold;
  }

  deleteModal(index: number): void {

    // Se agrega este condicional para validar el cierre del modal al oprimir en la X
    const closeFunction = this.modals[index].render.cancel;
    if (typeof closeFunction === 'function') {
      closeFunction();
    }else{
      const elements = document.querySelectorAll('.marker-active');
      elements.forEach(elements => {
        elements.remove();
      });
  
      this.modals.splice(index, 1);
      this.modalService.currentModals = this.modals;
      this.activeModalIndex = this.modals.length;
    }
  
  }

  dialogAction(fn?: any, data?: any): void {
    if (fn) {
      fn(data);
    }
    this.closeUniqueModal();
  }

  closeUniqueModal(): void {
    //console.log(this.uniqueModal$.value?.cancel);
    
    const closeFunction = this.uniqueModal$.value?.cancel;
    if (typeof closeFunction === 'function') {
      closeFunction();
    }
    this.uniqueModal$ ? this.uniqueModal$.next(undefined) : undefined;
  }

  cleanModals() {
    this.modalService.currentModals = this.modals;
    this.modals = [];
  }
}
