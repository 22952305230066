import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';

import { LoadingOverlayService } from '@ui-core/services/loading-overlay/loading-overlay.service';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';

@Injectable()
export class InterceptorOfficial implements HttpInterceptor {
  constructor(
    @Inject('environment') private environment: any,
    private localStorageManagementService: LocalStorageManagementService,
    private loginAuthService: LoginAuthService,
    private router: Router,
    private loadingOverlayService: LoadingOverlayService
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = request.url;
    this.loadingOverlayService.addHttpActiveCall(url);
    const _request = this.injectHeaders(request);
    const observable = this.setPipes(next.handle(_request), url);
    return observable;
  }

  private injectHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token =
      this.localStorageManagementService.getUserLocalStorage();
    if (token && !request.url.includes('https://services2.arcgis.com/') && !request.url.includes('https://qa.keycloak.sitbogota.com')
      && !request.url.includes('https://sig.simur.gov.co') && !request.url.includes('https://storage.googleapis.com')) {
      if (!request.url.includes(this.environment.geoLocations.getAddressUrl)) {
        const headers = {
          authorization: `Bearer ${token}`,
          // TODO: Temporarily disabled.
          // requestApp:'CDG'
        };
        return request.clone({ setHeaders: headers });
      }
    }
    return request;
  }

  private setPipes(
    obs: Observable<HttpEvent<any>>,
    url: string
  ): Observable<HttpEvent<any>> {
    return obs.pipe(
      tap((data) => {
        if (data.type !== 0) {
          this.loadingOverlayService.removeHttpActiveCall(url);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loadingOverlayService.removeHttpActiveCall(url);
        return this.handleError(error);
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (
      error.status == 401 &&
      !error.url.includes('https://pmv.movilidad-manizales.com/pmv/api/v1/') &&
      !error.url.includes(this.environment.vmsUrl) &&
      !error.url.includes('https://keycloak.sit-almeria.com')
    ) {
      this.loginAuthService.logout();
      this.router.navigate(['/']);
    }
    return throwError(() => error);
  }
}
