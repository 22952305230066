import { Component, Input } from '@angular/core';
import { IFilterModel } from '@types-custom/models/ui/filter.model';

/*
** Filters
** Render a location-selector component.
*/
@Component({
  selector: 'sit-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @Input() properties: IFilterModel | undefined;
}
