import { Injectable } from '@angular/core';
import { SensorsManageServiceService } from '@official/services/data-grid-service/sensors-manage-service/sensors-manage-service.service';
import { ManageCamerasService } from '@official/services/data-grid-service/manage-cameras-service/manage-cameras.service';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { IncidentsManageService } from '../data-grid-service/incidents-manage-service/incidents-manage.service';
import { ManageVariableMessagingService } from '@official/services/data-grid-service/manage-variable-messaging-service/manage-variable-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class PanelServiceProxyService {
  private servicePanelDataSourceMap: {
    [key: string]: AbstractPanelManagementDataSource;
  };

  constructor(
    private sensorsManageServiceService: SensorsManageServiceService,
    private manageCamerasService: ManageCamerasService,
    private incidentsManageService: IncidentsManageService,
    private manageVariableMessagingService: ManageVariableMessagingService,
  ) {
    this.servicePanelDataSourceMap = {
      [MapEventTypeEnum.SENSORS]: this.sensorsManageServiceService,
      [MapEventTypeEnum.CAMERAS]: this.manageCamerasService,
      [MapEventTypeEnum.INCIDENTS]: this.incidentsManageService,
      [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: this.incidentsManageService,
      [MapEventTypeEnum.PMV]: this.manageVariableMessagingService,
    };
  }

  mapDataSourceService(panelOnManagement: string) {
    return this.servicePanelDataSourceMap[panelOnManagement];
  }
}
