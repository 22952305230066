import { log } from 'console';
import { BehaviorSubject } from 'rxjs';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CoreThemingService } from '@ui-core/services/core-theming.service';
import { ISideMenu } from '@types-custom/models/ui/side-menu.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionService } from '@shared/services/action-service/action.service';
import { Console } from 'console';

/*
 ** Side menu
 ** Render a side menu that can be toggled and contains items.
 */
@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuComponent implements OnInit{
  @Input() isExpanded: boolean | undefined | null;
  @Output() expandedChange: EventEmitter<any> = new EventEmitter();
  @Input() iconInfo: ISideMenu<any>[] | undefined = undefined;
  @Input() activeIcon$: BehaviorSubject<any> | undefined;
  @Input() logoText: string | '' | undefined;
  @Input() userName: string | null | undefined;
  theme$ = this.coreThemingService.theme$;
  isDisabled = false;
  Icon = Icon;
  hourBoard = '';
  dateBoard = '';
  panelOpenState = false;

  constructor(
    private coreThemingService: CoreThemingService,
    private actionService: ActionService
  ) {
    setInterval(() => this.dateOnBoard(), 1000);
  }
  ngOnInit(): void {
  }

  dispatchEvent(dispatcher$: any, event: any): void {
    this.actionService.dispatch(event, dispatcher$);
    this.expandedChange.emit(false);
  }

  dateOnBoard() {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Bogota',
    };
    this.hourBoard = date.toLocaleString('es-ES', options);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      timeZone: 'America/Bogota',
    };
    this.dateBoard = date.toLocaleString('es-ES', optionsDate);
  }

  act (group: string){
    return this.iconInfo
    .filter(item => item.isSubmenu && item.group === group) 
    .some(sbg => sbg.isActive)

  }

  clearAllAct(){
    this.iconInfo.forEach(item => {
      if (item.hasOwnProperty('isActive')) {
        delete item.isActive;
      }
    })
  }

  isActive(item: ISideMenu<any>) {
    
    this.clearAllAct()
    this.iconInfo.find(elem => elem === item).isActive = true;
    // Object.assign(this.iconInfo[1], "isActive", true)

  }



  @HostListener('click', ['$event'])
  documentClick(event: any): void {
    if (this.isExpanded) {
      this.expandedChange.emit(false);
    }
  }
}