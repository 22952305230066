import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsTrafficManagmentPathModel {
  id: string;
  globalid: string;
  objectid: string;
  contratista: string;
  direccion: string;
  empresacon: string;
  fechaampli: string;
  fechaaprob: string;
  fechafin: string;
  fechainici: string;
  fechasegui: string;
  interven: string;
  intervento: string;
  lastedite: string;
  numerocelu: string;
  numeropmt: string;
  observacion: string;
  type: string;
  vigencia: string;
  geometry: unknown;
  apiObj: unknown;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class TrafficManagmentPlansPathModel extends MarkerModelBase {
  static className = 'TrafficManagmentPlansPathModel';
  classProperties: IPropsTrafficManagmentPathModel;

  constructor(
    props: IPropsTrafficManagmentPathModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, TrafficManagmentPlansPathModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
