import {
  BehaviorSubject,
  map,
  skipWhile,
  Subject,
  Subscription,
  take,
} from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { IHeaderModel } from '@types-custom/models/ui/header.model';
import { MenuService } from '@official/services/menu/menu.service';
import { NavigationEnd, Router, Event } from '@angular/router';
import { RouteNamesEnum } from '@official/routes/routes';
import { generateIndicatorsModel } from '@official/models/constants/indicators';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';

import { ModalService } from '@ui-core/services/modal/modal.service';

import { IndicatorService } from '@shared/services/indicator-service/indicator.service';
import {
  IndicatorModel,
  IndicatorProps,
} from '@types-custom/models/ui/indicators.model';
import { IMenuModel } from '@types-custom/models/ui/menu.model';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { filterMenuBasedOnRoles } from '@shared/utils/functions/filter-menu-based-on-roles';
import { ModalActionHandler } from '@shared/services/action-service/handlers/modal-action-handler';
import { MapActionHandler } from '@shared/services/action-service/handlers/map-action-handler';
import { PanelActionHandler } from '@shared/services/action-service/handlers/panel-action-handler';
import { RouterActionHandler } from '@shared/services/action-service/handlers/router-action-handler';
import { ActionService } from '@shared/services/action-service/action.service';
@Component({
  selector: 'sisctrl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MenuService, ModalService],
})
export class AppComponent implements OnInit {
  indicators = new BehaviorSubject<IndicatorModel[] | undefined>(undefined);
  menuEvents$ = new BehaviorSubject<any | undefined>(undefined);
  headerModel$ = new BehaviorSubject<IHeaderModel<any> | undefined>(undefined);
  currentRoute$ = new BehaviorSubject<RouteNamesEnum | undefined>(undefined);
  RouteNamesEnum = RouteNamesEnum;
  isVisibleMenu = true;
  getRuta: Subscription;
  unsubscribier$ = new Subject<void>();
  roles: string[];
  indicatorProps: IndicatorProps;
  isSideMenuVisible: boolean | undefined = undefined;
  actionHandlers = [
    { handler: this.panelActionHandler, service: this.menuService },
    { handler: this.modalActionHandler, service: this.modalService },
    { handler: this.mapActionHandler },
    { handler: this.routerActionHandler },
  ];

  constructor(
    private router: Router,
    private menuService: MenuService,
    private loginAuthService: LoginAuthService,
    private modalService: ModalService,
    private indicatorService: IndicatorService,
    private localStorageManagementService: LocalStorageManagementService,
    private actionService: ActionService,
    private modalActionHandler: ModalActionHandler,
    private mapActionHandler: MapActionHandler,
    private panelActionHandler: PanelActionHandler,
    private routerActionHandler: RouterActionHandler,
    @Inject('environment') private environment: any
  ) {}

  ngOnInit(): void {
    if(this.environment.production){
      console.log = function() {}; // JUST USED IN PRODUCTION MODE
    }
    this.getRuta = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.menuService
          .getHeaderModel(this.menuEvents$)
          .pipe(
            take(1),
            map((header) => {
              header.menus = filterMenuBasedOnRoles(header.menus, this.roles);
              return header;
            })
          )
          .subscribe((header) => {
            if (header.menus) {
              this.handleMenu(header.menus, event);
            }
            this.handleMenuLoaded(header);
          });
      }
    });

    this.router.events.subscribe(this.handleRouteEvents.bind(this));
    this.menuService.getHeaderModel(this.menuEvents$).subscribe((a) => {
      this.handleMenuLoaded(a);
    });

    this.indicatorProps = {
      indicatorDataSource: this.indicatorService,
      generateIndicatorsModel: generateIndicatorsModel,
    };

    this.localStorageManagementService.userInfo$.subscribe((res) => {
      this.roles = res?.roles;
    });

    this.actionHandlers.forEach(({ handler, service }) => {
      if (service && 'setService' in handler) {
        handler.setService(service);
      }
      this.actionService.registerActionHandler(handler);
    });
    this.actionService.listenToActions();
  }

  handleMenu(menu: IMenuModel<any>[], event: NavigationEnd) {
    const mapViewer = menu.find(
      (element: any) => element.action === 'visor-mapa'
    );

    if (mapViewer) {
      mapViewer.isVisible = event['url'] !== '/visor-mapa';
    }
  }

  toggleSideMenu($event: boolean): void {
   

    this.menuService.toggleSideMenu($event);
  }

  private handleMenuLoaded(model: IHeaderModel<any>): void {
    if (model.menus) {
      this.menuService.headerMenu$.next(model);
    }

    this.headerModel$.next(model);
  }

  private handleRouteEvents(event: Event): void {
    if (event instanceof NavigationEnd) {
      const url = `${event?.url}`.replace('/', '');
      this.currentRoute$.next(url as RouteNamesEnum);
    }
  }
}
