import { BehaviorSubject, fromEvent } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { LngLatLike, Map, MapboxOptions, NavigationControl } from 'mapbox-gl';
import { IMapIconModel } from '@types-custom/models/ui/map-icons.model';
// TODO: Remove environment dependencie
// import { environment } from '@citizen-env/environment';

@Injectable()
export class MapboxInstanceService {
  private _containerId: string | undefined;
  private mapSubject = new BehaviorSubject<mapboxgl.Map | undefined>(undefined);
  private mapStatusSubject = new BehaviorSubject('off');

  map$ = this.mapSubject.asObservable();
  mapStatus$ = this.mapStatusSubject.asObservable();

  get mapInstance(): mapboxgl.Map | undefined {
    return this.mapSubject.value;
  }

  get containerId(): string | undefined {
    return this._containerId;
  }

  constructor(@Inject('environment') private environment: any) {}

  private buildMap(options?: any): mapboxgl.Map {
    const mapInstance = new Map(options);
    return mapInstance;
  }

  private getDefaultOptions(container: string): MapboxOptions {
    // TODO: Implement configurable env
    // const options: MapboxOptions = {
    //   container,
    //   center: environment.mapbox.center as LngLatLike,
    //   style: environment.mapbox.style,
    //   zoom: environment.mapbox.zoom,
    //   accessToken: environment.mapbox.accessToken,
    // };
    const options: MapboxOptions = {
      container,
      center: [-76.521147, 3.419201] as LngLatLike,
      accessToken: 'pk.eyJ1Ijoic2l0Ym9nb3RhIiwiYSI6ImNsanNxMTBtNjBvNnkzZG55OWl0ZnFtejQifQ.lrEc5SpOS3TxJB4E5gQcpA',
      style: 'mapbox://styles/sitbogota/clkipni4z00pr01p55yzpai09',
      zoom: 12
    };

    return options;
  }

  initializeMap(data: string | MapboxOptions, mapIcons: IMapIconModel[]): void {
    let map: Map;
    if (typeof data === 'string') {
      this._containerId = data;
      const options = this.getDefaultOptions(data);
      map = this.buildMap(options);
    } else {
      this._containerId = data.container as string;
      map = this.buildMap(data);
    }
    map.once('idle', () => {
      this.mapSubject.next(map);
      this.loadIcons(mapIcons);
    });
    map.once('load', () => {
      this.mapStatusSubject.next('loaded');
    });
    map.addControl(new NavigationControl(), 'bottom-left');
  }

  loadIcons(mapIcons: IMapIconModel[]) {
    mapIcons.forEach((el: any) => {

      const image = new Image();
      image.src = el.path;
      fromEvent(image, 'load').subscribe(() => {
        this.mapInstance?.addImage(el.id, image);
      });
    });
  }

  setFocus(lat: number, lng: number, zoom: number = 16): void {
    this.mapInstance?.flyTo({ center: [lng, lat], zoom });
  }

  setDefaultFocus(): void {
    const defaultMap = this.environment.mapbox;
    this.setFocus(
      defaultMap?.center?.[1],
      defaultMap?.center?.[0],
      defaultMap?.zoom
    );
  }
}
