import { BehaviorSubject, Observable } from 'rxjs';
import { ILocationOption } from '../business/location.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export enum DynamicFilterTypeEnum {
  SELECTION = 'selection',
  TEXT = 'text',
  SELECTION_BUTTON = 'selection-button',
  CHIPS_BUTTON = 'chips-button',
  DATE_PICK = 'date-pick'
}
export interface ISdkDynamicFilterModel<Option = any, Value = any> {
  label: string;
  key: string;
  optionsMapper?: (response: any[]) => ILocationOption[];
  options?: BehaviorSubject<Option>;
  value: BehaviorSubject<Value>;
  type: DynamicFilterTypeEnum;
  searchChange?: boolean;
  multiple?: boolean;
}

export abstract class AbstractSdkDynamicFilterDataSource {
  abstract selectedData: BehaviorSubject<any>;
  abstract filterList: ISdkDynamicFilterModel[];
  abstract fetchData(
    value: any,
    next: ISdkDynamicFilterModel,
    index: number
  ): Observable<any>;
  abstract clearData(): void;
}

export interface IDynamicFilterModel {
  sdkDynamicFiltersService: AbstractDynamicFiltersService;
  dataSource: AbstractSdkDynamicFilterDataSource;
  filtersDispatcher: BehaviorSubject<any>;
  isSpacerFirst?: boolean;
  isActiveFilter?: BehaviorSubject<boolean>;
  title?: string;
  titleIcon?: Icon;
  showPanelConventions$?: BehaviorSubject<boolean>;
  showBttnConventions?: boolean;
  hiddenButtonsFilters?: boolean;
}

export enum DynamicFilterLayoutEnum {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export abstract class AbstractDynamicFiltersService {
  public abstract setFilters(
    dataSource: AbstractSdkDynamicFilterDataSource,
    startWithIndex: number | number[]
  ): void;

  public abstract getModel(): any;

  public abstract cleanFilters(): void;
}
