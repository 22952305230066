import { Component, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { DatexCategoriesService } from "@official/services/data-grid-service/datex-categories-service/datex-categories.service";
import { DatexSubcategoriesService } from "@official/services/data-grid-service/datex-categories-service/datex-subcategories.service";
import { IDatexCategory, IGridDatexCategory } from "@shared/models/datex-category.model";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { remapObjectToOneLevel } from "@ui-core/utils/functions/remap-object";

@Component({
  selector: "sit-enable-datex-subcategory",
  templateUrl: "./enable-datex-subcategory.component.html",
  styleUrls: ["./enable-datex-subcategory.component.scss"],
  providers: [
    DatexSubcategoriesService
  ]
})
export class EnableDatexSubcategoryComponent {

  @Input() onComplete: () => void;
  @Input() category: IGridDatexCategory;

  constructor(
    private datexService: DatexSubcategoriesService,
    public modalService: ModalService
  ) { }

  subcategories: any[] = [];

  ngOnInit(): void {
    if (this.category) {
      this.datexService.fetchSubcategories(this.category.id).subscribe(
        subcategories => {
          this.subcategories = subcategories.filter(s => !s.active);
          this.initializeForm();
        }
      );
    }
  }

  sendButtonProps: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  fb = new UntypedFormBuilder();
  createFormGroup = this.fb.group({});

  initializeForm() {
    const formControls: { [key: string]: any } = {
      subcategoryDatexIds: [{
        value: this.initialSelectedSubcategories(),
        disabled: false
      },
        // [Validators.required]
      ]
    }


    this.createFormGroup = this.fb.group(formControls);
  }

  private initialSelectedSubcategories() {
    return this.subcategories.filter(s => s.active).map(s => s.id);
  }

  handleSendForm() {
    this.datexService.addCategory({
      categoryDatexId: this.category.id,
      ...this.createFormGroup.getRawValue()
    }).subscribe({
      next: (v: any) => {
        this.modalService.closeConfirmationModal();
        setTimeout(() =>
          this.handleSuccess()
        );
      },
      error: () => this.validateFormGroup(),
      complete: () => this.onComplete(),
    });

  }

  handleCancel() {
    this.modalService.closeConfirmationModal();
  }

  handleSuccess() {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        // data: { message: `Éxito al actualizar ${this.category.name}` },
        data: { message: `La información fue almacenada exitosamente.` },
      },
      headerBackgroundClass: 'bg-color-background-1',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };
    this.modalService.confirmationModal(modal);
  }
  validateFormGroup() {
    this.createFormGroup.markAllAsTouched();
  }
}
