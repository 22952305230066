<ng-container *ngIf="theme$ | async as theme">
  <div [ngClass]="theme">
    <mat-toolbar color="transparent" class="justify-space-between toolbar-container">
      <sit-icon-button
        class="d-none d-md-flex section-container h-100 justify-center" style="width: 64px;"
        [btnType]="'menu-latera'"
        (click)="toggleSideMenu()"
        [icon]="Icon.menu"
        [colorClass]="'text-color-white'"
        [sizeClass]="'icon-size-32'"
        [alt]="
          properties.logoText === 'FUNCIONARIO'
            ? 'Encuentre información georreferenciada sobre tráfico, incidentes y accidentes, agentes, niveles de CO2, aforo, paneles de mensajería variable e información sobre RUNT/ DATEX de la ciudad de Cali.'
            : 'Encuentre información georreferenciada sobre tráfico, incidentes, niveles de CO2 y aforo de la ciudad de Cali.'
        ">
      </sit-icon-button>

      <ng-container *ngIf="properties?.logo as logo">
        <section class="h-100 section-container d-flex d-md-none">
          <picture class="logo-container">
            <img [src]="logo" alt="'Logo alcaldia de Cali'" class="w-auto h-100">
          </picture>
          <!-- <img-loader [source]="logo" [alt]="'Logo alcaldía de manizales'" class="logo"
            title="'Logo alcaldía de manizales'">
          </img-loader> -->
        </section>
      </ng-container>


      <ng-container>
        <div class="h-100 container-indicators">
          <section class="h-100 section-container indicators-box">
            <div class="full-width d-flex justify-center">
              <citizen-indicators [indicators]="indicators"></citizen-indicators>
            </div>
          </section>
        </div>
      </ng-container>



      <section class="h-100 section-container px-1 d-flex d-md-none">

        <ng-container>
          <time class="gap-2 flex-md-column flex-lg-row gap-md-0 ml-md-2 cont-menu lh-20">
            <div class="d-block date date-icon">
              <span class="calendar ml-8">{{ dateBoard }}</span>
            </div>
            <div class="d-block hour date-icon">
              <span class="hora ml-8">{{ hourBoard }} </span>
            </div>
          </time>
        </ng-container>
        
        <ng-container *ngIf="properties?.menus as menus">          
            <nav class="d-flex align-items-center container-tools">
              <ng-container *ngFor="let menu of menus; let i = index">
                <sit-menu [properties]="menu" class="d-flex align-items-center"></sit-menu>
              </ng-container>
            </nav>
        </ng-container>
      </section>

    </mat-toolbar>
  </div>
</ng-container>
