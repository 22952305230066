import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsZonesPathModel {
  id: string;
  ancho: string;
  capacidad: string;
  direccion: string;
  editor: string;
  estado: string;
  fechaEstado: string;
  horaFin: string;
  horaInicio: string;
  largo: string;
  objectId: string;
  reglamento: string;
  tarifa: string;
  tarifaAuto: string;
  tarifaMoto: string;
  tipo: string;

  geometry: unknown;
  apiObj: unknown;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class ZonesPathModel extends MarkerModelBase {
  static className = 'ZonesPathModel';
  classProperties: IPropsZonesPathModel;

  constructor(
    props: IPropsZonesPathModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, ZonesPathModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
