import { Component, Input, OnInit } from "@angular/core";
import {ICardRoadCorridorsModel} from "@types-custom/models/ui/card-road-corridors.model";
import {BehaviorSubject, finalize} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import { Icon } from '@types-custom/models/ui/icon-model';
import * as am4core from "@amcharts/amcharts4/core";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {ICardWazeAlertsModel} from "@types-custom/models/ui/card-alert-waze.model";
import { IPointOnTimeLine, ITimeLineGraphicModel, ITimeSeriesGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { WazeAlertGraphicService } from "@shared/services/layer-service/waze-alert-graphic/waze-alert-graphic.service";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { log } from "console";

@Component({
  selector: "sit-waze-alerts-panel",
  templateUrl: "./waze-alerts-panel.component.html",
  styleUrls: ["./waze-alerts-panel.component.scss"],
})
export class WazeAlertsPanelComponent implements OnInit {
  dataGraphicList:ITimeSeriesGraphicModel ;
  colorScale: any = [{}];
  fecha:any;

  Icon = Icon;
  cards: ICardWazeAlertsModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listTerritories: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;
  totalAccidents : number = 0;
  totalCloses : number = 0;
  totalClimate : number = 0;

  
    constructor(public wazeServiceGraph:WazeAlertGraphicService) {}
 

  ngOnInit(): void {
    console.log('waze alerts')
    this.setIndicators(this.cards);
    this.setGraphic(this.cards);

    this.wazeServiceGraph.getDataAlertUrl().subscribe(res => {
      this.setGraphicAlert(res);
    }); 
/*   this.wazeServiceGraph.getDataAlertMock().subscribe(res => {
      this.setGraphicAlert(res);
    });  */
  }

  setIndicators(cards: ICardWazeAlertsModel[]) {
    let sumspeedAvg = 0;
    let totDistance = 0;
    let totIncidents = 0;

    cards.map((cards: ICardWazeAlertsModel) => {
      if (cards.type === 'ACCIDENT') {
        this.totalAccidents += 1;
      }
      if (cards.type === 'WEATHERHAZARD') {
        this.totalClimate += 1;
      }
      if (cards.type === 'ROAD_CLOSED') {
        this.totalCloses += 1;
      }
    });
  }

  setGraphic(cards: ICardWazeAlertsModel[]) {
    const lookup = {} as any;
    const lookupD = {} as any;

    cards.map((card: ICardWazeAlertsModel) => {
      const locality = card.locality
      if (locality in lookup) {
        lookup[locality] = lookup[locality] + 1;
        lookupD[locality] = lookup[locality];
      } else {
        lookup[locality] = 1;
        lookupD[locality] = 1;
        const object: any = new Object();
        object['id'] = locality;
        object['label'] = locality;
        (object['dta'] = 1);
        (object['qty'] = 1);
        (object['color'] = am4core.color('rgba(166,181,255,0.72)'));
        (object['border'] = am4core.color('rgb(166,181,255)'));

        this.listTerritories.push(object);
      }
    });

    this.listTerritories.forEach((item) => {
      item.qty = lookup[item.id];
      item.dta = lookupD[item.id];
    });
  }
  
  setGraphicAlert(data: any): void {
 
    this.dataGraphicList = {
      data: data,
      label: ['Accidente','Vía cerrada','Climáticas'],
      labelX: 'Hora',
      labelY: 'Alertas', 
      colorLine:['#9271C5','#FF5722','#01BCD5']
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#92FDF6' }];
  }
  
}
