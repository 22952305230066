import { EventEmitter, Injectable } from '@angular/core';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import {
  GeometryTypeEnum,
  IMarkerBaseModel,
  IMarkerModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import {
  AnySourceImpl,
  LngLat,
  Map,
  MapMouseEvent,
  Marker,
  Popup,
} from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

import { BehaviorSubject, fromEvent, Subject, throwError } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { buildMarker } from '@sdk/utils/markers';
import { getIconAsset, Icon } from "@types-custom/models/ui/icon-model";

export interface geoJsonType {
  features: any[];
  type: string;
}
@Injectable()
export class MapboxDataService {
  private readonly defaultLayer: string = 'default';
  private isMapReady = false;
  private layers: string[] = [];

  markerBoxShadow: any;

  private linesClickDispatcher!: BehaviorSubject<any>;

  private dataSelectPmv = new BehaviorSubject({});
  selectpmv = this.dataSelectPmv.asObservable();

  mapClickEmitter = new EventEmitter<MapMouseEvent>();

  private get map(): Map {
    if (this.mapboxInstanceService.mapInstance) {
      return this.mapboxInstanceService.mapInstance;
    }
    throw new Error(`Mapbox instance hasn't been created.`);
  }

  constructor(private mapboxInstanceService: MapboxInstanceService) {
    this.mapboxInstanceService.map$.subscribe(
      this.handleMapInstance.bind(this)
    );
  }

  private validateMap(): void {
    if (this.isMapReady) {
      return;
    }

    throwError('MapBox Map instance is not ready to be used');
  }

  private handleMapInstance(map: Map | undefined): void {
    if (map) {
      this.isMapReady = true;

      this.setMapListeners();
    }
  }

  private setMapListeners(): void {
    fromEvent(this.map, 'click').subscribe(this.handleMapClick.bind(this));
  }

  public handleMapClick(event: MapMouseEvent): void {
    this.mapClickEmitter.next(event);
  }

  private buildDiv(marker: IMarkerBaseModel): HTMLDivElement {
    const wrapper: HTMLDivElement = document.createElement('div');
    const el: HTMLDivElement = document.createElement('div');
    el.className = marker.className;
    wrapper.appendChild(el);
    return wrapper;
  }

  private setListenersToNativeElement(
    el: HTMLElement,
    marker: IMarkerBaseModel
  ): HTMLElement {
    if (marker.unsubscribier) {
      fromEvent(el.childNodes[0], 'click') //TODO: check el.childNodes[0]
        .pipe(takeUntil(marker.unsubscribier))
        .subscribe(() => {
          marker.dispatcher?.next(marker.data);
        });
    }
    return el;
  }

  addClusterLayer(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): void {
    this.validateMap();
    if (this.layers.includes(layer)) return;
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    this.linesClickDispatcher = clickDispatcher;

    this.map.addSource(layer, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    const layerCluster = layer + '-Cluster';

    this.map.addLayer({
      id: layerCluster,
      type: 'circle',
      source: layer,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });
    const layerCount = layer + '-Count'
    this.map.addLayer({
      id: layerCount,
      type: 'symbol',
      source: layer,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });

    this.map.addLayer({
      id: layer,
      type: 'symbol',
      source: layer,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': layer === 'Accident' ? 'map_accident' : ['get', 'icon'],
        'icon-size': 0.15,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    });

    this.map.on('mouseenter', layer, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', layer, () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.setPopup(layer);

    this.map.on('click', layer, (event) =>
      this.handleLineClick(event, Source.markers[0].className)
    );

    this.map.on('click', layerCluster, (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [layerCluster],
      });
      const clusterId = features[0].properties.cluster_id;
      (this.map.getSource(layer) as any).getClusterExpansionZoom(
        clusterId,
        (err: any, zoom: any) => {
          if (err) return;

          this.map.easeTo({
            center: (features[0].geometry as any).coordinates,
            zoom: zoom,
          });
        }
      );
    });

    this.layers.push(layerCluster);
    this.layers.push(layerCount);
    this.layers.push(layer);
    this.setLayerVisibility(layer, layerVisibility);
  }

  addClusterLayerChart(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string, //Locations
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): void {
    this.validateMap();


    if (this.layers.includes(layer)) return;


    this.linesClickDispatcher = clickDispatcher;

    const priority0 = ['==', ['get', 'priority_name'], '0'];
    const priority1 = ['==', ['get', 'priority_name'], '1'];
    const priority2 = ['==', ['get', 'priority_name'], '2'];

    const colors = ['#FF3232', '#FF9901', '#00C479', 'rgba(255,255,255,0)', '#FFFFFF'];

    this.map.addSource(layer, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
      clusterProperties: {
        // Keep separate counts for each priority_name category in a cluster
        'priority0': ['+', ['case', priority0, 1, 0]],
        'priority1': ['+', ['case', priority1, 1, 0]],
        'priority2': ['+', ['case', priority2, 1, 0]],
      }
    });
    const layerCluster = layer + '-Cluster';

    this.map.addLayer({

      id: layerCluster,
      type: 'circle',
      source: layer,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'case',
          priority0,
          colors[0],
          priority1,
          colors[1],
          priority2,
          colors[2],
          colors[3]
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });
    const layerCount = layer + '-Count';

    this.map.addLayer({
      id: layerCount,
      type: 'symbol',
      source: layer,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': 'white',
      },
    });

    this.map.addLayer({
      id: layer,
      type: 'symbol',
      source: layer,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': layer === 'Accident' ? 'map_accident' : ['get', 'icon'],
        'icon-size': 0.15,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    });

    this.map.on('mouseenter', layer, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', layer, () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.setPopup(layer);

    this.map.on('click', layer, (event) =>
      this.handleLineClick(event, Source.markers[0].className)
    );

    this.map.on('click', layerCluster, (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [layerCluster],
      });
      const clusterId = features[0].properties.cluster_id;
      (this.map.getSource(layer) as any).getClusterExpansionZoom(
        clusterId,
        (err: any, zoom: any) => {
          if (err) return;

          this.map.easeTo({
            center: (features[0].geometry as any).coordinates,
            zoom: zoom,
          });
        }
      );
    });

    this.layers.push(layerCluster);
    this.layers.push(layerCount);
    this.layers.push(layer);
    this.setLayerVisibility(layer, layerVisibility);

    const markers: any = {};
    let markersOnScreen: any = {};

    const updateMarkers = () => {
      const newMarkers: any = {};
      const features = this.map.querySourceFeatures(layer);

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        // @ts-ignore
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;

        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(props);
          // @ts-ignore
          marker = markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!markersOnScreen[id]) marker.addTo(this.map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
      }

      if (!this.map.getLayer(layer)) {
        for (const id in newMarkers) {
          newMarkers[id].remove();
        }
      }
      markersOnScreen = newMarkers;
    }

    this.map.on('render', () => {
      if (this.map.getLayer(layer)) {
        if (!this.map.isSourceLoaded(layer)) return;
        updateMarkers();
      } else {
        updateMarkers();
      }

    });

    function createDonutChart(props: { [x: string]: any; priority0?: any; priority1?: any; priority2?: any; }) {
      const offsets = [];
      const counts = [
        props.priority0,
        props.priority1,
        props.priority2,
      ];
      let total = 0;
      for (const count of counts) {
        offsets.push(total);
        total += count;
      }
      const fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      const r =
        total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      const r0 = Math.round(r * 0.6);
      const w = r * 2;

      let html = `<div>
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block" color="white">`;

      for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          colors[i]
        );
      }
      html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="#1A1F26F2" />
<text dominant-baseline="central" transform="translate(${r}, ${r})" fill="white">
${total.toLocaleString()}
</text>
</svg>
</div>`;

      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
    }

    function donutSegment(start: number, end: number, r: number, r0: number, color: string) {
      if (end - start === 1) end -= 0.00001;
      const a0 = 2 * Math.PI * (start - 0.25);
      const a1 = 2 * Math.PI * (end - 0.25);
      const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
      const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
      const largeArc = end - start > 0.5 ? 1 : 0;

      // draw an SVG path
      return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
        } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
        } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
        }" fill="${color}" />`;
    }
  }

  addHeatLayer(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): void {
    this.validateMap();
    const mapboxLayerId = `${layer}-Heat`;

    if (this.layers.includes(mapboxLayerId)) return;
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    this.linesClickDispatcher = clickDispatcher;
    this.map.addSource(mapboxLayerId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
    });
    //console.log(this.map.getSource(mapboxLayerId))
    this.map.addLayer({
      id: mapboxLayerId,
      type: 'heatmap',
      source: mapboxLayerId,
      maxzoom: 24,
      paint: {
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 29, 3],

        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(33,102,172,0)',
          0.2,
          'rgb(103,169,207)',
          0.4,
          'rgb(209,229,240)',
          0.6,
          'rgb(253,219,199)',
          0.8,
          'rgb(239,138,98)',
          1,
          'rgb(178,24,43)',
        ],

        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 24, 40],

        'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 24, 0],
      },
    });

    this.layers.push(mapboxLayerId);
    this.setLayerVisibility(mapboxLayerId, layerVisibility);
  }

  addHeatWeightLayer(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): void {
    this.validateMap();
    const mapboxLayerId = `${layer}`;

    if (this.layers.includes(layer)) return;
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    this.linesClickDispatcher = clickDispatcher;
    //console.log(Source.dataFeatures)
    this.map.addSource(mapboxLayerId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures
      },
    });

    //console.log(this.map.getSource(mapboxLayerId))

    this.map.addLayer({
      id: mapboxLayerId,
      type: 'heatmap',
      source: mapboxLayerId,
      maxzoom: 13,
      paint: {
        'heatmap-intensity': [
          "interpolate",
          ["linear"],
          ["zoom"],
          0, 0.1,
          8, 0.2,
          10, 1,
          12, 7,
          13, 0.1,
          22, 0.1
        ],
        'heatmap-weight': [
          'interpolate',
          ['linear'],
          ['get', 'relativeWeight'],
          0, 0,
          0.5, 1
        ],
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0, 'rgba(33,102,172,0)',
          0.1, '#92ACBA',
          0.2, '#92ACBA',
          0.4, '#51ABBF',
          0.6, '#5970E1',
          0.8, '#DA5D35',
          1, '#DC8F37'
        ],
        'heatmap-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12, 25,
          13, 30
        ],
        'heatmap-opacity': 0.5
      },
    });

    if (this.layers.includes(mapboxLayerId)) {
      this.layers.splice(this.layers.indexOf(mapboxLayerId), 1)
    }
    this.layers.push(mapboxLayerId);
    this.setLayerVisibility(mapboxLayerId, layerVisibility);
  }

  addStatesLayer(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): void {
    this.validateMap();
    const mapboxLayerId = `${layer}-States`;
    if (this.layers.includes(mapboxLayerId)) return;
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    this.linesClickDispatcher = clickDispatcher;

    this.map.addSource(mapboxLayerId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
    });

    // Add a layer showing the state polygons.
    this.map.addLayer({
      'id': mapboxLayerId,
      'type': 'fill',
      'source': mapboxLayerId,
      'paint': {
        'fill-color': '#E45024',// [ 
        'fill-opacity': ['get', 'density'],
        'fill-outline-color': '#FFFFFF'
      }
    });

    var popup!: any;

    this.map.on('mouseenter', mapboxLayerId, e => {
      this.map.getCanvas().style.cursor = 'pointer';

      if (e.features[0].layer.id == 'AgentsNumber-States') {
        popup = new Popup({ closeButton: false, closeOnClick: true, className: '' })
          .setLngLat(e.lngLat)
          .setHTML(`
            <img src="../../../../assets/icons/agents_green.svg" class="icon-size-48 mr-2 ml-1" alt="" *ngIf="${e.features[0].layer.id == 'AgentsNumber-States'}">
            <img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
            <span class="mr-3-em" style="margin-left:2.1rem">${e.features[0].properties.Qty}</span> <span style="margin-left:2.9rem">${e.features[0].properties.density.toFixed(2)}</span> <br> 
            <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
            <span style="display:flex;justify-content:center">${e.features[0].properties?.name}</span>`)
          .addTo(this.map);

        var popupElem = popup.getElement();
        popupElem.classList.remove("mapboxgl-popup");
        popupElem.classList.remove("mapboxgl-popup-anchor-bottom");
        popupElem.childNodes[0].classList.remove("mapboxgl-popup-tip");
        // popupElem.childNodes[1].classList.remove("mapboxgl-popup-content");
        // setTimeout(()=>{ if (!(popupElem.childNodes[1] == undefined)){ popupElem.childNodes[1].childNodes[0].classList.add("trigger_tooltip");} }, 100);
        // this.setPopup(layer);
      } else {
        popup = new Popup({ closeButton: false, closeOnClick: true, className: '' })
          .setLngLat(e.lngLat)
          .setHTML(`
            <img src="../../../../assets/icons/crane.svg" class="icon-size-48 mr-2 ml-1" alt="" *ngIf="${e.features[0].layer.id == 'AgentsNumber-States'}">
            <img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
            <span class="mr-3-em" style="margin-left:2.1rem">${e.features[0].properties.Qty}</span> <span style="margin-left:2.9rem">${e.features[0].properties.density.toFixed(2)}</span> <br> 
            <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
            <span style="display:flex;justify-content:center">${e.features[0].properties?.name}</span>`)
          .addTo(this.map);

        var popupElem = popup.getElement();
        popupElem.classList.remove("mapboxgl-popup");
        popupElem.classList.remove("mapboxgl-popup-anchor-bottom");
        popupElem.childNodes[0].classList.remove("mapboxgl-popup-tip");
        // popupElem.childNodes[1].classList.remove("mapboxgl-popup-content");
        // setTimeout(()=>{ if (!(popupElem.childNodes[1] == undefined)){ popupElem.childNodes[1].childNodes[0].classList.add("trigger_tooltip");} }, 100);
        // this.setPopup(layer);
      }

    });

    this.map.on('mouseleave', mapboxLayerId, () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
    });

    // this.map.on('mousemove', mapboxLayerId, () => {
    //   this.map.getCanvas().style.cursor = '';
    //   popup.remove();
    // });

    this.map.on('click', mapboxLayerId, (event) => {
      this.handleLineClick(event, Source.markers[0].className);
      if (this.markerBoxShadow) {
        this.markerBoxShadow.remove();
      }
      if (event.features[0].geometry.type === 'Point') {
        const el = document.createElement("div");
        if (['TrafficLight', 'ParkingLot', 'TrafficManagmentPlans', 'StopBus', 'Zones', 'Cameras', 'Locations'].includes(event.features[0].layer.id)) {
          el.className = "marker-active-static-layer";
        }
        else if ((event.features[0].layer.id) == 'AlertsWaze') {
          el.className = "marker-active-situations-layer";
        }
        else {
          el.className = "marker-active"
        }
        this.markerBoxShadow = new mapboxgl.Marker(el)
          .setLngLat([event.features[0].geometry.coordinates[0], event.features[0].geometry.coordinates[1]])
          .addTo(this.map);
      }
    });

    this.layers.push(mapboxLayerId);
    this.setLayerVisibility(mapboxLayerId, layerVisibility);
  }

  markPoint(lat: number, lng: number, markerName: any) {
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    const el = document.createElement("div");
    el.className = markerName == 'AlertsWazeModel' ? "marker-active-situations-layer" : "marker-active";
    this.markerBoxShadow = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .addTo(this.map);
  }

  addPointLayer(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility = true
  ): void {
    this.validateMap();
    if (this.layers.includes(layer)) return;
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }

    this.linesClickDispatcher = clickDispatcher;
    this.map.addSource(layer, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
    });

    this.map.addLayer({
      id: layer,
      type: 'symbol',
      source: layer,
      layout: {
        'icon-image': layer === 'action' ? 'pointer_location' : ['get', 'icon'],
        'icon-size': 0.25,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    });
    this.setPopup(layer);

    if (layer !== 'action') {
      this.map.on('click', layer, (event) => {
        this.handleLineClick(event, Source.markers[0].className);
        if (this.markerBoxShadow) {
          this.markerBoxShadow.remove();
        }
        if (event.features[0].geometry.type === 'Point') {
          const el = document.createElement("div");
          el.className = "marker-active"
          el.style.width = "5rem"
          el.style.height = "5rem"
          this.markerBoxShadow = new mapboxgl.Marker(el)
            .setLngLat([event.features[0].geometry.coordinates[0], event.features[0].geometry.coordinates[1]])
            .addTo(this.map);

          // @ts-ignore
          const data = Icon[event.features[0].properties.icon].indexOf('_ng') > -1 ? Icon.map_climate : Icon[Icon[event.features[0].properties.icon.toString()]];
          this.markerBoxShadow.getElement().style.backgroundImage = "url(" + getIconAsset(data) + ")";
          this.markerBoxShadow.getElement().style.backgroundRepeat = "no-repeat"
          this.markerBoxShadow.getElement().style.backgroundSize = 'cover';

          const initialTransform = this.markerBoxShadow.getElement().style.transform;

          this.markerBoxShadow.getElement().style.transform = `${initialTransform} scale(.1)`;

          this.markerBoxShadow.getElement().style.transition = "transform .3s ease-in-out";
          setTimeout(() => {
            this.markerBoxShadow.getElement().style.transform = `${initialTransform} translateY(-10px) scale(1)`;
          }, 0);
        }
      });
    }
    this.layers.push(layer);
    this.setLayerVisibility(layer, layerVisibility);
  }

  addLines(
    Source: {
      type: GeometryTypeEnum;
      markers: MarkerModelBase[];
      dataFeatures: any[];
    },
    layer: string,
    clickDispatcher: BehaviorSubject<any>,
    layerVisibility: boolean = true
  ): any {
    this.validateMap();
    if (this.markerBoxShadow) {
      this.markerBoxShadow.remove();
    }
    this.linesClickDispatcher = clickDispatcher;
    const mapboxLayerId = `${layer}-Lines`;
    this.map.addSource(mapboxLayerId, {
      type: 'geojson',
      lineMetrics: this.setLineMetrics(layer),
      data: {
        type: 'FeatureCollection',
        features: Source.dataFeatures,
      },
    });
    this.map.addLayer({
      id: mapboxLayerId,
      type: 'line',
      source: mapboxLayerId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': this.getFilterColor(layer),
        'line-width': this.getLineWidth(layer),
      },
    });



    if (layer === 'RoadCorridors') {
      this.map.addLayer({
        id: 'routearrows',
        type: 'symbol',
        source: 'RoadCorridors-Lines',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': [
            "interpolate",
            ["linear"],
            ["zoom"],
            10, 20,
            22, 100
          ],
          'symbol-spacing': [
            "interpolate",
            ["linear"],
            ["zoom"],
            10, 16,
            22, 60
          ],
          'text-keep-upright': false,
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-optional': false,
          //data-driven style for line offset
          "text-offset": [0, 0]
        },
        paint: {
          'text-color': this.getFilterColor(layer),
        }
      }, 'waterway-label');
    }

    if (layer === 'TrafficConcordanceCorridors') {
      this.map.addLayer({
        id: 'routearrows',
        type: 'symbol',
        source: 'TrafficConcordanceCorridors-Lines',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': [
            "interpolate",
            ["linear"],
            ["zoom"],
            10, 20,
            22, 100
          ],
          'symbol-spacing': [
            "interpolate",
            ["linear"],
            ["zoom"],
            10, 16,
            22, 60
          ],
          'text-keep-upright': false,
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-optional': false,
          //data-driven style for line offset
          "text-offset": [0, 0]
        },
        paint: {
          'text-color': this.getFilterColor(layer),
        }
      }, 'waterway-label');
    }

    if (layer === 'AgentsHistorical' || layer === 'CranesHistorical' || layer === 'GroundResourceLasthours') {
      this.map.setPaintProperty(
        mapboxLayerId,
        'line-gradient',
        this.getLineGradient()
      );
    }
    if (
      layer === 'BicyclePath' ||
      layer === 'RoadRun' ||
      layer === 'TrafficSpeedRange' ||
      layer === 'ExodusAndReturnWaze' ||
      layer === 'WazeDataCorridors' ||
      layer === 'WazeDataSpeedRange' ||
      layer === 'RoadCorridors' ||
      layer === 'TrafficJamWaze' ||
      layer === 'TrafficConcordance' ||
      layer === 'TrafficConcordanceCorridors' ||
      layer === 'TrafficSpeedRangeCorridors' ||
      layer === 'CoosLine' ||
      layer === 'Coiline'
    ) {
      this.setPopup(mapboxLayerId);
    }

    this.map.on('click', mapboxLayerId, (event) =>
      this.handleLineClick(event, Source.markers[0].className)
    );

    this.layers.push(mapboxLayerId);
    this.setLayerVisibility(mapboxLayerId, layerVisibility);
  }

  clearLayer(layer: string): void {
    if (
      layer === 'Traffic' ||
      layer === 'TrafficSpeedRange' ||
      layer === 'TrafficConcordance' ||
      layer === 'TrafficConcordanceCorridors' ||
      layer === 'TrafficSpeedRangeCorridors' ||
      layer === 'ExodusAndReturnWaze' ||
      layer === 'WazeDataCorridors' ||
      layer === 'WazeDataSpeedRange' ||
      layer === 'RoadCorridors' ||
      layer === 'TrafficJamWaze' ||
      layer === 'BicyclePath' ||
      layer === 'RoadRun' ||
      layer === 'CoosLine' ||
      layer === 'Coiline'
    ) {
      layer = layer + '-Lines';
    }

    if (this.map.getLayer(layer)) {
      this.validateMap();
      this.map.off('click', layer, (e) => this.handleLineClick(e));
      this.removeLayerFromList(layer);
      if (layer === 'RoadCorridors-Lines' || layer === "TrafficConcordanceCorridors-Lines") {
        this.map.removeLayer('routearrows')
        this.removeLayerFromList('routearrows');
        this.map.off('click', 'routearrows', (e) => this.handleLineClick(e));
      }

      if (layer === 'Accident' || layer === 'LastHour') {
        this.map.removeLayer(layer + '-Cluster');
        this.map.removeLayer(layer + '-Count');
      }
      this.map.removeLayer(layer);
      if (this.map.getSource(layer))
        this.map.removeSource(layer);
    }
  }

  clearMap(): void {
    const LayersToDelete = [...this.layers];
    LayersToDelete.forEach((layer) => {
      this.clearLayer(layer);
    });
    this.markerBoxShadow && this.markerBoxShadow.remove()
  }

  removeLayerFromList(layer: string) {
    const index = this.layers.indexOf(layer, 0);
    if (index > -1) {
      this.layers.splice(index, 1);
    }
  }

  private handleLineClick(event: any, classLayerName?: string): void {
    const pseudoImarkerClass = {
      className: classLayerName,
      classProperties: event.features[0].properties || event.features[0].classProperties,
    };

    if (classLayerName == 'PmvModel') {
      this.dataSelectPmv.next(pseudoImarkerClass);
    }

    this.linesClickDispatcher.next(pseudoImarkerClass);
  }

  getFilterColor(layer: string): any | string {
    const filterArray = ['case'];
    let defaultColorArray = ['#3383F9'];
    const filterStatus = ['boolean', ['feature-state', 'hover'], false];
    const propFilterRoadCorridors = [
      'case',
      ['==', ['get', 'speed'], 0], '#FFFFFF',
      ['<=', ['get', 'speed'], 10], '#FF3232',
      ['<=', ['get', 'speed'], 20], '#FF9901',
      '#00C479'
    ];
    const propFilterSpeedRangeWaze = [
      'case',
      ['<=', ['get', 'velocity'], 10], '#FF3232',
      ['<=', ['get', 'velocity'], 20], '#FF9901',
      '#00C479'
    ];
    const propFilterTrafficJamWaze = [
      'interpolate',
      ['linear'],
      ['get', 'level'],
      1,
      '#FFE0A5',
      2,
      '#FEC27D',
      3,
      '#FF9355',
      4,
      '#FF6947',
      5,
      '#FFFFFF',
    ];
    const propFilterCooncordance = [
      'step',
      ['get', 'concordance'],
      '#FC0606',
      -40,
      '#EF1818',
      -30,
      '#EF5959',
      -20,
      '#0372D9',
      -10,
      '#0372D9',
      0,
      '#0372D9',
      10,
      '#0372D9',
      20,
      '#0372D9',
      30,
      '#86AF45',
      40,
      '#9BC646',
      50,
      '#75EB21',
    ];

    if (layer.includes('ExodusAndReturnWaze')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterSpeedRangeWaze],
      ];
    }

    if (layer.includes('TrafficSpeedRange')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterRoadCorridors],
      ];
    }

    if (layer.includes('RoadCorridors')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterRoadCorridors],
      ];
    }

    if (layer.includes('TrafficJamWaze')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterTrafficJamWaze],
      ];
    }

    if (layer.includes('TrafficConcordance')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterCooncordance],
      ];
    }

    if (layer.includes('WazeDataSpeedRange')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterSpeedRangeWaze],
      ];
    }

    if (layer.includes('WazeDataCorridors')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterSpeedRangeWaze],
      ];
    }

    if (layer.includes('RoadRun')) {
      defaultColorArray = ['#B2FAFF'];
    }

    return defaultColorArray[0];
  }

  getHoverFilterColor(layer: string): any | string {
    const filterArray = ['case'];
    let defaultColorArray = ['#3383F9'];
    const filterStatus = ['boolean', ['feature-state', 'hover'], false];
    const propFilterRoadCorridors = [
      'case',
      ['==', ['get', 'speed'], 0], '#777777',
      ['<=', ['get', 'speed'], 10], '#fc6060',
      ['<=', ['get', 'speed'], 20], '#f8b456',
      '#6ff1be'
    ];
    const propFilterSpeedRangeWaze = [
      'case',
      ['<=', ['get', 'velocity'], 10], '#fc6060',
      ['<=', ['get', 'velocity'], 20], '#f8b456',
      '#6ff1be'
    ];
    const propFilterCooncordance = [
      'step',
      ['get', 'concordance'],
      '#FC0606',
      -40,
      '#EF1818',
      -30,
      '#EF5959',
      -20,
      '#0372D9',
      -10,
      '#0372D9',
      0,
      '#0372D9',
      10,
      '#0372D9',
      20,
      '#0372D9',
      30,
      '#86AF45',
      40,
      '#9BC646',
      50,
      '#75EB21',
    ];
    const propFilterTrafficJamWaze = [
      'interpolate',
      ['linear'],
      ['get', 'level'],
      1,
      '#fce1b7',
      2,
      '#f8c99b',
      3,
      '#f8ab7e',
      4,
      '#fc8d75',
      5,
      '#fd8484',
    ];

    if (layer.includes('TrafficSpeedRange') || layer.includes('TrafficSpeedRangeCorridors')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterRoadCorridors],
      ];
    }

    if (layer.includes('ExodusAndReturnWaze')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterSpeedRangeWaze],
      ];
    }

    if (layer.includes('RoadCorridors')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterRoadCorridors],
      ];
    }

    if (layer.includes('TrafficConcordance') || layer.includes('TrafficConcordanceCorridors')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterCooncordance],
      ];
    }

    if (layer.includes('TrafficJamWaze')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterTrafficJamWaze],
      ];
    }

    if (layer.includes('WazeDataSpeedRange')) {
      return [
        ...filterArray,
        ...[filterStatus],
        ...defaultColorArray,
        ...[propFilterSpeedRangeWaze],
      ];
    }

    if (layer.includes('RoadRun')) {
      defaultColorArray = ['#B2FAFF'];
    }

    return defaultColorArray[0];
  }

  setLineMetrics(layer: string): boolean {
    if (layer === 'AgentsHistorical' || layer === 'CranesHistorical' || layer === 'GroundResourceLasthours') {
      return true;
    }

    return false;
  }
  getLineWidth(layer: string): any | number {
    const interpolate = ['interpolate', ['linear'], ['zoom'], 8, 0.5, 22, 7];
    const interpolate2 = ['interpolate', ['linear'], ['zoom'], 8, 2, 22, 7];

    let defaultWidth = 1;

    if (layer === 'RoadRun') {
      defaultWidth = 1.5;
    }

    if (layer === 'TrafficSpeedRange' || layer === 'TrafficConcordance' || layer === 'TrafficConcordanceCorridors' || layer === 'RoadCorridors' || layer === 'TrafficJamWaze' || layer === 'ExodusAndReturnWaze' || layer === 'Coiline' || layer === 'WazeDataCorridors' || layer === 'WazeDataSpeedRange'
      || layer === 'TrafficSpeedRangeCorridors') {
      return interpolate;
    }
    if (layer === 'CoosLine' || layer === 'BicyclePath') {
      return interpolate2
    }


    return defaultWidth;
  }

  getLineGradient(): any {
    const lineGradient = [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      'blue',
      0.1,
      'royalblue',
      0.3,
      'cyan',
      0.5,
      'lime',
      0.7,
      'yellow',
      1,
      'red',
    ];

    return lineGradient;
  }
  setFilter(layer: string, filters: any): void {
    // all -> AND
    // any -> OR
    if (filters.length === 0) {
      this.map.setFilter(layer, null);
      return;
    }

    this.map.setFilter(layer, filters);
  }

  setLayerVisibility(layer: string, visibility: boolean) {
    this.map.setLayoutProperty(
      layer,
      'visibility',
      visibility ? 'visible' : 'none'
    );
  }

  setLayerSource(source: any, layer: string) {
    const getSource: AnySourceImpl = this.map.getSource(layer);
    if (getSource) {
      const layerSource = {
        type: 'FeatureCollection',
        features: source,
      };
      (getSource as any).setData(layerSource);
    }
  }

  setPopup(layer: string) {

    const popup = new Popup({
      closeButton: false,
      closeOnClick: false,
    });

    this.map.on('mouseenter', layer, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const tooltip = document.getElementById('tooltip');

      if (
        layer !== 'TrafficSpeedRange-Lines' &&
        layer !== 'TrafficConcordance-Lines' &&
        layer !== 'TrafficConcordanceCorridors-Lines' &&
        layer !== 'TrafficSpeedRangeCorridors-Lines' &&
        layer !== 'RoadCorridors-Lines' &&
        layer !== 'ExodusAndReturnWaze-Lines' &&
        layer !== 'WazeDataSpeedRange-Lines' &&
        layer !== 'WazeDataCorridors-Lines' &&
        layer !== 'TrafficJamWaze-Lines' &&
        layer !== 'action'
      ) {
        let coordinates;
        if (e.features[0].geometry.type === GeometryTypeEnum.Point) {
          coordinates = (e.features[0].geometry as any).coordinates.slice();

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
        } else {
          coordinates = e.lngLat;
        }
        let popupText = layer === 'Accident' ? (JSON.parse(e.features[0].properties['0'])['DIRECCION']) : e.features[0].properties.markerPopupText;
        popupText = popupText ?? '-';

        popup
          .setLngLat(coordinates as unknown as LngLat)
          .setHTML(popupText)
          .addTo(this.map);
      } else {
        const features = this.map.queryRenderedFeatures(e.point, { layers: [layer] });
        if (features.length > 0) {
          const lineId = features[0].properties?.id || features[0].properties?.uuid;
          this.map.setPaintProperty(layer, 'line-color', ['case', ['==', ['get', 'id'], lineId], this.getHoverFilterColor(layer), this.getFilterColor(layer)]);
          if (layer.includes('RoadCorridors')) {
            this.map.setPaintProperty('routearrows', 'text-color', ['case', ['==', ['get', 'id'], lineId], this.getHoverFilterColor(layer), this.getFilterColor(layer)]);
          }

          const lineInfo = layer.includes('ExodusAndReturnWaze') || layer.includes('WazeDataCorridors') || layer.includes('WazeDataSpeedRange') ? features[0].properties.markerPopupText : features[0].properties.composed_name ||
            features[0].properties.street ||
            features[0].properties.nameTo ||
            features[0].properties.name ||
            features[0].properties.street;

          let coordinates;
          if (e.features[0].geometry.type === GeometryTypeEnum.Point) {
            coordinates = (e.features[0].geometry as any).coordinates.slice();

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
          } else {
            coordinates = e.lngLat;
          }

          popup
            .setLngLat(coordinates as unknown as LngLat)
            .setHTML(lineInfo)
            .addTo(this.map);
        }
      }
    });

    this.map.on('mouseleave', layer, () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
      if (layer.includes('Lines')) {
        this.map.setPaintProperty(layer, 'line-color', this.getFilterColor(layer));
        if (layer.includes('RoadCorridors')) {
          this.map.setPaintProperty('routearrows', 'text-color', this.getFilterColor(layer));
        }
      }
    });
  }
}
