import { IMenuApiModel } from '@official/models/menu-api.model';
import { RouteNamesEnum } from '@official/routes/routes';
import { IHeaderModel } from '@types-custom/models/ui/header.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionType, IMenuModel } from '@types-custom/models/ui/menu.model';
import { BehaviorSubject } from 'rxjs';
import { MenuEventType } from '@shared/models/menu-event-type.model';
import { IRole } from '@shared/models/user.model';
import { modals } from '@shared/utils/mappers/modal';
import { ModalType } from '@shared/models/modal-type';

function apiRoute(apiUrl: string): ActionType {
  const Routes: string[] = [
    RouteNamesEnum.UserManagement,
    RouteNamesEnum.RolesManagement,
    RouteNamesEnum.PhysicalResourcesManagement,
    RouteNamesEnum.ActivitiesSchedule,
    RouteNamesEnum.IcidentParametersManagement,
    RouteNamesEnum.IncidentConfiguration,
    RouteNamesEnum.ManageGroundResource,
    RouteNamesEnum.DatexCategories,
    RouteNamesEnum.ManageGroundResource,
    RouteNamesEnum.ManageIncidentsCoordinator,
    RouteNamesEnum.FieldsManagement,
    RouteNamesEnum.NoveltyPhysicalResources,
  ]
  return Routes.includes(apiUrl) ? ActionType.Route : ActionType.Panel;
}
const menuAPIToMenu = (
  menuApi: IMenuApiModel,
  dispatcher?: BehaviorSubject<any>
): IMenuModel<any> => {
  return {
    action: menuApi.url,
    dispatcher: dispatcher,
    text: menuApi.name,
    alt: menuApi.name,
    isVisible: true,
    iconsubmenu: menuApi.icon,
    returnPanel: apiRoute(menuApi.url) !== ActionType.Route,
    actionPanel: RouteNamesEnum.MapViewer,
    actionType:
      apiRoute(menuApi.url),
  };
};

const generateSubmenus = (
  apiMenuList: IMenuApiModel[],
  dispatcher: BehaviorSubject<any>
): IMenuModel<any>[] => {

  const mainMenus = apiMenuList.map((menuAPI) => {
    const menu = menuAPIToMenu(menuAPI, dispatcher);
    return menu;
  });
  return mainMenus;
};

export const generateHeaderModel = (
  dispatcher: BehaviorSubject<any>,
  settingsMenuList?: IMenuApiModel[],
  accountTextInline?: string
): IHeaderModel<any> => {
  const headerModel: IHeaderModel<any> = {
    logo: 'assets/img/logo-Alcaldia-Cali.png',
    sublogo: 'assets/img/logo-cgm-blanco-azul.png',
    logoText: 'FUNCIONARIO',
    menus: [
      {
        icon: Icon.map,
        action: RouteNamesEnum.MapViewer,
        dispatcher,
        alt: 'Mapa',
        isVisible: true,
        actionType: ActionType.Route,
      },
      {
        icon: Icon.check_circle,
        action: RouteNamesEnum.Reports,
        actionType: ActionType.Route,
        dispatcher,
        alt: 'Reportes',
        isVisible: true,
        url: '',
        requiredRole: [IRole.VISUALIZADOR, IRole.ADMIN, IRole.CONFIGURACION],
      },
      {
        icon: Icon.settings_w,
        text: 'CONFIGURACIÓN',
        action: 'settings',
        dispatcher: undefined,
        alt: 'Configuración',
        subMenu: settingsMenuList
          ? generateSubmenus(settingsMenuList, dispatcher)
          : undefined,
      },
      {
        icon: Icon.dashboard_white,
        action: RouteNamesEnum.Dashboard,
        actionType: ActionType.Route,
        dispatcher,
        alt: 'Dashboard',
        isVisible: true,
        url: '',
      },
      {
        textInline: accountTextInline,
        icon: Icon.account_w,
        action: 'close_session',
        dispatcher,
        alt: 'Cerrar Sesión',
        subMenu: [
          {
            icon: Icon.lock_reset_w,
            text: 'Cambiar Contraseña',
            alt: 'Cambiar Contraseña',
            action: MenuEventType.change_pass,
            dispatcher,
            isVisible: true,
            actionType: ActionType.Modal,
            data: modals[ModalType.ChangePassword],
          },
          {
            icon: Icon.logout_w,
            text: 'Cerrar Sesión',
            alt: 'Cerrar Sesión',
            action: RouteNamesEnum.Login,
            dispatcher,
            isVisible: true,
            actionType: ActionType.Route,
          },
        ],
        isVisible: true,
      },
    ],
  };
  return headerModel;
};
