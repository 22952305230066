import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardPmwModel } from '@types-custom/models/ui/card-pmv-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { StatusforcameraipDTO } from '@shared/models/pmv/DTO/statusforcameraipDTO.model';
import { AllmessagesforcameraipDTO } from '@shared/models/pmv/DTO/allmessagesforcameraipDTO.model';
import {
  gridTableheadersMap,
  GridTypeEnum,
} from '@official/utils/mappers/grid-mapper';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import { IGridTableCellDispatcher } from '@types-custom/models/ui/grid-table-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  TextManagePanelMap,
  TitlePanelGridMap,
} from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { ExportExcelService } from '@official/services/data-grid-service/export-excel-service/export-excel.service';
import { PmvEditarService } from '@shared/services/layer-service/pmv-service/pmveditar.service';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { notificationCodeMessage } from '@types-custom/models/ui/panel-edit-pmv.model';

@Component({
  selector: 'citizen-panel-editar-pmv',
  templateUrl: './panel-editar-pmv.component.html',
  styleUrls: ['./panel-editar-pmv.component.scss'],
})
export class PanelEditarPmvComponent implements OnInit, OnDestroy {
  Icon = Icon;
  cards: ICardPmwModel[] = [];
  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];
  totalPwm = 0;
  subscription = new Subscription();
  dataStatusforcamera!: StatusforcameraipDTO;
  datacameraSelect!: any;
  dataMessagesscheduleforcamera!: AllmessagesforcameraipDTO[];

  createButtonProps: IButtonModel | undefined = undefined;
  searchButtonProps: IButtonModel | undefined = undefined;
  resetButtonProps: IButtonModel | undefined = undefined;
  promautomaticProps: IButtonModel | undefined = undefined;

  createprogramableButtonProps: IButtonModel | undefined = undefined;

  titlePanel = '';
  textPanel = '';
  titleGridPanel = '';
  panelOnManagement: MapEventTypeEnum = '' as MapEventTypeEnum;
  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }
  panelAction$ = new BehaviorSubject(this.panelManageActionsEnum.GRID);

  dataselect: any;
  gridColumnactivate = new Subject<IGridTableCellDispatcher>();
  gridColumndelete = new Subject<IGridTableCellDispatcher>();
  gridColumnedit = new Subject<IGridTableCellDispatcher>();

  gridPageProps: IGridPageModel;
  gridRowData: any;

  servicePanelDataSource: AbstractPanelManagementDataSource;
  isAllowDownload = true;

  layerName = GridTypeEnum.PMVEditar as GridTypeEnum;

  @Input() serviceDataSource: any;

  constructor(
    private exportExcelService: ExportExcelService,
    private drawerPanelService: DrawerPanelService,
    public pmvService: PmvEditarService,
    private modalService: ModalService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.totalPwm = this.cards.length ?? 0;
    this.datacameraSelect = this.cards[0];
    this.initializePanel();
    // this.pmvService.setIp(this.cards[0].ip);
    this.setdataStatusForCamera(this.cards[0].ip);
    this.pmvService.ExpIp = this.cards[0].ip;
  }

  private initializePanel(): void {
    this.titlePanel = this.layerName;
    this.textPanel = TextManagePanelMap[MapEventTypeEnum.PMV];
    this.titleGridPanel =
      TitlePanelGridMap[this.panelOnManagement] ??
      `REGISTROS DE ${this.titlePanel}`;

    this.createButtonProps = {
      label: `PROGRAMAR NUEVO`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.promautomaticProps = {
      preIcon: Icon.icono_reload,
      label: `PROGRAMACION AUTOMATICA`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button_pmv,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.resetButtonProps = {
      preIcon: Icon.reset_w,
      label: `RESETEAR PMV`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button_pmv,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.searchButtonProps = {
      label: `MENSAJES PROGRAMADOS`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.createprogramableButtonProps = {
      label: `PROGRAMAR MENSAJE`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.servicePanelDataSource = this.pmvService;
    this.gridPageProps = {
      headers: gridTableheadersMap([
        this.gridColumnactivate,
        this.gridColumnedit,
        this.gridColumndelete,
      ])[this.layerName],
      dataSource: this.servicePanelDataSource,
      isAllowDownload: this.isAllowDownload,
    };

    this.servicePanelDataSource.init();

    this.gridColumnactivate.subscribe((columnAction) =>
      this.handleColumnAction(columnAction)
    );

    this.gridColumnedit.subscribe((columnAction) =>
      this.handleColumnActionEdit(columnAction)
    );

    this.gridColumndelete.subscribe((columnAction) =>
      this.handleColumnActionDelete(columnAction)
    );
  }

  openModal(card: ICardPmwModel): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
    this.datacameraSelect = card;
    // this.setdataStatusForCamera(card.ip)
  }

  async setdataStatusForCamera(data: any) {
    [this.dataStatusforcamera, this.dataMessagesscheduleforcamera] =
      await Promise.all([
        lastValueFrom(this.pmvService.getStatusforcameraip(data)),
        lastValueFrom(this.pmvService.getAllMessagesforcameraip(data, 1, 5)),
      ]);

    this.pmvService.setIp(data);
    this.initializePanel();
  }

  handleDownloadGridAction(fileType: GridPageActions): void {
    this.pmvService.ExpIp = this.cards[0].ip;
    const excelUrl = this.servicePanelDataSource.buildExcelPdfUrl(
      fileType as unknown as string
    );
    this.exportExcelService.downloadFile(
      excelUrl,
      this.layerName,
      fileType as unknown as string
    );
  }

  handleColumnAction(columnAction: IGridTableCellDispatcher) {
    this.gridRowData = columnAction.row;
    this.dataselect = columnAction.row;

    const data = {
      messageNumber: columnAction.row.messageNumber,
      activate: true,
    };
    this.pmvService.activateMessage(data).subscribe((data) => {
      this.servicePanelDataSource.init();
    });
  }
  handleColumnActionEdit(columnAction: IGridTableCellDispatcher) {
    this.gridRowData = columnAction.row;
    this.dataselect = columnAction.row;
    this.onPanelManageAction(this.panelManageActionsEnum.EDIT);
  }

  handleColumnActionDelete(columnAction: IGridTableCellDispatcher) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: { infoMessage: '¿Está seguro que desea eliminar el registro?' },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.removeItem.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.gridRowData = columnAction.row;
    this.modalService.confirmationModal(modal);
  }

  removeItem() {
    this.pmvService.deleteMessage(this.gridRowData.messageNumber).subscribe({
      next: (data) => this.servicePanelDataSource.init(),
    });
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  resetpmv() {
    const modal: IUniqueModalModel = {
      title: '¿Está seguro que desea resetear el PMV?',
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage:
            'Esta acción hace un reset al panel cuando este se encuentra bloqueado. No resetean los mensajes ni la programación.',
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.modalService.confirmationModal(modal);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
    this.pmvService.restartPmv(this.datacameraSelect.ip).toPromise();
  }
}
