import { Validators } from '@angular/forms';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import {
  CodeValidatorNameEnum,
  greaterThanDateValidator,
  InputTypeEnum,
  IValidatorModel,
  numberCharactersValidator,
  ParametricFormI,
  specialCharactersValidator,
} from '@types-custom/models/ui/generic-form.model';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';
import { involvedVehicleModel } from './incidents-multiple/involved-vehicle.model';
import { IncidentFormEnum } from '@types-custom/models/ui/incidents-model';

const sensorsFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre sensor',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Geometría Sensor',
      formKey: 'point',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(100),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 100.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Localización',
      formKey: InputTypeEnum.LOCATION_INPUT,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(150),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 150.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Fabricante',
      formKey: 'manufacturer',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Comuna',
      formKey: 'locationId',
      listOptionsType: SensorOptionTypeEnum.LOCALITY,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Protocolo',
      formKey: 'protocol',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Tipo de sensor',
      formKey: 'sensorTypeId',
      listOptionsType: SensorOptionTypeEnum.TYPE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'statusId',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Versión',
      formKey: 'versionFw',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.INPUT,
      label: 'Modelo',
      formKey: 'model',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.INPUT,
      label: 'Serial',
      formKey: 'serial',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'usermane',
      formKey: 'userName',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'password',
      formKey: 'password',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'MAC',
      formKey: 'mac',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(100),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 100.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Gateway',
      formKey: 'gateway',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.TEXT,
      label: 'Observaciones',
      formKey: 'description',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(100),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 1000.',
          },
        ],
      },
    },
  ],
];

// modelo editar camaras
const camerasFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Geometría cámara',
      formKey: 'point',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(100),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 100.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Localización',
      formKey: InputTypeEnum.LOCATION_INPUT,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(150),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 150.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: InputTypeEnum.LOCALITY,
      listOptionsType: IncidentsListEnum.LOCALIDAD,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Protocolo',
      formKey: 'protocol',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Tipo de camara',
      formKey: 'cameraTypeId',
      listOptionsType: SensorOptionTypeEnum.TYPE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'statusId',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Versión',
      formKey: 'versionFw',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.INPUT,
      label: 'Modelo',
      formKey: 'model',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.INPUT,
      label: 'Serial',
      formKey: 'serial',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'usermane',
      formKey: 'userName',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'password',
      formKey: 'password',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'URL',
      formKey: 'url',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(500),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 500.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.TEXT,
      label: 'Descripción',
      formKey: 'description',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(1000),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 1000.',
          },
        ],
      },
    },
  ],
];

// model edit variable messaging panel
const variableMessagingFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre panel',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Geometría',
      formKey: 'point',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(100),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 100.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Localización',
      formKey: InputTypeEnum.LOCATION_INPUT,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(150),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 150.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Comuna',
      formKey: 'communeId',
      listOptionsType: SensorOptionTypeEnum.LOCALITY,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Serial',
      formKey: 'serial',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Fabricante',
      formKey: 'manufacturer',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Protocolo',
      formKey: 'protocol',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'stateId',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Ip',
      formKey: 'ip',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: numberCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.NUMBERS_CHARACTERS,
            errorMessage: 'Este campo sólo acepta números enteros y (.)',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Puerto',
      formKey: 'port',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(50),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 50.',
          },
          {
            validator: numberCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.NUMBERS_CHARACTERS,
            errorMessage: 'Este campo sólo acepta números enteros y (.)',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.TOOGLESWITCH,
      label: 'Programación automática',
      formKey: 'automaticProgramming',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
];
const incidentsFormModel: ParametricFormI[][] = [

  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      hiddenControl: true,
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.DATETIME,
      label: 'Fecha y hora de creación',
      formKey: IncidentFormEnum.beginDate,
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
      },

    },
    {
      type: InputTypeEnum.DATETIME,
      label: 'Fecha y hora, última modificación',
      formKey: IncidentFormEnum.endDate,
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.DATETIME,
      label: 'Fecha y hora del incidente',
      formKey: IncidentFormEnum.incidentTime,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: greaterThanDateValidator('beginDate'),
            validatorCodeName: CodeValidatorNameEnum.GREATER_DATE,
            errorMessage: 'Fecha inválida.'
          }
        ]
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Ubicación',
      formKey: InputTypeEnum.LOCATION_INPUT,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
          {
            validator: Validators.maxLength(150),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 150.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Latitud',
      formKey: IncidentFormEnum.latitude,
      hiddenControl: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
        ],
      },
    },
    {
      type: InputTypeEnum.INPUT,
      label: 'Longitud',
      formKey: IncidentFormEnum.longitude,
      hiddenControl: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: InputTypeEnum.LOCALITY,
      listOptionsType: IncidentsListEnum.LOCALIDAD,
      turnLabelAsValue: true,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Corredor',
      formKey: IncidentFormEnum.brokerId,
      listOptionsType: IncidentsListEnum.CORREDOR,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Orientación de calle o carrera',
      formKey: IncidentFormEnum.orientId,
      listOptionsType: IncidentsListEnum.ORIENTACION,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    }
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Zona',
      formKey: 'zoneId',
      listOptionsType: IncidentsListEnum.ZONA,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ]
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Categoría',
      formKey: 'classesId',
      listOptionsType: IncidentsListEnum.CATEGORIA,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Sub-categoría',
      formKey: 'typeId',
      hiddenControl: true,
      parentFormKey: "classesId",
      listOptionsType: IncidentsListEnum.SUBCATEGORIA,
      cacheList: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    }
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Objeto',
      formKey: 'objectId',
      listOptionsType: IncidentsListEnum.OBJETO,
      hiddenControl: true,
      parentFormKey: "typeId",
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Gravedad',
      formKey: 'gravityId',
      listOptionsType: IncidentsListEnum.GRAVEDAD,
      hiddenControl: true,
      parentFormKey: 'classesId',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Prioridad',
      formKey: 'priorityId',
      listOptionsType: IncidentsListEnum.PRIORIDAD,
      ancestorValueDependant: {
        key: 'gravityId',
        type: 'PRIORITY'
      },
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Operador',
      formKey: 'creatorUserName',
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Fuente',
      formKey: 'sourceId',
      listOptionsType: IncidentsListEnum.FUENTE,
      cacheList: true,
      // conditionalFormMap: incidentTypeMap,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Estado',
      formKey: 'stateId',
      ancestorValueDependant: {
        key: 'sourceId',
        type: 'STATE'
      },
      listOptionsType: IncidentsListEnum.ESTADO_INCIDENTE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.'
          }
        ]
      }
    }
  ],
  [
    {
      type: InputTypeEnum.BUTTON_RADIO,
      label: 'Implicados',
      formKey: 'involvedAmount',
      listOptionsType: IncidentsListEnum.NUM_VEHICULO_INPLICADO,
      hiddenControl: true,
      parentFormKey: 'classesId',
      multipleFormControl: true,
      multipleFormControlModel: involvedVehicleModel,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.TEXT,
      label: 'Descripción',
      formKey: 'observation',
      formControl: {
        value: '',
        options: {
          focus: true,
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.TEXT,
      label: 'CAMPO OCULTO',
      formKey: 'observations',
      hiddenControl: true,
      formControl: {
        value: '',
        options: {
          disabled: true,
          nonNullable: true,
        },
      },
    },
  ],

];
export const editFormModelMap: {
  [key: string]: ParametricFormI[][];
} = {
  [MapEventTypeEnum.SENSORS]: sensorsFormModel,
  [MapEventTypeEnum.CAMERAS]: camerasFormModel,
  [MapEventTypeEnum.INCIDENTS]: incidentsFormModel,
  [MapEventTypeEnum.PMV]: variableMessagingFormModel,
};
