import {AfterContentInit, Component, Input, OnInit} from "@angular/core";
import {BehaviorSubject, Subscription} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {ICardPredictiveResourcesModel} from "@types-custom/models/ui/card-predictiveresources.model";
import {FieldResourcesService} from "@shared/services/layer-service/field-resources/field-resources.service";
import {PredictiveService} from "@shared/services/predictive-service/predictive.service";

@Component({
  selector: "sit-predictive-resources-panel",
  templateUrl: "./predictive-resources-panel.component.html",
  styleUrls: ["./predictive-resources-panel.component.scss"],
})
export class PredictiveResourcesPanelComponent implements AfterContentInit{

  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;
  @Input() Data : any
  Icon = Icon;
  cards : ICardPredictiveResourcesModel[]

  AgentsCounts  : number = 0
  CranesCounts  : number = 0

  organizedData : any[] = [];

  KPIAgents = {
    resourceTypeName  : "Agentes",
    TotalCount        : 0
  }

  KPICranes = {
    resourceTypeName  : "Grúas",
    TotalCount        : 0
  }

  private cardsDispatcherSubscription: Subscription | undefined;

  constructor(private predictiveService : PredictiveService) {
  }

  ngAfterContentInit(): void {
    this.initStacks();
    this.cardsDispatcherSubscription = this.predictiveService.$cards.subscribe((res : any) => {
      if(res.length > 0) {
        this.cards = res
        this.initStacks()
      }
    })
    }

  private initStacks(queryParam? : string) {

    this.organizedData = []
    this.AgentsCounts = 0
    this.CranesCounts = 0

    this.cards.forEach((e : ICardPredictiveResourcesModel) => {

      const locality  = e.localityName;
      const groupId  = e.type;

      let localityObj = this.organizedData.find((obj: { locality_Name: string; }) => obj.locality_Name === locality);

      if (!localityObj) {
        localityObj = {
          locality_Name: locality,
          policeCount: 0,
          craneCount: 0
        };
        this.organizedData.push(localityObj);
      }

      if (groupId === "agents") {
        localityObj.policeCount++;
        this.AgentsCounts += 1
      } else {
        localityObj.craneCount++;
        this.CranesCounts += 1
      }
    })

    this.KPIAgents.TotalCount = this.AgentsCounts
    this.KPICranes.TotalCount = this.CranesCounts

  }

}
