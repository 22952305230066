<div class="form-container pr-24 pl-24 mb-32 mt-32">
  <span class="seccionTitle text-color-white"> Recursos Fisicos</span>
  
  <form [formGroup]="registerFormGroup" class="login-form" ng>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
        <mat-form-field class="textWhite" appearance="outline">
          <mat-label class="text-white">Tipo de recurso Fisico</mat-label>
    
          <select matNativeControl formControlName="typeResource">
            <option *ngFor="let item of typeResource ; let i=index" [value]="item.id">{{item.name}}</option>
          </select>
          <mat-error>{{ typeResourceErrorMessage }}</mat-error>
        </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="text-white" appearance="outline">
        <mat-label class="text-white">Recurso físico a asignar</mat-label>

        <input
          type="text"
          class="borders"
          matInput
          [formControl]="physicalResourceCode"
          [matAutocomplete]="auto"
          required
        />
        <sit-icon-button
          matSuffix
          *ngIf="physicalResourceCode.value"
          (click)="physicalResourceCode.setValue(null)"
          [icon]="Icon.close_w"
          [colorClass]="'text-white'"
          [sizeClass]="'icon-size-20'"
          alt="Icono cerrar para limpiar campo"
        ></sit-icon-button>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let option of filteredOptions  | async "
            [value]="option"
          >
            {{ option.id }}-{{option.feature}}
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ codeResourceErrorMessage }}</mat-error> 
      </mat-form-field>

      <!-- <mat-form-field class="textWhite" appearance="outline">
          <input matInput formControlName="codeType" matTooltipPosition="below" matTooltipClass="custom-tooltip"
            matTooltip="ABC123" />
         
        </mat-form-field> -->
      <sit-button (click)="  registerFormGroup.valid ? AddPhysicalResource():validateFormGroup() " [buttonProperties]="buttonEditAttributes"></sit-button>
    </div>

    <div class="seccionTitle text-color-white mat-input d-flex fd-row align-items-center mb-16 mt-15">
      Condicion: 
      <ng-container *ngIf="objsNotAssigne">
        <span class="textWhite"> {{ objsNotAssigne[objsNotAssigne.length -1]?.conditionName  || ''}}</span>
      </ng-container>
    </div>


    <div class="row-resource">

      <div class="item-cont" *ngFor="let item of listdata">
        <span class="text-color-white item-resource">{{item.code}}--{{item.feature}}</span> 
        <a class="btn-dete" (click)="eliminarObjeto(item)"> <sit-icon [icon]="Icon.delete_w" [sizeClass]="'icon-size-24'"></sit-icon></a>
      </div>
      
      <!-- <div class="item-cont" *ngFor="let item of objsNotAssigne">
        <span class="text-color-white item-resource">{{item.code}}--{{item.feature}}</span> 
        <button class="btn-dete" (click)="eliminarObjeto(item)">
          <sit-icon [icon]="Icon.delete_w" [sizeClass]="'icon-size-24'" ></sit-icon>
        </button>
      </div> -->

    </div>
    
  </form>

  <div class="modal-actions d-flex align-items-end justify-end pt-30">
    <ng-container>
      <sit-button (click)="onCancel()" [buttonProperties]="buttonCancelAttributes"></sit-button>
    </ng-container>
    <ng-container>
      <sit-button
      
      (click)="onSubmit()"  
        [disabled]="!registerFormGroup.valid" [buttonProperties]="buttonSaveAttributes">   
      </sit-button>
    </ng-container>
  </div>

</div>