<div
  class="modal-box d-flex fd-row f-wrap flex-sm-nowrap align-items-center justify-center bg-color-background-3"
>
  <div class="text-modal-succsess">
    <sit-icon
      [icon]="icon"
      alt="icono confirmación exitosa"
      [sizeClass]="'icon-size-58 icon-sm-size-46'"
      class="text-green icon-rol-check"
    ></sit-icon>
    <span class="text-white text-size-24 text-sm-size-14 text-weight-600">{{
      infoMessage
    }}</span>
  </div>
</div>
