import { Injectable } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import {LoginAuthService} from "@shared/services/login-auth/login-auth.service";
import {
  LocalStorageManagementService
} from "@shared/services/local-storage-management/local-storage-management.service";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Injectable()
export class OAuthConfigService {

  private _decodedAccessToken: any;
  private _decodedIDToken: any;
  get decodedAccessToken() { return this._decodedAccessToken; }
  get decodedIDToken() { return this._decodedIDToken; }

  constructor(
    private readonly oauthService: OAuthService,
    private readonly authConfig: AuthConfig,
    private loginService : LoginAuthService,
    private localStorageManagementService : LocalStorageManagementService
  ) {}

  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      // setup oauthService
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(sessionStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      //this.oauthService.createAndSaveNonce();

      this.oauthService.events
          .pipe(filter((e: any) => {
            return e.type === 'token_received';
          }))
          .subscribe(() => this.handleNewToken());

      this.oauthService.loadDiscoveryDocumentAndLogin().then(isLoggedIn => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh();
          this.loginService.getUserInfoTotal(this.oauthService.getIdentityClaims().email).subscribe((res) => {
            res = res[0];
            res.roles = res.rolNames;
            res.names = res.name;
            delete res.rolNames;
            delete res.name;
            this.localStorageManagementService.setUserLocalStorage(res);
            //window.location.reload()
            resolveFn();
          })
        } else {
          this.oauthService.initImplicitFlow();
          rejectFn();
        }
      })
    });
  }

  private handleNewToken() {
    this._decodedAccessToken = this.oauthService.getAccessToken();
    this._decodedIDToken = this.oauthService.getIdToken();
  }

}
