<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
    <div class="pb-1-rem pt-20 full-width">
        <div class="grid-container-buttons">
            
            <a 
            class="buttonPanel"
            (click)="OnFilter('speed')" 
            type="submit"
            [ngClass]="{
                'active-action': validateActiveSpeed
            }">
                Velocidad
            </a>

            <a 
            class="buttonPanel"
            (click)="OnFilter('road')"
            type="submit"
            [ngClass]="{
                'active-action': validateActiveRoad
            }"
            aria-label="">
                Recorrido
            </a>
        </div>
    </div>

    <div class=" d-flex px-1 mb-1-em">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.speed_range_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título rango de velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
                Rango por corredor
            </h3>
        </div>
    </div>

    <div class="full-height full-width minSpeed" *ngIf="validateActiveSpeed && !validateActiveRoad">
        <div class="tittleGraphic mt-2-em">
            <span class="text-weight-400 text-size-14 text-md-size-16 text-white align-self-center">Corredores de entrada</span>
        </div>
        <sit-chart-column [data]="listspeedMaxEnter" [id]="'speed'" [valueXText]="'km/h'" className="h-350 special-chart"></sit-chart-column>
        <div class="tittleGraphic mt-2-em">
            <span class="text-weight-400 text-size-14 text-md-size-16 text-white align-self-center">Corredores de salida</span>
        </div>
        <sit-chart-column [data]="listspeedMaxLeaving" [id]="'speed'" [valueXText]="'km/h'" className="h-350 special-chart mb-2-em"></sit-chart-column>
    </div>

    <div class="full-height full-width minSpeed" *ngIf="!validateActiveSpeed && validateActiveRoad">
        <div class="tittleGraphic mt-2-em">
          <span class="text-weight-400 text-size-14 text-md-size-16 text-white align-self-center">Corredores de entrada</span>
        </div>
        <sit-chart-column-inverted [data]="listrouteMaxEnter" [id]="'speed'" [valueXText]="'minutos'" className="h-350 special-chart"></sit-chart-column-inverted>
        <div class="tittleGraphic mt-2-em">
          <span class="text-weight-400 text-size-14 text-md-size-16 text-white align-self-center">Corredores de salida</span>
        </div>
        <sit-chart-column-inverted [data]="listrouteMaxLeaving" [id]="'speed'" [valueXText]="'minutos'" className="h-350 special-chart mb-2-em"></sit-chart-column-inverted>
    </div>

    <div class="d-flex px-1 mb-1-em mt-1-em marginRoads">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="camino icono título rutas principales"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
                Rutas principales
            </h3>
        </div>
    </div>

    <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher">
        <ng-container *ngFor="let card of cardsMainRoutes; let i = index" class="pb-1-rem">
            <sit-list-exodus-return-cards [cards]="cardsMainRoutes"></sit-list-exodus-return-cards>
        </ng-container>
    </div>

</div>