import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthActionEnum,
  // SocialLoginPathEnum,
} from '@shared/models/auth-models.model';
import { IUserAuthModel } from '@shared/models/user.model';
import { concatMap, forkJoin, map, Observable, switchMap } from 'rxjs';
import { LocalStorageManagementService } from '../local-storage-management/local-storage-management.service';
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({ providedIn: 'root' })
export class LoginAuthService {
  constructor(
      private http: HttpClient,
      private router: Router,
      private localStorageManagementService: LocalStorageManagementService,
      private oauthservice: OAuthService,
      @Inject('environment') private environment: any
  ) { }

  login(userCredentials: any): Observable<IUserAuthModel> {
    const body = new URLSearchParams();
    body.set('client_id', this.environment.keyCloakParams.clientId);
    body.set('client_secret', this.environment.keyCloakParams.clientSecret);
    body.set('grant_type', 'password');
    body.set('username', userCredentials.username);
    body.set('password', userCredentials.password);
    body.set('scope', 'openid');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers: headers };

    const url = `${this.environment.keyCloakAuthUrl}/${AuthActionEnum.Login}`;

    return this.http.post<any>(url, body, options).pipe(
        switchMap((tokenResponse) => {
          return forkJoin({
            userInfoResponse: this.getUserInfo(tokenResponse),
            userRolesResponse: this.getUserRoles(tokenResponse),
          }).pipe(
              map(({ userInfoResponse, userRolesResponse }) => {
                const userAuthInfo: IUserAuthModel = {
                  tokenType: tokenResponse.token_type,
                  token: tokenResponse.access_token,
                  email: userInfoResponse.email,
                  names: userInfoResponse.name,
                  roles: userRolesResponse,
                  loginType: 'auth',
                };
                return userAuthInfo;
              })
          );
        })
    );
  }

  loginWithSocialNetwork(params: any) {
    const body = new URLSearchParams();
    body.set('client_id', this.environment.keyCloakParams.clientId);
    body.set('client_secret', this.environment.keyCloakParams.clientSecret);
    body.set('grant_type', 'authorization_code');
    body.set('code', params.code);
    body.set('redirect_uri', this.environment.keyCloakParams.redirect_uri);
    body.set('scope', 'openid');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers: headers };
    const url = `${this.environment.keyCloakAuthUrl}/${AuthActionEnum.Login}`;

    return this.http.post<any>(url, body, options).pipe(
        switchMap((tokenResponse) => {
          return forkJoin({
            userInfoResponse: this.getUserInfo(tokenResponse),
            userRolesResponse: this.getUserRoles(tokenResponse),
          }).pipe(
              map(({ userInfoResponse, userRolesResponse }) => {
                const userAuthInfo: IUserAuthModel = {
                  tokenType: tokenResponse.token_type,
                  token: tokenResponse.access_token,
                  email: userInfoResponse.email,
                  names: userInfoResponse.name,
                  roles: userRolesResponse.roles,
                  loginType: 'social',
                };
                return userAuthInfo;
              })
          );
        })
    );
  }

  logout() {
    this.oauthservice.logOut();
    this.router.navigate(['/']);
    this.localStorageManagementService.clearUserLocalStorage();
  }

  register(user: any) {
    return this.obtenerToken().pipe(
        concatMap((res: any): Observable<any> => {
          const headers = new HttpHeaders({
            Authorization: 'Bearer ' + res.access_token,
          });
          const options = { headers: headers };
          return this.http.post<any>(
              `${this.environment.authUrl}/user/`,
              user,
              options
          );
        })
    );
  }

  obtenerToken() {
    const body = new URLSearchParams();
    body.set('client_id', this.environment.getTokenParams.client_id);
    body.set('client_secret', this.environment.getTokenParams.client_secret);
    body.set('grant_type', this.environment.getTokenParams.grant_type);
    body.set('username', this.environment.getTokenParams.username);
    body.set('password', this.environment.getTokenParams.password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers: headers };

    const url = `${this.environment.keyCloakAuthUrl}/${AuthActionEnum.Login}`;

    return this.http.post(url, body, options);
  }

  emailForgotPass(dataForm: any) {
    const url = `${this.environment.userUrl}/password/send-recover-email?userEmail=${dataForm.email}`;
    const body = dataForm;
    return this.http.post(url, body);
  }

  verificationForgotPass(dataForm: any) {
    const url = `${this.environment.userUrl}/password/send-recover-email?userEmail=${dataForm.email}&confirmCode=${dataForm.code}&newPassword=${dataForm.newPassword}`;
    const body = dataForm;
    return this.http.post(url, body);
  }

  resetPassword(dataForm: any) {
    const url = `${this.environment.userUrl}/password/reset-password`;

    const params = {
      userEmail: dataForm.email,
      confirmCode: dataForm.code,
      newPassword: dataForm.newPassword,
    };

    return this.http.put(url, {}, { params: params });
  }

  changePassword(params: any) {
    const url = `${this.environment.userUrl}/user/password/change-password`;
    return this.http.put(url, params);
  }

  setSingleSession() {
    const url = `${this.environment.userUrl}/offline-sessions-revoke`;
    return this.http.post(url, {});
  }

  public getUser(params: any): Observable<any> {
    return this.obtenerToken().pipe(
        concatMap((res: any): Observable<any> => {
          const headers = new HttpHeaders({
            Authorization: 'Bearer ' + res.access_token,
          });
          const options = { headers: headers, params: params };
          const url = `${this.environment.userUrl}/`;

          return this.http.get<any>(url, options);
        })
    );
  }

  getUserInfo(params: any): Observable<any> {
    const url = `${this.environment.keyCloakAuthUrl}/${AuthActionEnum.UserInfo}`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + params.access_token,
    });
    const options = { headers: headers };
    return this.http.post(url, {}, options);
  }

  getUserInfoTotal(email: string): Observable<any> {
    return this.http.get(`${this.environment.userUrl}/user/?email=${email}`)
  }

  getUserRoles(params: any): Observable<any> {
    const url = `${this.environment.userUrl}/roles`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + params.access_token,
    });
    const options = { headers: headers };
    return this.http.get(url, options);
  }
}
