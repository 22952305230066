<loader-overlay></loader-overlay>
<modal></modal>

<main class="app-container full-height">
  <h1>SIT Cali - Portal del Funcionario</h1>
  <section class="section-header">
    <ng-container *ngIf="{
        currentRoute$: currentRoute$ | async,
        headerModel$: headerModel$ | async
      } as status">
      <ng-container *ngIf="
          status.currentRoute$ &&
          status.currentRoute$ !== RouteNamesEnum.Login &&
          status.headerModel$ as headerModel
        ">
        <header [properties]="headerModel" [visibleSideMenu]="false" (flagSideMenu)="toggleSideMenu($event)"
          [indicators]="indicators" [indicatorProps]="indicatorProps">

        </header>
      </ng-container>
    </ng-container>
  </section>
  <section class="content-container">
    <div class="full-width full-height">
      <router-outlet></router-outlet>
    </div>
  </section>
</main>
