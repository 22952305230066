import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AirIndicators} from "@types-custom/models/ui/air_quality/air-quality-modal.model";

@Component({
  selector: "sit-air-quality-indicators",
  templateUrl: "./air-quality-indicators.component.html",
  styleUrls: ["./air-quality-indicators.component.scss"],
})
export class AirQualityIndicatorsComponent implements OnInit{
  @Input()  properties          : AirIndicators;
  @Input()  active              : boolean                     = false;
  @Output() IndicatorDispatcher : EventEmitter<AirIndicators> = new EventEmitter<AirIndicators>;

  ngOnInit() {
    console.log(this.active)
  }

  public SelectIndicator(item : AirIndicators) {
    this.IndicatorDispatcher.emit(item);
  }

  public DefineColor(value: string) {
    // Buena                        1-10    #00FFFF
    // Razonablemente Buena         11-20   #00B050
    // Regular                      21-30   #FFCC00
    // Desfavorable                 31-40   #FF3300
    // Muy Desfavorable             41-50   #9A0000
    // Extremadamente desfavorable  51-60   #9A00FF
    var number = Number(value)
    if(number > 0 && number < 11) {
      return "#00FFFF"
    }
    else if(number > 10 && number < 21) {
      return "#00B050"
    }
    else if(number > 20 && number < 31) {
      return "#FFCC00"
    }
    else if(number > 30 && number < 41) {
      return "#FF3300"
    }
    else if(number > 40 && number < 51) {
      return "#9A0000"
    }
    else if(number > 50 && number < 61) {
      return "#9A00FF"
    }
    else {
      return "#9A00FF"
    }
  }
}
