import { Component, Input } from '@angular/core';
import { IModalTrafficManagmentPathModel } from '@types-custom/models/ui/modal-trafficManagment-path';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'citizen-modal-traffic-managment',
  templateUrl: './modal-traffic-managment.component.html',
  styleUrls: ['./modal-traffic-managment.component.scss'],
})
export class ModalTrafficManagmentComponent {
  @Input() properties: IModalTrafficManagmentPathModel | undefined;
  Icon = Icon;
}
