<br>
<span class="text-white text-weight-500 text-size-18 text-md-size-18 mb-1 ml-16">Tipos *</span>
<div class="buttons mb-1 mt-1-em ml-16">
  <sit-button [buttonProperties]="IncidentButtonProps"  class="width-12 text-white buttons_align"
  (click)="OnFilter('incidents')"
  [ngClass]="{
    'active-action': validateActiveIncidents}"></sit-button>
  <sit-button [buttonProperties]="RoadCloseButtonProps" class="width-12 text-white buttons_align"
  (click)="OnFilter('roadClosed')"
  [ngClass]="{
    'active-action': validateActiveRoadClosed}"></sit-button>
  <sit-button [buttonProperties]="ClimaticButtonProps"  class="width-12 text-white buttons_align"
  (click)="OnFilter('climatic')"
  [ngClass]="{
    'active-action': validateActiveClimatic}"></sit-button>

    <!-- <mat-form-field class="text-white" appearance="outline">
      <mat-label class="text-white">Alertas</mat-label>
  
      <input
        type="text"
        matInput
        [formControl]="agent"
        [matAutocomplete]="auto"
      />
      <sit-icon-button
        matSuffix
        *ngIf="agent.value"
        (click)="agent.reset(''); filterAgent('')"
        [icon]="Icon.close_w"
        [colorClass]="'text-white'"
        [sizeClass]="'icon-size-20'"
        alt="Icono cerrar para limpiar campo"
      ></sit-icon-button>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="filterAgent($event.option.value)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->
  </div>

  <span class="text-white text-weight-500 text-size-18 text-md-size-18 mb-1 ml-16">Localidades *</span>

  <mat-form-field class="text-white" appearance="outline">
      <!-- <mat-label class="text-white">Localidades</mat-label> -->
  
      <input
        type="text"
        matInput
        [formControl]="agent"
        [matAutocomplete]="auto"
        placeholder="Localidades"
      />
      <sit-icon-button
        matSuffix
        *ngIf="agent.value"
        (click)="resetFilterLocations()"
        [icon]="Icon.close_w"
        [colorClass]="'text-white'"
        [sizeClass]="'icon-size-20'"
        alt="Icono cerrar para limpiar campo"
      ></sit-icon-button>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="filterAlert($event.option.value)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let option of locations"
          [value]="option"
        >
          {{ option.LocNombre }}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
  <div class="cleanButton">
    <sit-button [buttonProperties]="CleanButtonProps"  class="width-20 text-white"
    (click)="resetFilter()"></sit-button>
  </div>
  