import { Injectable } from '@angular/core';
import { IUserAuthModel, IUserModel } from '@shared/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { CryptoService } from '@shared/services/crypto-service/crypto.service';
import {OAuthService} from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root',
})
export class LocalStorageManagementService {
  localStorageKey!: string;

  private userInfo = new BehaviorSubject<IUserAuthModel>(undefined);
  userInfo$ = this.userInfo.asObservable();

  constructor(private cryptoService: CryptoService, private oauthService: OAuthService) {}

  setLocalStorageKey(key: string): void {
    this.localStorageKey = key;
  }

  setUserLocalStorage(userInfo: IUserAuthModel | any): void {
    this.loadUserInfo(userInfo);

    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  getUserLocalStorage(): IUserAuthModel | undefined | any {
    return this.oauthService.getAccessToken();
  }

  clearUserLocalStorage(): void {
    sessionStorage.removeItem('userInfo');
    this.loadUserInfo(undefined);
  }

  private loadUserInfo(userInfo?: IUserAuthModel): void {
    this.userInfo.next(userInfo);
  }
}
