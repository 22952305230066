<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">
    <div class="pb-1-rem pt-20">
        <div class=" d-flex px-1">
            <div class="d-flex full-width py-05 bg-color-background-1 title-card">
                <sit-icon [icon]="Icon.chronometer_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                          alt="velocímetro icono título velocidad"></sit-icon>
                <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                    Velocidad
                </h3>
            </div>
        </div>

        <div class="d-flex ind-row">
            <div class="d-flex justify-space-around full-width py-container-speed">
                <div class="container-graphic-speed">
                    <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
                </div>
            </div>
            <div class="d-flex justify-space-around full-width py-container-speed">
                <div class="container-graphic-speed">
                    <sit-incidents [properties]="avgIncidentsData"></sit-incidents>
                </div>
            </div>
        </div>
    </div>

    <div class=" d-flex px-1">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.speed_range_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título rango de velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                Rango de Velocidad
            </h3>
        </div>
    </div>

    <div class="full-height full-width">
        <sit-chart-column-waze [data]="listspeed" [id]="'speed-range'" [valueXAttrib]="'dta'" [valueXText]="'Número de corredores'"  [valueYText]="'Km/h'" className="h-350 special-chart"></sit-chart-column-waze>
    </div>

    <div class="d-flex px-1 mb-1-em">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="camino icono título rutas principales"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                Corredores
            </h3>
        </div>
    </div>

    <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher">
        <ng-container *ngFor="let card of cards; let i = index" class="pb-1-rem">
            <sit-list-corridors [card]="card" [number]="i"></sit-list-corridors>
        </ng-container>
    </div>

</div>