<div class="grid-container text-white text-weight-600">
  <div class="card">
    <span class="text-size-16 text-res">VELOCIDAD</span>
    <sit-icon
      [icon]="Icon.speed_range_blue"
      alt="velocímetro"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.speed }} km/h</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">DISTANCIA</span>
    <sit-icon
      [icon]="Icon.distance_w"
      alt="tramo con punteros"
      [sizeClass]="'icon-size-64'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.distance }} m</span>
  </div>

  <div class="card">
    <span class="text-size-16 text-res">PENDIENTE</span>
    <sit-icon
      [icon]="Icon.check_w"
      alt="montaña con pendiente"
      [sizeClass]="'icon-size-64'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.slope }}%</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">ANCHO DE LA VÍA</span>
    <sit-icon
      [icon]="Icon.street_width_blue"
      alt="tramo de camino"
      [sizeClass]="'icon-size-64'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.distance }} m</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">ESTADO DE LA VÍA</span>
    <sit-icon
      [icon]="Icon.street_blue"
      alt="tramo de camino"
      [sizeClass]="'icon-size-64'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.status }}</span>
  </div>
</div>
