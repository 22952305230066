<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">
    <div class="pb-1-rem pt-20">
        <div class=" d-flex px-1">
            <div class="d-flex full-width py-05 bg-color-background-1 title-card">
                <sit-icon [icon]="Icon.FieldResourcesHumanGroup" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                          alt="velocímetro icono título velocidad"></sit-icon>
                <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                    Ejecución de actividades por tipo de recurso
                </h3>
            </div>
        </div>
        <div class="d-flex gap-10-px justify-center mt-1-em">
            <sit-kpiresources [KPIData]="this.CentipedeGeneral" [CardWith]="'dcrem'"></sit-kpiresources>
            <sit-kpiresources [KPIData]="this.CycleGuideGeneral"[CardWith]="'dcrem'"></sit-kpiresources>
            <sit-kpiresources [KPIData]="this.GroupGuideGeneral"[CardWith]="'dcrem'"></sit-kpiresources>
        </div>
    </div>

    <div class=" d-flex px-1">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.FieldResourcesHumanGroup" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título rango de velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                Recursos Activos
            </h3>
        </div>
    </div>
    <div class="d-flex fd-row chart-line-container">
        <sit-chart-series [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width h-350">
        </sit-chart-series>
    </div>

    <div class="d-flex px-1 mb-1-em">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.FieldResourcesHumanGroup" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="camino icono título rutas principales"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                Recursos en Localidades
            </h3>
        </div>
    </div>

    <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher" *ngIf="cardsLocality.length > 0">
        <sit-resources-list-locality *ngFor="let item of cardsLocality" [card]="item"></sit-resources-list-locality>
    </div>
</div>
