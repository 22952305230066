import { Component, Input } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IMapConventionsPropertiesModel } from '@types-custom/models/ui/map-conventions-model';

/*
** Map Conventions
** Render a Convention panel
*/
@Component({
  selector: 'sit-map-conventions',
  templateUrl: './map-conventions.component.html',
  styleUrls: ['./map-conventions.component.scss'],
})
export class MapConventionsComponent {
  @Input() mapConventionsProperties: IMapConventionsPropertiesModel | undefined;
  Icon = Icon;

  onShowPanel() {
    this.mapConventionsProperties?.showPanel$?.value
      ? this.mapConventionsProperties?.showPanel$?.next(false)
      : this.mapConventionsProperties?.showPanel$?.next(true);
  }

  closePanel() {
    this.mapConventionsProperties?.showPanel$?.next(false);
  }
}
