<div class="modal-alerts-container d-flex fd-col">
    <div class="modal-alerts-duplicates d-flex fd-col full-height overflow-y-auto overflow-x-none custom-scrollbar"
        tabindex="0">
        <div class="modal-traffic w-100 h-auto d-flex fd-col text-weight-600 pt-30">
            <div class="wrapper-speed pb-20">
                <div class="container-info separator-line-info indicator-container">
                    <div class="element">
                        <span class="title">
                            {{this.accidentType}}
                        </span>
                        <span class="subject">
                            Tipo
                        </span>
                    </div>
                </div>

                <div class="container-info separator-line-info indicator-container">
                    <div class="element">
                        <span class="title">
                            {{this.accidentSubType}}
                        </span>
                        <span class="subject">
                            Subtipo
                        </span>
                    </div>
                </div>

                <div class="container-info separator-line-info indicator-container">
                    <div class="element">
                        <span class="title">
                            {{properties.reliability}}
                        </span>
                        <span class="subject">
                            Confiabilidad
                        </span>
                    </div>
                </div>

            </div>

            <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
                <div class="text-size-16 text-white text-weight-600 mb-25 text-md-size-14 description-box">
                    <span>Descripción:</span>
                    <span>{{properties.description}}</span>
                </div>

                <div class="d-flex footer-box">
                    <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white" [sizeClass]="'icon-size-28'"
                            alt="icono persona"></sit-icon> Reporte : {{this.date}}</span>

                    <span class="d-flex"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-28'"
                            alt="icono persona"></sit-icon> Hora : {{this.time}}</span>
                </div>
                <hr class="mb-1 line-separator" />

                <div class="d-flex mb-12">
                    <sit-icon [icon]="Icon.duplicate" [sizeClass]="'icon-size-28'" alt="icono persona">
                    </sit-icon>
                    <span class="text-size-20 text-white text-weight-600 text-md-size-14 ml-8">Posible duplicados</span>
                </div>

                <mat-checkbox [(ngModel)]="selectedAll" (change)="setAll()">
                    <span class="card-checkbox-label">Todos</span>
                </mat-checkbox>

                <div class="list-cards gap-15">
                    <ng-container *ngFor="let card of properties.duplicates; let i = index" class="pb-1-rem">
                        <div class="text-blue-1 text-weight-700 sit-card">
                            <mat-checkbox [(ngModel)]="card.selected" (change)="selectSingle(card)"></mat-checkbox>
                            <span class="location-title pl-05 text-color-primary-1 text-size-16 text-weight-500">{{
                                card?.street }}</span>
                            <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
                                <div class="container-info separator-line-info indicator-container">
                                    <div class="element">
                                        <span class="title">
                                            {{this.accidentType}}
                                        </span>
                                        <span class="subject">
                                            Tipo
                                        </span>
                                    </div>
                                </div>

                                <div class="container-info separator-line-info indicator-container">
                                    <div class="element">
                                        <span class="title">
                                            {{this.accidentSubType}}
                                        </span>
                                        <span class="subject">
                                            Subtipo
                                        </span>
                                    </div>
                                </div>

                                <div class="container-info separator-line-info indicator-container">
                                    <div class="element">
                                        <span class="title">
                                            {{card.reliability}}
                                        </span>
                                        <span class="subject">
                                            Fiabilidad
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex footer-box">
                                <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white"
                                        [sizeClass]="'icon-size-15'" alt="icono persona"></sit-icon>
                                    {{this.date}}</span>

                                <span class="d-flex"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-15'"
                                        alt="icono persona"></sit-icon> {{this.time}}</span>

                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="d-flex fd-row justify-end">
                    <sit-button (click)="cancel()" [buttonProperties]="cancelButtonProps" class="width-49"></sit-button>
                    <sit-button (click)="save()" [disabled]="disabledSave" [buttonProperties]="saveButtonProps"
                        class="width-49 ml-16"></sit-button>
                </div>

            </div>
        </div>
    </div>
</div>