import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { accidentFromApiMapper } from '@shared/utils/mappers/geo-api-map';
import {
  GeometryTypeEnum,
  ILayerModel,
} from '@types-custom/models/business/marker.model';
import { Observable } from 'rxjs';
import {IPropsVehiclesModel, VehiclesModel} from "@shared/models/vehicles.model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {IPropsLocationsModel, IncidentsLocationsModel} from "@shared/models/LocationsIncidents.model";
import {GeoAPITypeEnum} from "@shared/models/geo-api-type.model";
import {IPropsRoadCorridorsModel, RoadCorridorsModel} from "@shared/models/road-corridors.model";

@Injectable({
  providedIn: 'root',
})
export class AccidentService {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  getAccidentGeoJson(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.baseStaticLayerUrl}/0/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
    );
  }

  getLocationsIncidentsGeoJson(): Observable<any> {
    return this.httpClient.get<any>(
    //    `${this.environment.baseStaticLayerUrl}`
        `/assets/mock/MockIncidents.json`
    );
  }

  getAccidentMapper(accidentSource: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const pointsGroupedByCoordinates = this.groupSourceByCoordinates(
      accidentSource.features
    );

    const source: any = {
      type: 'FeatureCollection',
      features: pointsGroupedByCoordinates,
    };

    const accidentPoints = source.features.map((e: any) =>
      accidentFromApiMapper(e)
    );

    layer.push({
      marker: accidentPoints,
      geometryType: GeometryTypeEnum.Point,
      feature: source,
      visibility: true,
    });

    return layer;
  }

  getLocationsIncidentsMapper = ( apiObj: any ): IncidentsLocationsModel => {
    const propsLocationsIncidents : IPropsLocationsModel = {
      id : apiObj.id,
      address : apiObj.address,
      latitude : apiObj.latitude,
      longitude : apiObj.longitude,
      location_name : apiObj.location_name,
      class_name : apiObj.class_name,
      type_name : apiObj.type_name,
      priority_name : apiObj.priority_name,
      source_name : apiObj.source_name,
      state_name : apiObj.state_name,
      create_date : apiObj.create_date,
      gravity_name : apiObj.gravity_name,
      corredor_name : apiObj.corredor_name,
      description : apiObj.description,
      incident_time : apiObj.incident_time,
      markerPopupText: `${apiObj?.address}`,
      icon: Icon.coiMap,
    }

    const geometry = {
      coordinates: [apiObj?.longitude, apiObj?.latitude],
      type: GeometryTypeEnum.Point,
    };

    return new IncidentsLocationsModel(propsLocationsIncidents, geometry);
  };

  groupSourceByCoordinates(source: any): any[] {
    const mappedresult = source.reduce(function (r: any, a: any) {
      const key = a.geometry.coordinates[0] + '_' + a.geometry.coordinates[1];
      r[key] = r[key] || [];
      r[key].push(a.properties);
      return r;
    }, Object.create(null));

    const points = [];

    for (const [key, value] of Object.entries(mappedresult)) {
      const point = {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: key.split('_') },
        properties: value,
      };
      points.push(point);
    }
    return points;
  }
}

