import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IndicatorApiModel,
  IndicatorModel,
} from '@types-custom/models/ui/indicators.model';

export const generateIndicatorsModel = (
  indicator: IndicatorApiModel
): IndicatorModel[] => {
  return [
    {
      icon: Icon.chronometer_blue,
      tooltipTag: 'Velocidad promedio ponderada de los últimos 5 minutos',
      data: indicator.speedAvg,
    },
    {
      icon: Icon.alert_blue,
      tooltipTag: 'Número de incidentes en estado abierto',
      data: indicator.incidentsCount,
    },

    {
      icon: Icon.car_blue,
      tooltipTag: 'Conteo total de vehículos de los últimos 5 minutos',
      data: indicator.vehiclesCount,
    },
    {
      icon: Icon.agents_blue,
      tooltipTag: 'Cantidad de agentes conectados en los últimos 5 minutos',
      data: indicator.agentsCount,
    },
    {
      icon: Icon.camera_header,
      tooltipTag: 'Cantidad de cámaras conectadas en los últimos 5 minutos',
      data: indicator.camerasCount,
    },
    {
      icon: Icon.bicycle_header,
      tooltipTag: 'Conteo total de bicicletas de los últimos 5 minutos',
      data: indicator.bicyclesCount,
    },
    {
      icon: Icon.crane_header,
      tooltipTag: 'Conteo total de grúas de los últimos 5 minutos',
      data: indicator.cranesCount,
    },
  ];
};
