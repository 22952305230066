import { Component, OnInit, Input } from '@angular/core';
import { IModalRoadRunPathModel } from '@types-custom/models/ui/modal-road-run';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'citizen-modal-road-run',
  templateUrl: './modal-road-run.component.html',
  styleUrls: ['./modal-road-run.component.scss'],
})
export class ModalRoadRunComponent {
  @Input() properties: IModalRoadRunPathModel | undefined;
  Icon = Icon;
  roadDirection: string;
  directionType: { [id: string]: string } = {
    '-1': 'SENTIDO CONTRARIO DEL ARCO',
    '0': 'DOBLE SENTIDO',
    '1': 'SENTIDO DEL ARCO',
  };

  ngOnInit() {
    this.roadDirection = this.directionType[this.properties.sentido];
  }
}
