import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import { IParamListTypeOption, parameterListOptions, parameterListTypeOptions } from '@shared/models/incident-parameter.model';
import { ILocationOption, ILocationPoint } from '@types-custom/models/business/location.model';
import { AbstractSdkDynamicFilterDataSource, DynamicFilterTypeEnum, IDynamicFilterModel, ISdkDynamicFilterModel } from '@types-custom/models/ui/dynamic-filter';
import { AbstractPaginatorDataSource, IPaginatorModel } from '@types-custom/models/ui/paginator-model';
import { IIncidentCategoriesFeature, IIncidentCategory } from '@shared/models/incident-categories.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { fileTypes } from '@types-custom/models/ui/file-types.model';

@Injectable()
export class IncidentConfigurationService  implements
  AbstractPaginatorDataSource<any>
  , AbstractSdkDynamicFilterDataSource
{
  constructor(
    @Inject('environment') private environment: any,
    private sdkDynamicFiltersService: SdkDynamicFiltersService,
    private httpClient: HttpClient
  ) { }
  options: { [key: string]: BehaviorSubject<ILocationOption[] | undefined> } = {
    type: new BehaviorSubject<ILocationOption[] | undefined>(undefined),
    state: new BehaviorSubject<ILocationOption[] | undefined>(undefined),
  };

  filterList: ISdkDynamicFilterModel<any, any>[] = [

    {
      key: 'searchValue',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'Busqueda',
      value: new BehaviorSubject(undefined),
      searchChange: true
    },
  ]

  private dataSubject = new BehaviorSubject<IIncidentCategory[]>([]);
  data$: Observable<any> = this.dataSubject.asObservable();
  pageInfo: BehaviorSubject<IPaginatorModel> = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 20, totalCount: 0 });

  selected$ = new BehaviorSubject<any | undefined>(undefined);
  selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;

  formFiltersValue: any | undefined = {
    recordsPerPage: this.pageInfo.getValue().pageSize,
    page: this.pageInfo.getValue().pageSize,
    searchValue: undefined
  };

  buildUrl(dataObject: IPaginatorModel): string {
    const data = dataObject.data;
    this.formFiltersValue.recordsPerPage = dataObject.pageSize;
    this.formFiltersValue.page = dataObject.page;
    let url: string;
    if (data) {
      const searchValue = data['searchValue'];
      this.formFiltersValue.searchValue = searchValue;
      url = `${this.environment.incidentUrl}/incident-categories/pagination/categories?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}${searchValue ? '&searchValue=' + searchValue : ''}`;
    } else {
      url = `${this.environment.incidentUrl}/incident-categories/pagination/categories?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
    }
    return url;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value;
    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: IIncidentCategory[] = response.records.map(
          (item: IIncidentCategory) => {
            return item;
          });
        this.dataSubject.next(data ?? []);
      })
    );
  }

  getUrlParams(pageInfo: IPaginatorModel): HttpParams {
    const { pageSize, data } = pageInfo;
    let params: { [key: string]: any }
      = { ...pageInfo, recordsPerPage: pageSize, onlyActive: false, searchValue: '', typeCodes: IParamListTypeOption.FUENTE };
    if (data) {
      params = { ...params, ...data };
      if (data.typeCodes)
        params.typeCodes = [data.typeCodes]
      else {
        delete params.typeCodes
      }
    }

    let paramsRet = new HttpParams();
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) { // Solo añadir parámetros que no son `undefined`
        paramsRet = paramsRet.append(key, params[key]);
      }
    });
    return paramsRet;
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 20,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
    this.options.state.next(parameterListOptions);
    this.options.type.next(parameterListTypeOptions);
  }

  generateDynamicFiltersModel(): IDynamicFilterModel {
    const dynamicFiltersModel: IDynamicFilterModel = {
      sdkDynamicFiltersService: this.sdkDynamicFiltersService,
      dataSource: this,
      filtersDispatcher: this.selected$,
      showBttnConventions: false,
      hiddenButtonsFilters: true
    };
    return dynamicFiltersModel;
  }

  dataMap: { [key: number]: any } = {
  };

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const path = this.dataMap[index]?.();
    return this.httpClient.get(path);
  }

  clearData(): void {
    this.filterList.forEach((filter) => {
      filter.value.next(undefined);
    });
  }

  buildExcelPdfUrl(fileType: string) {
    let urlBase = `${this.environment.incidentUrl}/incident-categories/export/`;
    if (fileType === fileTypes.EXCEL) {
      urlBase += 'excel';
    } else if (fileType === fileTypes.PDF) {
      urlBase += 'pdf';
    }

    urlBase = `${urlBase}/categories?recordsPerPage=${this.formFiltersValue.recordsPerPage}&page=${this.formFiltersValue.page}`;

    if (this.formFiltersValue && this.formFiltersValue.searchValue) {
      urlBase = `${urlBase}&searchValue=${this.formFiltersValue.searchValue}`;
    }

    return urlBase;
  }

  public getListTypes(incidentTypeCode: string): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories/mobile/by-type-or-parent?incidentTypeCode=${incidentTypeCode}&onlyActive=true&includeRemoved=false`;
    return this.httpClient.get<any>(url);
  }

  public getListSubcategories(parentId: number): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories/mobile/by-type-or-parent?incidentTypeCode=Subcategoria&parentId=${parentId}&onlyActive=true&includeRemoved=false`;
    return this.httpClient.get<any>(url);
  }

  public getListGravity(parentId: number): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories/mobile/by-type-or-parent?incidentTypeCode=Gravedad&parentId=${parentId}&onlyActive=true&includeRemoved=false`;
    return this.httpClient.get<any>(url);
  }

  public getIncidentCategoriesFeatures(subcategoryId: number ): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories-features/${subcategoryId}`;
    return this.httpClient.get<any>(url);
  }

  public createIncidentCategoriesFeatures(data: IIncidentCategoriesFeature): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories-features/`;
    return this.httpClient.post<IIncidentCategoriesFeature>(url, data);
  }

  public deleteIncidentCategoriesFeatures(id: number, featureType: string) {
    let urlBase = `${this.environment.incidentUrl}/incident-categories-features/${id}?featureType=${featureType}`;
    return this.httpClient.delete(urlBase)
  }

  public getListSocialMediaByCategory(subCategoryId: any,gravityId: any,stateId: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories-social/search-by-category-ids?subcategoryId=${subCategoryId}&gravityId=${gravityId}&stateId=${stateId}`;
    return this.httpClient.get<any>(url);
  }

  public createIncidentCategoriesSocial(data: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories-social/`;
    return this.httpClient.post<any>(url, data);
  }

  public updateIncidentCategoriesSocial(data: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-categories-social/`;
    return this.httpClient.put<any>(url, data);
  }

  public getTextSocialMediaById(id: any,socialMediaTypeCode: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incidents/${id}/social-media-message?socialMediaTypeCode=${socialMediaTypeCode}`;
    return this.httpClient.get<any>(url);
  }
  
}
