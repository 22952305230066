import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  constructor(private http: HttpClient) { }

  private gridName = '';
  private extensionFile = '';

  downloadFile(url: string, layerName: string, fileType: string, params?: any): void {
    this.gridName = layerName;
    this.extensionFile = fileType;

    this.http
      .get(url, {
        params: params ?? {},
        responseType: 'blob',
      })
      .subscribe((response) => {
        this.handleFileDownload(response);
      });
  }

  public downloadImage(url: string, layerName: string, fileType: string) {
    this.gridName = layerName;
    this.extensionFile = fileType;

    return this.http
      .get(url, {
        responseType: 'blob',
      })

  }

  downloadIncidentsFile(
    url: string,
    bodyFilter: any,
    layerName: string,
    fileType: string
  ): void {
    this.gridName = layerName;
    this.extensionFile = fileType;

    this.http
      .post(url, bodyFilter, {
        responseType: 'blob',
      })
      .subscribe((response) => {
        this.handleFileDownload(response);
      });
  }

  private handleFileDownload(data: any): void {
    const date = new Date();
    const formatedDate = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;

    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(data);
    a.href = objectUrl;
    a.download = `${this.gridName} ${formatedDate}.${this.extensionFile}`;
    a.click();
    //console.log(a.href);
    URL.revokeObjectURL(objectUrl);
  }
}
