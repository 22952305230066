import {IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";

export interface IPropsResourcesPredictiveModelLocationsModel {
    id                        : string,
    agentId                   : string,
    day                       : string,
    fileName                  : string,
    groupId                   : number,
    groupName                 : string,
    hash?                     : string,
    hour                      : string,
    processDate               : string,
    localityId                : number,
    localityName              : string,
    type                      : string,
    markerPopupText?          : string,
    icon?                     : unknown
}

export class ResourcesPredictiveModelLocationsModel extends MarkerModelBase {
    static className = 'ResourcesPredictiveModelLocationsModel';
    classProperties: IPropsResourcesPredictiveModelLocationsModel;

    constructor(props: IPropsResourcesPredictiveModelLocationsModel, geometry: IGeometryModel) {
        super(geometry, ResourcesPredictiveModelLocationsModel.className);
        this.classProperties = props;
    }
}