import {
  IGeometryLineModel,
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsExodusAndReturnWazeModel {
  id: string;
  nameFrom: string;
  nameTo: string;
  distance: string;
  speed: string;
  levelOfService: string;
  concordance: string;
  elapsedTime: number;
  expectedValue: number;
  readTime: string;
  readings: number;
  time: string;
  speedRange: [];
  icon: Icon;
}

export class ExodusAndReturnWazeModel extends MarkerModelBase {
  static className = 'ExodusAndReturnWazeModel';
  classProperties: IPropsExodusAndReturnWazeModel;

  constructor(
    props: IPropsExodusAndReturnWazeModel,
    geometry: IGeometryLineModel | IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, ExodusAndReturnWazeModel.className, lat, lng);
    this.classProperties = props;
    this.geometry = geometry;
  }
}
