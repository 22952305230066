import { Injectable } from '@angular/core';
import { filterStateDataSource } from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterManageService implements filterStateDataSource {

  private filterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterObservable: Observable<any> = this.filterSubject.asObservable();

  saveStateFilter(formFilter: any) {
    this.filterSubject.next(formFilter);
  }

  get filterValue() {
    return this.filterSubject.value;
  }
}
