import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AbstractIncidentDataSource } from '@types-custom/models/ui/modal-roadworks-model';
import { Subject, map, Observable, of, BehaviorSubject  } from 'rxjs';
import { incidentData } from '@sdk/mock/incidentsMap';
import {
  GeometryTypeEnum,
  ILayerModel,
} from '@types-custom/models/business/marker.model';
import { incidentsHeatMapModelFromAPIMapper } from '@shared/utils/mappers/geo-api-map';


@Injectable({
  providedIn: 'root'
})
export class IncidentsService implements AbstractIncidentDataSource {

  incidentFilterNotifier$ = new Subject<any>();
  filter$ = new BehaviorSubject<any>(undefined);
  defaultBodyPayload = {}
  queryFilterDefault:string

  constructor(
    private httpClient: HttpClient,
    private datePipe:DatePipe,
    @Inject('environment') private environment: any
  ) {
    this.getDefaultBody();
    this.getDateFilters();
  }

  postIncidentssGeoJSON(dataForm: any = this.defaultBodyPayload): Observable<any> {
    //return this.httpClient.get<any>('assets/mock/situations.json');
    return this.httpClient.post<any>(
      `${this.environment.incidentUrl}/incident/format-geo-json`,
      dataForm
    );
  }
  getIncidentsByHour(): Observable<any> {
    return this.httpClient.get<any>(`${this.environment.incidentUrl}/incidents/graph/class-hours`)
    // return this.httpClient.get<any>('assets/mock/incidentsByHour.json')
    .pipe(
      map((response) => this.formatData(response))
    );
  }

  getDateFilters(){
    const defaultDateStart = (new Date(new Date().setHours(0,0,0,0))).toISOString();
    const defaultDateEnd = (new Date().toISOString());
    this.queryFilterDefault = `dateStart=${this.datePipe.transform(defaultDateStart, 'yyyy-MM-dd HH:mm:ss')}&dateEnd=${this.datePipe.transform(defaultDateEnd, 'yyyy-MM-dd HH:mm:ss')}`
  }

  getIncidentsHeatMap(queryFilters = this.queryFilterDefault){
        // return this.httpClient.get<any>('assets/mock/heat-map-weight-mock.json');
        return this.httpClient.get<any>(
          `${this.environment.incidentHeatMAp}?${queryFilters}`,
        );
  }

  getIncidentsMapper(incidents: any): ILayerModel[] {
    const layer: ILayerModel[] = [];
    const sourceFeatures = incidents.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            totalIncidents: Number(e.properties.totalIncidents),
            relativeWeight: Number(e.properties.relativeWeight),
            totalIncidentsOnHexagon: String(e.properties.totalIncidentsOnHexagon),
          },
        };
      })

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };

    const incidentsHeatMapPoints = source.features.map((e: any) =>
    incidentsHeatMapModelFromAPIMapper(e)
    );
    
    layer.push({
      marker: incidentsHeatMapPoints,
      geometryType: GeometryTypeEnum.HeatMapWeight,
      feature: source,
      visibility: true,
    });
    return layer;
  }

  getfiltersIncidents(): Observable<any> {
    return this.httpClient.get<any>(`${this.environment.incidentUrl}/incident-categories/1`);
  }

  getDataModal(incidentId: string): Observable<any> {
    return this.httpClient.get<any[]>(`${this.environment.incidentUrl}/incident/${incidentId}`);
  }

  getIncidentsModal(incidentId: string): Observable<any> {
    return this.httpClient.get<any[]>(`${this.environment.trafficUrl}/incidents-mobility/verified?corridorId=${incidentId}`);
  }


  getIncidents(){
    let geojson2 = {'type':'FeatureCollection', 'features':[] as any[]}
    geojson2['features'] = incidentData.map(function(agent){
        let geojsonAgent = {"geometry":{'coordinates':[agent['longitude'],agent['latitude']], 'type':'Point'},
                            "properties":agent,
                            "type": 'Feature'}
        return geojsonAgent
    })
    return of(geojson2)
  }

  getIncidentsLocationsGeoJSON(incidentBeginDate : Date, queryParams? : string): Observable<any> {
    let url = ''
    if(queryParams){
      url = `${this.environment.incidentUrl}/incidents/format-geo-json?${queryParams}`
    } else {
      url = `${this.environment.incidentUrl}/incidents/format-geo-json?beginDate=${incidentBeginDate.toISOString().replace('Z', '')}`
    }//let url = `assets/mock/IncidentsFieldResources.json`; //TODO mocked data for cammeras
    return this.httpClient.get<any>(
        url
    );
  }


  getDefaultBody() {
    const beginDate = new Date().toLocaleDateString('fr-CA');
    const beginDateFormat = `${beginDate}T00:00:00.402Z`;
    const endDateFormat = `${beginDate}T23:59:59.402Z`;

    this.getfiltersIncidents().subscribe((response) => {
      // const incidentTypes = response.map((incidentType:any) => incidentType.id );
      const incidentTypes = response.id;
      this.defaultBodyPayload = { "beginDate": beginDateFormat, "endDate": endDateFormat, "incidentTypeIds": incidentTypes };
    })
  }

  formatData(data: []) {
    const dataFormatted: any = {};
    const colors=['#9271C5','#FF5722','#01BCD5','#9271C5','#FF5722','#01BCD5','#f30000','#873e23']
  
    data.forEach((item: any,index: number) => {
      const formattedTime = item.time.substr(11,5);

      //console.log(formattedTime);
  
      if (!dataFormatted[formattedTime]) {
        dataFormatted[formattedTime] = { hour: formattedTime };
      }
  
      dataFormatted[formattedTime]['label'] = item.className;
      dataFormatted[formattedTime][item.className] = item.count;
      dataFormatted[formattedTime]['color'] = colors[index % colors.length];
    });
  
    return Object.values(dataFormatted).sort(
      (hourDetailA: any, hourDetailB: any) => {
        const hourA = parseInt(hourDetailA.time);
        const hourB = parseInt(hourDetailB.time);
        return hourA - hourB;
      }
    );
  }

  public setFilter(filter: any): void {
    this.filter$.next(filter);
  }

  public getFilter() {
    return this.filter$;
  }

}
