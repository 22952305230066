import { Component, Input, OnInit } from '@angular/core';
import {
  ButtonClassesEnum,
  IButtonModel,
  IClassesAttributes,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  IFilterModel,
} from '@types-custom/models/ui/filter.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { BehaviorSubject } from 'rxjs';

/*
** Location Selector
** Render a sit-dropdown with a button to search locations.
*/
@Component({
  selector: 'location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit {
  @Input() properties: IFilterModel | undefined = undefined;
  selectionDispatcher = new BehaviorSubject<any>(undefined);
  buttonTag = 'BUSCAR';
  buttonAttributes: IButtonModel = {};
  cleanbuttonAttributes: IButtonModel = {};
  dropdownAttributes: IClassesAttributes = {};

  ngOnInit(): void {
    this.selectionDispatcher = this.properties?.filtersDispatcher;
    this.generateButtonAttributes();
    this.generateDropdownAttributes();
  }

  private generateDropdownAttributes(): void {
    this.dropdownAttributes = {
      iconClass: IconClassesEnum.blue_icon,
    };
  }

  private generateButtonAttributes(): void {
    this.buttonAttributes = {
      label: this.buttonTag,
      postIcon: Icon.search_w,
      classAttributes: {
        buttonClass: ButtonClassesEnum.blue_button,
      },
    };
    this.cleanbuttonAttributes = {
      label: 'LIMPIAR',
      postIcon: undefined,
      classAttributes: {
        buttonClass: ButtonClassesEnum.blue_button,
      },
    };
  }

  public search(): void {
    // TODO: SdkLocationService should be responsable of this function.
    const dataSelected = this.properties?.locationDataSource.map(
      (item) => item?.selection$?.value
    );
    this.selectionDispatcher.next(dataSelected);
  }

  public cleanFiltersValue() {
    this.properties?.locationDataSource.forEach(
      (item) => item?.selection$?.next(undefined)
    );
    this.selectionDispatcher.next(undefined);
  }
}
