import { Component, Input } from '@angular/core';

export enum Icon {
  //assessment = report 
  //los que no se utilizan estan en check   , time =schudel aforo = densidad
  //modification icon
  areas_w = <any>'area',
  areas_blue = <any>'area-blue',
  areas_black = <any>'area-black',
  accident = <any>'accident',
  accident_green = <any>'accident_green',
  accident_w = <any>'accident-white',
  accident_black = <any>'accident-black',
  account_blue = <any>'account-blue',
  account_w = <any>'account-white',
  arrow_down_w = <any>'arrow-down',
  arrow_down_black = <any>'arrow-down-black',
  arrow_backward_w = <any>'arrow_back',
  arrow_backward_black = <any>'arrow_back-black',
  chronometer_w = <any>'chronometer',
  chronometer_blue = <any>'chronometer-blue',
  chronometer_black = <any>'chronometer-black',
  car_w = <any>'car_white',
  car_black = <any>'car_black',
  car_blue = <any>'car-blue',
  categories = <any>'categories',
  categories_blue = <any>'categories_blue',
  alert_w = <any>'alert',
  alert_black = <any>'alert-black',
  alert_green = <any>'alert-green',
  alert_white = <any>'alert-white',
  alert_blue = <any>'alert-blue',
  alert_red = <any>'alert-red',
  agents_w = <any>'agents',
  agents_green = <any>'agents_green',
  agents_black = <any>'agents_black',
  agents_blue = <any>'agents_blue',
  add_blue = <any>'add-blue',
  add_w = <any>'add_w',
  agency = <any>'agency',
  agency_w = <any>'agency_w',
  agency_blue = <any>'agency-blue',
  active_agent = <any>'active-agent',
  auto=<any>'auto',
  location_w = <any>'location_white',
  location_blue = <any>'location_blue',
  location_black = <any>'location_black',
  layers_w = <any>'layers-white',
  layers_black = <any>'layers-black',
  coi = <any>'coi',
  coi_black = <any>'coi-black',
  coiMap = <any>'coi-map',
  coi_yellow = <any>'coi-yellow',
  coi_blue = <any>'coi-blue',
  coos_w = <any>'coos',
  coos_blue = <any>'coos-blue',
  coos_black = <any>'coos-black',
  coos_orange = <any>'coos-orange',
  peaton_yellow = <any>'peaton_yellow',
  peaton_w = <any>'peaton',
  access_black = <any>'access-black',
  access_blue = <any>'access-blue',
  access_white = <any>'access-white',
  reports_w = <any>'reports',
  reports_black = <any>'reports-black',
  backward_w = <any>'backward',
  backward_black = <any>'backward-black',
  nextward_black = <any>'nextward-black',
  nextward_w = <any>'nextward',
  bicycles_w = <any>'bicycles_w',
  bicycles_blue = <any>'bicycles_blue',
  bicycles_black = <any>'bicycles_black',
  bus_black = <any>'bus-black',
  bus_blue = <any>'bus-blue',
  bus_route_w = <any>'bus-route',
  bus_route_black = <any>'bus-route-black',
  bus_stop_blue = <any>'bus-stop-blue',
  bus_stop_black = <any>'bus-stop-black',
  bus_stop_w = <any>'bus-stop-white',
  bus_w = <any>'bus',
  calendar_blue = <any>'calendar_blue',
  calendar_black = <any>'calendar-black',
  camera_play_w = <any>'camera-play-white',
  camera_play_blue = <any>'camera-play-blue',
  camera_play_black = <any>'camera-play-black',
  density_w = <any>'density_white',
  density_blue = <any>'density_blue',
  density_black = <any>'density_black',
  traffic_jams_w = <any>'traffic-jams-white',
  traffic_jams_blue = <any>'traffic-jams-blue',
  traffic_jams_black = <any>'traffic-jams-black',
  cars_capacity_w = <any>'cars_capacity',
  cars_capacity_blue = <any>'cars_capacity-blue',
  cars_capacity_black = <any>'cars_capacity-black',
  check_circle = <any>'check_circle',
  check_circle_green = <any>'check_circle_green',
  check_box_outilne = <any>'check_box_outilne',
  check_box_selected = <any>'check_box_selected',
  check_w = <any>'check',
  check_black = <any>'check-black',
  check_blue = <any>'check-blue',
  check_trafficLight_w = <any>'check-trafficLight-w',
  check_trafficLight_blue = <any>'check-trafficLight-blue',
  climate_blue = <any>'climate_blue',
  co2_blue = <any>'CO2_blue',
  co2_w = <any>'CO2_w',
  crane_right_panel=<any>'crane_right_panel',
  crane_w = <any>'crane',
  crane_black = <any>'crane_black',
  delete_w = <any>'delete-white',
  delete_blue = <any>'delete-blue',
  icon_delete_w = <any>'icon_delete_w',
  lock_reset_w = <any>'lock_reset_white',
  lock_reset_blue = <any>'lock_reset_blue',
  logout_w = <any>'logout',
  logout_blue = <any>'logout-blue',
  logout_black = <any>'logout-black',
  edit_w = <any>'edit',
  edit_black = <any>'edit-black',
  edit_blue = <any>'edit-blue',
  lock_w = <any>'lock-white',
  lock_blue = <any>'lock-white',
  user_w = <any>'users',
  users_blue = <any>'users-blue',
  users_black = <any>'user-black',
  exodo_w = <any>'exodo',
  exodo_black = <any>'exodo-black',
  exodo_blue = <any>'exodo-blue',
  distance_w = <any>'distance',
  distance_blue = <any>'distance_blue',
  direction_w = <any>'direction',
  direction_blue = <any>'direction_blue',
  document_w = <any>'document_w',
  document_blue = <any>'document-blue',
  dot_w = <any>'dot',
  dot_black = <any>'dot-black',
  pointer_location = <any>'pointer_location',
  point_options_w = <any>'point-options',
  point_options_black = <any>'point-options-black',

  role_cgt = <any>'role_CGT',
  role_coordinador = <any>'role_coordinador',
  role_policia = <any>'role_policia',
  role_cienpies = <any>'role_cienpies',
  role_colegio_en_bici = <any>'role_colegio_en_bici',
  role_gogev = <any>'role_gogev',
  role_recurso = <any>'role_recurso',
  register_management = <any> 'register_management',
  register_management_w = <any> 'register_management_white',
  

  email_black = <any>'email-black',
  email_w = <any>'email_w',
  visibility = <any>'visibility',
  visibility_on = <any>'visibility_on',
  visibility_off = <any>'visibility_off',
  filter_blue = <any>'filter-blue',
  filter_w = <any>'filter_w',
  last_page_arrow = <any>'arrow_last_page',
  first_page_arrow = <any>'arrow_first_page',
  folder = <any>'folder',
  highlight_off = <any>'highlight_off',
  reset_w = <any>'reset',
  reset_black = <any>'reset-black',
  gota = <any>'gota',
  run_over_w = <any>'run-over',
  run_over_blue = <any>'run-over-blue',
  notification_w = <any>'notification',
  notification_black = <any>'notification_black',
  info = <any>'info_blue',
  info_w = <any>'info_w',
  last_hours_w = <any>'last_hours_w',
  last_hours_blue = <any>'last_hours_blue',
  last_hours_black = <any>'last_hours_black',
  launch_w = <any>'launch',
  launch_blue = <any>'launch-blue',
  login_w = <any>'login',
  login_black = <any>'login-black',
  login_blue = <any>'login-blue',
  manage_accounts = <any>'manage_accounts',
  manage_accounts_w = <any>'manage_accounts_w',
  map = <any>'map_b',
  alert_circle_w = <any>'alert-circle',
  alert_circle_red = <any>'alert-circle-red',
  alert_circle_black = <any>'alert-circle-black',
  monitoring_services = <any>'monitoring_services',
  monitoring_services_w = <any>'monitoring_services_w',
  file_not_found = <any>'not-found',
  pause_w = <any>'pause',
  questions_w = <any>'questions',
  questions_black = <any>'questions-black',
  parameters = <any>'parameters',
  play_w = <any>'play',
  play_black = <any>'arrow-play-black',
  street_w = <any>'street',
  street_black = <any>'street-black',
  street_blue = <any>'street-blue',
  street_width_w = <any>'street-width',
  street_width_blue = <any>'street-width-blue',
  street_lenght_w = <any>'street_lenght',
  street_lenght_blue = <any>'street_lenght-blue',
  road_w = <any>'roads_w',
  road_black = <any>'road-black',
  road_blue = <any>'road-blue',
  road_speed_w = <any>'road-speed-w',
  road_speed_black = <any>'road-speed-black',
  road_speed_blue = <any>'road-speed-blue',
  speed_range_w = <any>'speed-range',
  speed_range_black = <any>'speed_range_black',
  speed_range_blue = <any>'speed_range_blue',
  sensor_w = <any>'sensor_w',
  sensor_blue = <any>'sensor_blue',
  search_w = <any>'search_w',
  search_black = <any>'search-black',
  schedule_w = <any>'schedule',
  sound_w = <any>'sound',
  sound_blue = <any>'sound-blue',
  right_street_w = <any>'right-street',
  right_street_blue = <any>'right-street-blue',
  settings_w = <any>'settings_w',
  text_box = <any>'text-box',
  toggle_off = <any>'toggle_off',
  toggle_on = <any>'toggle_on',
  traffic_arrows_w = <any>'traffic_arrows',
  traffic_arrows_black = <any>'traffic_arrows_black',
  traffic_light_w = <any>'traffic_light',
  traffic_light_blue = <any>'traffic_light_blue',
  traffic_light_black = <any>'traffic-light-black',
  whistle_agent_w = <any>'whistle-agent',
  whistle_agent_blue = <any>'whistle-agent-blue',
  zoom_w = <any>'zoom',
  zoom_blue = <any>'zoom-blue',
  document = <any>'document',
  download = <any>'download',
  download_document = <any>'download_document',
  download_pdf = <any>'download_document_pdf',
  icono_reload = <any>'icono-reload',
  pack = <any>'icons-pack',
  close_w = <any>'close-x',
  close_black = <any>'close-x-black',
  home_w = <any>'home',
  menu = <any>'menu',
  arrow_next_w = <any>'arrow_next',
  arrow_next_black = <any>'arrow_next_black',
  agents_panel = <any>'agentsPanel',
  active_agents = <any>'active-agents',
  help_w = <any>'help',
  dashboard_white = <any>'icon-dashboard-white',
  map_transit_update = <any>'agente_map_transit',
  map_police_update = <any>'agente_map_policy',
  clusters_w = <any>'clusters-w',
  clusters_black = <any>'clusters-black',
  eye_blue = <any>'eye-blue',
  eye_w = <any>'eye-w',
  close_blue = <any>'close-x-blue',
  layers_blue = <any>'layers-blue',
  manifestacion_blue =<any>'manifestacion-blue',
  atropello_blue =<any>'atropello-blue',
  herido_blue =<any>'herido_blue',
  semaforo_blue =<any>'semaforo_blue',
  tool_b=<any>'tool_b',
  help_modal=<any>'help_confirmation',
  calendar_historic=<any>'calendar_historic',
  hours_historic=<any>'hours_historic',
  state_excelente=<any>'state_excelente',
  state_exc=<any>'state_exc',
  state_bue=<any>'state_bue',
  state_ma=<any>'state_ma',
  moto=<any>'moto',
  icon_Activo=<any>'icon_active',
  icon_Inactivo=<any>'icon_Inactivo',
  icon_Dadodebaja=<any>'icon_Dado de baja',
  camera_header=<any>'camera_header',
  bicycle_header=<any>'bicycle_header',
  crane_header=<any>'crane_header',
  police=<any>'police',

  // Iconos tipo de recursos físicos
  e_casco =<any>'E-Casco',
  e_moto =<any>'E-Moto',
  e_patrulla =<any>'E-Patrulla',
  e_pda =<any>'E-PDA',
  e_radio =<any>'E-Radio',

  g_casco =<any>'G-Casco',
  g_moto =<any>'G-Moto',
  g_patrulla =<any>'G-Patrulla',
  g_pda =<any>'G_PDA',
  g_radio =<any>'G-Radio',

  r_casco =<any>'R-Casco',
  r_moto =<any>'R-Moto',
  r_patrulla =<any>'R-Patrulla',
  r_pda =<any>'R-PDA',
  r_radio =<any>'R-Radio',
  rudder_blue = <any> 'rudder_blue',

  b_casco =<any>'B-Casco',
  b_moto =<any>'B-Moto',
  b_patrulla =<any>'B-Patrulla',
  b_pda =<any>'B-PDA',
  b_radio =<any>'B-Radio',

  // Social media 
  icon_waze_sm=<any>'icon_waze_sm',
  icon_whatsApp_sm=<any>'icon_whatsApp_sm',
  icon_x_sm=<any>'icon_x_sm',

  login_user = <any>'login-user',

  stranded_level0 = <any>'varado-0',
  stranded_level1 = <any>'varado-1',
  stranded_level2 = <any>'varado-2',
  stranded_level3 = <any>'varado-3',
  sinister_level0 = <any>'siniestro-0',
  sinister_level1 = <any>'siniestro-1',
  sinister_level2 = <any>'siniestro-2',
  sinister_level3 = <any>'siniestro-3',
  semaforizacion_level0 = <any>'semaforizacion_0',
  semaforizacion_level1 = <any>'semaforizacion_1',
  semaforizacion_level2 = <any>'semaforizacion_2',
  semaforizacion_level3 = <any>'semaforizacion_3',
  massive_level0 = <any>'masivo_0',
  massive_level1 = <any>'masivo_1',
  massive_level2 = <any>'masivo_2',
  massive_level3 = <any>'masivo_3',
  manifestation_level0 = <any>'manifestacion_0',
  manifestation_level1 = <any>'manifestacion_1',
  manifestation_level2 = <any>'manifestacion_2',
  manifestation_level3 = <any>'manifestacion_3',
  accident_level0 = <any>'accidentes_0',
  accident_level1 = <any>'accidentes_1',
  accident_level2 = <any>'accidentes_2',
  accident_level3 = <any>'accidentes_3',
  alert_map = <any>'alert-map',
  incidents = <any>'incidents',
  dinamic = <any>'dinamic',
  dinamic_w = <any>'dinamic_w',

  //categories datex
  accident_datex = <any>'accidente_datex',
  road_weather_datex = <any>'carretera_clima_datex',
  road_not_weather_datex = <any>'carretera_no_clima_datex',
  surface_condition_datex = <any>'condicion_ superficie_datex',
  condition_datex = <any>'condiciones_datex',
  road_management_datex = <any>'gestion_carretera_datex',
  network_management_datex = <any>'gestion_red_datex',
  damaged_infrastructure_datex = <any>'infraestructura_danada_datex',
  bad_conditions_datex = <any>'malas_condiciones_datex',
  maintenance_datex = <any>'mantenimiento_datex',
  general_obstruction_datex = <any>'obstruccion_general_datex',
  environmental_obstruction_datex = <any>'obstruccion_ambiental_datex',
  animal_obstruction_datex = <any>'obstruccion_animal_datex',
  vehicular_obstruction_datex = <any>'obstruccion_vehicular_datex',
  construction_work_datex = <any>'trabajo_construccion_datex',
  abnormal_traffic_datex = <any>'trafico_anormal_datex',

  //Icon Right Panel
  wazePanelIcon = <any>'WazePanelIcon',
  trafficpanelicon = <any>'TrafficPanelIcon',
  generalPanelIcon = <any>'panelR-general',
  incidentsPanelIcon = <any>'RPincidents',
  incidentsSocialMediaPanelIcon = <any>'redesPanel',
  historyPanelIcon = <any>'RPHistory',

  //icon map
  map_cameras = <any>'map-cameras',
  map_camera_blue = <any>'icon-camera-blue',
  map_accident = <any>'map-accident',
  map_roadClosed = <any>'map-roadClosed',
  map_climate = <any>'map-climate',
  map_cctv = <any>'map-cctv',
  map_bus_stop = <any>'map-bus-stop',
  map_traffic_light = <any>'map_traffic_light',
  map_agents_green = <any>'map-agents-green',
  map_agents_blue = <any>'map-agents-blue',
  agente_map_verde = <any>'agente_map-azul',
  agente_map_azul = <any>'agente_map-verde',
  map_crane = <any>'map-crane',
  map_density = <any>'map-density',
  map_Coos = <any>'map-Coos',
  map_sensor = <any>'map-sensor',
  map_policy = <any>'map_policy',
  map_transit = <any>'map_transit',
  map_vehicle = <any>'vehiculo-icon_act',
  map_crane_laden = <any>'grua_cargada_a',
  map_crane_available = <any>'grua_disponible_a',
  icon_crane = <any>'grua_map',
  crane_panel = <any>'crane_panel',
  heat_map_weight_menu = <any>'heat-map-weight-menu',
  heat_map_weight_menu_blue = <any>'heat-map-weight-menu-blue',

  //funcionalidades
  general_road_w = <any>'general',
  general_road_black = <any>'general-black',
  general_road_blue = <any>'general-blue',

  //waze alert
  climate_w = <any>'climate',
  closures = <any>'closures',
  ambulance = <any>'ambulance',
  alert_orange = <any>'alert-orange',
  calendar_w = <any>'calendar',
  velocidad = <any>'velocidad',
  tacometro = <any>'tacometro',
  automovil = <any>'automovil_map',
  tramo = <any>'tramo',
  square = <any>'square',
  icons_vehicles = <any>'icons',
  areas = <any>'area',
  layer_traffic_green = <any>'area',
  user_y = <any>'user_y',
  upload_file = <any>'upload_file',
  help_blue = <any>'help_blue',

  // Waze icons
  waze = <any>'Waze',
  waze_blue = <any>'waze-blue',
  waze_black = <any>'waze-black',
  ArrowLevel1 = <any>'Arrow-Level-1',
  ArrowLevel2 = <any>'Arrow-Level-2',
  ArrowLevel3 = <any>'Arrow-Level-3',
  ArrowLevel4 = <any>'Arrow-Level-4',
  ArrowLevel5 = <any>'Arrow-Level-5',
  VelocimetroCard10 = <any>'Velocimetro_Card-10',
  VelocimetroCard20 = <any>'Velocimetro_Card-20',
  VelocimetroCard30 = <any>'Velocimetro_Card-30',
  VelocimetroCard40 = <any>'Velocimetro_Card-40',
  VelocimetroCard50 = <any>'Velocimetro_Card-50',
  Dustance = <any>'distance',
  Cronometro = <any>'Cronometro',
  densidad = <any>'densidad',
  exodo = <any>'exodo',
  Exodo_black = <any>'Exodo_Black',
  atascos = <any>'Atasco',
  atascos_black = <any>'Atasco_Black',
  speed_exodus_return = <any>'speed_exodus_return',
  calendar_outlined_white = <any>'calendar_outlined_white',
  time = <any>'time',
  time_blue = <any>'time_blue',

  // Waze alerts icons
  AccidentWaze = <any>'WazeAccident',
  ClimateWaze = <any>'WazeClimate',
  CloseWaze = <any>'WazeClosed',
  AccidentWazeM = <any>'WazeMAccident',
  ClimateWazeM = <any>'WazeMClimate',
  CloseWazeM = <any>'WazeMRoadClose',
  AccidentWazeP = <any>'WazePAccident',
  ClimateWazeP = <any>'WazePClimate',
  CloseWazeP = <any>'WazePClosures',

  // Field Resources
  ResourcesBlack = <any>'FieldResourceBlack',
  ResourcesWhite = <any>'FieldResourceWhite',
  HelmetIcon = <any>'HelmetIcon',
  HelmetIconMap = <any>'HelmetIconMap',
  CentipedeIcon = <any>'CentipedeIcon',
  CentipedeIconMap = <any>'CentipedeIconMap',
  CycleSchoolIcon = <any>'ShCycleIcon',
  CycleSchoolIconMap = <any>'ShCycleIconMap',
  FieldResourcesPanelIcon = <any>'FieldResourcesPanelIcon',
  FieldResourcesHumanGroup = <any>'FieldResourcesHumanGroup',
  GuideGroup_Blue = <any>'GuideGroup_Blue',
  SchoolCycle_Blue = <any>'SchoolCycle_Blue',
  Centipede_Blue = <any>'Centipede_Blue',
  StationModal = <any>'StationModal',
  PatrolModal = <any>'PatrolModal',
  PDAModal = <any>'PDAModal',
  BikeModal = <any>'BikeModal',
  // Bike layer
  BikeEnabled   = <any> 'bicicletas',
  BikeDisabled  = <any> 'bicicletas-disabled',

  Tool = <any>'Tool',
  BookIncidentsFieldResources = <any>'BookIncidentsFieldResources',
  groups = <any>'groups',
  program = <any>'program',
  accept = <any>'accept',
  assignment = <any>'assignment',
  without_assignment = <any>'without_assignment',
  assigment_white = <any>'assigment_white',
  // Grupos
  GrupoGuia = <any>'GrupoGuia',
  AgentesCiviles = <any>'AgentesCiviles',
  AlColegioenBici = <any>'AlColegioenBici',
  Ciempies = <any>'Ciempies',
  taxi_alert = <any>'taxi_alert',
  comment = <any>'comment',
  // Predictive models
  PredictivePolice          = <any> 'PredcitivePolice',
  PredictiveAgents          = <any> 'PredictiveAngents',
  PredictiveCrane           = <any> 'PredictiveCrane',
  PredictivePanelAgents     = <any> 'PredictivePolice',
  PredictivePanelCranes     = <any> 'PredictiveCranes',
  PredictivePanelIco        = <any> 'Predictive_Icon_Panel',
  ClockWhite                = <any> 'clock_w',
  ClockBlack                = <any> 'clock_b',
  // Services
  serva                     = <any>'serva',
  servb                     = <any>'servb',
  servc                     = <any>'servc',
  servd                     = <any>'servd',
  serve                     = <any>'serve',
  duplicate                 = <any>'duplicate',

  // Air Quality
  StationPoint              = <any> 'mini-station',
  StationIconPoint          = <any> 'mini-station-icon',
  Temperature               = <any> 'temperature',
  Humidity                  = <any> 'humidity',
  Pressure                  = <any> 'pressure',
  MenuIconBlackAir          = <any> 'AirMenuB',
  MenuIconWhiteAir          = <any> 'AirMenuW',

}

// TODO: Remove map
// export const IconMap: { [key: string]: string } = {
//   [Icon.surface_condition]: `assets/icons.${Icon.surface_condition}.svg`,
// };

export const getIconAsset = (icon: Icon) =>
  Icon[icon].indexOf('_ng') > -1 ? undefined : `assets/icons/${icon}.svg`;

export const notFoundClass = 'marker-not-found';

/**
 * Get the icon scss marker class name by Icon
 * @param icon
 * @returns SCSS class name marker
 */
export const getIconClass = (icon: Icon): string =>
  Icon[icon].indexOf('_ng') > -1 ? notFoundClass : `marker-${Icon[icon]}`;
// IconMap[icon] ? `marker-${Icon[icon]}` : notFoundClass;

export const getIcon = (): { [key: string]: Icon } => ({
  [Icon.GrupoGuia.toString()]: Icon.GrupoGuia,
  [Icon.AgentesCiviles.toString()]: Icon.AgentesCiviles,
  [Icon.AlColegioenBici.toString()]: Icon.AlColegioenBici,
  [Icon.Ciempies.toString()]: Icon.Ciempies,
});

@Component({ template: '' })
export class IconBaseComponent {
  @Input() icon: Icon | undefined;
  @Input() alt = 'icono';
  @Input() tabIndex: undefined | number;
  get iconPath(): string | undefined {
    return this.icon ? getIconAsset(this.icon) : undefined;
  }
}
