import { Component, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import {BehaviorSubject, finalize} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import { ICardIncidentsLocationsModel } from "@types-custom/models/ui/cards-incidents-locations";
import * as am4core from "@amcharts/amcharts4/core";
import { IncidentsService } from "@shared/services/layer-service/incidents-service/incidents.service";
import { ITimeSeriesGraphicModel } from "@types-custom/models/ui/chart-line-color-model";

@Component({
  selector: "sit-incidents-locations",
  templateUrl: "./panel-incidents-locations.component.html",
  styleUrls: ["./panel-incidents-locations.component.scss"],
})
export class PanelIncidentsLocationsComponent implements OnInit {

  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;
  dataGraphicLine:ITimeSeriesGraphicModel ;
  colorScale: any = [{}];
  listIncidentsLocations:Array<any>=[];
  listIncidentsPriority:Array<any>=[]
  Icon=Icon;

  toChecked:number = 0;
  verified:number = 0;
  Duplicates:number = 0;
  Closed:number = 0;
  
  cards: ICardIncidentsLocationsModel[] = [];

  constructor(public serviceIncidents:IncidentsService){

  }
  ngOnInit(): void {
    this.setIndicators(this.cards);
    this.setGraphic(this.cards);
    this.setGraphicPriority(this.cards);
    this.serviceIncidents.getIncidentsByHour().subscribe(res => {
      this.setGraphicLineIncidents(res);
    }); 
    //console.log(this.toChecked)
  }
  
  setIndicators(cards: ICardIncidentsLocationsModel[]) {
    let Closed = 0;
    let Duplicates = 0;
    let verified = 0;
    let toChecked = 0;
   
    cards.map((cards: ICardIncidentsLocationsModel) => {
      if (cards.state_name === "Verificado") {
        this.toChecked += 1;
      }
      if (cards.state_name === "Por verificar") {
        this.verified += 1;
      }
      if (cards.state_name === "Duplicado") {
        this.Duplicates += 1;
      }
      if (cards.state_name === "Cerrado") {
        this.Closed += 1;
      }
    });
  }

  
  setGraphicPriority(cards: ICardIncidentsLocationsModel[]) {
    const lookup = {} as any;
    const lookupD = {} as any;
  
    cards.forEach((card: ICardIncidentsLocationsModel) => {
      const locality = card.priority;
  
      if (locality in lookup) {
        lookup[locality] = lookup[locality] + 1;
        lookupD[locality] = lookup[locality];
      } else {
        lookup[locality] = 1;
        lookupD[locality] = 1;
        const object: any = new Object();
        object['id'] = locality;
        object['label'] = locality;
        object['dta'] = 1;
        object['qty'] = 1;
        object['color'] = am4core.color('rgba(166,181,255,0.72)');
        object['border'] = am4core.color('rgb(166,181,255)');
  
        this.listIncidentsPriority.push(object);
      }
    });
  
    this.listIncidentsPriority.forEach((item) => {
      item.qty = lookup[item.id];
      item.dta = lookupD[item.id];
    });
  
    this.listIncidentsPriority.sort((a, b) => b.dta - a.dta);
  }
  
  
  

setGraphic(cards:ICardIncidentsLocationsModel []) {
  const lookup = {} as any;
  const lookupD = {} as any;

  cards.map((card:ICardIncidentsLocationsModel ) => {
    const locality = card.location_name

    if (locality in lookup) {
      lookup[locality] = lookup[locality] + 1;
      lookupD[locality] = lookup[locality];
    } else {
      lookup[locality] = 1;
      lookupD[locality] = 1;
      const object: any = new Object();
      object['id'] = locality;
      object['label'] = locality;
      (object['dta'] = 1);
      (object['qty'] = 1);
      (object['color'] = am4core.color('rgba(166,181,255,0.72)'));
      (object['border'] = am4core.color('rgb(166,181,255)'));

      this.listIncidentsLocations.push(object);
    }
  });

  this.listIncidentsLocations.forEach((item) => {
    item.qty = lookup[item.id];
    item.dta = lookupD[item.id];
  });

}

  setGraphicLineIncidents(data: any): void {
    
    this.dataGraphicLine = {
      data: data,
      label: this.unique(data),
      labelX: 'Tipo',
      labelY: 'Cantidad', 
      colorLine:['#9271C5','#FF5722','#01BCD5','#9271C5','#FF5722','#01BCD5','#f30000','#873e23']
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#92FDF6' }];
  }

  unique(arr: any) {
    let result:any = [];
  
    for (let str of arr) {
      if (!result.includes(str.label)) {
        result.push(str.label);
      }
    }

    return result;
  }

}
