import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapFilterAgentsComponent } from '@ui-core/components/map-filters/agents/map-filter-agents.component';
import { MapFilterAgentsHistoricalComponent } from '@ui-core/components/map-filters/agents-historical/map-filter-agents-historical.component';
import { MapFilterIncidentsComponent } from '@ui-core/components/map-filters/incidents/map-filter-incidents.component';
import { AlertsWazeComponent } from '@ui-core/components/map-filters/alerts-waze/alerts-waze.component';
import { MapFilterCranesComponent } from '@ui-core/components/map-filters/cranes/map-filter-cranes/map-filter-cranes.component';
import { MapFilterCranesHistoricalComponent } from '@ui-core/components/map-filters/cranes-historical/map-filter-cranes-historical/map-filter-cranes-historical.component';
import {
  FieldResourcesFilterComponent
} from "@ui-core/components/map-filters/field-resources-filter/field-resources-filter.component";
import { MapFilterHeatMapIncidentsComponent } from '@ui-core/components/map-filters/heat-map-incidents/map-filter-heat-map-incidents.component';
import {
  IncidentsFieldResourcesFilterComponent
} from "@ui-core/components/incidents-field-resources-filter/incidents-field-resources-filter.component";
import {
  PredictiveResourceFilterComponent
} from "@ui-core/components/predictive-resource-filter/predictive-resource-filter.component";
import { GroundResourceHistoricalComponent } from '@ui-core/components/map-filters/ground-resource-historical/ground-resource-historical.component';
import { GroundResourceGroupComponent } from '@ui-core/components/map-filters/ground-resource-group/ground-resource-group.component';
import { MapFilterLocationIncidentsComponent } from '@ui-core/components/map-filters/location-incidents/map-filter-location-incidents/map-filter-location-incidents.component';
import { MapFilterLastHoursIncidentsComponent } from '@ui-core/components/map-filters/last-hours-incidents/map-filter-last-hours-incidents/map-filter-last-hours-incidents.component';
import { AssignableResourcesComponent } from '@ui-core/components/map-filters/assignable-resources/assignable-resources.component';


export const mapFilter = {
  [MapLayers.Agents]: [
    {
      title: 'FILTRAR POR AGENTE',
      alt: 'FILTRAR POR AGENTE',
      titleIcon: Icon.filter_w,
      topPosition: '170',
      content: {
        component: MapFilterAgentsComponent,
      },
    },
  ],
  [MapLayers.AgentsCluster]: [
    {
      title: 'FILTRAR POR AGENTE',
      alt: 'FILTRAR POR AGENTE',
      titleIcon: Icon.filter_w,
      topPosition: '170',
      content: {
        component: MapFilterAgentsComponent,
      },
    },
  ],
  [MapLayers.AgentsHistorical]: [
    {
      title: 'HISTÓRICO AGENTES',
      alt: 'HISTÓRICO AGENTES',
      titleIcon: Icon.last_hours_blue,
      topPosition: '170',
      content: {
        component: MapFilterAgentsHistoricalComponent,
      },
    },
    {
      title: 'FILTRAR POR AGENTE',
      alt: 'FILTRAR POR AGENTE',
      titleIcon: Icon.filter_w,
      topPosition: '275',
      content: {
        component: MapFilterAgentsComponent,
      },
    },
  ],
  [MapLayers.Cranes]: [
    {
      title: 'FILTRAR POR PLACA',
      alt: 'FILTRAR POR PLACA',
      titleIcon: Icon.filter_w,
      topPosition: '170',
      content: {
        component: MapFilterCranesComponent,
      },
    },
  ],
  [MapLayers.CranesCluster]: [
    {
      title: 'FILTRAR POR PLACA',
      alt: 'FILTRAR POR PLACA',
      titleIcon: Icon.filter_w,
      topPosition: '170',
      content: {
        component: MapFilterCranesComponent,
      },
    },
  ],
  [MapLayers.CranesHistorical]: [
    {
      title: 'HISTÓRICO GRÚAS',
      alt: 'HISTÓRICO GRÚAS',
      titleIcon: Icon.last_hours_blue,
      topPosition: '170',
      content: {
        component: MapFilterCranesHistoricalComponent,
      },
    },
    {
      title: 'FILTRAR POR GRÚA',
      alt: 'FILTRAR POR GRÚA',
      titleIcon: Icon.filter_w,
      topPosition: '275',
      content: {
        component: MapFilterCranesComponent,
      },
    },
  ],
  [MapLayers.Situations]: [
    {
      title: 'FILTRAR POR TIPO DE NOVEDAD',
      alt: 'FILTRAR POR TIPO DE NOVEDAD',
      titleIcon: Icon.filter_w,
      customClass: 'filter-incident',
      content: {
        component: MapFilterIncidentsComponent,
      },
    },
  ],
  [MapLayers.AlertsWaze]: [
    {
      title: 'FILTRAR POR ALERTAS',
      alt: 'FILTRAR POR ALERTAS',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: AlertsWazeComponent,
      },
    },
  ],
  [MapLayers.FieldResourcesLocations]: [
    {
      title: 'FILTRAR POR RECURSOS',
      alt: 'FILTRAR POR RECURSOS',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: FieldResourcesFilterComponent,
      },
    },
  ],
  [MapLayers.IncidentsFieldResources]: [
    {
      title: 'FILTRADO',
      alt: 'FILTRADO',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: IncidentsFieldResourcesFilterComponent,
      },
    },
  ],
  [MapLayers.IncidentsLocations]: [
    {
      title: 'FILTRAR POR ESTADO',
      alt: 'FILTRAR POR ESTADO',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: MapFilterLocationIncidentsComponent,
      },
    },
  ],
  [MapLayers.LastHour]: [
    {
      title: 'FILTRAR POR HORA',
      alt: 'FILTRAR POR HORA',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: MapFilterLastHoursIncidentsComponent,
      },
    },
  ],
  [MapLayers.IncidentsHeatMap]: [
    {
      title: 'FILTRAR POR INCIDENTES MAPA DE CALOR',
      alt: 'FILTRAR POR INCIDENTES MAPA DE CALOR',
      titleIcon: Icon.heat_map_weight_menu,
      topPosition: '155',
      content: {
        component: MapFilterHeatMapIncidentsComponent,
      },
    },
  ],
  [MapLayers.ResourcesPredictiveModelLocations]: [
    {
      title: 'FILTRAR MODELO PREDICTIVO',
      alt: 'FILTRAR POR MODELO PREDICTIVO',
      titleIcon: Icon.heat_map_weight_menu,
      topPosition: '155',
      content: {
        component: PredictiveResourceFilterComponent,
      },
    },
  ],
  [MapLayers.GroundResourceLasthours]: [
    {
      title: 'FILTRAR POR RECURSOS',
      alt: 'FILTRAR POR RECURSOS',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component:GroundResourceGroupComponent ,
      },
    },
    {
      title: 'HISTÓRICO RECURSOS',
      alt: 'HISTÓRICO RECURSOS',
      titleIcon: Icon.last_hours_blue,
      topPosition: '470',
      content: {
        component: GroundResourceHistoricalComponent,
      },
    },
  ],
  [MapLayers.incidentAddResources]: [
    {
      title: 'FILTRAR POR RECURSOS EN CAMPO',
      alt: 'FILTRAR POR RECURSOS EN CAMPO',
      titleIcon: Icon.filter_w,
      topPosition: '155',
      content: {
        component: AssignableResourcesComponent,
      },
    },
  ]
};

