export enum LayerPath {
  CAMERAS = 'cameras',
  SITUATIONS = 'situation',
  INCIDENTS_CATEGORIES = 'incidents-categories/type',
  INCIDENTS_CATEGORIES_PARENT = 'incidents-categories/parent',
  TRAFFIC = 'measureddata',
  CO2 = 'Co2',
  BICYCLEPATH = 'bicyclepath',
  COOSLINE = 'CoosLine',
  PARKINGLOT = 'parkinglot',
  TRAFFICLIGHT = 'trafficlight',
  AGENTS = 'AGENTS',
  AGENTSHISTORICAL = 'AGENTSHISTORICAL',
  CRANES = 'CRANES',
  CRANESHISTORICAL = 'CRANESHISTORICAL',
  TRAFFICMANAGMENTPLANSPATH = 'trafficmanagmentplanspath',
  ROADRUN = 'roadrun',
  PMV = 'pmv',
  PMVEDITAR = 'pmvEditar',
  PMVCONSULTA = 'pmvConsulta',
  INCIDENTSHEATMAP = 'IncidentsHeatMap',
  GROUNDRESOURCELASTHOURS= 'GroundResourceLasthours',
  incidentAddResources = 'incidentAddResources',
}
