import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterContentChecked,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  reverseMappingExtensionAllowed,
  getExtensionAllowed,
} from '@types-custom/models/ui/drop-file-model';
import { first, lastValueFrom, map, of, Subject, take, takeUntil } from 'rxjs';
import { ManagePhysicalResourcesService } from '@official/services/data-grid-service/manage-physical-resource-service/manage-physical-resources.service';
import { ExportExcelService } from '@official/services/data-grid-service/export-excel-service/export-excel.service';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';

/*
** Drop Files Physical Component
** Allows you to drop or browse for files to upload.
*/
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "drop-files-physical-resources",
  templateUrl: "./drop-files-physical-resources.component.html",
  styleUrls: ["./drop-files-physical-resources.component.scss"],
})
export class DropFilesPhysicalResourcesComponent implements OnInit, OnChanges,AfterContentChecked {
  @Output() filesDropped = new EventEmitter<Array<any>>();
  @Input() fileSubmited = false;
  @Input() extensionAllowedEnum!: { [keys: string]: string };
  @Input() filesEdited !: [];
  files: Array<any> = [];
  filesEdit: Array<any> = [];
  icon: Icon = Icon.close_w;
  private fileTypesAllowed = '';
  @ViewChild('filesInput')
  filesInput!: ElementRef;
  isEdited!:boolean;

  urlImage!:any;

  _destroy$ = new Subject<void>();

  constructor(private cdref: ChangeDetectorRef,public pyshicalResourcesManagementService: ManagePhysicalResourcesService,
    private exportExcelService: ExportExcelService,private modalService: ModalService,
    @Inject('environment') private environment: any){

  }

  get getFileTypesAllowed() {
    return this.fileTypesAllowed;
  }

  ngOnInit(): void {
    this.fileTypesAllowed = getExtensionAllowed(this.extensionAllowedEnum);

    if(this.filesEdited?.length>0){
      this.isEdited = true;
      
      this.files = this.filesEdited

      this.files.forEach(async (item:any)=>{
        item.urlAct = window.URL.createObjectURL(await this.getValueFromObservable(item.id));
        //console.log(item.urlAct);
      })
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileSubmited.currentValue) {
      this.files = [];
      this.filesInput.nativeElement.value = "";
      this.filesDropped.emit(this.files);
    }
  }

  onDroppedFile($event: File[]): void {
    if ($event) {
      for (const file of $event) {
        const ext = file.name
          .substring(file.name.lastIndexOf('.'))
          .toLowerCase();
        if (reverseMappingExtensionAllowed(this.extensionAllowedEnum, ext)) {
          if(this.files.length < 3){
            if(file.size <= 5000000){
              //console.log("agregue archivo");
              this.files.push(file);
            }else{
              this.showModalError('Por favor, verifique que el peso del archivo no supere los 5 MB.');
            }
          }else{
            this.showModalError('Se pueden adjuntar máximo 3 fotos');
          }
        } else {
          this.showModalError('Por favor, verifique que el formato del archivo sea PNG, JPG o JPEG');
        }
        
      }
      
      // console.log(this.files);

      // if(this.isEdited){
      //   this.files =this.files.filter((item:any)=> item.size);
      //   console.log(this.files);
      this.filesDropped.emit(this.files);
      // }else{
      //   this.filesDropped.emit(this.files);
      // }
      console.log(this.filesDropped);
    }
  }

  onBrowseFile($event: any): void {
    const { files } = $event.target;
    if (files) {
      this.onDroppedFile(files);
    }
  }
  

  deleteFile(index: number, file:any): void {
    if(file.id){
      this.pyshicalResourcesManagementService.deleteImagePhysicialResource(file.id).subscribe((resp:any)=>{
        alert('Imagen eliminada');
        this.files.splice(index, 1);
        this.filesDropped.emit(this.files);
      })
    }else{
      this.files.splice(index, 1);
      this.filesDropped.emit(this.files);
    }
    
  }

  formatBytes(a: number, b = 2, k = 1024): string {
    const d = Math.floor(Math.log(a) / Math.log(k));
    return 0 == a
      ? '0B'
      : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
          ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
  }

  getUrl(file:any){
    console.log(file);
    return window.URL.createObjectURL(file);
  }

  getUrl2(file:any){
    return window.URL.createObjectURL(file);
  }

  // async getImage(id:any){
  //   const data2 = await this.getUrlById(id);
  //   return window.URL.createObjectURL(data2);
  // }

  getUrlById(id:any){
    // this.getValueFromObservable(id)
    // .then((data:any)=>{
    //   console.log(data);
    //   //... continue with anything depending on "data" after the Promise has resolved
    // })


    // this.urlImage = '';
    
    // const data = await lastValueFrom(this.exportExcelService.downloadImage(
    //   `https://gateway.sitbogota.com/resources/api/v1/physical-resources/files/${id}`,
    //   '',
    //   'png' as unknown as string
    // ));

    // return data;

    // this.urlImage = '';

    // console.log(this.urlImage);
    // this.urlImage = '';
    
    this.exportExcelService.downloadImage(
      `${this.environment.resourcesUrl}/physical-resources/files/${id}`,
      '',
      'png' as unknown as string
    ).pipe(map((response:any) => {
      console.log(response);
      this.urlImage = this.getUrl2(response);
    }));

    // this._destroy$.unsubscribe();
    // this._destroy$.complete();

    return this.urlImage;

  }

  getValueFromObservable(id:any) {
    const data = this.exportExcelService.downloadImage(
        `${this.environment.resourcesUrl}/physical-resources/files/${id}`,
        '',
        'png' as unknown as string
      ).pipe(
        first() //useful if you need the data once and don't want to manually cancel the subscription again
    ) .toPromise()

    return data;
    
  }

  getPath(file:any){
    var imgUrl = of(file.getDownloadURL())
    console.log(imgUrl);
  }

  showModalError(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

}

