import { IHorizontalMenuModel } from '@types-custom/models/ui/horizontal-menu.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISideMenu } from './side-menu.model';
import { MapLayerMouseEvent, Marker } from 'mapbox-gl';
import { IDynamicSideMenuModel } from './dynamic-side-menu';
import { IDynamicFilterModel } from './dynamic-filter';
import { RightPanelMapType } from './right-panel-model';
import { IMapConventionsPropertiesModel } from './map-conventions-model';

// export interface IMapviewerDataSource {
//   getAPIList: (listType: any, isClearingCache: boolean) => Observable<any[]>;
// }

export abstract class AbstractMapviewerDataSource {
  abstract getAPIList(
    listType: any,
    isClearingCache: boolean,
    isAllDataLoaded: boolean
  ): Observable<any[]>;
}

export interface IMapViewerPropsModel<Events, Layers> {
  containerId: string;
  // Mappers
  layersDataMap: any;
  menuRightPanelMap: RightPanelMapType;
  markerModalMap: any;
  geoModalMap: any;
  mapEventsMap?: { [key: string]: IDynamicSideMenuModel };
  // DS
  dataSource: AbstractMapviewerDataSource;
  // LeftMenu
  leftMenuEvents$: Subject<Events | undefined>;
  rightMenuEvents$?: Subject<Events | undefined>;
  panelContentDispatcher$?: BehaviorSubject<any>; 
  iconInfo: ISideMenu<Layers>[];
  logoText?: string;
  textInline?: BehaviorSubject<string> | Observable<string>;
  // Footer
  currentLayer$: BehaviorSubject<Layers | undefined>;
  footerDynamicEvents$: Subject<Events | undefined>;
  footerStaticEvents$: Subject<Events | undefined>;
  footerModel: IHorizontalMenuModel[];
  // Filters
  dynamicFiltersModel?: IDynamicFilterModel;
  // Conventions Component
  mapConventionsProps: IMapConventionsPropertiesModel | undefined;
  // Refresh Dispatcher
  refreshDispatcher?: Subject<any>;
  // Click interaction Dispatcher
  clickInteractionDispatcher?: BehaviorSubject<MapEventTypeEnum>;
  isSideMenuVisible?: BehaviorSubject<boolean> | Observable<boolean>;
  mapFilter: any;
  mapIcons: any;
  markerRightPanelMap: any;
  autoUpdatedLayers: string[];
}

export interface IMapEvent {
  type: MapEventTypeEnum; //MapLayerEventType;
  event?: MapLayerMouseEvent;
  lng: number;
  lat: number;
  marker?: Marker;
}

export enum MapEventTypeEnum {
  VOID = '',
  CLICK = 'click',
  INCIDENT_PARAMETER = 'incident-parameters',
  INCIDENTS = 'manage-incidents',
  INCIDENTS_SOCIAL_MEDIA_TEXTS = 'manage-incidents-social-media-text',
  CAMERAS = 'manage-cameras',
  SENSORS = 'manage-sensors',
  PMV = 'manage-variable-messaging',
  PMVconsulta = 'PMVconsulta',
  PMVEditar = 'PMVEditar',
  ALERT_WAZE = 'alert-waze',
}
