<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
  <div class="d-flex fd-row justify-space-around align-items-center">
    <div class="d-flex fd-col justify-center align-items-center text-white">
      <sit-icon [icon]="Icon.tacometro" [sizeClass]="'icon-size-78'" class="mr-1 ml-16"
        alt=""></sit-icon>
      <span>{{speedAvg}} Km/h</span>
      <label>Velocidad Promedio</label>
    </div>
  </div>
  <div class="d-flex fd-row justify-space-around align-items-center">
    <div class="card-info">
      <div class="row circle-blue text-white">
        <svg class=" mt-1" style="height: 3em; width: 4em; display: inline-block;" [ngStyle]="{'fill': color }"  title="area" role="img">
          <use [attr.href]="'../../../../assets/icons/icons.svg#' + icon" />
        </svg>
      </div>
      <div class="capsule text-white">
        <label>Nivel de Servicio</label>
      </div>
    </div>
    <div class="d-flex fd-col justify-center align-items-center text-white mt-1">
      <div class="row circle-blue text-white">
        <svg class=" mt-1" style="height: 3em; width: 4em; display: inline-block;" [ngStyle]="{'fill': colorFlow }"  title="area" role="img">
          <use [attr.href]="'../../../../assets/icons/icons.svg#icon-flujo'" />
        </svg>
      </div>
      <span>{{ totFlow }}</span>
      <label>Flujo</label>
    </div>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.last_hours_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="reloj icono título gráfica historico de agentes"></sit-icon>
    <h3 class="text-white m-0 text-size-18 text-weight-500">
      FLUJO
    </h3>
  </div>
  <div class="d-flex fd-row chart-line-container">
    <sit-chart-line [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width">
    </sit-chart-line>
  </div>
  <div
    
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.chronometer_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="gorra agentes icono título lista de agentes"></sit-icon>
    <h3 class="text-white m-0 text-size-18 text-weight-500">
      VELOCIDAD
    </h3>
  </div>
  <div class="d-flex fd-row chart-line-container">
    <sit-chart-line [properties]="dataGraphicListSpeed" [colorScale]="colorScaleSpeed" class="full-width">
    </sit-chart-line>
  </div>
  <div
  class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
  <div class="left-title-block bg-color-primary-1"></div>
  <sit-icon [icon]="Icon.sensor_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
    alt="reloj icono título gráfica historico de agentes"></sit-icon>
  <h3 class="text-white m-0 text-size-18 text-weight-500">
    SENSORES
  </h3>
</div>
  <div>
    <table class="table-agents">
      <thead class="text-white text-size-12">
        <th>Dirección</th>
        <th> Flujo
        </th>
        <th> Velocidad
        </th>
        <th>Nivel de Servicio
        </th>
      </thead>
      <tbody class="p-0 text-weight-400 work-sans-font text-size-16 text-white">
        <ng-container *ngFor="let item of lists">
          <tr (click)="openModal(item)">
            <td>{{ item.name }}</td>
            <td class="mr-1">{{ item.flowSum }}</td>
            <td>{{ item.speedAverage | number : '1.2-2' }}</td>
            <td>{{ item.serviceLevel}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
