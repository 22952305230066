import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';

export enum InputTypeEnum {
  INPUT = 'text',
  INPUT_NUMBER = 'number',
  CHIP = 'chip',
  LOCATION_INPUT = 'address',
  LOCALITY = 'Localidad',
  LOCALITY_NAME = 'NombreLocalidad',
  LOCATION_ID = 'locationId',
  LOCATION_INPUT_KEY = 'location',
  POSTAL_CODE_INPUT = 'postalCode',
  AUTOCOMPLETE_INPUT = 'autocomplete',
  LATITUD_CODE_INPUT = 'latitudCode',
  LONGITUD_CODE_INPUT = 'longitudCode',
  SELECT = 'select',
  TEXT = 'textarea',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  TOOGLESWITCH = 'toogleSwitch',
  BUTTON_CHECKBOX = 'button-checkbox',
  BUTTON_RADIO = 'button-radio',

  LIST = 'list',
  BINARY = 'binary',
}

export enum FormGroupNamesEnum {
  MAIN = 'mainForm',
  OPTIONAL = 'OptionalForm',
  INCIDENT_TYPE = 'incidentTypeId',
  OPTIONAL_DEPENDENT = 'optionalDependent',
}

export interface ParametricFormI {
  type: InputTypeEnum;
  label: string;
  formKey: string;
  formControl: {
    value: string | string[];
    options: any;
    validators?: IValidatorModel[];
  };
  turnLabelAsValue?: boolean;
  isDynamicControl?: boolean;
  parentFormKey?: string;
  parentFormName?: string;
  ancestorValueDependant?: {
    key: string,
    type: string,
  }
  hiddenControl?: boolean;
  hiddenLabel?: boolean;
  listOptionsType?: string;
  dynamicInsideControlList?: string;
  list?: { value: string, name: string }[];
  cacheList?: boolean;
  formName?: string;
  isOptionalFormInSelfGroup?: boolean;
  isOptionalFormDependentInSelfGroup?: boolean;
  multipleFormControl?: boolean;
  multipleFormControlModel?: ParametricFormI[][];
  conditionalFormMap?: { [key: string]: ParametricFormI[][] };
  dependentFormModel?: ParametricFormI[][];
  changeValueFn?: (control: AbstractControl) => (params?: any) => any;
}

export interface IGenericFormModel {
  serviceDataSource: AbstractPanelManagementDataSource;
  geolocationDataSource?: any;
  formGroup: UntypedFormGroup;
  formModel: ParametricFormI[][];
  actionDataForm?: Subject<any>;
  actionPanel$: BehaviorSubject<any>;
  panelOnManagement: string;
}

export interface IDynamicFormModel {
  enabled: boolean;
  hasAssociatedRecords: boolean;
  id: string;
  maxCharacterSize: number | null;
  minCharacterSize: number | null;
  name: string;
  options: IDynamicFormOption[];
  type: string;
}

export interface IDynamicFormOption {
  id: string,
  name: string,
}

export enum CodeValidatorNameEnum {
  REQUIRED = 'required',
  MIN_LENGTH_VALIDATOR = 'minlength',
  MAX_LENGTH_VALIDATOR = 'maxlength',
  MAX_VALIDATOR = 'max',
  SAME_VALIDATOR = 'sameValueValidator',
  EMAIL_VALIDATOR = 'email',
  SPECIAL_CHARACTERS = 'specialCharacters',
  ONLY_NUMBERS = 'onlyNumbers',
  NUMBERS_CHARACTERS = 'numberCharacters',
  COMPARE_DATETIMES = 'compareDateTimeValidator',
  GREATER_DATE = 'greaterThanDateValidator',
  LESS_DATE = 'lessThanDateValidator',
  COMPARE_VALUE_KEY = 'compareValueKey',
}

export interface IValidatorModel {
  validator: ValidationErrors;
  validatorCodeName: CodeValidatorNameEnum;
  errorMessage: string;
}

export const SameValueValidator = (
  controlName: string,
  matchingControlName: string
) => {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (
      matchingControl?.errors &&
      !matchingControl?.errors[`${CodeValidatorNameEnum.SAME_VALIDATOR}`]
    ) {
      return;
    }
    if (control?.value !== matchingControl?.value) {
      const error = { [CodeValidatorNameEnum.SAME_VALIDATOR]: true };
      matchingControl.setErrors(error);
    } else {
      matchingControl.setErrors(null);
    }
  };
};

export const specialCharactersValidator = (control: FormControl) => {
  // eslint-disable-next-line no-useless-escape
  const specialCharactersRegexp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (control.value && specialCharactersRegexp.test(control.value)) {
    const error = { [CodeValidatorNameEnum.SPECIAL_CHARACTERS]: true };
    return error;
  }
  return null;
};

export const onlyNumbersValidator = (control: FormControl) => {
  // eslint-disable-next-line no-useless-escape
  const onlyNumbersRegexp = /^[0-9]*$/;
  if (control.value && !onlyNumbersRegexp.test(control.value)) {
    const error = { [CodeValidatorNameEnum.ONLY_NUMBERS]: true };
    return error;
  }
  return null;
};

export const numberCharactersValidator = (control: FormControl) => {
  // eslint-disable-next-line no-useless-escape
  const numberCharactersRegexp = /^(?!.*\.\..*)[0-9.]+$/;
  if (control.value && !numberCharactersRegexp.test(control.value)) {
    const error = { [CodeValidatorNameEnum.NUMBERS_CHARACTERS]: true };
    return error;
  }
  return null;
};

export const greaterThanDateValidator = (nameControlDateCompare: string) => (control: AbstractControl) => {
  const dateOne = control?.value;
  const dateTwoControl = control?.parent?.get(nameControlDateCompare);
  const dateTwo = dateTwoControl?.value;

  if (dateOne && dateTwo) {
    if (`${dateOne}`.localeCompare(`${dateTwo}`) > 0) {
      const error = { [CodeValidatorNameEnum.GREATER_DATE]: true };
      return error;
    }
  }
  return null;
}

export const lessThanDateValidator = (nameControlDateCompare: string) => (control: AbstractControl) => {
  const dateOne = control?.value;
  const dateTwoControl = control?.parent?.get(nameControlDateCompare);
  const dateTwo = dateTwoControl?.value;

  if (dateOne && dateTwo) {
    if (dateOne < dateTwo) {
      const error = { [CodeValidatorNameEnum.LESS_DATE]: true };
      return error;
    }
  }
  return null;
}

export const compareValueKeyValidator = (nameControl: string, codeCompare: string) => (control: AbstractControl) => {
  const dateOne = control?.value;
  const dateTwoControl = control?.parent?.get(nameControl);
  const dateTwo = dateTwoControl?.value;
  const currentDate = new Date();

  if (dateOne) {
    if (dateTwo === codeCompare) {
      if (dateOne > currentDate) {
        const error = { [CodeValidatorNameEnum.COMPARE_VALUE_KEY]: true };
        return error;
      }
    }
  }

  return null;
}

