import { number, string } from "@amcharts/amcharts4/core";

import {
    IGeometryModel,
    MarkerModelBase,
  } from '@types-custom/models/business/marker.model';
import { Icon } from "@types-custom/models/ui/icon-model";
  
 export interface IcoiProp {

    id:string;
    location:string;
    title:string;
    icon:Icon;
    alt:string;
    observaciones: string;
    contratista: string;
    fini: string;
    ffin: string;
    direccion: string;
    tcoi: string;
    htra: string;
    hcie: string;
    markerPopupText: string;
    

  }
  
 export class coiModel extends MarkerModelBase {
    static className = 'Coi';
    classProperties: IcoiProp;
  
    constructor(props: IcoiProp, geometry: IGeometryModel) {
      super(geometry, coiModel.className);
      this.classProperties = props;
    }
  }
    