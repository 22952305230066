import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  reverseMappingExtensionAllowed,
  getExtensionAllowed,
} from '@types-custom/models/ui/drop-file-model';

/*
** Drop Files Component
** Allows you to drop or browse for files to upload.
*/

@Component({
  selector: 'drop-files',
  templateUrl: './drop-files.component.html',
  styleUrls: ['./drop-files.component.scss'],
})
export class DropFilesComponent implements OnInit, OnChanges {
  @Output() filesDropped = new EventEmitter<Array<any>>();
  @Input() fileSubmited = false;
  @Input() extensionAllowedEnum!: { [keys: string]: string };
  @Input() maxFiles!: number;
  @Input() messageValidateFiles!: string;
  files: Array<File> = [];
  icon: Icon = Icon.delete_w;
  private fileTypesAllowed = '';
  @ViewChild('filesInput')
  filesInput!: ElementRef;

  get getFileTypesAllowed() {
    return this.fileTypesAllowed;
  }

  ngOnInit(): void {
    this.fileTypesAllowed = getExtensionAllowed(this.extensionAllowedEnum);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileSubmited.currentValue) {
      this.files = [];
      this.filesInput.nativeElement.value = "";
      this.filesDropped.emit(this.files);
    }
  }

  onDroppedFile($event: File[]): void {
    if ($event) {
      for (const file of $event) {
        const ext = file.name
          .substring(file.name.lastIndexOf('.'))
          .toLowerCase();
        if (reverseMappingExtensionAllowed(this.extensionAllowedEnum, ext)) {
          if(this.files.length < this.maxFiles){
            this.files.push(file);
          }else{
              alert('Solo se puede cargar un archivo');
          }
        } else {
          if(this.messageValidateFiles){
            alert(this.messageValidateFiles);
          }else{
            alert('Archivos no soportados');
          }
        }
      }
      this.filesDropped.emit(this.files);
    }
  }

  onBrowseFile($event: any): void {
    const { files } = $event.target;
    if (files) {
      this.onDroppedFile(files);
    }
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    (document.getElementById('files') as HTMLInputElement).value = "";
    this.filesDropped.emit(this.files);
  }

  formatBytes(a: number, b = 2, k = 1024): string {
    const d = Math.floor(Math.log(a) / Math.log(k));
    return 0 == a
      ? '0B'
      : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
          ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
  }
}
