<side-menu
  [activeIcon$]="props.currentLayer$"
  [iconInfo]="props.iconInfo"
  [logoText]="props.logoText"
  [isExpanded]="
    props?.isSideMenuVisible ? (props.isSideMenuVisible | async) : false
  "
  [userName]="props?.textInline ? (props.textInline | async) : undefined"
  (expandedChange)="toggleSideMenu($event)"
>
</side-menu>

<div class="map-viewer-container full-height">
  <div class="pos-rel">
    <div class="icon-filter d-none d-sm-block d-md-block">
      <sit-icon
        *ngIf="isVisibleIcon"
        [icon]="Icon.filter_w"
        [sizeClass]="'icon-size-40 '"
        (click)="toggleFilterIncident()"
      >
      </sit-icon>
    </div>
    <!-- <sit-map-conventions
      [mapConventionsProperties]="props?.mapConventionsProps"
    ></sit-map-conventions> -->
    <map-filter [properties]="mapFilterModel"></map-filter>
    <ng-container *ngIf="props.currentLayer$.value === 'Situations'">
      <sit-map-conventions
        [mapConventionsProperties]="props?.mapConventionsProps"
      ></sit-map-conventions>
    </ng-container>

    <dynamic-side-menu [properties]="dynamicMenuModel"></dynamic-side-menu>
    <div
      #mapboxContainer
      tabindex="-1"
      id="{{ props.containerId }}"
      class="full-height"
    ></div>
  </div>
</div>
<sit-footer
  class="pos-abs"
  [footerMenus]="props.footerModel"
></sit-footer>

<div class="custom-tooltip" id="tooltip">Tooltip Content</div>

