import { Observable } from "rxjs";

export abstract class AbstractGraphicDataSource {
  abstract getTravelTimesHistoryTid(
    tid: string,
    type: number
  ): Observable<any[]>;
}

export interface IPointOnTimeLine {
  time?: string,
  value?: number,
  color?: any,
}
export interface ITimeLineGraphicModel {
  data: IPointOnTimeLine[],
  label?:string,
  labelX?:string,
  labelY?:string,
  opacity?:boolean
  colorLine?:string
}

export interface ITimeSeriesGraphicModel {
  data: IPointOnTimeLine[];
  label?:string[]
  labelX?:string
  labelY?:string
  colorLine?:string[]
}
