import { StateFieldToggleComponent } from '@official/pages/fields-management/controls/state-field-toggle/state-field-toggle.component';
import { ActionsIncidentParametersComponent } from '@official/pages/incident-parameters-management/actions-incident-parameters/actions-incident-parameters/actions-incident-parameters.component';
import { ActionsIncidentsCoordinationComponent } from '@official/pages/manage-incidents/actions-incidents-coordination/actions-incidents-coordination.component';
import { ActionDatexCategoryComponent } from '@official/pages/manage-datex-categories/enable-category/action-datex-category/action-datex-category.component';
import { ActionsGroundsResourceComponent } from '@official/pages/management-ground-resource/actions-grounds-resource/actions-grounds-resource.component';
import { formatDate, isoFormatDate } from '@shared/utils/functions/format-date';
import {
  IGridTableCellDispatcher,
  IGridTableHeaderModel,
} from '@types-custom/models/ui/grid-table-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IconComponent } from '@ui-core/controls/icon/icon.component';
import { StateIndicatorComponent } from '@ui-core/controls/state-indicator/state-indicator.component';
import { StateToogleComponent } from '@ui-core/controls/state-toogle/state-toogle.component';
import { Subject } from 'rxjs';
import { ActionsIncidentsComponent } from '@ui-core/components/actions-incidents/actions-incidents.component';
import { ActionsNoveltyVevhicleComponent } from '@official/pages/manage-novelty-vehicle/actions-novelty-vehicle/actions-novelty-vehicle.component';
import { ActionsIncidentsSocialMediaTextComponent } from '@ui-core/components/actions-incidents-social-media-text/actions-incidents-social-media-text.component';

export enum GridTypeEnum {
  Situations = 'Situations',
  UserManage = 'UserManage',
  ManageCameras = 'ManageCameras',
  ManageSensors = 'ManageSensors',
  ManageVariableMessaging = 'Manage-variable-messaging',
  ManageIncidents = 'ManageIncidents',
  ManageIncidentsSocialMediaText = 'ManageIncidentsSocialMediaText',
  ManageDatexCategories = 'ManageDatexCategories',
  ManageIncidentParameters = 'ManageIncidentParameters',
  ManageIncidentsCoordinator = 'ManageIncidentsCoordinator',
  IncidentConfiguration = 'IncidentConfiguration',
  PMVconsulta = 'PMVconsulta',
  PMVEditar = 'PMVEditar',
  PMVEditarProgramados = 'PMVEditarProgramados',
  RoleManage = 'RoleManage',
  ManageGroundResource = 'manage-ground-resource',
  PhysicalResources = 'PhysicalResources',
  ScheduleActivities = 'ScheduleActivities',
  ScheduleActivitiesReport = 'ScheduleActivitiesReport',
  ScheduleActivitiesHistoryReport = 'ScheduleActivitiesHistoryReport',
  HumanResources = 'HumanResources',
  ManageFields = 'ManageFields',
  ManageWazeAlerts = 'ManageWazeAlerts',
  VehicleNovelty = 'VehicleNovelty'
}


export const gridTableheadersMap = (
  dispatchers: Subject<IGridTableCellDispatcher>[] = [],
): { [key: string]: IGridTableHeaderModel[] } => ({

  [GridTypeEnum.VehicleNovelty]: [
    { key: 'id', label: 'ID', cellInteger: true },
    { key: 'typeEventName', label: 'Evento', },
    { key: 'creationDate', label: 'Fecha', },
    { key: 'physicalResourceTypeName', label: 'Tipo de Vehículo' },
    { key: 'physicalResourceName', label: 'Tipo de Recurso' },
    { key: 'physicalResourceId', label: 'ID Recurso' },
    { key: 'humanResourceNames', label: 'Nombre de Recurso en Campo' },
    { key: 'humanResourceLastNames', label: 'Apellido de Recurso en Campo' },
    {
      key: 'parameter',
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: ActionsNoveltyVevhicleComponent,
        data: {
          dispatchers: dispatchers ?? []
        }
      },
    },
  ],
  [GridTypeEnum.ManageGroundResource]: [
    { key: 'fullName', label: 'Nombre y Apellido' },
    { key: 'groupName', label: 'Grupo' },
    { key: 'jobName', label: 'Cargo' },
    { key: 'indicative', label: 'Indicativo' },
    { key: 'stateName', label: 'Estado' },
    { key: 'physicalResourceCount', label: 'No. de Recursos asignados', cellInteger: true },
    { key: 'email', label: 'Correo Electrónico' },
    {
      key: 'idEditresource',
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: ActionsGroundsResourceComponent
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined
    },
  ],

  [GridTypeEnum.Situations]: [
    { key: 'SituationDate', label: 'Fecha' },
    { key: 'CodeDeparment', label: 'Departamento' },
    { key: 'CodeCity', label: 'Municipio' },
    { key: 'SituationType', label: 'Clase de incidente' },
    { key: 'SubType', label: 'Tipo de incidente' },
  ],
  [GridTypeEnum.ManageIncidentParameters]: [
    {
      key: 'state',
      label: 'Estado',
      valueFormatter: (state) => state ? 'Activo' : 'Inactivo',
    },
    { key: 'name', label: 'Nombre' },
    {
      key: 'parameter',
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: ActionsIncidentParametersComponent,
        data: {
          dispatcher: dispatchers ? dispatchers[0] : undefined
        }
      },
    },
  ],
  [GridTypeEnum.ManageDatexCategories]: [
    {
      key: 'id',
      label: 'ID',
      cellInteger: true,
      // valueFormatter: (state) => state ? 'Activo' : 'Inactivo',
    },
    { key: 'name', label: 'Nombre' },
    {
      key: 'subcategories',
      label: 'Subcategorias',
      // cellClass: 'cursor-pointer fx-cell-hover pr-8',
      // toRender: {
      //   component: ActionsIncidentParametersComponent,
      //   data: {
      //     dispatcher: dispatchers ? dispatchers[0] : undefined
      //   }
      // },
    },
    {
      key: 'category',
      label: 'Acciones',
      cellClass: 'cursor-pointer pr-8',
      toRender: {
        component: ActionDatexCategoryComponent,
        data: {
          dispatcher: dispatchers ? dispatchers[0] : undefined
        }
      },
    },
  ],

  [GridTypeEnum.UserManage]: [
    { key: 'name', label: 'Nombres' },
    { key: 'lastName', label: 'Apellidos' },
    { key: 'email', label: 'Correo electrónico' },
    { key: 'rolNames', label: 'Rol', cellClass: 'wb-break-all wb-md-unset' },
    {
      key: 'stateIndicatorProperties',
      label: 'Estado',
      toRender: {
        component: StateIndicatorComponent,
      },
    }
  ],
  [GridTypeEnum.PhysicalResources]: [
    { key: 'resourceTypeName', label: 'Tipo de recurso' },
    { key: 'feature', label: 'Característica' },
    { key: 'stateName', label: 'Estado' },
    { key: 'code', label: 'Código Único de Identificación' },
    { key: 'businessAreaName', label: 'Área del Negocio' },
    { key: 'humanResource', label: 'Recurso humano vinculado' },
    { key: 'conditionName', label: 'Condición' },
    // {
    //   key: 'idStateName',
    //   label: 'Acciones',
    //   cellClass: 'cursor-pointer fx-cell-hover pr-8',
    //   toRender: {
    //     component: ActionsPyshicalResourcesComponent,
    //     data: {properties:{ key: 'humanResource'}}
    //   },
    //   dispatcher: dispatchers ? dispatchers[0] : undefined
    // },
  ],
  [GridTypeEnum.ManageCameras]: [
    { key: 'id', label: 'Id', cellInteger: true, showLabel: true },
    { key: 'name', label: 'Nombre' },
    { key: 'address', label: 'Localización' },
    { key: 'statusName', label: 'Estado' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover',
      dispatcher: dispatchers.at(0), //UX defined at unique card
    },
  ],
  [GridTypeEnum.ScheduleActivities]: [
    { key: 'id', label: 'Código', cellInteger: true },
    { key: 'stateName', label: 'Estado' },
    { key: 'workingDayName', label: 'Jornada' },
    { key: 'beginDate', label: 'Fecha y hora inicio' },
    { key: 'endDate', label: 'Fecha y hora fin' },
    { key: 'countHumanResources', label: 'Cantidad de recursos asignados' },
    { key: 'activityName', label: 'Actividad' },
    { key: 'objective', label: 'Objetivo de la actividad' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.check_w,
          alt: 'icono check acción publicar',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.layers_w,
          alt: 'icono clonar acción clonar',
        },
      },
      dispatcher: dispatchers ? dispatchers[2] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.delete_w,
          alt: 'icono canasto acción borrar',
        },
      },
      dispatcher: dispatchers ? dispatchers[3] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.reports_w,
          alt: 'icono canasto acción reporte',
        },
      },
      dispatcher: dispatchers ? dispatchers[4] : undefined,
    },
  ],
  [GridTypeEnum.ManageSensors]: [
    { key: 'id', label: 'Id', cellInteger: true, showLabel: true },
    { key: 'sensorTypeName', label: 'Tipo Sensor' },
    { key: 'address', label: 'Localización' },
    { key: 'statusName', label: 'Estado' },
    {
      key: undefined,
      label: '',
    },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
  ],
  [GridTypeEnum.ManageVariableMessaging]: [
    { key: 'id', label: 'Id', cellInteger: true },
    { key: 'name', label: 'Nombre' },
    { key: 'address', label: 'Localización' },
    { key: 'stateName', label: 'Estado' },
    {
      key: undefined,
      label: '',
    },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
  ],
  [GridTypeEnum.ManageIncidents]: [
    { key: 'id', label: 'Id', cellInteger: true, showLabel: true, headerClass: 'text-blue', cellClass: 'text-button-clean-filter' },
    { key: 'sourceName', label: 'Fuente:', showLabel: true },
    { key: 'classesName', label: 'categoria:', showLabel: true },
    { key: 'typeName', label: 'Sub-categoria:', showLabel: true },
    { key: 'creationDate', label: 'Fecha', valueFormatter: (value) => formatDate(value) },
    { key: 'stateName', label: 'Estado' },
    // {
    //   key: undefined,
    //   label: 'Acciones',
    //   cellClass: 'cursor-pointer pr-8',
    //   headerClass: 'pr-8',
    //   toRender: {
    //     component: IconComponent,
    //     data: {
    //       icon: Icon.point_options_w,
    //       alt: 'icono lápiz acción editar',
    //       dispatcher: dispatchers ? dispatchers[0] : undefined,
    //     },
    //   },
    //   // dispatcher: dispatchers ? dispatchers[0] : undefined,
    // },
    {
      key: undefined,
      label: 'add',
      cellClass: 'cursor-pointer pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: ActionsIncidentsComponent,
        data: {
          icon: Icon.point_options_w,
          alt: 'icono lápiz acción editar',
          dispatcher: dispatchers ? dispatchers[0] : undefined,
        },
      },
      // dispatcher: dispatchers ? dispatchers[0] : undefined
    }
  ],
  [GridTypeEnum.ManageIncidentsSocialMediaText]: [
    { key: 'id', label: 'Id', cellInteger: true, showLabel: true, headerClass: 'text-blue', cellClass: 'text-button-clean-filter' },
    { key: 'sourceName', label: 'Fuente:', showLabel: true },
    { key: 'classesName', label: 'categoria:', showLabel: true },
    { key: 'typeName', label: 'Sub-categoria:', showLabel: true },
    { key: 'creationDate', label: 'Fecha', valueFormatter: (value) => formatDate(value) },
    { key: 'stateName', label: 'Estado' },
    {
      key: 'id',
      label: 'add',
      cellClass: 'cursor-pointer pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: ActionsIncidentsSocialMediaTextComponent,
        data: {
          icon: Icon.point_options_w,
          alt: 'icono lápiz acción editar',
          dispatcher: dispatchers ? dispatchers[0] : undefined,
        },
      },
      // dispatcher: dispatchers ? dispatchers[0] : undefined
    }
  ],
  [GridTypeEnum.ManageIncidentsCoordinator]: [
    { key: 'id', label: 'Id Incidente', cellInteger: true, showLabel: true, headerClass: 'text-blue', cellClass: 'text-button-clean-filter' },
    { key: 'creationDate', label: 'Fecha' },
    { key: 'classesName', label: 'Categoría' },
    { key: 'typeName', label: 'Subcategoría' },
    { key: 'zoneName', label: 'Zona' },
    { key: 'brokerName', label: 'Corredor' },
    { key: 'sourceName', label: 'Fuente' },
    { key: 'gravityName', label: 'Gravedad' },
    { key: 'priorityName', label: 'Prioridad' },
    {
      key: 'id',
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: ActionsIncidentsCoordinationComponent
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined
    },
  ],
  [GridTypeEnum.PMVconsulta]: [
    { key: 'index', label: 'Id', cellInteger: true },
    { key: 'message', label: 'Mensaje' },
    { key: 'localTime', label: 'Fecha Programación' },
  ],
  [GridTypeEnum.PMVEditar]: [
    { key: 'messageNumber', label: 'Id', cellInteger: true },
    { key: 'message', label: 'Mensaje' },
    {
      key: 'isActive',
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: StateToogleComponent,
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.delete_w,
          alt: 'icono canasto acción borrar',
        },
      },
      dispatcher: dispatchers ? dispatchers[2] : undefined,
    },
  ],
  [GridTypeEnum.PMVEditarProgramados]: [
    { key: 'index', label: 'Id', cellInteger: true },
    { key: 'message', label: 'Mensaje' },
    { key: 'localTime', label: 'Fecha Inicio' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.delete_w,
          alt: 'icono canasto acción borrar',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
  ],
  [GridTypeEnum.RoleManage]: [
    { key: 'name', label: 'Rol' },
    { key: 'icon', label: 'Icono' },
    {
      key: 'edit',
      label: 'Acciones',
      span: 1,
      cellClass: 'no-width cursor-pointer fx-cell-hover',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'Icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    }
  ],
  [GridTypeEnum.ScheduleActivitiesReport]: [
    { key: 'groupName', label: 'Grupo', addIcon: true },
    { key: 'humanResourceName', label: 'Recurso Humano' },
    { key: 'location', label: 'Ubicación' },
    { key: 'work', label: 'Labor a realizar' },
    { key: 'currentStatusName', label: 'estado' },
  ],
  [GridTypeEnum.ScheduleActivitiesHistoryReport]: [
    { key: 'authorNames', label: '', cellClass: 'text-button-clean-filter' },
    { key: 'creationDate', label: '', cellClass: 'text-green-2' },
    { key: 'stateName', label: '', cellClass: 'font-weight-700' },
    { key: 'observations', label: '' },
    { key: 'images', label: '', isArray: true },
  ],
  [GridTypeEnum.HumanResources]: [
    { key: 'groupName', label: 'Grupo' },
    { key: 'humanResourceName', label: 'Recurso humano' },
    { key: 'location', label: 'Ubicación' },
    { key: 'work', label: 'Labor a realizar' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.delete_w,
          alt: 'icono canasto acción borrar',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
  ],
  [GridTypeEnum.IncidentConfiguration]: [
    { key: 'name', label: 'Categoría' },
    { key: 'subcategories', label: 'Subcategorías' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: undefined,
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.comment,
          alt: 'icono canasto acción coment',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
  ],
  [GridTypeEnum.ManageFields]: [
    { key: 'name', label: 'Campo' },
    { key: 'nameType', label: 'Tipo de campo' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer fx-cell-hover pr-8 no-width',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.edit_w,
          alt: 'icono lápiz acción editar',
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
    {
      key: 'stateParameter',
      label: '',
      cellClass: 'gridcolum cursor-pointer fx-cell-hover pr-8 no-width',
      headerClass: 'pr-8',
      toRender: {
        component: StateFieldToggleComponent,
        data: {
          icon: Icon.check_w,
          alt: 'icono check acción publicar',
        },
      },
      dispatcher: dispatchers ? dispatchers[1] : undefined,
    },
  ],
  [GridTypeEnum.ManageWazeAlerts]: [
    { key: 'id', label: 'Id', cellInteger: true, showLabel: true, headerClass: 'text-blue', cellClass: 'text-button-clean-filter' },
    { key: 'sourceName', label: 'Fuente:', showLabel: true },
    { key: 'classesName', label: 'categoria:' , showLabel: true},
    { key: 'typeName', label: 'Sub-categoria:', showLabel: true },
    { key: 'creationDate', label: 'Fecha',  valueFormatter: (value) => formatDate(value)},
    { key: 'stateName', label: 'Estado' },
    {
      key: undefined,
      label: 'Acciones',
      cellClass: 'cursor-pointer pr-8',
      headerClass: 'pr-8',
      toRender: {
        component: IconComponent,
        data: {
          icon: Icon.point_options_w,
          alt: 'icono lápiz acción editar',
          // dispatcher: dispatchers ? dispatchers[0] : undefined,
        },
      },
      dispatcher: dispatchers ? dispatchers[0] : undefined,
    },
  ],
});
