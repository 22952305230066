import {Component, Input, OnInit} from "@angular/core";
import {IModalTrafficJamWazeModel} from "@types-custom/models/ui/modal-wazejam-model";
import {IModalTrafficModel} from "@types-custom/models/ui/modal-traffic-model";
import {ITimeLineGraphicModel} from "@types-custom/models/ui/chart-line-color-model";
import {DrawerPanelService} from "@ui-core/services/drawer-panel/drawer-panel.service";
import {adjustConcordanceRotation, adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-modal-traffic-jam",
  templateUrl: "./modal-traffic-jam.component.html",
  styleUrls: ["./modal-traffic-jam.component.scss"],
})
export class ModalTrafficJamComponent implements OnInit{
  @Input() properties: any | undefined;

  constructor() {}

  ngOnInit(): void {
    this.properties.pubtimeHour = new Date(Number(this.properties.pubtime)).toLocaleTimeString();
    this.properties.pubtime = new Date(Number(this.properties.pubtime)).toLocaleDateString();
    this.properties.receptiontimeHour = this.properties.receptiontime.substr(11,8);
    this.properties.receptiontime = this.properties.receptiontime.split(" ")[0];
    //console.log(this.properties.receptiontimeHour)
  }

  protected readonly Icon = Icon;
}
