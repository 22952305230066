import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MapboxLayoutService } from '@sdk/services/map-box-layout/mapbox-layout.service';
import { IHorizontalMenuModel } from '@types-custom/models/ui/horizontal-menu.model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
** Footer
** A component that generates a horizontal-menu for each item that is passed through props.
*/
@Component({
  selector: 'sit-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() footerMenus: IHorizontalMenuModel[] | undefined;
  Icon = Icon;
  isHidden = false;
  maxWidth = 1001;

  constructor(private mapboxLayoutService: MapboxLayoutService) {}

  ngOnInit(): void {
    this.render();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.render();
  }

  toggleFooter() {
    this.isHidden = !this.isHidden;
  }

  render(): void {
    if (this.maxWidth < document.documentElement.clientWidth) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }


  }
}
