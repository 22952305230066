import { Component, OnInit, Input } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IndicatorModel } from '@types-custom/models/ui/indicators.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'citizen-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
})
export class IndicatorsComponent {
  @Input() indicators = new BehaviorSubject<IndicatorModel[] | undefined>(
    undefined
  );
  Icon = Icon;
}
