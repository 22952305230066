import {Component, Input, OnInit} from "@angular/core";
import {TrafficApiService} from "@shared/services/layer-service/traffic-api-service/traffic-api.service";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {ICardTrafficSpeedRangeModel} from "@types-custom/models/ui/card-traffic-speed-range-model";
import {adjustConcordanceRotation, adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {IcardConvention} from "@types-custom/models/ui/map-conventions-model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {ICardBikesModel} from "@shared/models/Bikes.model";

@Component({
  selector: "cdm-manizales-panel-bikes",
  templateUrl: "./panel-bikes.component.html",
  styleUrls: ["./panel-bikes.component.scss"],
})
export class PanelBikesComponent implements  OnInit{
  cards : ICardBikesModel[] = []

  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  flow : IcardConvention[] = [
    {
      icon : Icon.bicycles_w,
      label : "108",
      alt : "Flujo"
    }
  ]

  levelOfService : IcardConvention[] = [{
    icon : Icon.serva,
    label : "Nivel de Servicio",
    alt : "Nivel de Servicio"
  }]

  constructor(private trafficservice : TrafficApiService) {
  }
  public ngOnInit() {
    this.cards = []
    this.trafficservice.getLastBikesSensorsMeasurements().subscribe((res : any) => {
      res.forEach((item : any) => {
        this.cards.push(item)
      });
      this.CalculateAVGSpeed();
    })
  }

  private CalculateAVGSpeed() {
    let sumspeedAvg = 0;
    let sumflowAVG = 0

    var count = {
      "A": 0,
      "B": 0,
      "C": 0,
      "D": 0,
      "E": 0
    };

    this.cards.map((cards: ICardBikesModel) => {
      if (!isNaN(+cards.speed)) {
        sumspeedAvg = sumspeedAvg + +cards.speed;
      }
      if (!isNaN(+cards.flow)) {
        sumflowAVG = sumflowAVG + +cards.flow;
      }
      if (count.hasOwnProperty(cards.levelOfService)) {
        // @ts-ignore
        count[cards.levelOfService.toString()]++;
      }
    });

    var maxLetter = "";
    var maxCount = 0;

    for (var letter in count) {
      if (count.hasOwnProperty(letter)) {
        // @ts-ignore
        if (count[letter] > maxCount) {
          maxLetter = letter;
          // @ts-ignore
          maxCount = count[letter];
        }
      }
    }

    this.levelOfService[0].icon = maxLetter === "A" ? Icon.serva : maxLetter === "B" ? Icon.servb :
        maxLetter === "C" ? Icon.servc : maxLetter === "D" ? Icon.servd : Icon.serve

    this.avgSpeedProps.value = Math.round(sumspeedAvg/this.cards.length);
    this.flow[0].label = Math.round(sumflowAVG/this.cards.length).toString();

    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
  }

  protected readonly Icon = Icon;
}
