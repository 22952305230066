import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {IcoilineModel } from '@types-custom/models/ui/modal-coiline';
import { IcoilineProp } from '@shared/models/coiline.model';
/*
 ** Modal Coi Line
 ** Render a modal that displays information about Coi Line.
 */

@Component({
  selector: 'citizen-modal-coi-line', 
  templateUrl: './modal-coi-line.component.html',
  styleUrls: ['./modal-coi-line.component.scss'],
})
export class ModalCoilineComponent {
  @Input() properties: IcoilineModel | undefined
  Icon = Icon;
}