import { Subject } from 'rxjs';
import { IGridTableCellDispatcher, IGridTableHeaderModel } from './grid-table-model';
import { AbstractPaginatorDataSource } from './paginator-model';
import { fileTypes } from '@types-custom/models/ui/file-types.model';

export interface IGridPageModel<T = any> {
  headers: IGridTableHeaderModel[];
  dataSource: AbstractPaginatorDataSource<T>;
  isAllowDownload: boolean;
  isPublishedAll? : boolean;
  dispatcher?: Subject<IGridTableCellDispatcher>;
}

export enum GridPageActions {
  ExportExcel = fileTypes.EXCEL,
  ExportPdf = fileTypes.PDF,
}

