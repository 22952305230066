<div class="w-auto h-auto">
  <div class="d-flex fd-row pb-20 pt-30 content-card">
    <ng-container *ngFor="let indicator of runtIndicators">
      <div
        class="card p-20-px d-flex fd-col justify-space-between flex-basis-0 flex-grow gap-5-px align-items-center text-white"
      >
        <sit-icon
          [icon]="indicator.icon"
          [sizeClass]="'icon-size-58'"
          [alt]="indicator.alt"
          class="mr-1 ml-16"
        ></sit-icon>
        <span class="text-weight-600">{{ indicator.value }}</span>
        <span class="text-size-14 text-center">{{ indicator.text }}</span>
      </div>
    </ng-container>
  </div>

  <div class="container-table-runt bg-color-background-1">
    <table class="table-runt montserrat-font">
      <caption class="text-color-white text-size-18 text-weight-500">
        ÚLTIMAS PLACAS REGISTRADAS
      </caption>
      <thead class="text-white text-size-16 text-weight-400">
        <th>Placa</th>
        <th>Tipo de Vehículo</th>
        <th>Fecha de Lectura</th>
        <th>Hora de Lectura</th>
        <th>SOAT</th>
        <th>Tecnomecánica</th>
      </thead>
      <tbody class="text-white text-size-12">
        <ng-container *ngFor="let plate of platesGrid">
          <tr>
            <td>{{ plate.plate }}</td>
            <td>
              {{ plate.vehicleClass === 'cars' ? 'Carro' : 'Motocicleta' }}
            </td>
            <td>{{ plate.dateReading | date: 'dd/MM/yyyy' }}</td>
            <td>{{ plate.dateReading | date: 'HH:MM' }}</td>
            <td>{{ plate.soatExpired ? 'Si' : 'No' }}</td>
            <td>{{ plate.technoExpired ? 'Si' : 'No' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
