import { Subscribable } from 'rxjs';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { map, Observable, startWith } from 'rxjs';
import {FieldResourcesService} from "@shared/services/layer-service/field-resources/field-resources.service";

@Component({
  selector: "sit-field-resources-filter",
  templateUrl: "./field-resources-filter.component.html",
  styleUrls: ["./field-resources-filter.component.scss"],
})
export class FieldResourcesFilterComponent {
  @Input() properties: any;
  Icon = Icon;
  options: any[];
  filteredOptions: Observable<any[]>;
  agent = new FormControl<string>('');
  fb = new UntypedFormBuilder();

  CentipedeButtonProps: IButtonModel = {
    label: 'Ciempiés',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  HelmetButtonProps: IButtonModel = {
    label: 'Grupo Guía',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  SchoolCycleButtonProps: IButtonModel = {
    label: 'Al Colegio en Bici',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  AllButtonProps: IButtonModel = {
    label: 'Todos',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  CleanButtonProps: IButtonModel = {
    label: 'Limpiar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  validateActiveCentipede!: boolean;
  validateActiveGuideGroup!: boolean;
  validateActiveCycleSchool!: boolean;
  validateAll!: boolean;

  locations: any[] = [];
  filter: any[] = [];

  constructor(fieldResourcesService : FieldResourcesService) {
    fieldResourcesService.getResourcesFromGeoJSON().subscribe( (res : any) => {
      //console.log(res)
      res.features.forEach((item : any) => {
        this.locations.push({
          "id" : item.properties.id.toString()
        })
      })
    })
  }

  ngOnInit() {
    this.validateActiveCentipede = JSON.parse(localStorage.getItem('centipede'));
    this.validateActiveGuideGroup = JSON.parse(localStorage.getItem('guide-group'));
    this.validateActiveCycleSchool = JSON.parse(localStorage.getItem('cycle-school'));
    this.validateAll = JSON.parse(localStorage.getItem('all-resources'));
    console.log('prop',this.properties.mapFilterDispatcher$)

    if(localStorage.getItem('filter-resources')){
      this.filter = JSON.parse(localStorage.getItem('filter-resources'))
      this.properties.mapFilterDispatcher$.next({ filter: this.filter });
    }

    if(localStorage.getItem('id') != undefined && localStorage.getItem('id') != ''){
      this.agent.setValue(JSON.parse(localStorage.getItem('id')));
    }

    this.filteredOptions = this.agent.valueChanges.pipe(
        startWith(''),
        map((value: any) => {
          const name = typeof value === 'string' ? value : value?.id;

          return name
              ? this._filterOptions(name as string)
              : this.options.slice();
        })
    );
  }

  displayFn(alert: any): string {
    //console.log(alert)
    return alert && alert.id ? alert.id : '';
  }

  private _filterOptions(agent: string): any[] {
    return this.options.filter((option) =>
        option.name.toLowerCase().includes(agent.toLowerCase())
    );
  }


  OnFilter(FilterType:any){
    if(FilterType !== 'All') {
      //let pos = this.filter.find(e => e[1] === 'groupId')
      //this.filter.splice(this.filter.indexOf(pos),1)
      this.agent.setValue('')
      localStorage.removeItem('groupId')
      let key = 'groupId'
      let value = 0;
      let type = 1;
      let localstorage = ''
      let localValue = 'false';
      if (FilterType === "Centipede") {
        localstorage = 'centipede'
        value = 20;
        if (!this.validateActiveCentipede) {
          this.validateActiveCentipede = true;
          type = 1;
          localValue = 'true';
        } else {
          this.validateActiveCentipede = false;
          localValue = 'false'
          type = 0;
        }
      }
      else if (FilterType === "GuideGroup") {
        localstorage = 'guide-group'
        value = 17;
        if (!this.validateActiveGuideGroup) {
          this.validateActiveGuideGroup = true
          type = 1;
          localValue = 'true';
        } else {
          this.validateActiveGuideGroup = false;
          localValue = 'false'
          type = 0;
        }
      }
      else if (FilterType === 'CycleSchool') {
        localstorage = 'cycle-school'
        value = 19;
        if (!this.validateActiveCycleSchool) {
          this.validateActiveCycleSchool = true;
          type = 1;
          localValue = 'true';
        } else {
          this.validateActiveCycleSchool = false;
          localValue = 'false'
          type = 0;
        }
      }
      if((this.validateActiveCycleSchool && this.validateActiveCentipede && this.validateActiveGuideGroup) ||
         (!this.validateActiveCycleSchool && !this.validateActiveCentipede && !this.validateActiveGuideGroup) ){
        this.selectAll()
      }else {
        this.editStorage(localstorage, localValue)
        this.editFilter(key, value, type)
      }
    }
    else {
      this.selectAll()
    }
  }

  private selectAll(){
    localStorage.setItem('all-resources','true')
    this.validateAll = true;
    localStorage.setItem('centipede','false')
    this.validateActiveCentipede = false;
    localStorage.setItem('guide-group','false')
    this.validateActiveGuideGroup = false;
    localStorage.setItem('cycle-school','false')
    this.resetFilter()
  }

  private editStorage(key : string, value : string){
    localStorage.setItem('all-resources','false')
    this.validateAll = false;
    localStorage.setItem(key,value)
  }

  private editFilter(key : string, value : number, type : number){
    if(type === 0) { // Disable filter
      //console.log(value)
      let pos = this.filter.find((e : any) => (e[1] === key && e[2] === value))
      this.filter.splice(this.filter.indexOf(pos),1)
      //console.log(this.filter,'Valor a eliminar',value,'La key',key)
    }else {
      if(this.filter.length >= 1 && this.filter.length <= 3){
        this.filter.push(["==",key,value])

      }
      else if(this.filter.length == 0){
        this.filter = ["any",
          ["==",key,value],
        ];
      }
    }

    this.checkAvailableFilters()
  }

  private checkAvailableFilters() {
    if((this.filter.length === 1 && this.filter[0] === 'any')) {
      this.filter = [];
    }
    localStorage.setItem('filter-resources',JSON.stringify(this.filter))
    this.properties.mapFilterDispatcher$.next({ filter: this.filter });
  }

  filterAlert(alert: any): void {
    this.selectAll()
    localStorage.setItem('all-resources','false')
    //console.log(alert)
    this.filter = ["all",
      ["==","id",alert.id],
    ];

    localStorage.setItem('filter-resources',JSON.stringify(this.filter))
    localStorage.setItem('id',JSON.stringify(alert));
    this.agent.setValue(JSON.parse(localStorage.getItem('id')));

    this.properties.mapFilterDispatcher$.next({ filter: this.filter });
  }

  resetFilter(){
    let filter: string[] = [];
    this.filter = [];
    this.properties.mapFilterDispatcher$.next({ filter: filter });
    this.validateActiveCycleSchool = false;
    this.validateActiveGuideGroup = false;
    this.validateActiveCentipede = false;
    localStorage.setItem('centipede','false');
    localStorage.setItem('guide-group','false');
    localStorage.setItem('cycle-school','false');
    this.agent.reset('');
    localStorage.setItem('filter-resources',JSON.stringify(this.filter))
  }

  resetFilterLocations(){
    let filter: string[] = [];
    this.filter = this.filter.filter((item1:any)=> item1[1] != "id")
    this.properties.mapFilterDispatcher$.next({ filter: this.filter });
    this.agent.reset('');
  }
}
