const BaseURL       = 'https://qa.gateway.sitcali.com';
const StaticBaseURL = 'https://sig.simur.gov.co/arcgis/rest/services';

export const environment = {
  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1Ijoic2l0Ym9nb3RhIiwiYSI6ImNsanNxMTBtNjBvNnkzZG55OWl0ZnFtejQifQ.lrEc5SpOS3TxJB4E5gQcpA',
    style:       'mapbox://styles/skgtecnologia2020/ckw3osaq411de16o487rmlx30',
    zoom:   14.54,
    center: [-74.056307, 4.695538],
  },
  geoLocations: {
    bounds:{
      swlat:  4.475,
      swlng:  -74.217,
      nelat:  4.840190450915529,
      nelng:  -73.99434174671173,
    },
    apiKey:         'AIzaSyAkmkEsQJ-VTJqkxmfGvpPEIT6uFStVYCE',
    getAddressUrl:  'https://maps.googleapis.com/maps/api/geocode',
  },
  authUrl:                `${BaseURL}/auth/api/v1`,
  userUrl:                `${BaseURL}/auth/api/v1`,
  resourcesUrl:           `${BaseURL}/resources/api/v1`,
  listPhysicalResources:  `${BaseURL}/resources/api/v1/categories`,
  role:                   `${BaseURL}/auth/api/v1/rol/`,
  type:                   `${BaseURL}/auth/api/v1/document-type/`,

  agentsUrl:              `${BaseURL}/agents/api/v1`,
  camerasUrl:             `${BaseURL}/camera/api/v1`,
  streamingCCTVUrl:       `${BaseURL}/cctv/api/v1/camera-streaming/`,
  environmentalUrl:       `${BaseURL}/enviromental/api/v1`,
  incidentUrl:            `${BaseURL}/incidents/api/v1`,
  vehicleNovelty:         `${BaseURL}/resources/api/v1/novelty/`,
  incidentHeatMAp:        `${BaseURL}/heat-map`,
  trafficUrl:             `${BaseURL}/traffic/api/v1`,

  BikesUrl:               `${BaseURL}/bikes/api/v1/`,

  vmsUrl:                 `${BaseURL}/vms/api/v1`,
  vehiclesUrl:            `${BaseURL}/vehicles/api/v1`,
  cranesUrl:              `${BaseURL}/cranes/api/v1`,
  cranesHistoryUrl:       `${BaseURL}/cranes/api/v1`,

  predictiveUrl:          `${BaseURL}/analytical-services/api/v1`,
  
  WazeUrl:                `${BaseURL}/waze`,
  trafficJamWaze:         `${StaticBaseURL}/Hosted/V3_Waze_Jams_Recent/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&datumTransformation=&lodType=geohash&lod=&lodSR=&f=geojson`,

  // Field Resources
  resource:               `${BaseURL}/resources/api/v1`,

  geoApiPageSize:   1000,
  keycloak: {
    issuer:     'https://keycloak.sitcali.com/auth/realms/scali-qa-env',
    redirectUri: window.location.origin + '/visor-mapa',

    clientId:   'scali_qa_client',

    responseType: 'code',
    scope:        'openid profile email offline_access',

    requireHttps:         true,
    showDebugInformation: true,
    disableAtHashCheck:   true,

    skipIssuerCheck : true,
    strictDiscoveryDocumentValidation : false,
    clearHashAfterLogin: true,
  },
  keyCloakParams: {
    realmId:    'smm-qa-env',
    clientId:   'smm-qa-test',
    clientSecret: 'cSKg6Ppr1sFFbGoNNEr4SFtubbMsJiuy',
  },
  keyCloakAuthUrl: `https://qa.movilidad-manizales.com/keycloak/realms/smm-qa-env/protocol/openid-connect`,
  baseStaticLayerUrl:
    'https://sigmzl.manizales.gov.co/wadmzl/rest/services/MOVILIDAD/Inventario_Vial/MapServer',
  reportsUrl:
    'https://cxcvse9bke40sfn.us.qlikcloud.com/sense/app/cef493ed-2515-4c15-9f8d-5b84776ef56d/sheet/eea98c1d-dadf-48fa-8ada-2fbd618406e5/state/analysis',
  simacUrl:       'https://cdiac.manizales.unal.edu.co/geoportal-simac/',
  dashboardUrl:   'https://app.powerbi.com/view?r=eyJrIjoiODE2OTI2YmQtN2ViMy00N2VlLWFkZDctNWUwYzdkOGQwM2NjIiwidCI6IjFjMTg4ZWY2LTllOGYtNGQ5My04YjhjLWM4Njg4ZWFiYTAyYiIsImMiOjR9',
  observatoryUrl: 'https://laboratorio.manizales.gov.co/observatorio-de-movilidad-de-manizales/',
  aletsGrapph:    `${BaseURL}/waze/api/v1/waze/alerts/history/graph`,
  
  //cappas estaticas
  coiPointUrl:  `${StaticBaseURL}/PMT/PMT_Publicacion_Vigentes_Provisional/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson`,
  semaforos:    `${StaticBaseURL}/CentroGestion/CG_Semaforos/MapServer/0/query?where=1%3D1&outFields=*&returnGeometry=true&returnTrueCurves=false&returnIdsOnly=false&returnCountOnly=false&returnZ=false&returnM=false&returnDistinctValues=false&returnExtentOnly=false&featureEncoding=esriDefault&f=geojson`,
  coosLineUrl:  `${StaticBaseURL}/PMT/PMT_Publicacion_Vigentes_Provisional/FeatureServer/3/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson`,
  coosUrl:      `${StaticBaseURL}/PMT/PMT_Publicacion_Vigentes_Provisional/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson`,
  bicicletasUrl:`${StaticBaseURL}/PMT/RedCiclorrutaPMT/MapServer/0/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`,
  coiLineUrl:   `${StaticBaseURL}/PMT/PMT_Publicacion_Vigentes_Provisional/FeatureServer/2/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson`,
  paradaBusUrl: 'https://services2.arcgis.com/NEwhEo9GGSHXcRXV/arcgis/rest/services/Paraderos_SITP_Bogot%C3%A1_D_C/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json',
  baseApiUrlPolygons: 'https://storage.googleapis.com/storage/v1/b/sit_3_files/o/localidades.geojson?alt=media',
  corredores:   `https://sit-corredores-dot-smart-helios-sit.appspot.com/corredores`,
};
