<ng-container *ngIf="properties | async as props">
  <ng-container *ngFor="let filter of props">

    <div
      class="bttn d-flex fd-row align-items-center borders bg-white mb-8 cursor-pointer filter-button border-r-8"
      id="closeFilter"
      (click)="onShowFilter()"
    >Filtro 
    <sit-icon 
    class="mt-4"
        [alt]="'Abrir panel filtros'"
        [icon]="Icon.arrow_down_w"
        [sizeClass]="'icon-size-20'"
      ></sit-icon>
    </div>

    <ng-container *ngIf="filterShow">
      <div
      class="main-container border-r-8 {{ filter.customClass }} "
      [style.top.px]="filter.topPosition ? filter.topPosition : 10"
      >
      <div class="filter-container bg-color-filter text-white">
        <div class="content">
          <ng-container *ngIf="filter.content as toRender">
            <sit-renderer [toRender]="toRender"></sit-renderer>
          </ng-container>
        </div>
      </div>
    </div>

    </ng-container>

  <div *ngIf="filterAlerts">
      <div
      [ngClass]="{'active': showConventions}"
      class="bttn2 d-flex fd-row align-items-center borders bg-white cursor-pointer conventions-button"
      (click)="showConventionsPanel()"
    >
      <sit-icon
        [alt]="'Abrir panel filtros'"
        [icon]="Icon.help_w"
        [sizeClass]="'icon-size-20'"
      ></sit-icon>
      <h2 class="text-white text-weight-600 text-size-16 ml-05 m-0">
        Convenciones
      </h2>
    </div>

    <div class="bttn3 align-items-center borders bg-white conventions-info"
    *ngIf="showConventions">

          <div *ngIf="conventionAlerts">          
          <div class="d-flex fd-row mt-1-em">
            <sit-icon
              [alt]="'Abrir panel filtros'"
              [icon]="Icon.map_accident"
              [sizeClass]="'icon-size-30'"
            ></sit-icon>
            <h2 class="text-white text-weight-600 text-size-16 ml-05">
              Accidentes
            </h2>
          </div>

          <div class="d-flex fd-row">
            <sit-icon
              [alt]="'Abrir panel filtros'"
              [icon]="Icon.map_roadClosed"
              [sizeClass]="'icon-size-30'"
            ></sit-icon>
            <h2 class="text-white text-weight-600 text-size-16 ml-05">
              Vía Cerrada
            </h2>
          </div>
          
          <div class="d-flex fd-row">
            <sit-icon
              [alt]="'Abrir panel filtros'"
              [icon]="Icon.map_climate"
              [sizeClass]="'icon-size-30'"
            ></sit-icon>
            <h2 class="text-white text-weight-600 text-size-16 ml-05">
              Climáticas
            </h2>
          </div>
        </div>  
  
        <div *ngIf="conventionHeatMap">
            <div class="p-16-px">
              <div class="d-flex fd-row justify-space-around mb-16 ">
                  <div class="mr-16">
                      <span class=""> Menor densidad</span>             
                  </div>
                  <div class="">
                      <span class=""> Mayor densidad</span>             
                  </div>                     
              </div>
              <div class="d-flex fd-row justify-center">
                  <div class="heatmapcolor1">
                  </div> 
                  <div class=" heatmapcolor2">
                  </div> 
                  <div class="heatmapcolor3">
                  </div> 
                  <div class="heatmapcolor4">
                  </div>     
                  <div class="heatmapcolor5">
                  </div>         
              </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
