import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IDynamicSideMenuModel } from '@types-custom/models/ui/dynamic-side-menu';
import { BehaviorSubject } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

/*
 ** Dynamic Side Menu
 ** Render a side menu that allows you to show data based on a specified model.
 */
@Component({
  selector: 'dynamic-side-menu',
  templateUrl: './dynamic-side-menu.component.html',
  styleUrls: ['./dynamic-side-menu.component.scss'],
})
export class DynamicSideMenuComponent implements OnInit {
  @Input() properties: BehaviorSubject<IDynamicSideMenuModel> | undefined;
  isComponentVisible = new BehaviorSubject<boolean>(false);
  Icon = Icon;

  ngOnInit(): void {
    if (this.properties) {
      this.properties
        .pipe(
          tap(() => this.isComponentVisible.next(false)),
          delay(10)
        )
        .subscribe(this.handlePropertiesUpdate.bind(this));
    }
  }

  handlePropertiesUpdate(props: IDynamicSideMenuModel): void {
    if (props.content) {
      this.isComponentVisible.next(true);
    }
  }

  toggleMenu(): void {
    if (this.properties?.value.toggleDispatcher$) {
      const {
        properties: {
          value: { toggleDispatcher$ },
        },
      } = this;
      const isVisible = toggleDispatcher$.value;
      toggleDispatcher$.next(!isVisible);
    }
  }

  disableClickDispatcher() {
    this.properties.value.content.data.properties?.clickInteractionDispatcher?.next(
      undefined
    );
  }
}
