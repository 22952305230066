<div
  class="modal-traffic w-auto h-auto d-flex fd-col text-blue text-weight-700 pt-30"
>


  <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
    <div class="d-flex d-row justify-space-evenly">

      <div class="averageSpeed-indicator d-flex fd-col align-items-center">
          <div class="container-graphic-speed">
            <sit-speedometer
            [properties]="{chartType: 4, value: +properties.speed, unitLabel: 'Km/h', divId: 'id', labelsClass: 'mt-0', unitLabelClass: 'text-size-14' }"
            ></sit-speedometer>
        </div>
      </div>
      <!-- TODO: dont delete this, SINITT-473. -->
      <!-- <div class="v-separator-line"></div> -->

      <!-- <div class="travelTime-indicator d-flex fd-col align-items-center">
        <sit-icon
          [sizeClass]="'icon-size-50'"
          [icon]="properties?.iconTravelTime"
        ></sit-icon>
        <span class="mb-05 text-size-24">{{ properties?.travelTime }}</span>
        <label class="text-gray text-size-16 text-weight-500"
          >Tiempo de Recorrido</label
        >
      </div> -->

      <div class="v-separator-line"></div>

      <div class="vehicleCount-indicator d-flex fd-col align-items-center">
        <div class="d-flex fd-col text-white">
          <label class="text-size-14 text-weight-400">Esperado</label>
          <label class="text-size-18 text-weight-500">3m 25s</label>
          <label class="text-size-14 text-weight-400">Real</label>
          <label class="text-size-18 text-weight-500">3m 26s</label>
        </div>

      </div>

      <div class="v-separator-line"></div>

      <div class="vehicleCount-indicator d-flex fd-col align-items-center">
        <sit-bar-chart [properties]="{tid: +properties?.id, dataAll: dataHistory, type: 2}"></sit-bar-chart>
      </div>

      <div class="v-separator-line"></div>

      <div class="vehicleCount-indicator d-flex fd-col align-items-center">
        <div class="container-graphic-speed">
          <sit-speedometer
          [properties]="{chartType: 5, value: +properties.concordance, unitLabel: '%', divId: 'id', labelsClass: 'mt-0', unitLabelClass: 'text-size-14' }"
          ></sit-speedometer>
      </div>
      </div>

    </div>

    <div class="d-flex full-width" style="height: 200px;">
      <!-- <sit-chart-line
        [properties]="{tid: +properties.id, type: 1, dataAll: dataHistoryTravel, typeLocal: 2}" style="width: 100%;"
      ></sit-chart-line> -->
    </div>

  </div>
</div>
