import {Component, Input, OnInit} from "@angular/core";
import {AirIndicators, IModalAirQuality} from "@types-custom/models/ui/air_quality/air-quality-modal.model";
import {AirQualityModel} from "@shared/models/air_quality/AirQuality.model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {ITimeLineGraphicModel} from "@types-custom/models/ui/chart-line-color-model";

@Component({
  selector: "sit-air-quality-modal",
  templateUrl: "./air-quality-modal.component.html",
  styleUrls: ["./air-quality-modal.component.scss"],
})
export class AirQualityModalComponent implements OnInit {
  @Input()  properties      : IModalAirQuality;
  public    Indicators      : AirIndicators[]    = [];
  public    ActiveIndicator : AirIndicators      = null;

  public dataGraphicList    : ITimeLineGraphicModel;
  public colorScale = [
    { minValue: 0, maxValue: 10, color: 'red' },
    { minValue: 10, maxValue: 20, color: 'yellow' },
    { minValue: 20, maxValue: 1000, color: '#00FFC2' },
  ];

  ngOnInit() : void {
    this.properties.airQualityDataSource.getIndicatorsByStationId(this.properties.id).subscribe((res : any) => {
      this.Indicators       = res.indicators;
      this.ActiveIndicator  = this.Indicators.filter(e => e.name === "PM2.5")[0];
      this.getMeasurements(this.ActiveIndicator.indicator_id,this.properties.id)
    });
  }

  public ChangeIndicator($event: AirIndicators) {
    this.ActiveIndicator = $event;
    this.getMeasurements(this.ActiveIndicator.indicator_id,this.properties.id)
  }

  public getMeasurements(indicator_id : number, sensor_id : string) {
    this.properties.airQualityDataSource.getMeasurementsByStationId(sensor_id, indicator_id).subscribe((res : any) => {
      this.setGraphicData(res.data);
    })
  }

  public convertDateFormat(dateString : string) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero indexed, so adding 1
    const year = date.getFullYear() % 100; // Getting only the last two digits of the year

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Making sure to add leading zeros if less than 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year < 10 ? `0${year}` : year;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear} - ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return formattedDate;
  }

  private setGraphicData(info: any): void {
    const dataGraphic = info
        .map((graph: any) => {
          return {
            time: graph.time,
            value: graph.value,
          };
        })
        .sort((x: any, y: any) => x.time - y.time);

    this.dataGraphicList = {
      data: dataGraphic,
      labelX: 'Hora',
    };
  }

  protected readonly Icon   = Icon;

}
