<div class="card-footer">
  <label class="card-tit">
    <sit-icon alt="icon calendario" [icon]="Icon.calendar_w" [sizeClass]="'icon-size-24'" class=" icon-footer">
    </sit-icon>
  </label>
  <span class="text-white"><strong> {{ properties?.creationDate | date: 'dd/MM/yyy' }} </strong> </span>
</div><br>

<div class="card-footer">
  <label class="card-tit">
    <sit-icon alt="icon grupoguia" [icon]="Icon.GrupoGuia" [sizeClass]="'icon-size-24'" class="icon-footer">
    </sit-icon>
  </label>
  <span class="text-white"><strong> {{ properties?.humanResourceId }}</strong></span>
  <span class="text-white"><strong> {{ properties?.humanResourceNames}} {{
      properties?.humanResourceLastNames}}</strong></span>

  <div class="text-modal">
    <div class="text-cont">
      <p class="text-white">
        El recurso {{ properties?.humanResourceNames }} {{ properties?.humanResourceLastNames}} informa que en el
        sistema no se registra asignación vehículo para el desarrollo de sus actividades, por lo que generó la novedad
        con la siguiente informacion:
      </p>
    </div>
  </div><br>


  <span class="text-white"><strong>Descripción de la gestión*</strong></span>
</div>

<form [formGroup]="registerFormGroup" class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
  <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
      'error-field':
        registerFormGroup.controls.description.invalid &&
        registerFormGroup.controls.description.touched
    }">
    <mat-label class="text-white">Descripción</mat-label>
    <textarea matInput formControlName="description"></textarea>
    <mat-error>
      <!-- {{ observationsErrorMessage }} -->
      El campo es obligatiorio
    </mat-error>
  </mat-form-field>
</form>

<div class="modal-actions d-flex align-items-end justify-end pt-20">
  <ng-container>
    <sit-button
            (click)="onCancel()"
            [buttonProperties]="buttonCancelAttributes"
    ></sit-button>
  </ng-container>
  <ng-container>
    <sit-button
              (click)="
              registerFormGroup.valid ? validateUserSubmit() : validateFormGroup()
              "
            [buttonProperties]="buttonSaveAttributes"
    ></sit-button>
  </ng-container>