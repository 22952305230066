import { AfterViewInit, Component, ElementRef, Input, ViewChild, PLATFORM_ID, NgZone, Inject, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ISitChartColumnModel } from '@types-custom/models/ui/sit-chart-column-model'
import { isPlatformBrowser } from '@angular/common';

/*
** Sit Chart Column
** Render a column chart
*/

@Component({
  selector: 'sit-chart-column',
  templateUrl: './sit-chart-column.component.html',
  styleUrls: ['./sit-chart-column.component.scss']
})
export class SitChartColumnComponent implements AfterViewInit, OnChanges, OnDestroy  {

  @Input() props: ISitChartColumnModel = {
    filterHoverBar: false,
    mapDispatcher$: undefined,
    dataSource: undefined
  };

  @Input() data: Array<any> = [];
  @Input() divid: string;
  @Input() className = '';
  @Input() valueXAttrib = '';
  @Input() valueXText = '';
  @Input() valueYText = '';

  private chart!: am4charts.XYChart;

  AfterViewInit = false;

  @ViewChild('chart') currentChart!: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) { /* TODO document why this constructor is empty */  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {
    //console.log(this.props);
    this.createPieSpeedChart();
    this.props.dataSource?.getAPISitChart().subscribe(this.loadDataChart.bind(this));
    this.AfterViewInit = true;
  }

  ngOnChanges(changes: SimpleChanges){

    if(changes.data && this.AfterViewInit){
      this.createPieSpeedChart();
      this.loadDataChart(this.data);
      this.sortByQtyDescending(this.data);
    }
  }



  createPieSpeedChart()
  {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_dark);
      am4core.useTheme(am4themes_animated);

      const chart = am4core.create(this.currentChart.nativeElement,  am4charts.XYChart);

      this.data.sort((a: any, b: any) => b['id'] - a['id']);

      chart.data = this.data;

      chart.padding(40, 40, 40, 40);

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.minGridDistance = 1;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.title.text = this.valueYText;

      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = this.valueXText 

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "label";
      series.dataFields.valueX = "qty";
      if(this.valueXAttrib !== '') series.dataFields.valueY = this.valueXAttrib;
      series.columns.template.propertyFields.fill = "color";
      series.columns.template.propertyFields.stroke = "border"
      series.strokeWidth = .5;
      if (this.valueXAttrib !== '') {
        series.tooltipText = "{valueY.value} ";
      } else {
        series.tooltipText = "{valueX.value} " + this.valueXText ?? '';
      }
      series.columns.template.column.cornerRadiusBottomRight = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;
      series.columns.template.column.width = am4core.percent(90);

      const labelBullet = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = "left";
      labelBullet.label.dx = 10;
      labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#')}";
      labelBullet.locationX = 1;
      const cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = "none";

      //añadir evento hover sobre las barras para filtrar tramos en el mapa, segun el valor  de la entrada "filterHoverBar".
      if(this.props.filterHoverBar){
        chart.cursor.events.on("cursorpositionchanged", (event) => {
          const dataItem: any = series.tooltipDataItem;
          if(dataItem._dataContext){
            const type = ((dataItem._dataContext.label.toString().indexOf('km/h') == -1)? 2:1);

          //  this.sharedMainMapboxService.emitFilterTra({type:type, value: dataItem._dataContext.id});
          }
        });
      }

    });
  }

  loadDataChart(data: any) {
    const  {
        section: { speedGraphic }
    } = data;

    data.sort((a: any, b: any) => {
      if (a['id'] < b['id']) {
        return 1;
      }
      if (a['id'] > b['id']) {
        return -1;
      }

      return 0;
    });

    this.chart.data = data;
  }

  sortByQtyDescending(data: any[]): any[] {
    return data.sort((a: any, b: any) => b['qty'] - a['qty']);
  }
  ngOnDestroy() {
      if (this.chart) {
        this.chart.dispose();
      }
  }
}
