import { ModalType } from '@shared/models/modal-type';
import { IRole } from '@shared/models/user.model';
import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { ISideMenu } from '@types-custom/models/ui/side-menu.model';
import { Subject } from 'rxjs';
import { modals } from '../../../../../../libs/shared/src/lib/utils/mappers/modal';

export const generateLeftMenuModel = (
  dispatcher$: Subject<any | undefined>
): ISideMenu<any>[] => {
  return [
    {
      group: 'general',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.general_road_w,
      tagSelected: Icon.general_road_black,
      tooltipTag: 'General',
      alt: 'Botón para cargar capa generales',
    },
    {
      isSubmenu: true,
      group: 'general',
      isInfoMenu: false,
      tag: Icon.road_speed_w,
      tagSelected: Icon.road_speed_black,
      tooltipTag: 'Índice de concordancia',
      alt: 'Botón para cargar capa tráfico rango de velocidad',
      dispatcher$,
      event: {
        action: MapLayers.TrafficConcordanceCorridors,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'general',
      tag: Icon.chronometer_w,
      tagSelected: Icon.chronometer_black,
      tooltipTag: 'Rango de velocidad',
      alt: 'Botón para cargar capa general (Rango de velocidad)',
      isLastItemSubMenu: true,
      dispatcher$,
      event: {
        action: MapLayers.RoadCorridors,
        actionType: ActionType.Map
      },
    },


    {
      group: 'traffic',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.traffic_arrows_w,
      tagSelected: Icon.traffic_arrows_black,
      tooltipTag: 'Tráfico',
      alt: 'Botón para cargar capa tráfico',
    },
    {
      isSubmenu: true,
      group: 'traffic',
      isInfoMenu: false,
      tag: Icon.street_w,
      tagSelected: Icon.street_black,
      tooltipTag: 'Corredores',
      alt: 'Botón para cargar capa tráfico indice de velocidad en corredores',
      dispatcher$,
      event: {
        action: MapLayers.TrafficSpeedRangeCorridors,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      group: 'traffic',
      isInfoMenu: false,
      tag: Icon.road_speed_w,
      tagSelected: Icon.road_speed_black,
      tooltipTag: 'Índice de concordancia',
      alt: 'Botón para cargar capa tráfico indice de concordancia',
      dispatcher$,
      event: {
        action: MapLayers.TrafficConcordance,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      group: 'traffic',
      isInfoMenu: false,
      tag: Icon.chronometer_w,
      tagSelected: Icon.chronometer_black,
      tooltipTag: 'Rango de velocidad',
      alt: 'Botón para cargar capa tráfico rango de velocidad',
      dispatcher$,
      event: {
        action: MapLayers.TrafficSpeedRange,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },


    {
      group: 'agents',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.agents_w,
      tagSelected: Icon.agents_black,
      tooltipTag: 'Agentes',
      alt: 'Botón para cargar capa agentes',
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'agents',
      tag: Icon.last_hours_w,
      tagSelected: Icon.last_hours_black,
      tooltipTag: 'Últimas 24 Horas',
      alt: 'Botón para cargar capa agentes (últimas horas)',
      dispatcher$,
      event: {
        action: MapLayers.AgentsHistorical,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'agents',
      tag: Icon.areas_w,
      tagSelected: Icon.areas_black,
      tooltipTag: 'Número',
      alt: 'Botón para cargar capa agentes (número)',
      dispatcher$,
      event: {
        action: MapLayers.AgentsNumber,
        actionType: ActionType.Map,
      },
    },
   /*  {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'agents',
      tag: Icon.clusters_w,
      tagSelected: Icon.clusters_black,
      tooltipTag: 'Localización del clúster',
      alt: 'Botón para cargar capa agentes (clústers)',
      dispatcher$,
      event: {
        action: MapLayers.AgentsNumber,
        actionType: ActionType.Map,
      },
    }, //cambiar apuntado de capa de cluster */
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'agents',
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa agentes (Localización)',
      dispatcher$,
      event: {
        action: MapLayers.Agents,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'agents',
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Clusterización',
      alt: 'Botón para cargar capa agentes (Localización)',
      dispatcher$,
      event: {
        action: MapLayers.AgentsCluster,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },



    {
      group: 'trafficwaze',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.waze,
      tagSelected: Icon.waze_black,
      tooltipTag: 'SDM Waze',
      alt: 'Botón para cargar capa waze',
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.chronometer_w,
      tagSelected: Icon.chronometer_black,
      tooltipTag: 'Rango de velocidad',
      alt: 'Botón para cargar capa tráfico waze (Rango de Velocidad)',
      dispatcher$,
      event: {
        action: MapLayers.WazeDataSpeedRange,
        actionType: ActionType.Map
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.street_w,
      tagSelected: Icon.street_black,
      tooltipTag: 'Velocidad de Corredores',
      alt: 'Botón para cargar capa tráfico waze (Corredores)',
      dispatcher$,
      event: {
        action: MapLayers.WazeDataCorridors,
        actionType: ActionType.Map
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.exodo_w,
      tagSelected: Icon.exodo_black,
      tooltipTag: 'Velocidad éxodo y retorno',
      alt: 'Botón para cargar capa tráfico waze (Velocidad exodo y retorno)',
      dispatcher$,
      event: {
        action: MapLayers.ExodusAndReturnWaze,
        actionType: ActionType.Map
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.traffic_jams_w,
      tagSelected: Icon.traffic_jams_black,
      tooltipTag: 'Congestión',
      alt: 'Botón para cargar capa tráfico waze (Congestión)',
      dispatcher$,
      event: {
        action: MapLayers.TrafficJamWaze,
        actionType: ActionType.Map
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.alert_w,
      tagSelected: Icon.alert_black,
      tooltipTag: 'Alertas Waze',
      alt: 'Botón para cargar capa tráfico waze (alertas)',
      dispatcher$,
      event: {
        action: MapLayers.AlertsWaze,
        actionType: ActionType.Map
      },
      isLastItemSubMenu: true,
    },
    /*{
      isSubmenu: true,
      isInfoMenu: false,
      group: 'trafficwaze',
      tag: Icon.alert_w,
      tagSelected: Icon.alert_black,
      tooltipTag: 'Alertas Waze Duplicadas',
      alt: 'Botón para cargar capa tráfico waze (alertas duplicadas)',
      dispatcher$,
      event: {
        action: MapLayers.AlertsDuplicatesWaze,
        actionType: ActionType.Map
      },
      isLastItemSubMenu: true,
    },*/
    {
      group: 'incidents',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.alert_w,
      tagSelected: Icon.alert_black,
      tooltipTag: 'Incidentes',
      alt: 'Botón para cargar capa Incidente',
    },
    {
      isSubmenu: true,
      group: 'incidents',
      isInfoMenu: false,
      tag: Icon.heat_map_weight_menu,
      tagSelected: Icon.heat_map_weight_menu_blue,
      tooltipTag: ' Mapa de Calor ',
      alt: 'Botón para cargar capa Mapa de calor',
      dispatcher$,
      event: {
        action: MapLayers.IncidentsHeatMap,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      group: 'incidents',
      isInfoMenu: false,
      tag: Icon.last_hours_w,
      tagSelected: Icon.last_hours_black,
      tooltipTag: 'Ultimas Horas ',
      alt: 'Botón para cargar capa Ultimas Horas',
      dispatcher$,
      event: {
        action: MapLayers.LastHour,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      group: 'incidents',
      isInfoMenu: false,
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa localización',
      dispatcher$,
      event: {
        action: MapLayers.IncidentsLocations,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },
 

    {
      group: 'vehicle',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.car_w,
      tagSelected: Icon.car_black,
      tooltipTag: 'Automóviles',
      alt: 'Botón para cargar capa de Automóviles',
    },
    {
      group: 'vehicle',
      isSubmenu: true,
      isInfoMenu: false,
      tag: Icon.car_w,
      tagSelected: Icon.car_black,
      tooltipTag: 'Automóviles',
      alt: 'Botón para cargar capa automoviles',
      dispatcher$,
      event: {
        action: MapLayers.Vehicles,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },

    {
      group: 'bicycle',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.bicycles_w,
      tagSelected: Icon.bicycles_black,
      tooltipTag: 'Bicicletas',
      alt: 'Botón para cargar capa de Bicicletas',
    },
    {
      group: 'bicycle',
      isSubmenu: true,
      isInfoMenu: false,
      tag: Icon.bicycles_w,
      tagSelected: Icon.bicycles_black,
      tooltipTag: 'Bicicletas',
      alt: 'Botón para cargar capa de Bicicletas',
      dispatcher$,
      event: {
        action: MapLayers.Bikes,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },

    {
      group: 'cranes',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.crane_w,
      tagSelected: Icon.crane_black,
      tooltipTag: 'Grúas',
      alt: 'Botón para cargar capa de Grúas',
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'cranes',
      tag: Icon.last_hours_w,
      tagSelected: Icon.last_hours_black,
      tooltipTag: 'Últimas 24 Horas',
      alt: 'Botón para cargar capa grúas (últimas horas)',
      dispatcher$,
      event: {
        action: MapLayers.CranesHistorical,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'cranes',
      tag: Icon.areas_w,
      tagSelected: Icon.areas_black,
      tooltipTag: 'Número',
      alt: 'Botón para cargar capa grúas (número)',
      dispatcher$,
      event: {
        action: MapLayers.CranesNumber,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'cranes',
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa grúas (Localización)',
      dispatcher$,
      event: {
        action: MapLayers.Cranes,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'cranes',
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Clusterización',
      alt: 'Botón para cargar capa grúas (Localización)',
      dispatcher$,
      event: {
        action: MapLayers.CranesCluster,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },
/*     {
      group: 'climate',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.sensor_w,
      tagSelected: Icon.sensor_blue,
      tooltipTag: 'CO2',
      alt: 'Botón para cargar capa c o 2',
      dispatcher$,
      event: {
        action: MapLayers.Co2,
        actionType: ActionType.Map,
      },
    }, 
    {
      group: 'capacity',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.density_w,
      tagSelected: Icon.capacity_blue,
      tooltipTag: 'Aforo',
      alt: 'Botón para cargar capa aforo',
      dispatcher$,
      event: {
        action: MapLayers.Aforo,
        actionType: ActionType.Map,
      },
    },
   
    {
      group: 'pmv',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.check_w,
      tagSelected: Icon.pmv_green,
      tooltipTag: 'Pmv',
      alt: 'Botón para cargar capa panel mensajería variable',
    },
    {
      group: 'runtdatex',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.camera_play_w,
      tagSelected: Icon.camera_runt_green,
      tooltipTag: 'Runt - Datex',
      alt: 'Botón para cargar capa runt datex',
      dispatcher$,
      event: {
        action: MapLayers.RuntDatex,
        actionType: ActionType.Map,
      },
    }, 
/*    
   {
      isSubmenu: true,
      group: 'pmv',
      tag: Icon.consulting_pmv,
      tagSelected: Icon.consulting_pmv_green,
      tooltipTag: 'Consultar PMV',
      alt: 'Botón para cargar capa consultar panel mensajería variable',
      dispatcher$,
      event: {
        action: MapLayers.PmvConsulta,
        actionType: ActionType.Map,
      },
    },
    {
      isSubmenu: true,
      group: 'pmv',
      tag: Icon.edit_pmv,
      tagSelected: Icon.edit_pmv_green,
      tooltipTag: 'Programar PMV',
      alt: 'Botón para cargar capa programar panel mensajería variable',
      dispatcher$,
      event: {
        action: MapLayers.PmvEditar,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
      requiredRole: [IRole.ADMIN, IRole.PROGRAMADORPMV],
    }, */

    {
      group: 'fieldresources',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.ResourcesWhite,
      tagSelected: Icon.ResourcesBlack,
      tooltipTag: 'Recursos en campo',
      alt: 'Botón para cargar capa recursos en campo',
    },
    {
      isSubmenu: true, 
      isInfoMenu: false, 
      group: 'fieldresources', 
      tag: Icon.last_hours_w,
      tagSelected: Icon.last_hours_black,
      tooltipTag: 'Últimas 24 horas',
      alt: 'Botón para cargar capa de Últimas 24 horas de recursos en campo',
      dispatcher$,
      event: {
        action: MapLayers.GroundResourceLasthours,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: false,
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'fieldresources',
      tag: Icon.alert_w,
      tagSelected: Icon.alert_black,
      tooltipTag: 'Asignación',
      alt: 'Botón para cargar capa de asignación de recursos en campo',
      dispatcher$,
      event: {
        action: MapLayers.IncidentsFieldResources,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: false,
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'fieldresources',
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa recursos en campo (Localización)',
      dispatcher$,
      event: {
        action: MapLayers.FieldResourcesLocations,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: false,
    },
    {
      isSubmenu: true,
      isInfoMenu: false,
      group: 'fieldresources',
      tag: Icon.areas_w,
      tagSelected: Icon.areas_black,
      tooltipTag: 'Número',
      alt: 'Botón para cargar capa recursos en campo (Número)',
      dispatcher$,
      event: {
        action: MapLayers.GroundResourceNumber,
        actionType: ActionType.Map,
      },
      isLastItemSubMenu: true,
    },

    {
      group: 'predictive',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.ClockWhite,
      tagSelected: Icon.ClockBlack,
      tooltipTag: 'modelo Predictivo',
      alt: 'Botón para cargar capa predictiva',
    },
    {
      isSubmenu: true,
      group: 'predictive',
      isInfoMenu: false,
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa localización',
      dispatcher$,
      event: {
        action: MapLayers.ResourcesPredictiveModelLocations,
        actionType: ActionType.Map,
      },
    },

    {
      group: 'air_quality',
      isSubmenu: false,
      isInfoMenu: false,
      tag: Icon.MenuIconWhiteAir,
      tagSelected: Icon.MenuIconBlackAir,
      tooltipTag: 'Calidad del Aire',
      alt: 'Botón para cargar capa calidad del aire',
      isLastItemSubMenu: true,
    },
    {
      isSubmenu: true,
      group: 'air_quality',
      isInfoMenu: false,
      tag: Icon.location_w,
      tagSelected: Icon.location_black,
      tooltipTag: 'Localización',
      alt: 'Botón para cargar capa localización',
      dispatcher$,
      event: {
        action: MapLayers.ResourcesAirQualityPoints,
        actionType: ActionType.Map,
      },
    },
  ];
};
