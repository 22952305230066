import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsStopBusPathModel {
  id: string;
  paradaNombre: string;
  paradaDireccion : string;
  paradaTipo : string;
  paradaCodigo : string;
  geometry: unknown;
  apiObj: unknown;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class StopBusPathModel extends MarkerModelBase {
  static className = 'StopBusPathModel';
  classProperties: IPropsStopBusPathModel;

  constructor(
    props: IPropsStopBusPathModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, StopBusPathModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
