import { ILocationOption } from '@types-custom/models/business/location.model';

export const locationsA: ILocationOption[] = [
  { value: '91', label: 'Amazonas' },
  { value: '5', label: 'Antioquia' },
  { value: '81', label: 'Arauca' },
  {
    value: '88',
    label: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
  },
  { value: '8', label: 'Atlántico' },
  { value: '11', label: 'Bogotá D.C.' },
  { value: '13', label: 'Bolívar' },
  { value: '15', label: 'Boyacá' },
  { value: '17', label: 'Caldas' },
  { value: '18', label: 'Caquetá' },
  { value: '85', label: 'Casanare' },
  { value: '19', label: 'Cauca' },
  { value: '20', label: 'Cesar' },
  { value: '27', label: 'Chocó' },
  { value: '23', label: 'Córdoba' },
  { value: '25', label: 'Cundinamarca' },
  { value: '94', label: 'Guainía' },
  { value: '95', label: 'Guaviare' },
  { value: '41', label: 'Huila' },
  { value: '44', label: 'La Guajira' },
  { value: '47', label: 'Magdalena' },
  { value: '50', label: 'Meta' },
  { value: '52', label: 'Nariño' },
  { value: '54', label: 'Norte de Santander' },
  { value: '86', label: 'Putumayo' },
  { value: '63', label: 'Quindío' },
  { value: '66', label: 'Risaralda' },
  { value: '68', label: 'Santander' },
  { value: '70', label: 'Sucre' },
  { value: '73', label: 'Tolima' },
  { value: '76', label: 'Valle del Cauca' },
  { value: '97', label: 'Vaupés' },
  { value: '99', label: 'Vichada' },
];

export const locationsB: { [key: string]: ILocationOption[] } = {
  '91': [
    { value: '91001', label: 'Leticia' },
    { value: '91263', label: 'El Encanto' },
    { value: '91405', label: 'La Chorrera' },
    { value: '91407', label: 'La Pedrera' },
    { value: '91430', label: 'La Victoria' },
    { value: '91536', label: 'Puerto Arica' },
    { value: '91540', label: 'Puerto Nariño' },
    { value: '91669', label: 'Puerto Santander' },
    { value: '91798', label: 'Tarapacá' },
    { value: '91530', label: 'Puerto Alegría' },
    { value: '91460', label: 'Miriti Paraná' },
  ],
  '5': [
    { value: '5001', label: 'Medellín' },
    { value: '5002', label: 'Abejorral' },
    { value: '5004', label: 'Abriaquí' },
    { value: '5021', label: 'Alejandría' },
    { value: '5030', label: 'Amagá' },
    { value: '5031', label: 'Amalfi' },
    { value: '5034', label: 'Andes' },
    { value: '5036', label: 'Angelópolis' },
    { value: '5038', label: 'Angostura' },
    { value: '5040', label: 'Anorí' },
    { value: '5044', label: 'Anza' },
    { value: '5045', label: 'Apartadó' },
    { value: '5051', label: 'Arboletes' },
    { value: '5055', label: 'Argelia' },
    { value: '5059', label: 'Armenia' },
    { value: '5079', label: 'Barbosa' },
    { value: '5088', label: 'Bello' },
    { value: '5091', label: 'Betania' },
    { value: '5093', label: 'Betulia' },
    { value: '5101', label: 'Ciudad Bolívar' },
    { value: '5107', label: 'Briceño' },
    { value: '5113', label: 'Buriticá' },
    { value: '5120', label: 'Cáceres' },
    { value: '5125', label: 'Caicedo' },
    { value: '5129', label: 'Caldas' },
    { value: '5134', label: 'Campamento' },
    { value: '5138', label: 'Cañasgordas' },
    { value: '5142', label: 'Caracolí' },
    { value: '5145', label: 'Caramanta' },
    { value: '5147', label: 'Carepa' },
    { value: '5150', label: 'Carolina' },
    { value: '5154', label: 'Caucasia' },
    { value: '5172', label: 'Chigorodó' },
    { value: '5190', label: 'Cisneros' },
    { value: '5197', label: 'Cocorná' },
    { value: '5206', label: 'Concepción' },
    { value: '5209', label: 'Concordia' },
    { value: '5212', label: 'Copacabana' },
    { value: '5234', label: 'Dabeiba' },
    { value: '5237', label: 'Don Matías' },
    { value: '5240', label: 'Ebéjico' },
    { value: '5250', label: 'El Bagre' },
    { value: '5264', label: 'Entrerrios' },
    { value: '5266', label: 'Envigado' },
    { value: '5282', label: 'Fredonia' },
    { value: '5306', label: 'Giraldo' },
    { value: '5308', label: 'Girardota' },
    { value: '5310', label: 'Gómez Plata' },
    { value: '5315', label: 'Guadalupe' },
    { value: '5318', label: 'Guarne' },
    { value: '5321', label: 'Guatapé' },
    { value: '5347', label: 'Heliconia' },
    { value: '5353', label: 'Hispania' },
    { value: '5360', label: 'Itagui' },
    { value: '5361', label: 'Ituango' },
    { value: '5086', label: 'Belmira' },
    { value: '5368', label: 'Jericó' },
    { value: '5376', label: 'La Ceja' },
    { value: '5380', label: 'La Estrella' },
    { value: '5390', label: 'La Pintada' },
    { value: '5400', label: 'La Unión' },
    { value: '5411', label: 'Liborina' },
    { value: '5425', label: 'Maceo' },
    { value: '5440', label: 'Marinilla' },
    { value: '5467', label: 'Montebello' },
    { value: '5475', label: 'Murindó' },
    { value: '5480', label: 'Mutatá' },
    { value: '5483', label: 'Nariño' },
    { value: '5490', label: 'Necoclí' },
    { value: '5495', label: 'Nechí' },
    { value: '5501', label: 'Olaya' },
    { value: '5541', label: 'Peñol' },
    { value: '5543', label: 'Peque' },
    { value: '5576', label: 'Pueblorrico' },
    { value: '5579', label: 'Puerto Berrío' },
    { value: '5585', label: 'Puerto Nare' },
    { value: '5591', label: 'Puerto Triunfo' },
    { value: '5604', label: 'Remedios' },
    { value: '5607', label: 'Retiro' },
    { value: '5615', label: 'Rionegro' },
    { value: '5628', label: 'Sabanalarga' },
    { value: '5631', label: 'Sabaneta' },
    { value: '5642', label: 'Salgar' },
    { value: '5652', label: 'San Francisco' },
    { value: '5656', label: 'San Jerónimo' },
    { value: '5660', label: 'San Luis' },
    { value: '5664', label: 'San Pedro' },
    { value: '5667', label: 'San Rafael' },
    { value: '5670', label: 'San Roque' },
    { value: '5674', label: 'San Vicente' },
    { value: '5679', label: 'Santa Bárbara' },
    { value: '5690', label: 'Santo Domingo' },
    { value: '5697', label: 'El Santuario' },
    { value: '5736', label: 'Segovia' },
    { value: '5761', label: 'Sopetrán' },
    { value: '5789', label: 'Támesis' },
    { value: '5790', label: 'Tarazá' },
    { value: '5792', label: 'Tarso' },
    { value: '5809', label: 'Titiribí' },
    { value: '5819', label: 'Toledo' },
    { value: '5837', label: 'Turbo' },
    { value: '5842', label: 'Uramita' },
    { value: '5847', label: 'Urrao' },
    { value: '5854', label: 'Valdivia' },
    { value: '5856', label: 'Valparaíso' },
    { value: '5858', label: 'Vegachí' },
    { value: '5861', label: 'Venecia' },
    { value: '5885', label: 'Yalí' },
    { value: '5887', label: 'Yarumal' },
    { value: '5890', label: 'Yolombó' },
    { value: '5893', label: 'Yondó' },
    { value: '5895', label: 'Zaragoza' },
    { value: '5665', label: 'San Pedro de Uraba' },
    { value: '5042', label: 'Santafé de Antioquia' },
    { value: '5686', label: 'Santa Rosa de Osos' },
    { value: '5647', label: 'San Andrés de Cuerquía' },
    { value: '5873', label: 'Vigía del Fuerte' },
    { value: '5658', label: 'San José de La Montaña' },
    { value: '5659', label: 'San Juan de Urabá' },
    { value: '5148', label: 'El Carmen de Viboral' },
    { value: '5649', label: 'San Carlos' },
    { value: '5284', label: 'Frontino' },
    { value: '5313', label: 'Granada' },
    { value: '5364', label: 'Jardín' },
    { value: '5756', label: 'Sonsón' },
  ],
  '81': [
    { value: '81065', label: 'Arauquita' },
    { value: '81220', label: 'Cravo Norte' },
    { value: '81300', label: 'Fortul' },
    { value: '81591', label: 'Puerto Rondón' },
    { value: '81736', label: 'Saravena' },
    { value: '81794', label: 'Tame' },
    { value: '81001', label: 'Arauca' },
  ],
  '88': [
    { value: '88564', label: 'Providencia' },
    { value: '88001', label: 'San Andrés' },
  ],
  '8': [
    { value: '8001', label: 'Barranquilla' },
    { value: '8078', label: 'Baranoa' },
    { value: '8141', label: 'Candelaria' },
    { value: '8296', label: 'Galapa' },
    { value: '8421', label: 'Luruaco' },
    { value: '8433', label: 'Malambo' },
    { value: '8436', label: 'Manatí' },
    { value: '8549', label: 'Piojó' },
    { value: '8558', label: 'Polonuevo' },
    { value: '8634', label: 'Sabanagrande' },
    { value: '8638', label: 'Sabanalarga' },
    { value: '8675', label: 'Santa Lucía' },
    { value: '8685', label: 'Santo Tomás' },
    { value: '8758', label: 'Soledad' },
    { value: '8770', label: 'Suan' },
    { value: '8832', label: 'Tubará' },
    { value: '8849', label: 'Usiacurí' },
    { value: '8372', label: 'Juan de Acosta' },
    { value: '8520', label: 'Palmar de Varela' },
    { value: '8137', label: 'Campo de La Cruz' },
    { value: '8606', label: 'Repelón' },
    { value: '8573', label: 'Puerto Colombia' },
    { value: '8560', label: 'Ponedera' },
  ],
  '11': [{ value: '11001', label: 'Bogotá D.C.' }],
  '13': [
    { value: '13006', label: 'Achí' },
    { value: '13042', label: 'Arenal' },
    { value: '13052', label: 'Arjona' },
    { value: '13062', label: 'Arroyohondo' },
    { value: '13140', label: 'Calamar' },
    { value: '13160', label: 'Cantagallo' },
    { value: '13188', label: 'Cicuco' },
    { value: '13212', label: 'Córdoba' },
    { value: '13222', label: 'Clemencia' },
    { value: '13248', label: 'El Guamo' },
    { value: '13430', label: 'Magangué' },
    { value: '13433', label: 'Mahates' },
    { value: '13440', label: 'Margarita' },
    { value: '13458', label: 'Montecristo' },
    { value: '13468', label: 'Mompós' },
    { value: '13473', label: 'Morales' },
    { value: '13490', label: 'Norosí' },
    { value: '13549', label: 'Pinillos' },
    { value: '13580', label: 'Regidor' },
    { value: '13600', label: 'Río Viejo' },
    { value: '13647', label: 'San Estanislao' },
    { value: '13650', label: 'San Fernando' },
    { value: '13657', label: 'San Juan Nepomuceno' },
    { value: '13673', label: 'Santa Catalina' },
    { value: '13683', label: 'Santa Rosa' },
    { value: '13744', label: 'Simití' },
    { value: '13760', label: 'Soplaviento' },
    { value: '13780', label: 'Talaigua Nuevo' },
    { value: '13810', label: 'Tiquisio' },
    { value: '13836', label: 'Turbaco' },
    { value: '13838', label: 'Turbaná' },
    { value: '13873', label: 'Villanueva' },
    { value: '13074', label: 'Barranco de Loba' },
    { value: '13688', label: 'Santa Rosa del Sur' },
    { value: '13300', label: 'Hatillo de Loba' },
    { value: '13244', label: 'El Carmen de Bolívar' },
    { value: '13667', label: 'San Martín de Loba' },
    { value: '13030', label: 'Altos del Rosario' },
    { value: '13655', label: 'San Jacinto del Cauca' },
    { value: '13670', label: 'San Pablo de Borbur' },
    { value: '13654', label: 'San Jacinto' },
    { value: '13268', label: 'El Peñón' },
    { value: '13001', label: 'Cartagena' },
    { value: '13442', label: 'María la Baja' },
    { value: '13620', label: 'San Cristóbal' },
    { value: '13894', label: 'Zambrano' },
  ],
  '15': [
    { value: '15832', label: 'Tununguá' },
    { value: '15476', label: 'Motavita' },
    { value: '15189', label: 'Ciénega' },
    { value: '15001', label: 'Tunja' },
    { value: '15022', label: 'Almeida' },
    { value: '15047', label: 'Aquitania' },
    { value: '15051', label: 'Arcabuco' },
    { value: '15090', label: 'Berbeo' },
    { value: '15092', label: 'Betéitiva' },
    { value: '15097', label: 'Boavita' },
    { value: '15104', label: 'Boyacá' },
    { value: '15106', label: 'Briceño' },
    { value: '15109', label: 'Buena Vista' },
    { value: '15114', label: 'Busbanzá' },
    { value: '15131', label: 'Caldas' },
    { value: '15135', label: 'Campohermoso' },
    { value: '15162', label: 'Cerinza' },
    { value: '15172', label: 'Chinavita' },
    { value: '15176', label: 'Chiquinquirá' },
    { value: '15180', label: 'Chiscas' },
    { value: '15183', label: 'Chita' },
    { value: '15185', label: 'Chitaraque' },
    { value: '15187', label: 'Chivatá' },
    { value: '15204', label: 'Cómbita' },
    { value: '15212', label: 'Coper' },
    { value: '15215', label: 'Corrales' },
    { value: '15218', label: 'Covarachía' },
    { value: '15223', label: 'Cubará' },
    { value: '15224', label: 'Cucaita' },
    { value: '15226', label: 'Cuítiva' },
    { value: '15232', label: 'Chíquiza' },
    { value: '15236', label: 'Chivor' },
    { value: '15238', label: 'Duitama' },
    { value: '15244', label: 'El Cocuy' },
    { value: '15248', label: 'El Espino' },
    { value: '15272', label: 'Firavitoba' },
    { value: '15276', label: 'Floresta' },
    { value: '15293', label: 'Gachantivá' },
    { value: '15296', label: 'Gameza' },
    { value: '15299', label: 'Garagoa' },
    { value: '15317', label: 'Guacamayas' },
    { value: '15322', label: 'Guateque' },
    { value: '15325', label: 'Guayatá' },
    { value: '15332', label: 'Güicán' },
    { value: '15362', label: 'Iza' },
    { value: '15367', label: 'Jenesano' },
    { value: '15368', label: 'Jericó' },
    { value: '15377', label: 'Labranzagrande' },
    { value: '15380', label: 'La Capilla' },
    { value: '15401', label: 'La Victoria' },
    { value: '15425', label: 'Macanal' },
    { value: '15442', label: 'Maripí' },
    { value: '15455', label: 'Miraflores' },
    { value: '15464', label: 'Mongua' },
    { value: '15466', label: 'Monguí' },
    { value: '15469', label: 'Moniquirá' },
    { value: '15480', label: 'Muzo' },
    { value: '15491', label: 'Nobsa' },
    { value: '15494', label: 'Nuevo Colón' },
    { value: '15500', label: 'Oicatá' },
    { value: '15507', label: 'Otanche' },
    { value: '15511', label: 'Pachavita' },
    { value: '15514', label: 'Páez' },
    { value: '15516', label: 'Paipa' },
    { value: '15518', label: 'Pajarito' },
    { value: '15522', label: 'Panqueba' },
    { value: '15531', label: 'Pauna' },
    { value: '15533', label: 'Paya' },
    { value: '15542', label: 'Pesca' },
    { value: '15550', label: 'Pisba' },
    { value: '15572', label: 'Puerto Boyacá' },
    { value: '15580', label: 'Quípama' },
    { value: '15599', label: 'Ramiriquí' },
    { value: '15600', label: 'Ráquira' },
    { value: '15621', label: 'Rondón' },
    { value: '15632', label: 'Saboyá' },
    { value: '15638', label: 'Sáchica' },
    { value: '15646', label: 'Samacá' },
    { value: '15660', label: 'San Eduardo' },
    { value: '15673', label: 'San Mateo' },
    { value: '15686', label: 'Santana' },
    { value: '15690', label: 'Santa María' },
    { value: '15696', label: 'Santa Sofía' },
    { value: '15720', label: 'Sativanorte' },
    { value: '15723', label: 'Sativasur' },
    { value: '15740', label: 'Siachoque' },
    { value: '15753', label: 'Soatá' },
    { value: '15755', label: 'Socotá' },
    { value: '15757', label: 'Socha' },
    { value: '15759', label: 'Sogamoso' },
    { value: '15761', label: 'Somondoco' },
    { value: '15762', label: 'Sora' },
    { value: '15763', label: 'Sotaquirá' },
    { value: '15764', label: 'Soracá' },
    { value: '15774', label: 'Susacón' },
    { value: '15776', label: 'Sutamarchán' },
    { value: '15778', label: 'Sutatenza' },
    { value: '15790', label: 'Tasco' },
    { value: '15798', label: 'Tenza' },
    { value: '15804', label: 'Tibaná' },
    { value: '15808', label: 'Tinjacá' },
    { value: '15810', label: 'Tipacoque' },
    { value: '15814', label: 'Toca' },
    { value: '15820', label: 'Tópaga' },
    { value: '15822', label: 'Tota' },
    { value: '15835', label: 'Turmequé' },
    { value: '15839', label: 'Tutazá' },
    { value: '15842', label: 'Umbita' },
    { value: '15861', label: 'Ventaquemada' },
    { value: '15879', label: 'Viracachá' },
    { value: '15897', label: 'Zetaquira' },
    { value: '15816', label: 'Togüí' },
    { value: '15407', label: 'Villa de Leyva' },
    { value: '15537', label: 'Paz de Río' },
    { value: '15693', label: 'Santa Rosa de Viterbo' },
    { value: '15681', label: 'San Pablo de Borbur' },
    { value: '15667', label: 'San Luis de Gaceno' },
    { value: '15664', label: 'San José de Pare' },
    { value: '15676', label: 'San Miguel de Sema' },
    { value: '15837', label: 'Tuta' },
    { value: '15806', label: 'Tibasosa' },
    { value: '15403', label: 'La Uvita' },
    { value: '15087', label: 'Belén' },
  ],
  '17': [
    { value: '17001', label: 'Manizales' },
    { value: '17013', label: 'Aguadas' },
    { value: '17042', label: 'Anserma' },
    { value: '17050', label: 'Aranzazu' },
    { value: '17088', label: 'Belalcázar' },
    { value: '17174', label: 'Chinchiná' },
    { value: '17272', label: 'Filadelfia' },
    { value: '17380', label: 'La Dorada' },
    { value: '17388', label: 'La Merced' },
    { value: '17433', label: 'Manzanares' },
    { value: '17442', label: 'Marmato' },
    { value: '17446', label: 'Marulanda' },
    { value: '17486', label: 'Neira' },
    { value: '17495', label: 'Norcasia' },
    { value: '17513', label: 'Pácora' },
    { value: '17524', label: 'Palestina' },
    { value: '17541', label: 'Pensilvania' },
    { value: '17614', label: 'Riosucio' },
    { value: '17616', label: 'Risaralda' },
    { value: '17653', label: 'Salamina' },
    { value: '17662', label: 'Samaná' },
    { value: '17665', label: 'San José' },
    { value: '17777', label: 'Supía' },
    { value: '17867', label: 'Victoria' },
    { value: '17873', label: 'Villamaría' },
    { value: '17877', label: 'Viterbo' },
    { value: '17444', label: 'Marquetalia' },
  ],
  '18': [
    { value: '18001', label: 'Florencia' },
    { value: '18029', label: 'Albania' },
    { value: '18205', label: 'Curillo' },
    { value: '18247', label: 'El Doncello' },
    { value: '18256', label: 'El Paujil' },
    { value: '18479', label: 'Morelia' },
    { value: '18592', label: 'Puerto Rico' },
    { value: '18756', label: 'Solano' },
    { value: '18785', label: 'Solita' },
    { value: '18860', label: 'Valparaíso' },
    { value: '18610', label: 'San José del Fragua' },
    { value: '18094', label: 'Belén de Los Andaquies' },
    { value: '18150', label: 'Cartagena del Chairá' },
    { value: '18460', label: 'Milán' },
    { value: '18410', label: 'La Montañita' },
    { value: '18753', label: 'San Vicente del Caguán' },
  ],
  '85': [
    { value: '85001', label: 'Yopal' },
    { value: '85010', label: 'Aguazul' },
    { value: '85015', label: 'Chámeza' },
    { value: '85125', label: 'Hato Corozal' },
    { value: '85136', label: 'La Salina' },
    { value: '85162', label: 'Monterrey' },
    { value: '85263', label: 'Pore' },
    { value: '85279', label: 'Recetor' },
    { value: '85300', label: 'Sabanalarga' },
    { value: '85315', label: 'Sácama' },
    { value: '85410', label: 'Tauramena' },
    { value: '85430', label: 'Trinidad' },
    { value: '85440', label: 'Villanueva' },
    { value: '85325', label: 'San Luis de Gaceno' },
    { value: '85250', label: 'Paz de Ariporo' },
    { value: '85225', label: 'Nunchía' },
    { value: '85139', label: 'Maní' },
    { value: '85400', label: 'Támara' },
    { value: '85230', label: 'Orocué' },
  ],
  '19': [
    { value: '19001', label: 'Popayán' },
    { value: '19022', label: 'Almaguer' },
    { value: '19050', label: 'Argelia' },
    { value: '19075', label: 'Balboa' },
    { value: '19100', label: 'Bolívar' },
    { value: '19110', label: 'Buenos Aires' },
    { value: '19130', label: 'Cajibío' },
    { value: '19137', label: 'Caldono' },
    { value: '19142', label: 'Caloto' },
    { value: '19212', label: 'Corinto' },
    { value: '19256', label: 'El Tambo' },
    { value: '19290', label: 'Florencia' },
    { value: '19300', label: 'Guachené' },
    { value: '19318', label: 'Guapi' },
    { value: '19355', label: 'Inzá' },
    { value: '19364', label: 'Jambaló' },
    { value: '19392', label: 'La Sierra' },
    { value: '19397', label: 'La Vega' },
    { value: '19418', label: 'López' },
    { value: '19450', label: 'Mercaderes' },
    { value: '19455', label: 'Miranda' },
    { value: '19473', label: 'Morales' },
    { value: '19513', label: 'Padilla' },
    { value: '19532', label: 'Patía' },
    { value: '19533', label: 'Piamonte' },
    { value: '19548', label: 'Piendamó' },
    { value: '19573', label: 'Puerto Tejada' },
    { value: '19585', label: 'Puracé' },
    { value: '19622', label: 'Rosas' },
    { value: '19701', label: 'Santa Rosa' },
    { value: '19743', label: 'Silvia' },
    { value: '19760', label: 'Sotara' },
    { value: '19780', label: 'Suárez' },
    { value: '19785', label: 'Sucre' },
    { value: '19807', label: 'Timbío' },
    { value: '19809', label: 'Timbiquí' },
    { value: '19821', label: 'Toribio' },
    { value: '19824', label: 'Totoró' },
    { value: '19845', label: 'Villa Rica' },
    { value: '19698', label: 'Santander de Quilichao' },
    { value: '19693', label: 'San Sebastián' },
    { value: '19517', label: 'Páez' },
  ],
  '20': [
    { value: '20001', label: 'Valledupar' },
    { value: '20011', label: 'Aguachica' },
    { value: '20013', label: 'Agustín Codazzi' },
    { value: '20032', label: 'Astrea' },
    { value: '20045', label: 'Becerril' },
    { value: '20060', label: 'Bosconia' },
    { value: '20175', label: 'Chimichagua' },
    { value: '20178', label: 'Chiriguaná' },
    { value: '20228', label: 'Curumaní' },
    { value: '20238', label: 'El Copey' },
    { value: '20250', label: 'El Paso' },
    { value: '20295', label: 'Gamarra' },
    { value: '20310', label: 'González' },
    { value: '20383', label: 'La Gloria' },
    { value: '20443', label: 'Manaure' },
    { value: '20517', label: 'Pailitas' },
    { value: '20550', label: 'Pelaya' },
    { value: '20570', label: 'Pueblo Bello' },
    { value: '20621', label: 'La Paz' },
    { value: '20710', label: 'San Alberto' },
    { value: '20750', label: 'San Diego' },
    { value: '20770', label: 'San Martín' },
    { value: '20787', label: 'Tamalameque' },
    { value: '20614', label: 'Río de Oro' },
    { value: '20400', label: 'La Jagua de Ibirico' },
  ],
  '27': [
    { value: '27361', label: 'Istmina' },
    { value: '27001', label: 'Quibdó' },
    { value: '27006', label: 'Acandí' },
    { value: '27025', label: 'Alto Baudo' },
    { value: '27050', label: 'Atrato' },
    { value: '27073', label: 'Bagadó' },
    { value: '27075', label: 'Bahía Solano' },
    { value: '27077', label: 'Bajo Baudó' },
    { value: '27099', label: 'Bojaya' },
    { value: '27160', label: 'Cértegui' },
    { value: '27205', label: 'Condoto' },
    { value: '27372', label: 'Juradó' },
    { value: '27413', label: 'Lloró' },
    { value: '27425', label: 'Medio Atrato' },
    { value: '27430', label: 'Medio Baudó' },
    { value: '27450', label: 'Medio San Juan' },
    { value: '27491', label: 'Nóvita' },
    { value: '27495', label: 'Nuquí' },
    { value: '27580', label: 'Río Iro' },
    { value: '27600', label: 'Río Quito' },
    { value: '27615', label: 'Riosucio' },
    { value: '27745', label: 'Sipí' },
    { value: '27800', label: 'Unguía' },
    { value: '27250', label: 'El Litoral del San Juan' },
    { value: '27135', label: 'El Cantón del San Pablo' },
    { value: '27245', label: 'El Carmen de Atrato' },
    { value: '27660', label: 'San José del Palmar' },
    { value: '27086', label: 'Belén de Bajira' },
    { value: '27150', label: 'Carmen del Darien' },
    { value: '27787', label: 'Tadó' },
    { value: '27810', label: 'Unión Panamericana' },
  ],
  '23': [
    { value: '23675', label: 'San Bernardo del Viento' },
    { value: '23001', label: 'Montería' },
    { value: '23068', label: 'Ayapel' },
    { value: '23079', label: 'Buenavista' },
    { value: '23090', label: 'Canalete' },
    { value: '23162', label: 'Cereté' },
    { value: '23168', label: 'Chimá' },
    { value: '23182', label: 'Chinú' },
    { value: '23300', label: 'Cotorra' },
    { value: '23417', label: 'Lorica' },
    { value: '23419', label: 'Los Córdobas' },
    { value: '23464', label: 'Momil' },
    { value: '23500', label: 'Moñitos' },
    { value: '23555', label: 'Planeta Rica' },
    { value: '23570', label: 'Pueblo Nuevo' },
    { value: '23574', label: 'Puerto Escondido' },
    { value: '23586', label: 'Purísima' },
    { value: '23660', label: 'Sahagún' },
    { value: '23670', label: 'San Andrés Sotavento' },
    { value: '23672', label: 'San Antero' },
    { value: '23686', label: 'San Pelayo' },
    { value: '23807', label: 'Tierralta' },
    { value: '23815', label: 'Tuchín' },
    { value: '23855', label: 'Valencia' },
    { value: '23682', label: 'San José de Uré' },
    { value: '23189', label: 'Ciénaga de Oro' },
    { value: '23678', label: 'San Carlos' },
    { value: '23466', label: 'Montelíbano' },
    { value: '23350', label: 'La Apartada' },
    { value: '23580', label: 'Puerto Libertador' },
  ],
  '25': [
    { value: '25035', label: 'Anapoima' },
    { value: '25053', label: 'Arbeláez' },
    { value: '25086', label: 'Beltrán' },
    { value: '25095', label: 'Bituima' },
    { value: '25099', label: 'Bojacá' },
    { value: '25120', label: 'Cabrera' },
    { value: '25123', label: 'Cachipay' },
    { value: '25126', label: 'Cajicá' },
    { value: '25148', label: 'Caparrapí' },
    { value: '25151', label: 'Caqueza' },
    { value: '25168', label: 'Chaguaní' },
    { value: '25178', label: 'Chipaque' },
    { value: '25181', label: 'Choachí' },
    { value: '25183', label: 'Chocontá' },
    { value: '25200', label: 'Cogua' },
    { value: '25214', label: 'Cota' },
    { value: '25224', label: 'Cucunubá' },
    { value: '25245', label: 'El Colegio' },
    { value: '25260', label: 'El Rosal' },
    { value: '25279', label: 'Fomeque' },
    { value: '25281', label: 'Fosca' },
    { value: '25286', label: 'Funza' },
    { value: '25288', label: 'Fúquene' },
    { value: '25293', label: 'Gachala' },
    { value: '25295', label: 'Gachancipá' },
    { value: '25297', label: 'Gachetá' },
    { value: '25307', label: 'Girardot' },
    { value: '25312', label: 'Granada' },
    { value: '25317', label: 'Guachetá' },
    { value: '25320', label: 'Guaduas' },
    { value: '25322', label: 'Guasca' },
    { value: '25324', label: 'Guataquí' },
    { value: '25326', label: 'Guatavita' },
    { value: '25335', label: 'Guayabetal' },
    { value: '25339', label: 'Gutiérrez' },
    { value: '25368', label: 'Jerusalén' },
    { value: '25372', label: 'Junín' },
    { value: '25377', label: 'La Calera' },
    { value: '25386', label: 'La Mesa' },
    { value: '25394', label: 'La Palma' },
    { value: '25398', label: 'La Peña' },
    { value: '25402', label: 'La Vega' },
    { value: '25407', label: 'Lenguazaque' },
    { value: '25426', label: 'Macheta' },
    { value: '25430', label: 'Madrid' },
    { value: '25436', label: 'Manta' },
    { value: '25438', label: 'Medina' },
    { value: '25473', label: 'Mosquera' },
    { value: '25483', label: 'Nariño' },
    { value: '25486', label: 'Nemocón' },
    { value: '25488', label: 'Nilo' },
    { value: '25489', label: 'Nimaima' },
    { value: '25491', label: 'Nocaima' },
    { value: '25506', label: 'Venecia' },
    { value: '25513', label: 'Pacho' },
    { value: '25518', label: 'Paime' },
    { value: '25524', label: 'Pandi' },
    { value: '25530', label: 'Paratebueno' },
    { value: '25535', label: 'Pasca' },
    { value: '25572', label: 'Puerto Salgar' },
    { value: '25580', label: 'Pulí' },
    { value: '25592', label: 'Quebradanegra' },
    { value: '25594', label: 'Quetame' },
    { value: '25596', label: 'Quipile' },
    { value: '25599', label: 'Apulo' },
    { value: '25612', label: 'Ricaurte' },
    { value: '25649', label: 'San Bernardo' },
    { value: '25653', label: 'San Cayetano' },
    { value: '25658', label: 'San Francisco' },
    { value: '25736', label: 'Sesquilé' },
    { value: '25740', label: 'Sibaté' },
    { value: '25743', label: 'Silvania' },
    { value: '25745', label: 'Simijaca' },
    { value: '25754', label: 'Soacha' },
    { value: '25769', label: 'Subachoque' },
    { value: '25772', label: 'Suesca' },
    { value: '25777', label: 'Supatá' },
    { value: '25779', label: 'Susa' },
    { value: '25781', label: 'Sutatausa' },
    { value: '25785', label: 'Tabio' },
    { value: '25793', label: 'Tausa' },
    { value: '25797', label: 'Tena' },
    { value: '25799', label: 'Tenjo' },
    { value: '25805', label: 'Tibacuy' },
    { value: '25807', label: 'Tibirita' },
    { value: '25815', label: 'Tocaima' },
    { value: '25817', label: 'Tocancipá' },
    { value: '25823', label: 'Topaipí' },
    { value: '25839', label: 'Ubalá' },
    { value: '25841', label: 'Ubaque' },
    { value: '25845', label: 'Une' },
    { value: '25851', label: 'Útica' },
    { value: '25867', label: 'Vianí' },
    { value: '25871', label: 'Villagómez' },
    { value: '25873', label: 'Villapinzón' },
    { value: '25875', label: 'Villeta' },
    { value: '25878', label: 'Viotá' },
    { value: '25898', label: 'Zipacón' },
    { value: '25662', label: 'San Juan de Río Seco' },
    { value: '25843', label: 'Villa de San Diego de Ubate' },
    { value: '25328', label: 'Guayabal de Siquima' },
    { value: '25645', label: 'San Antonio del Tequendama' },
    { value: '25001', label: 'Agua de Dios' },
    { value: '25154', label: 'Carmen de Carupa' },
    { value: '25862', label: 'Vergara' },
    { value: '25019', label: 'Albán' },
    { value: '25040', label: 'Anolaima' },
    { value: '25175', label: 'Chía' },
    { value: '25258', label: 'El Peñón' },
    { value: '25758', label: 'Sopó' },
    { value: '25299', label: 'Gama' },
    { value: '25718', label: 'Sasaima' },
    { value: '25885', label: 'Yacopí' },
    { value: '25290', label: 'Fusagasugá' },
    { value: '25899', label: 'Zipaquirá' },
    { value: '25269', label: 'Facatativá' },
  ],
  '94': [
    { value: '94001', label: 'Inírida' },
    { value: '94343', label: 'Barranco Minas' },
    { value: '94663', label: 'Mapiripana' },
    { value: '94883', label: 'San Felipe' },
    { value: '94884', label: 'Puerto Colombia' },
    { value: '94885', label: 'La Guadalupe' },
    { value: '94886', label: 'Cacahual' },
    { value: '94887', label: 'Pana Pana' },
    { value: '94888', label: 'Morichal' },
  ],
  '95': [
    { value: '95015', label: 'Calamar' },
    { value: '95001', label: 'San José del Guaviare' },
    { value: '95200', label: 'Miraflores' },
    { value: '95025', label: 'El Retorno' },
  ],
  '41': [
    { value: '41001', label: 'Neiva' },
    { value: '41006', label: 'Acevedo' },
    { value: '41013', label: 'Agrado' },
    { value: '41016', label: 'Aipe' },
    { value: '41020', label: 'Algeciras' },
    { value: '41026', label: 'Altamira' },
    { value: '41078', label: 'Baraya' },
    { value: '41132', label: 'Campoalegre' },
    { value: '41206', label: 'Colombia' },
    { value: '41244', label: 'Elías' },
    { value: '41298', label: 'Garzón' },
    { value: '41306', label: 'Gigante' },
    { value: '41319', label: 'Guadalupe' },
    { value: '41349', label: 'Hobo' },
    { value: '41357', label: 'Iquira' },
    { value: '41359', label: 'Isnos' },
    { value: '41378', label: 'La Argentina' },
    { value: '41396', label: 'La Plata' },
    { value: '41483', label: 'Nátaga' },
    { value: '41503', label: 'Oporapa' },
    { value: '41518', label: 'Paicol' },
    { value: '41524', label: 'Palermo' },
    { value: '41530', label: 'Palestina' },
    { value: '41548', label: 'Pital' },
    { value: '41551', label: 'Pitalito' },
    { value: '41615', label: 'Rivera' },
    { value: '41660', label: 'Saladoblanco' },
    { value: '41676', label: 'Santa María' },
    { value: '41770', label: 'Suaza' },
    { value: '41791', label: 'Tarqui' },
    { value: '41797', label: 'Tesalia' },
    { value: '41799', label: 'Tello' },
    { value: '41801', label: 'Teruel' },
    { value: '41807', label: 'Timaná' },
    { value: '41872', label: 'Villavieja' },
    { value: '41885', label: 'Yaguará' },
    { value: '41668', label: 'San Agustín' },
  ],
  '44': [
    { value: '44001', label: 'Riohacha' },
    { value: '44035', label: 'Albania' },
    { value: '44078', label: 'Barrancas' },
    { value: '44090', label: 'Dibula' },
    { value: '44098', label: 'Distracción' },
    { value: '44110', label: 'El Molino' },
    { value: '44279', label: 'Fonseca' },
    { value: '44378', label: 'Hatonuevo' },
    { value: '44430', label: 'Maicao' },
    { value: '44560', label: 'Manaure' },
    { value: '44847', label: 'Uribia' },
    { value: '44855', label: 'Urumita' },
    { value: '44874', label: 'Villanueva' },
    { value: '44420', label: 'La Jagua del Pilar' },
    { value: '44650', label: 'San Juan del Cesar' },
  ],
  '47': [
    { value: '47001', label: 'Santa Marta' },
    { value: '47030', label: 'Algarrobo' },
    { value: '47053', label: 'Aracataca' },
    { value: '47058', label: 'Ariguaní' },
    { value: '47161', label: 'Cerro San Antonio' },
    { value: '47170', label: 'Chivolo' },
    { value: '47205', label: 'Concordia' },
    { value: '47245', label: 'El Banco' },
    { value: '47258', label: 'El Piñon' },
    { value: '47268', label: 'El Retén' },
    { value: '47288', label: 'Fundación' },
    { value: '47318', label: 'Guamal' },
    { value: '47460', label: 'Nueva Granada' },
    { value: '47541', label: 'Pedraza' },
    { value: '47551', label: 'Pivijay' },
    { value: '47555', label: 'Plato' },
    { value: '47605', label: 'Remolino' },
    { value: '47675', label: 'Salamina' },
    { value: '47703', label: 'San Zenón' },
    { value: '47707', label: 'Santa Ana' },
    { value: '47745', label: 'Sitionuevo' },
    { value: '47798', label: 'Tenerife' },
    { value: '47960', label: 'Zapayán' },
    { value: '47980', label: 'Zona Bananera' },
    { value: '47692', label: 'San Sebastián de Buenavista' },
    { value: '47660', label: 'Sabanas de San Angel' },
    { value: '47545', label: 'Pijiño del Carmen' },
    { value: '47720', label: 'Santa Bárbara de Pinto' },
    { value: '47570', label: 'Pueblo Viejo' },
    { value: '47189', label: 'Ciénaga' },
  ],
  '50': [
    { value: '50001', label: 'Villavicencio' },
    { value: '50006', label: 'Acacias' },
    { value: '50124', label: 'Cabuyaro' },
    { value: '50223', label: 'Cubarral' },
    { value: '50226', label: 'Cumaral' },
    { value: '50245', label: 'El Calvario' },
    { value: '50251', label: 'El Castillo' },
    { value: '50270', label: 'El Dorado' },
    { value: '50313', label: 'Granada' },
    { value: '50318', label: 'Guamal' },
    { value: '50325', label: 'Mapiripán' },
    { value: '50330', label: 'Mesetas' },
    { value: '50350', label: 'La Macarena' },
    { value: '50370', label: 'Uribe' },
    { value: '50400', label: 'Lejanías' },
    { value: '50450', label: 'Puerto Concordia' },
    { value: '50568', label: 'Puerto Gaitán' },
    { value: '50573', label: 'Puerto López' },
    { value: '50577', label: 'Puerto Lleras' },
    { value: '50590', label: 'Puerto Rico' },
    { value: '50606', label: 'Restrepo' },
    { value: '50686', label: 'San Juanito' },
    { value: '50689', label: 'San Martín' },
    { value: '50711', label: 'Vista Hermosa' },
    { value: '50110', label: 'Barranca de Upía' },
    { value: '50287', label: 'Fuente de Oro' },
    { value: '50680', label: 'San Carlos de Guaroa' },
    { value: '50683', label: 'San Juan de Arama' },
    { value: '50150', label: 'Castilla la Nueva' },
  ],
  '52': [
    { value: '52699', label: 'Santacruz' },
    { value: '52001', label: 'Pasto' },
    { value: '52019', label: 'Albán' },
    { value: '52022', label: 'Aldana' },
    { value: '52036', label: 'Ancuyá' },
    { value: '52079', label: 'Barbacoas' },
    { value: '52203', label: 'Colón' },
    { value: '52207', label: 'Consaca' },
    { value: '52210', label: 'Contadero' },
    { value: '52215', label: 'Córdoba' },
    { value: '52224', label: 'Cuaspud' },
    { value: '52227', label: 'Cumbal' },
    { value: '52233', label: 'Cumbitara' },
    { value: '52250', label: 'El Charco' },
    { value: '52254', label: 'El Peñol' },
    { value: '52256', label: 'El Rosario' },
    { value: '52260', label: 'El Tambo' },
    { value: '52287', label: 'Funes' },
    { value: '52317', label: 'Guachucal' },
    { value: '52320', label: 'Guaitarilla' },
    { value: '52323', label: 'Gualmatán' },
    { value: '52352', label: 'Iles' },
    { value: '52354', label: 'Imués' },
    { value: '52356', label: 'Ipiales' },
    { value: '52378', label: 'La Cruz' },
    { value: '52381', label: 'La Florida' },
    { value: '52385', label: 'La Llanada' },
    { value: '52390', label: 'La Tola' },
    { value: '52399', label: 'La Unión' },
    { value: '52405', label: 'Leiva' },
    { value: '52411', label: 'Linares' },
    { value: '52418', label: 'Los Andes' },
    { value: '52427', label: 'Magüí' },
    { value: '52435', label: 'Mallama' },
    { value: '52473', label: 'Mosquera' },
    { value: '52480', label: 'Nariño' },
    { value: '52490', label: 'Olaya Herrera' },
    { value: '52506', label: 'Ospina' },
    { value: '52520', label: 'Francisco Pizarro' },
    { value: '52540', label: 'Policarpa' },
    { value: '52560', label: 'Potosí' },
    { value: '52565', label: 'Providencia' },
    { value: '52573', label: 'Puerres' },
    { value: '52585', label: 'Pupiales' },
    { value: '52612', label: 'Ricaurte' },
    { value: '52621', label: 'Roberto Payán' },
    { value: '52678', label: 'Samaniego' },
    { value: '52683', label: 'Sandoná' },
    { value: '52685', label: 'San Bernardo' },
    { value: '52687', label: 'San Lorenzo' },
    { value: '52693', label: 'San Pablo' },
    { value: '52696', label: 'Santa Bárbara' },
    { value: '52720', label: 'Sapuyes' },
    { value: '52786', label: 'Taminango' },
    { value: '52788', label: 'Tangua' },
    { value: '52838', label: 'Túquerres' },
    { value: '52885', label: 'Yacuanquer' },
    { value: '52694', label: 'San Pedro de Cartago' },
    { value: '52258', label: 'El Tablón de Gómez' },
    { value: '52110', label: 'Buesaco' },
    { value: '52835', label: 'San Andrés de Tumaco' },
    { value: '52083', label: 'Belén' },
    { value: '52240', label: 'Chachagüí' },
    { value: '52051', label: 'Arboleda' },
  ],
  '54': [
    { value: '54743', label: 'Silos' },
    { value: '54125', label: 'Cácota' },
    { value: '54820', label: 'Toledo' },
    { value: '54480', label: 'Mutiscua' },
    { value: '54261', label: 'El Zulia' },
    { value: '54660', label: 'Salazar' },
    { value: '54223', label: 'Cucutilla' },
    { value: '54553', label: 'Puerto Santander' },
    { value: '54313', label: 'Gramalote' },
    { value: '54250', label: 'El Tarra' },
    { value: '54800', label: 'Teorama' },
    { value: '54051', label: 'Arboledas' },
    { value: '54418', label: 'Lourdes' },
    { value: '54099', label: 'Bochalema' },
    { value: '54206', label: 'Convención' },
    { value: '54344', label: 'Hacarí' },
    { value: '54347', label: 'Herrán' },
    { value: '54810', label: 'Tibú' },
    { value: '54673', label: 'San Cayetano' },
    { value: '54670', label: 'San Calixto' },
    { value: '54398', label: 'La Playa' },
    { value: '54172', label: 'Chinácota' },
    { value: '54599', label: 'Ragonvalia' },
    { value: '54385', label: 'La Esperanza' },
    { value: '54874', label: 'Villa del Rosario' },
    { value: '54174', label: 'Chitagá' },
    { value: '54720', label: 'Sardinata' },
    { value: '54003', label: 'Abrego' },
    { value: '54405', label: 'Los Patios' },
    { value: '54498', label: 'Ocaña' },
    { value: '54109', label: 'Bucarasica' },
    { value: '54680', label: 'Santiago' },
    { value: '54377', label: 'Labateca' },
    { value: '54128', label: 'Cachirá' },
    { value: '54871', label: 'Villa Caro' },
    { value: '54239', label: 'Durania' },
    { value: '54518', label: 'Pamplona' },
    { value: '54520', label: 'Pamplonita' },
    { value: '54001', label: 'Cúcuta' },
    { value: '54245', label: 'El Carmen' },
  ],
  '86': [
    { value: '86001', label: 'Mocoa' },
    { value: '86219', label: 'Colón' },
    { value: '86320', label: 'Orito' },
    { value: '86569', label: 'Puerto Caicedo' },
    { value: '86571', label: 'Puerto Guzmán' },
    { value: '86573', label: 'Leguízamo' },
    { value: '86749', label: 'Sibundoy' },
    { value: '86755', label: 'San Francisco' },
    { value: '86757', label: 'San Miguel' },
    { value: '86760', label: 'Santiago' },
    { value: '86865', label: 'Valle de Guamez' },
    { value: '86568', label: 'Puerto Asís' },
    { value: '86885', label: 'Villagarzón' },
  ],
  '63': [
    { value: '63001', label: 'Armenia' },
    { value: '63111', label: 'Buenavista' },
    { value: '63190', label: 'Circasia' },
    { value: '63212', label: 'Córdoba' },
    { value: '63272', label: 'Filandia' },
    { value: '63401', label: 'La Tebaida' },
    { value: '63470', label: 'Montenegro' },
    { value: '63548', label: 'Pijao' },
    { value: '63594', label: 'Quimbaya' },
    { value: '63690', label: 'Salento' },
    { value: '63130', label: 'Calarcá' },
    { value: '63302', label: 'Génova' },
  ],
  '66': [
    { value: '66001', label: 'Pereira' },
    { value: '66045', label: 'Apía' },
    { value: '66075', label: 'Balboa' },
    { value: '66170', label: 'Dosquebradas' },
    { value: '66318', label: 'Guática' },
    { value: '66383', label: 'La Celia' },
    { value: '66400', label: 'La Virginia' },
    { value: '66440', label: 'Marsella' },
    { value: '66456', label: 'Mistrató' },
    { value: '66572', label: 'Pueblo Rico' },
    { value: '66594', label: 'Quinchía' },
    { value: '66687', label: 'Santuario' },
    { value: '66682', label: 'Santa Rosa de Cabal' },
    { value: '66088', label: 'Belén de Umbría' },
  ],
  '68': [
    { value: '68575', label: 'Puerto Wilches' },
    { value: '68573', label: 'Puerto Parra' },
    { value: '68001', label: 'Bucaramanga' },
    { value: '68013', label: 'Aguada' },
    { value: '68020', label: 'Albania' },
    { value: '68051', label: 'Aratoca' },
    { value: '68077', label: 'Barbosa' },
    { value: '68079', label: 'Barichara' },
    { value: '68081', label: 'Barrancabermeja' },
    { value: '68092', label: 'Betulia' },
    { value: '68101', label: 'Bolívar' },
    { value: '68121', label: 'Cabrera' },
    { value: '68132', label: 'California' },
    { value: '68152', label: 'Carcasí' },
    { value: '68160', label: 'Cepitá' },
    { value: '68162', label: 'Cerrito' },
    { value: '68167', label: 'Charalá' },
    { value: '68169', label: 'Charta' },
    { value: '68179', label: 'Chipatá' },
    { value: '68190', label: 'Cimitarra' },
    { value: '68207', label: 'Concepción' },
    { value: '68209', label: 'Confines' },
    { value: '68211', label: 'Contratación' },
    { value: '68217', label: 'Coromoro' },
    { value: '68229', label: 'Curití' },
    { value: '68245', label: 'El Guacamayo' },
    { value: '68255', label: 'El Playón' },
    { value: '68264', label: 'Encino' },
    { value: '68266', label: 'Enciso' },
    { value: '68271', label: 'Florián' },
    { value: '68276', label: 'Floridablanca' },
    { value: '68296', label: 'Galán' },
    { value: '68298', label: 'Gambita' },
    { value: '68307', label: 'Girón' },
    { value: '68318', label: 'Guaca' },
    { value: '68320', label: 'Guadalupe' },
    { value: '68322', label: 'Guapotá' },
    { value: '68324', label: 'Guavatá' },
    { value: '68327', label: 'Güepsa' },
    { value: '68368', label: 'Jesús María' },
    { value: '68370', label: 'Jordán' },
    { value: '68377', label: 'La Belleza' },
    { value: '68385', label: 'Landázuri' },
    { value: '68397', label: 'La Paz' },
    { value: '68406', label: 'Lebríja' },
    { value: '68418', label: 'Los Santos' },
    { value: '68425', label: 'Macaravita' },
    { value: '68432', label: 'Málaga' },
    { value: '68444', label: 'Matanza' },
    { value: '68464', label: 'Mogotes' },
    { value: '68468', label: 'Molagavita' },
    { value: '68498', label: 'Ocamonte' },
    { value: '68500', label: 'Oiba' },
    { value: '68502', label: 'Onzaga' },
    { value: '68522', label: 'Palmar' },
    { value: '68533', label: 'Páramo' },
    { value: '68547', label: 'Piedecuesta' },
    { value: '68549', label: 'Pinchote' },
    { value: '68572', label: 'Puente Nacional' },
    { value: '68615', label: 'Rionegro' },
    { value: '68669', label: 'San Andrés' },
    { value: '68679', label: 'San Gil' },
    { value: '68682', label: 'San Joaquín' },
    { value: '68686', label: 'San Miguel' },
    { value: '68705', label: 'Santa Bárbara' },
    { value: '68745', label: 'Simacota' },
    { value: '68755', label: 'Socorro' },
    { value: '68770', label: 'Suaita' },
    { value: '68773', label: 'Sucre' },
    { value: '68780', label: 'Suratá' },
    { value: '68820', label: 'Tona' },
    { value: '68861', label: 'Vélez' },
    { value: '68867', label: 'Vetas' },
    { value: '68872', label: 'Villanueva' },
    { value: '68895', label: 'Zapatoca' },
    { value: '68524', label: 'Palmas del Socorro' },
    { value: '68689', label: 'San Vicente de Chucurí' },
    { value: '68684', label: 'San José de Miranda' },
    { value: '68720', label: 'Santa Helena del Opón' },
    { value: '68655', label: 'Sabana de Torres' },
    { value: '68235', label: 'El Carmen de Chucurí' },
    { value: '68855', label: 'Valle de San José' },
    { value: '68673', label: 'San Benito' },
    { value: '68344', label: 'Hato' },
    { value: '68176', label: 'Chimá' },
    { value: '68147', label: 'Capitanejo' },
    { value: '68250', label: 'El Peñón' },
  ],
  '70': [
    { value: '70001', label: 'Sincelejo' },
    { value: '70110', label: 'Buenavista' },
    { value: '70124', label: 'Caimito' },
    { value: '70204', label: 'Coloso' },
    { value: '70221', label: 'Coveñas' },
    { value: '70230', label: 'Chalán' },
    { value: '70233', label: 'El Roble' },
    { value: '70235', label: 'Galeras' },
    { value: '70265', label: 'Guaranda' },
    { value: '70400', label: 'La Unión' },
    { value: '70418', label: 'Los Palmitos' },
    { value: '70429', label: 'Majagual' },
    { value: '70473', label: 'Morroa' },
    { value: '70508', label: 'Ovejas' },
    { value: '70523', label: 'Palmito' },
    { value: '70678', label: 'San Benito Abad' },
    { value: '70708', label: 'San Marcos' },
    { value: '70713', label: 'San Onofre' },
    { value: '70717', label: 'San Pedro' },
    { value: '70771', label: 'Sucre' },
    { value: '70823', label: 'Tolú Viejo' },
    { value: '70742', label: 'San Luis de Sincé' },
    { value: '70702', label: 'San Juan de Betulia' },
    { value: '70820', label: 'Santiago de Tolú' },
    { value: '70670', label: 'Sampués' },
    { value: '70215', label: 'Corozal' },
  ],
  '73': [
    { value: '73024', label: 'Alpujarra' },
    { value: '73026', label: 'Alvarado' },
    { value: '73030', label: 'Ambalema' },
    { value: '73055', label: 'Armero' },
    { value: '73067', label: 'Ataco' },
    { value: '73124', label: 'Cajamarca' },
    { value: '73168', label: 'Chaparral' },
    { value: '73200', label: 'Coello' },
    { value: '73217', label: 'Coyaima' },
    { value: '73226', label: 'Cunday' },
    { value: '73236', label: 'Dolores' },
    { value: '73268', label: 'Espinal' },
    { value: '73270', label: 'Falan' },
    { value: '73275', label: 'Flandes' },
    { value: '73283', label: 'Fresno' },
    { value: '73319', label: 'Guamo' },
    { value: '73347', label: 'Herveo' },
    { value: '73349', label: 'Honda' },
    { value: '73352', label: 'Icononzo' },
    { value: '73443', label: 'Mariquita' },
    { value: '73449', label: 'Melgar' },
    { value: '73461', label: 'Murillo' },
    { value: '73483', label: 'Natagaima' },
    { value: '73504', label: 'Ortega' },
    { value: '73520', label: 'Palocabildo' },
    { value: '73547', label: 'Piedras' },
    { value: '73555', label: 'Planadas' },
    { value: '73563', label: 'Prado' },
    { value: '73585', label: 'Purificación' },
    { value: '73616', label: 'Rio Blanco' },
    { value: '73622', label: 'Roncesvalles' },
    { value: '73624', label: 'Rovira' },
    { value: '73671', label: 'Saldaña' },
    { value: '73686', label: 'Santa Isabel' },
    { value: '73861', label: 'Venadillo' },
    { value: '73870', label: 'Villahermosa' },
    { value: '73873', label: 'Villarrica' },
    { value: '73854', label: 'Valle de San Juan' },
    { value: '73148', label: 'Carmen de Apicala' },
    { value: '73678', label: 'San Luis' },
    { value: '73675', label: 'San Antonio' },
    { value: '73152', label: 'Casabianca' },
    { value: '73043', label: 'Anzoátegui' },
    { value: '73001', label: 'Ibagué' },
    { value: '73411', label: 'Líbano' },
    { value: '73408', label: 'Lérida' },
    { value: '73770', label: 'Suárez' },
  ],
  '76': [
    { value: '76250', label: 'El Dovio' },
    { value: '76622', label: 'Roldanillo' },
    { value: '76054', label: 'Argelia' },
    { value: '76736', label: 'Sevilla' },
    { value: '76895', label: 'Zarzal' },
    { value: '76248', label: 'El Cerrito' },
    { value: '76147', label: 'Cartago' },
    { value: '76122', label: 'Caicedonia' },
    { value: '76246', label: 'El Cairo' },
    { value: '76400', label: 'La Unión' },
    { value: '76606', label: 'Restrepo' },
    { value: '76233', label: 'Dagua' },
    { value: '76318', label: 'Guacarí' },
    { value: '76041', label: 'Ansermanuevo' },
    { value: '76113', label: 'Bugalagrande' },
    { value: '76403', label: 'La Victoria' },
    { value: '76306', label: 'Ginebra' },
    { value: '76892', label: 'Yumbo' },
    { value: '76497', label: 'Obando' },
    { value: '76100', label: 'Bolívar' },
    { value: '76001', label: 'Cali' },
    { value: '76670', label: 'San Pedro' },
    { value: '76111', label: 'Guadalajara de Buga' },
    { value: '76126', label: 'Calima' },
    { value: '76036', label: 'Andalucía' },
    { value: '76563', label: 'Pradera' },
    { value: '76890', label: 'Yotoco' },
    { value: '76520', label: 'Palmira' },
    { value: '76616', label: 'Riofrío' },
    { value: '76020', label: 'Alcalá' },
    { value: '76863', label: 'Versalles' },
    { value: '76243', label: 'El Águila' },
    { value: '76823', label: 'Toro' },
    { value: '76130', label: 'Candelaria' },
    { value: '76377', label: 'La Cumbre' },
    { value: '76845', label: 'Ulloa' },
    { value: '76828', label: 'Trujillo' },
    { value: '76869', label: 'Vijes' },
    { value: '76834', label: 'Tuluá' },
    { value: '76275', label: 'Florida' },
    { value: '76364', label: 'Jamundí' },
    { value: '76109', label: 'Buenaventura' },
  ],
  '97': [
    { value: '97001', label: 'Mitú' },
    { value: '97161', label: 'Caruru' },
    { value: '97511', label: 'Pacoa' },
    { value: '97666', label: 'Taraira' },
    { value: '97777', label: 'Papunaua' },
    { value: '97889', label: 'Yavaraté' },
  ],
  '99': [
    { value: '99001', label: 'Puerto Carreño' },
    { value: '99524', label: 'La Primavera' },
    { value: '99624', label: 'Santa Rosalía' },
    { value: '99773', label: 'Cumaribo' },
  ],
};

export const locationsC = (id: any): ILocationOption[] | undefined => {
  if(id) {
    return [
      { value: 'ra '+id, label: 'Ruta a '+id },
      { value: 'rb '+id, label: 'Ruta b '+id },
      { value: 'rc '+id, label: 'Ruta c '+id },
      { value: 'rd '+id, label: 'Ruta d '+id },
      { value: 're '+id, label: 'Ruta e '+id },
      { value: 'rf '+id, label: 'Ruta f '+id },
    ];
  }
  return undefined;
};
