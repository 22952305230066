import { Component, Input } from '@angular/core';
import { IModalAgentsModel } from '@types-custom/models/ui/modal-agents-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal Agents
 ** Render a modal that displays information about Agents.
 */

@Component({
  selector: 'modal-agents',
  templateUrl: './modal-agents.component.html',
  styleUrls: ['./modal-agents.component.scss'],
})
export class ModalAgentsComponent {
  @Input() properties: IModalAgentsModel | undefined;
  Icon = Icon;
}
