import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IModelincidentsModel } from "@types-custom/models/ui/modal-incidents.model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { Observable, debounceTime, distinctUntilChanged, map, retry, startWith, switchMap } from "rxjs";
import { ModalSuccessComponent } from "../modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "sit-modal-incidents-field-resources",
  templateUrl: "./modal-incidents-field-resources.component.html",
  styleUrls: ["./modal-incidents-field-resources.component.scss"],
})
export class ModalIncidentsFieldResourcesComponent implements OnInit {
  @Input() properties : any | undefined;
  Icon = Icon;
  items = new Array(10);
  resourcesAssignedList: any[] = [];
  listResourceHuman: Observable<any>;

  resourceControl = new FormControl('');

  iconResourceAssignedMap: { [key: string]: Icon } = {
    'Al Colegio en Bici': Icon.AlColegioenBici,
    'Agentes Civiles': Icon.AgentesCiviles,
    'CATT': Icon.AgentesCiviles,
    'Grupo Guía': Icon.GrupoGuia,
    'Ciempiés': Icon.Ciempies
  };

  props: {
    getHumanResourcesByIncident: (incidentId: number) => Observable<any>,
    addHumanResourceIncident: (incidentId: number, humanResourceId: number) => Observable<any>,
    removeHumanResources: (humanResourceId: number, incidentId: number) => Observable<any>,
    listHumanResourceByIncident: (incidentId: number, value?: string) => Observable<any>
  }

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.props = this.properties.resourcesDataSource;
    
    this.init();

    this.listResourceHuman = this.resourceControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.filterHumanResource(value || undefined)),
    );

  }

  init(): void {
    
    this.listResourceHuman = this.filterHumanResource();

    this.props.getHumanResourcesByIncident(this.properties?.incidentId).subscribe({
      next: (response) => {
        this.resourcesAssignedList = response;
      },
      error: (error: any) => this.handlingError(error)
    });

  }

  filterHumanResource(value?: string) {
    return this.props.listHumanResourceByIncident(this.properties?.incidentId, value).pipe(
      retry(2)
    );
  }

  btnAddResource(item: any, trigger:MatAutocompleteTrigger) {
    this.props.addHumanResourceIncident(this.properties?.incidentId, item.id).subscribe({
      next: (response) => {
        this.init();
        trigger.closePanel();
        this.resourceControl.reset();
        this.showModalSuccess('El recurso fue asignado');
      },
      error: (error: any) => this.handlingError(error)
    });
  }

  btnRemoveResource(item: any) {
    this.props.removeHumanResources(item.humanResourceId, this.properties?.incidentId).subscribe({
      next: () => {
        this.init();
        this.showModalSuccess('El recurso fue desasignado');
      },
      error: (error: any) => this.handlingError(error)
    });
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      leftLineClass: 'bg-color-state-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

  private handlingError(error: any): void {
    //mensaje error
    console.error({ error });
  }

}
