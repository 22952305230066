<div>
    <sit-list-cards-report
      [headers]="properties.headers"
      [dataSource]="properties.dataSource.data$"
    ></sit-list-cards-report>
    <!--
    <div class="footer-table d-flex fd-col justify-space-between mt-16">
      <div class="d-flex justify-end mb-16">
        <sit-paginator
          [dataSource]="properties.dataSource"
          class="d-flex"
        ></sit-paginator>
      </div>
    </div> -->
  </div>
  