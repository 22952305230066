import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { ParametricFormI } from '@types-custom/models/ui/generic-form.model';
import { startWith, map } from 'rxjs';

@Component({
  selector: 'autocomplete-control',
  templateUrl: './autocomplete-control.component.html',
  styleUrls: ['./autocomplete-control.component.scss'],
})
export class AutocompleteControlComponent implements OnInit, OnChanges {
  @Input() formGroupInstance: any;
  @Input() formControlInstance: AbstractControl;
  @Input() controlModel: ParametricFormI;
  @Input() controlList: { name: string; value: string }[];

  autoControl = new FormControl<string>('');
  autoFormGroup = this.formBuilder.group({});

  list: { name: string; value: string }[];
  valueList: any[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.list = this.controlList;
    this.initSubscriberOnControlChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.controlList &&
      changes.controlList.previousValue !== changes.controlList.currentValue
    ) {
      this.initSubscriberOnControlChanges();
    }
    this.list = this.controlList;
  }

  initSubscriberOnControlChanges() {
    this.formControlInstance.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => {
          return typeof value === 'string'
            ? this._filterOptions((value as string) ?? '')
            : this.list;
        })
      )
      .subscribe((value) => {
        setTimeout(() => {
          this.list = value;
          this.handlePatchValueOnInitEdit(this.formControlInstance.value);
        }, 1000);
        this.formControlInstance.setErrors({ required: true });
      });
  }

  private _filterOptions(agent: string): any[] {
    return this.controlList?.filter((option) =>
      option.name.toLowerCase().includes(agent.toLowerCase())
    );
  }

  displayFn(agent: any): string {
    return agent && agent.name ? agent.name : '';
  }

  handleOptionSelection(item: any) {
    item[this.controlModel.formKey] = item.value;
    this.formControlInstance.patchValue(item, { emitEvent: false });
  }

  handlePatchValueOnInitEdit(controlValue: any) {
    const itemObjToEdit = this.list?.find((item) => item.value == controlValue);
    itemObjToEdit ? this.handleOptionSelection(itemObjToEdit) : undefined;
  }
}
