import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IPaginatorModel,
  AbstractPaginatorDataSource,
} from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject } from 'rxjs';

/*
 ** Paginator
 ** Render a pagination that can be used with a sit-grid-table.
 */
@Component({
  selector: 'sit-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() dataSource!: AbstractPaginatorDataSource;

  Icon = Icon;

  pageInfoSubject = new BehaviorSubject<IPaginatorModel | undefined>(undefined);

  pageSizesList = ['10', '20', '25', '30', '50'];
  pageSizeSelector = '10';

  ngOnInit(): void {
    this.dataSource.pageInfo.subscribe(this.handleFetchedData.bind(this));
  }

  setPage(page: number): void {
    if (page >= 0 && page <= this.getLatestPage()) {
      this.dataSource
        .fetchPageData(
          {
            data: this.dataSource.pageInfo,
            page,
            pageSize: this.dataSource.pageInfo.value.pageSize,
          })
        .subscribe();
    }
  }

  getLatestPage(): number {
    const totalCount = this.pageInfoSubject?.value?.totalCount ?? 1;
    const pageSize = this.dataSource.pageInfo.value.pageSize;
    const latestPage = Math.ceil(totalCount / pageSize);
    return latestPage - 1;
  }

  private handleFetchedData(pageInfo: IPaginatorModel): void {
    this.pageInfoSubject.next(pageInfo);
    this.setSizeSelector();
  }

  pageSizeSelectorHandler(pageSizeValue: any) {
    this.dataSource.pageInfo.value.pageSize = parseInt(pageSizeValue);
    this.dataSource
      .fetchPageData({
        page: 0,
        pageSize: this.dataSource.pageInfo.value.pageSize,
      })
      .subscribe();
  }

  setSizeSelector() {
    const pageSizeFilter = this.pageSizesList.filter(element => parseInt(element) === this.dataSource.pageInfo.value.pageSize);
    this.pageSizeSelector = pageSizeFilter.length == 0 ? '10' : pageSizeFilter[0];
  }
}
