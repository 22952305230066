<div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
    <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
        <div class="container-graphic-speed">
            <sit-speedometer [properties]="this.avgSpeedProps"></sit-speedometer>
        </div>
    </div>
    <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
        <div class="container-graphic">
            <sit-general-card [properties]="this.levelOfService" [size]="'icon-size-80'"></sit-general-card>
        </div>
    </div>
    <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
        <div class="container-graphic">
            <sit-general-card [properties]="this.flow" [size]="'icon-size-34'"></sit-general-card>
        </div>
    </div>
</div>
<div class="d-flex fd-row border-b-s-1 border-color-separator mb-16"></div>

<div *ngIf="properties.orientation > 1" class="d-grid">
    <span class="text-white fw-bold pb-1-rem"> Filtro de dirección. </span>
    <div>
        <div class="chip" [ngClass]="{'active': isAllSelected}" (click)="toggleSelection('all')">Ambas</div>
        <div class="chip" [ngClass]="{'active': isNorteSelected}" (click)="toggleSelection('norte')">Norte</div>
        <div class="chip" [ngClass]="{'active': isSurSelected}" (click)="toggleSelection('sur')">Sur</div>
    </div>
</div>

<div class="modal-body d-flex fd-col mb-1 justify-space-evenly w-100">
    <mat-tab-group class="text-size-16 text-white text-weight-500 mb-25 text-md-size-16 test-label custom-tab-group">
        <mat-tab label="Rango de Velocidad">
            <div class="d-flex full-width speed-range-chart">
            </div>
        </mat-tab>
        <mat-tab label="Flujo de Bicicletas">
            <div class="IncidentsContainer">
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
