import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'map-filter-agents',
  templateUrl: './map-filter-agents.component.html',
  styleUrls: ['./map-filter-agents.component.scss'],
})
export class MapFilterAgentsComponent implements OnInit {
  @Input() properties: any;
  Icon = Icon;
  options: any[];
  filteredOptions: Observable<any[]>;
  agent = new FormControl<string>('');
  fb = new UntypedFormBuilder();

  CleanButtonProps: IButtonModel = {
    label: 'Limpiar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  ngOnInit() {
    //console.log(this.properties)
    switch (this.properties.layer) {
      case 'Agents':
        this.options = this.properties.data[0].map((e: any) => {
          return { id: e.classProperties.id, name: e.classProperties.id };
        });
        break;
      case 'AgentsHistorical':
        //console.log(this.properties.data[0]);
        this.options = [
          ...new Set(
            this.properties.data[0]
              .filter((e: any) => e.geometryType === GeometryTypeEnum.Point)[0]
              .feature.features.map((e: any) => e.properties.id)
          ),
        ]
          .sort((a: any, b: any) => a.localeCompare(b))
          .map((e: any) => {
            return { id: e, name: e };
          });

          //console.log(this.options);
        break;

    }

    this.filteredOptions = this.agent.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;

        return name
          ? this._filterOptions(name as string)
          : this.options.slice();
      })
    );
  }

  displayFn(agent: any): string {
    return agent && agent.id ? agent.id : '';
  }

  private _filterOptions(agent: string): any[] {
    return this.options.filter((option) =>
      option.name.toLowerCase().includes(agent.toLowerCase())
    );
  }

  filterAgent(agent: any): void {
    let filter: string[] = [];

    if (agent) {
      filter = ['==', 'id', agent.id];
    }

    //console.log(filter);


    switch (this.properties.layer) {
      case 'Agents':
        this.properties.mapFilterDispatcher$.next({ filter: filter });
        break;
      case 'AgentsHistorical':
        //console.log(filter);
        this.filterAgentsHistorical({
          layer: this.properties.layer + '-Heat',
          filter: undefined,
          visibility: filter.length > 0 ? false : true,
        });

        this.filterAgentsHistorical({
          layer: this.properties.layer,
          filter: filter,
          visibility: filter.length > 0 ? true : false,
        });

        this.filterAgentsHistorical({
          layer: this.properties.layer + '-Lines',
          filter: filter,
          visibility: filter.length > 0 ? true : false,
        });
        break;
    }
  }

  filterAgentsHistorical(filter: any) {
    this.properties.mapFilterDispatcher$.next(filter);
  }

  resetFilter(){
    let filter: string[] = [];
    this.properties.mapFilterDispatcher$.next({ filter: filter });
    this.agent.reset('');
  }
}
