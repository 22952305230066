import { Component, Input } from '@angular/core';
import { IGridTableHeaderModel } from '@types-custom/models/ui/grid-table-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: "sit-list-cards-report",
  templateUrl: "./list-cards-report.component.html",
  styleUrls: ["./list-cards-report.component.scss"],
})
export class ListCardsReportComponent {
  @Input() headers: IGridTableHeaderModel[] = [];
  @Input() dataSource: Observable<[]> | undefined;

  icono: Icon = Icon.check_circle_green;
  
  dataCellType = {
    isNumber: (data: any) => typeof data === 'number',
  };

}
