import { SituationsModel } from '@shared/models/situations.model';
import { CameraModel } from '@shared/models/camera.model';
import { Co2Model } from '@shared/models/co2.model';
import { TrafficSpeedRangeModel } from './traffic-speed-range.model';
import { BicyclePathModel } from '@shared/models/bicycle-path.model';
import { CoosModel } from '@shared/models/coos.model';
import { TrafficLightModel } from '@shared/models/traffic-light.model';
import { AgentsModel } from '@shared/models/agents.model';
import { AgentsHistoricalModel } from '@shared/models/agents-historical.model';
import { CranesHistoricalModel } from '@shared/models/cranes-historical.model';
import { TrafficManagmentPlansPathModel } from '@shared/models/trafficmanagmentplans-path.model';
import { StopBusPathModel } from './stopbus-path.model';
import { ZonesPathModel } from './zones-path.model';
import { RoadRunPathModel } from './roadrun-path.model';
import { AccidentModel } from './accident.model';
import { PmvModel } from './pmv.model';
import { IcoiProp, coiModel } from './coi.model';
import { IcoilineProp, coilineModel } from './coiline.model';
import { AgentsNumberModel } from './agents-number.model';
import { CranesNumberModel } from './cranes-number.model';
import {RoadCorridorsModel} from "@shared/models/road-corridors.model";
import { VehiclesModel } from './vehicles.model';
import { coosLineModel } from '@shared/models/coosLine-model';
import {TrafficJamWazeModel} from "@shared/models/traffic-jams-waze.model";
import { ExodusAndReturnWazeModel } from './exodus-and-return-waze.model';
import {WazeDataTrafficModel} from "@shared/models/WazeDataTrafficModel";
import {AlertsDuplicatesWazeModel, AlertsWazeModel} from "@shared/models/alerts-waze-model";
import {IncidentsLocationsModel} from "@shared/models/LocationsIncidents.model";
import { CranesModel } from '@shared/models/cranes.model';
import { LastHoursModel } from './LastHoursIncidents.model';
import { IncidentsHeatMapModel } from '@shared/models/incidents-heat-map.model';
import {FieldResourcesLocationsModel} from "@shared/models/field-resources-model";
import {IncidentsFieldResourcesModel} from "@shared/models/incidents-field-resources-model";
import { HistoryGroundResourceModel } from './HistoryGroundResource.model';
import {ResourcesPredictiveModelLocationsModel} from "@shared/models/ResourcesPredictiveModelLocations.model";
import {BikesModel} from "@shared/models/Bikes.model";
import { NumberGroundResourceModel } from './numberGroundResource.model';
import { incidentAddResourcesModel } from './incidentAddResources.model';
import {AirQualityModel} from "@shared/models/air_quality/AirQuality.model";








export enum GeoAPITypeEnum {
  Camera                            = 'Camera',
  Situation                         = 'Situation',
  Incident                          = 'Incident',
  Traffic                           = 'Traffic',
  Blank                             = 'Blank',
  Co2                               = 'Co2',
  Aforo                             = 'Aforo',
  TrafficConcordance                = 'TrafficConcordance',
  TrafficConcordanceCorridors       = 'TrafficConcordanceCorridors',
  TrafficSpeedRangeCorridors        = 'TrafficSpeedRangeCorridors',
  TrafficSpeedRange                 = 'TrafficSpeedRange',
  RoadCorridors                     = 'RoadCorridors',
  WazeDataCorridors                 = 'WazeDataCorridors',
  WazeDataSpeedRange                = 'WazeDataSpeedRange',
  TrafficJamWaze                    = 'TrafficJamWaze',
  ExodusAndReturnWaze               = 'ExodusAndReturnWaze',
  AlertsWaze                        = 'AlertsWaze',
  BicyclePath                       = 'BicyclePath',
  CoosPoint                         = 'CoosPoint',
  TrafficLight                      = 'TrafficLight',
  CoosLine                          = 'CoosLine',
  Agents                            = 'Agents',
  AgentsCluster                     = 'AgentsCluster',
  AgentsHistorical                  = 'AgentsHistorical',
  CranesHistorical                  = 'CranesHistorical',
  AgentsNumber                      = 'AgentsNumber',
  CranesNumber                      = 'CranesNumber',
  Cranes                            = 'Cranes',
  TrafficManagmentPlans             = 'TrafficManagmentPlans',
  StopBus                           = 'StopBus',
  Zones                             = 'Zones',
  RoadRun                           = 'RoadRun',
  Accident                          = 'Accident',
  Pmv                               = 'Pmv',
  PmvEditar                         = 'PmvEditar',
  PmvConsulta                       = 'PmvConsulta',
  RuntDatex                         = 'RuntDatex',
  Coiline                           = 'Coiline',
  Coi                               = 'Coi',
  Vehicles                          = "Vehicles",
  lastHours                         = "lastHours",
  IncidentsLocations                = 'IncidentsLocations',
  IncidentsHeatMap                  = 'IncidentsHeatMap',
  FieldResourcesLocations           = 'FieldResourcesLocations',
  IncidentsFieldResources           = 'IncidentsFieldResources',
  ResourcesPredictiveModelLocations = "ResourcesPredictiveModelLocations",
  GroundResourceLasthours           = 'GroundResourceLasthours',
  GroundResourceNumber              = 'GroundResourceNumber',
  Bikes                             = 'Bikes',
  incidentAddResources              = 'incidentAddResources',
  AlertsDuplicatesWaze              = 'AlertsDuplicatesWaze',
  ParkingLot                        = "ParkingLot",

  // Air Quality
  ResourcesAirQualityPoints         = "ResourcesAirQualityPoints",
}

export type GeoAPIType =
  | CameraModel
  | SituationsModel
  | Co2Model
  | TrafficSpeedRangeModel
  | RoadCorridorsModel
  | TrafficJamWazeModel
  | WazeDataTrafficModel
  | AlertsWazeModel
  | BicyclePathModel
  | CoosModel
  | TrafficLightModel
  | AgentsModel
  | CranesModel
  | TrafficManagmentPlansPathModel
  | StopBusPathModel
  | AgentsHistoricalModel
  | CranesHistoricalModel
  | AgentsNumberModel
  | CranesNumberModel
  | ZonesPathModel
  | RoadRunPathModel
  | AccidentModel
  | IncidentsLocationsModel
  | PmvModel
  | coiModel
  | coilineModel
  | VehiclesModel
  | coosLineModel
  | LastHoursModel
  | IncidentsHeatMapModel
  | IncidentsFieldResourcesModel
  | HistoryGroundResourceModel
  | FieldResourcesLocationsModel
  | BikesModel
  | ResourcesPredictiveModelLocationsModel
  | NumberGroundResourceModel
  | incidentAddResourcesModel
  | AlertsDuplicatesWazeModel
  | AirQualityModel;
