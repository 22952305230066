<div class="text-blue-1 text-weight-700 font-s fx-card-hover cursor-pointer bg-color-background-3 card-container pt-16 pb-20 py-24-px" (click)="openModal()">
  <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-18 text-weight-600">
    <span>{{ card?.composedName || card?.location }}</span>
  </div>
  <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
    <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="{
            chartType: 4,
            value: +card?.speed,
            unitLabel: 'Km/h',
            divId: 'id',
            labelsClass: 'mt-0',
            unitLabelClass: 'text-size-14',
            handClockRotation: speedometerRotation,
            descriptionLabel: 'Velocidad Promedio'
          }">
        </sit-speedometer>
      </div>
    </div>
    <!-- TODO: dont delete this, SINITT-473. -->
    <!-- <div class="v-separator-line border-blue opacity-06"></div>
    <div
      class="travelTime-indicator d-flex fd-col mt-1 mb-1 align-items-center"
    >
      <sit-icon
        class="indicator-icon text-blue-1 mb-05"
        [icon]="card?.iconTravelTime"
        [sizeClass]="'icon-size-46'"
      ></sit-icon>
      <span class="indicator-info mb-05">{{ card?.travelTime }}</span>
    </div> -->

    <div class="d-flex fd-col mt-1 mb-1 justify-center">
      <div class="d-flex fd-col text-white time-gap-10">
        <div class="d-flex fd-col">
          <label class="text-size-18 text-color-primary-1 text-weight-500">{{ expMinTimeIndicator }}m {{ expSecTimeIndicator }}s</label>
          <label class="text-size-14 text-weight-400">Tiempo esperado</label>
        </div>
        <div class="d-flex fd-col">
          <label class="text-size-18 text-color-primary-1 text-weight-500">{{ actMinTimeIndicator }}m {{ actSecTimeIndicator }}s</label>
          <label class="text-size-14 text-weight-400">Tiempo real</label>
        </div>
      </div>
    </div>

    <!-- <div class="d-flex fd-col fw-120 justify-center">
      <div class="d-flex justify-center font-size-16 text-white text-weight-400 align-items-center pb-20 range-gap-8">
        <sit-icon [icon]="card?.iconSpeed" class="text-blue-1 d-flex {{ statusIndicator.classNow }}"
          [sizeClass]="'icon-size-32'" alt="icono velocímetro"></sit-icon>
        <label class="text-size-18">{{ statusIndicator.now }}</label>
      </div>

      <div>
        <sit-bar-chart [properties]="{ tid: +card?.id, dataAll: dataHistory, type: 2 }"></sit-bar-chart>
      </div>
    </div> -->

    <div class="d-flex fd-col mt-1 mb-1 align-items-center">
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="{
            chartType: 5,
            value: +card?.concordance,
            unitLabel: '%',
            divId: 'id',
            labelsClass: 'mt-0',
            unitLabelClass: 'text-size-14',
            handClockRotation: concordanceRotation
          }"></sit-speedometer>
      </div>
    </div>
  </div>
</div>
