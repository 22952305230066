import {IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {Icon} from "@types-custom/models/ui/icon-model";

export interface IPropsNumberGroundResourceModel {
  Qty: number;
  density: number;
  LocNombre: string;
  id: string;
  name: string;
  updatedDate: string;
  groupName: string;
  title: string;
  markerPopupText: string;
  icon: unknown;
}


export class NumberGroundResourceModel extends MarkerModelBase {
    static className = 'NumberGroundResourceModel';
    classProperties: IPropsNumberGroundResourceModel;

    constructor(props: IPropsNumberGroundResourceModel, geometry: IGeometryModel) {
        super(geometry, NumberGroundResourceModel.className);
        this.classProperties = props;
    }
}