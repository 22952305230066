import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {ILocationOption} from "@types-custom/models/business/location.model";
import {IClassesAttributes} from "@types-custom/models/ui/button-model";
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from "@angular/material/datepicker";

@Component({
  selector: "sit-dropdown-button-date-picker",
  templateUrl: "./dropdown-button-date-picker.component.html",
  styleUrls: ["./dropdown-button-date-picker.component.scss"],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DropdownButtonDatePickerComponent {
  @Input() dataSource$:
      | BehaviorSubject<ILocationOption[] | undefined>
      | undefined;
  @Input() label: string | undefined;

  @Input() selection$: BehaviorSubject<any> | undefined;
  @Input() classesAttributes: IClassesAttributes | undefined;
  @Input() filterPosition!: number;
  @Input() filterOnChange!: BehaviorSubject<number>;

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  _dataSource$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  subscription$ = new Subject();
  matSelectItem = '';

  showDropdown = false;

  selectedDateRange: DateRange<Date>;

  _onSelectedChange(date: Date): void {
    if (
        this.selectedDateRange &&
        this.selectedDateRange.start &&
        // @ts-ignore
        date > this.selectedDateRange.start &&
        !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
          // @ts-ignore
          this.selectedDateRange.start,
          // @ts-ignore
          date
      );
    } else {
      // @ts-ignore
      this.selectedDateRange = new DateRange(date, null);
    }
    if(this.selectedDateRange.start && this.selectedDateRange.end) {
      this.toggleDropdown()
      let date = this._onChangeDatesFormat(this.selectedDateRange)
      this.selection$?.next(date);
      this.filterOnChange?.next(this.filterPosition);
      this.changeValue.emit(date)
    }
  }

  _onChangeDatesFormat(date : DateRange<Date>) : string{
    if(date){
      if(date.start && date.end){
        let startDay = '' + (date.start.getDate())
        let startMonth = '' + (date.start.getMonth() + 1)
        let startYear = '' + (date.start.getFullYear())

        startDay    = startDay.length < 2 ? '0' + startDay : startDay;
        startMonth  = startMonth.length < 2 ? '0' + startMonth : startMonth;

        let startDate = [startMonth, startDay, startYear].join('/')

        let endDay = '' + (date.end.getDate())
        let endMonth = '' + (date.end.getMonth() + 1)
        let endYear = '' + (date.end.getFullYear())

        endDay    = endDay.length < 2 ? '0' + endDay : endDay;
        endMonth  = endMonth.length < 2 ? '0' + endMonth : endMonth;

        let endDate = [endMonth, endDay, endYear].join('/')

        return [startDate, endDate].join(' - ')
      }
    }
    return undefined
  }

  ngOnInit(): void {
    if (this.dataSource$) {
      this.dataSource$
          .pipe(takeUntil(this.subscription$))
          .subscribe((data) => this._dataSource$.next(data));
    }

    if (this.selection$) {
      this.selection$
          .pipe(takeUntil(this.subscription$))
          .subscribe((value) => (this.matSelectItem = value ?? ''));
    }
  }

  ngOnDestroy(): void {
    this.subscription$.next(undefined);
    this.subscription$.complete();
  }


  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  // selectItem(item: any) {
  //   console.log('Has seleccionado:', item);
  //   this.showDropdown = false;
  // }
}
