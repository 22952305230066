import {Component, Input, OnInit} from "@angular/core";
import {ICardTrafficJamWazeModel} from "@types-custom/models/ui/card-traffic-jams-waze.model";
import {IModalTrafficJamWazeModel} from "@types-custom/models/ui/modal-wazejam-model";
import {IModalTrafficModel} from "@types-custom/models/ui/modal-traffic-model";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-trafficjam-card",
  templateUrl: "./sit-trafficjam-card.component.html",
  styleUrls: ["./sit-trafficjam-card.component.scss"],
})
export class SitTrafficjamCardComponent implements OnInit{
  @Input() card:
      | IModalTrafficJamWazeModel
      | undefined;

  @Input() type : number
  public icon : Icon;
  public measure : String;
  public length : String;

  ngOnInit() {
    this.defineCard(this.card);
  }

  public defineCard(card: IModalTrafficJamWazeModel) : void {
    this.icon = (this.type === 1) ? this.ChoseIconSpeed() : this.ChoseIconLevel();
    this.card.speedkmh = Math.ceil(Number(this.card.speedkmh)).toString();
    this.card.delay = Math.ceil(Number(this.card.delay) / 60).toString();
    this.measure = (Number(this.card.length0) < 1000) ? "m" : "Km";
    this.length = (Number(this.card.length0) < 1000) ? this.card.length0 : (Number(this.card.length0) / 1000).toFixed(1).toString();
  }

  public ChoseIconSpeed() : Icon {
    var speed = Math.ceil(Number(this.card.speedkmh) / 10) * 10;
    return (speed >= 0 && speed < 20) ? Icon.VelocimetroCard10 : (speed >= 20 && speed < 30) ? Icon.VelocimetroCard20 :
           (speed >= 30 && speed < 40) ? Icon.VelocimetroCard30 : (speed >= 40 && speed < 50) ? Icon.VelocimetroCard40 :
           Icon.VelocimetroCard50
  }

  public ChoseIconLevel() : Icon {
    var level = Number(this.card.level)
    return  (level == 1) ? Icon.ArrowLevel1 : (level == 2) ? Icon.ArrowLevel2 :
            (level == 3) ? Icon.ArrowLevel3 : (level == 4) ? Icon.ArrowLevel4 :
            Icon.ArrowLevel5
  }

  public get SpeedCeil(){

    return  (Math.ceil(Number(this.card.speedkmh) / 10) * 10) == 0? "10" :
            (Math.ceil(Number(this.card.speedkmh) / 10) * 10) > 50? "52" :
            (Math.ceil(Number(this.card.speedkmh) / 10) * 10).toString();
  }

  protected readonly Icon = Icon;
}
