import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ScrollHandlingEventsEnum } from "@types-custom/models/ui/scroll-model";
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardWazeAlertsModel } from "@types-custom/models/ui/card-alert-waze.model";
import { ITimeSeriesGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { WazeAlertGraphicService } from "@shared/services/layer-service/waze-alert-graphic/waze-alert-graphic.service";
import { ModalService } from '@ui-core/services/modal/modal.service';
import { WazeAlertsDuplicatesModalComponent } from '../waze-alerts-duplicates-modal/waze-alerts-duplicates-modal.component';
import { IModalAlertsDuplicatesWazeModel } from '@types-custom/models/ui/modal.alerts.waze.model';
import { InputTypeEnum } from "@types-custom/models/ui/generic-form.model";
import { IncidentFormEnum } from "@types-custom/models/ui/incidents-model";
import { MapEventTypeEnum } from "@types-custom/models/ui/map-viewer-model";
import { MenuService } from "@official/services/menu/menu.service";
import { GeoLocationService } from "@shared/services/geo-location/geo-location.service";

@Component({
  selector: "sit-waze-alerts-duplicates-panel",
  templateUrl: "./waze-alerts-duplicates-panel.component.html",
  styleUrls: ["./waze-alerts-duplicates-panel.component.scss"],
})
export class WazeAlertsDuplicatesPanelComponent implements OnInit {

  @Input() serviceDataSource: any; //Observable

  dataGraphicList: ITimeSeriesGraphicModel;
  colorScale: any = [{}];
  fecha: any;
  Icon = Icon;
  cards: ICardWazeAlertsModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listTerritories: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
    ScrollHandlingEventsEnum | undefined
  >;
  totalAccidents: number = 0;
  totalCloses: number = 0;
  totalClimate: number = 0;

  constructor(
    public wazeServiceGraph: WazeAlertGraphicService
    , private modalService: ModalService
    , private menuService: MenuService
    , private geoService: GeoLocationService
  ) { }

  ngOnInit(): void {
    this.setIndicators(this.cards);
  }

  setIndicators(cards: ICardWazeAlertsModel[]) {

    cards.map((cards: ICardWazeAlertsModel) => {
      if (cards.type === 'ACCIDENT') {
        this.totalAccidents += 1;
      }
      if (cards.type === 'WEATHERHAZARD') {
        this.totalClimate += 1;
      }
      if (cards.type === 'ROAD_CLOSED') {
        this.totalCloses += 1;
      }
    });
  }

  public handleManaheDuplicates(row: any) {
    row.duplicates = JSON.parse(row.duplicates); // Se lo convierte en objeto
    const card: IModalAlertsDuplicatesWazeModel = row;
    this.modalService.addModal({
      component: WazeAlertsDuplicatesModalComponent,
      data: {
        properties: {
          ...card,
          title: card.street,
          customClass: 'modal-duplicates',
        },
        cardSelected: card,
        // onComplete: () => this.incidentConfigurationService.init()
      },
    });

  }

  handleCreateIncident(row: any) {
    const card: IModalAlertsDuplicatesWazeModel = row;
    const localityInfo = this.geoService.getLocalityByName(card.locality);
    const data: { [key: string]: any } = { // la idea es convertir esta data en un modelo estándar
      //Estos valores vienen del create-panel.model.ts, los usados en el `formKey`
      [InputTypeEnum.LOCATION_INPUT]: card.street,
      [InputTypeEnum.LOCALITY]: localityInfo?.localityId || '',
      [IncidentFormEnum.incidentTime]: '2018-06-12T19:30',
      [IncidentFormEnum.endDate]: '2018-06-12T19:30',
      [IncidentFormEnum.latitude]: card.latitude,
      [IncidentFormEnum.longitude]: card.longitude
    }
    this.serviceDataSource.next(data);
    this.menuService.handleMenuPanelActions(MapEventTypeEnum.INCIDENTS);
  }

}
