import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsCameraModel {
  id: string;
  icon: Icon;
  address: string;
  name: string;
  markerPopupText: string;
}

export class CameraModel extends MarkerModelBase {
  static className = 'CameraModel';

  classProperties: IPropsCameraModel;

  constructor(props: IPropsCameraModel, geometry: IGeometryModel) {
    super(geometry, CameraModel.className);
    this.classProperties = props;
  }
}
