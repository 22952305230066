import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IModalStopBusPathModel } from '@types-custom/models/ui/modal-stopBus-path';

@Component({
  selector: 'citizen-modal-stop-bus',
  templateUrl: './modal-stop-bus.component.html',
  styleUrls: ['./modal-stop-bus.component.scss'],
})
export class ModalStopBusComponent {
  @Input() properties: IModalStopBusPathModel | undefined;
  Icon = Icon;
}
