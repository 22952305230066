import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAlertsDuplicatesWazeModel } from '@types-custom/models/ui/modal.alerts.waze.model';

@Injectable({ providedIn: 'root' })
export class ManageWazeAlertsService {

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) { }

  public saveDuplicatesAlerts(user: IAlertsDuplicatesWazeModel): Observable<any> {
    const url = `${this.environment.WazeUrl}/api/v1/waze/alerts/duplicates`;
    return this.httpClient.post<any>(url, user);
  }

}
