import {Component, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {ICardIncidentsLocationsModel} from "@types-custom/models/ui/cards-incidents-locations";
import {IIncidentsCardFieldResourceModel} from "@types-custom/models/ui/card-incidents-field-resources";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-panel-incidents-field-resources",
  templateUrl: "./panel-incidents-field-resources.component.html",
  styleUrls: ["./panel-incidents-field-resources.component.scss"],
})
export class PanelIncidentsFieldResourcesComponent implements OnInit{
  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;

  cards: IIncidentsCardFieldResourceModel[] = [];

  public stacks = {
    totalIncidents          : {
      count : 0,
      icon  : Icon.alert_blue,
      title : 'Incidentes'
    },
    totalAssignedIncidents  : {
      count : 0,
      icon  : Icon.assignment,
      title : 'Con asignación'
    },
    totalPendingIncidents   : {
      count : 0,
      icon  : Icon.without_assignment,
      title : 'Sin asignación'
    }
  }

  public incidents : IIncidentsCardFieldResourceModel[] = []

  ngOnInit() {
    this.setStacks();
  }

  private setStacks() {
    this.cards.forEach((item : any) => {
      if(item.type === "incident"){
        this.incidents.push(item)
        this.stacks.totalIncidents.count += 1
        if(item.incidentCountsGroup.length > 0) {
          this.stacks.totalAssignedIncidents.count += 1
        }
        else {
          this.stacks.totalPendingIncidents.count += 1
        }
      }
    })
  }

  protected readonly Icon = Icon;
}
