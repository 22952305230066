import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { Component, OnInit } from '@angular/core';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalDataTreatmentComponent } from '@shared/components/modal-data-treatment/modal-data-treatment.component';
import { ModalPrivacyNoticeComponent } from '@shared/components/modal-privacy-notice/modal-privacy-notice.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { Router } from '@angular/router';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ModalForgotPasswordComponent } from '@ui-core/components/modal-forgot-password/modal-forgot-password.component';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';

@Component({
  selector: 'sit-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginAuthService, ModalService],
})
export class LoginComponent implements OnInit {
  Icon = Icon;
  buttonAttributes: IButtonModel | undefined = undefined;
  signinButtonProps: IButtonModel | undefined;

  isShowLoginForm = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private loginAuthService: LoginAuthService
  ) {}

  ngOnInit(): void {
    this.initializeProps();
  }

  private initializeProps(): void {
    this.buttonAttributes = {
      label: 'REGISTRARSE',
      classAttributes: {
        buttonClass: ButtonClassesEnum.white_background_button,
        spanClass: IconClassesEnum.text_login_button,
      },
    };

    this.signinButtonProps = {
      label: 'INGRESAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.primary_1_button,
        spanClass: IconClassesEnum.text_complementary_2,
      },
    };
  }

  showLoginForm() {
    this.isShowLoginForm = true;
  }

  showModalTerms(notice: string): void {
    let modal: IUniqueModalModel;

    if (notice === 'FORGOT PASS') {
      modal = {
        title: 'Restablecer Contraseña',
        icon: Icon.lock_reset_w,
        headerBackgroundClass: 'bg-color-background-1',
        toRender: {
          component: ModalForgotPasswordComponent,
          data: {
            properties: {
              loginAuthService: this.loginAuthService,
              modalService: this.modalService,
              cancel: this.closeModal.bind(this),
            },
          },
        },
      };
    } else if (notice === 'privacyNotice') {
      modal = {
        modalClass: 'privacyNotice',
        toRender: { component: ModalPrivacyNoticeComponent },
      };
    } else if (notice === 'dataTreatment') {
      modal = {
        modalClass: 'dataTreatment',
        toRender: { component: ModalDataTreatmentComponent },
      };
    }

    this.modalService.confirmationModal(modal);
  }

  closeModal() {
    this.modalService.closeConfirmationModal();
  }
}
