<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar container" tabindex="0">
<br>
  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.chronometer_w" class="text-blue-1 ml-32" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Velocidad
      </h3>
    </div>
  </div>

  <div class="d-flex">
    <div class="d-flex justify-space-around full-width py-container-speed">
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
      </div>
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="avgConcordanceProps"></sit-speedometer>
      </div>
    </div>
  </div>

  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.road_speed_w" class="text-blue-1 ml-32" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título indice de concordancia"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Índice De Concordancia
      </h3>
    </div>
  </div>

  <div class="full-width">
    <sit-chart-column [data]="listConcordance" [id]="'speed-range'"  [valueXText]="'Número de corredores'"  [valueYText]="'%'"  className="h-700"></sit-chart-column>
  </div>

  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-32" [sizeClass]="'icon-size-32'"
        alt="camino icono título rutas principales"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Rutas Principales
      </h3>
    </div>
  </div>

  <div class="list-cards pt-10 gap-15" [scrollHandler]="panelActionsDispatcher">
    <ng-container *ngFor="let card of cards">
      <sit-card-traffic [card]="card"></sit-card-traffic>
    </ng-container>
  </div>
</div>
