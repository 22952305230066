import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { agentsHistoricalFromAPIMapper } from '@shared/utils/mappers/geo-api-map';
import {
  GeometryTypeEnum,
  ILayerModel,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { forkJoin, Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import {vehiclesJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-json-data';
import {vehiclesNivelJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-nivel-json-data';
import {vehiclesSensoresJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-sensores-json-data';
import {vehiclesSnapsJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-snaps-json-data';
import {vehiclesGraphicFlowJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-graphic-flow-json-data';
import {vehiclesGraphicSpeedJsonMockData} from 'libs/sdk/src/lib/mock/vehicles-graphic-speed-json-data';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  getVehiclesGeoJSON(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicle/format-geo-json`
    );

    // let geojson2 = {'type':'FeatureCollection', 'features':[] as any[]}
    // geojson2['features'] = vehiclesJsonMockData.map(function(vehicle){
    //     let geojsonAgent = {"geometry":{'coordinates':[vehicle['longitude'],vehicle['latitude']], 'type':'Point'},
    //                         "properties":vehicle,
    //                         "type": 'Feature'}
    //     return geojsonAgent
    // })

    // return of(geojson2);
  }

  getInfoBySiteId(siteId:any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicle/${siteId}`
    );

  }

  getVehiclesAll() : Observable<any>{
    let geojson;

      forkJoin({vehiculos: of(vehiclesJsonMockData), 
      sensores: of(vehiclesSensoresJsonMockData),
      redis: of(vehiclesSnapsJsonMockData),
      nivel: of(vehiclesNivelJsonMockData)}
      ).subscribe((list:{vehiculos: any, sensores: any, redis: any, nivel: any}) => {
  
    
        geojson = {'type': 'FeatureCollection', 'features': [] as any}
                        
        geojson["features"] = list.vehiculos.map((item:any) =>{
  
          let d: any = new Object();
                  d['properties'] = item
                  
                  let items_sensores = list.sensores.filter((e:any) => e.siteid ==  item.siteid)
                  let level_of_serviceAVG = 0;
                  items_sensores.map((itemSensor:any) =>{
  
                    let items_redis = list.redis.filter((e:any) => e.laneid ==  itemSensor.laneid)
  
                    if(items_redis.length > 0){
                      
                      itemSensor["flow"] = items_redis[0].carcount; 
                      itemSensor["speed"] = items_redis[0].speed; 
                      itemSensor["length"] = items_redis[0].length; 
                      itemSensor["gap"] = items_redis[0].gap; 
                      itemSensor["level_of_service"] = items_redis[0].level_of_service; 
  
                    }
                    else{
                      itemSensor["flow"] = 0; 
                      itemSensor["speed"] = 0; 
                      itemSensor["length"] = 0; 
                      itemSensor["gap"] = 0; 
                      itemSensor["level_of_service"] = 1; 
  
                    }
  
                    level_of_serviceAVG += itemSensor.level_of_service;
  
                    let items_nivel = list.nivel.filter((e:any) => e.start_range <  itemSensor.level_of_service && e.end_range >  itemSensor.level_of_service)
                
                    if(items_nivel.length > 0){
                      itemSensor["level_of_serviceVal"] = itemSensor["level_of_service"]; 
                      itemSensor["level_of_service"] = items_nivel[0].letter; 
                      itemSensor["color"] = items_nivel[0].color; 
                    }
                    else {
                      itemSensor["level_of_serviceVal"] = itemSensor["level_of_service"]; 
                      itemSensor["level_of_service"] = list.nivel[0].letter; 
                      itemSensor["color"] = list.nivel[0].color;
                    }
                  });
  
                  if (items_sensores.length > 0) {
  
                    level_of_serviceAVG = (level_of_serviceAVG/items_sensores.length)
  
                    let items_nivel = list.nivel.filter((e:any) => e.start_range <  level_of_serviceAVG && e.end_range >  level_of_serviceAVG)
                
                    if(items_nivel.length > 0){
                      d['properties']["level_of_service"] = items_nivel[0].letter; 
                      d['properties']["color"] = items_nivel[0].color; 
                    }
                    else {
                      d['properties']["level_of_service"] = list.nivel[0].letter; 
                      d['properties']["color"] = list.nivel[0].color;
                    }
  
                    d['properties']["sensores"] = items_sensores;
                  }
  
                  d['geometry'] = {'type': 'Point', 'coordinates': [item.longitude, item.latitude] };
                
                  d['type'] = 'Feature';
                  return d;
  
        });
      });

      //console.log(geojson);
      return of(geojson);
  }

  getMeasurementVehicles(): Observable<any> {
    //return this.httpClient.get<any>('assets/mock/activeAgentsByHour.json');
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicles-history/last-measurements`
    );
  }

  getvehiclesHistoricalGeoJSON(): Observable<any> {
    // return this.httpClient.get<any>('assets/mock/agentsHistorical.json');
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicles-history/format-geo-json?hours=24`
    );
  }

  getNivel(): Observable<any> {
    return of(vehiclesNivelJsonMockData);
  }

  getGraphicFlow(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicle/graphic`
    );
    // return of(vehiclesGraphicFlowJsonMockData);
  }

  getGraphicSpeed(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicle/graphic`
    );
    // return of(vehiclesGraphicSpeedJsonMockData);
  }


  getListSensors(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.vehiclesUrl}/vehicle/locations`
    );
    // return of(vehiclesGraphicSpeedJsonMockData);
  }

  // getAgentsMapper(agentsHistorical: any): ILayerModel[] {
  //   const layer: ILayerModel[] = [];

  //   const sourceFeatures = agentsHistorical.features
  //     .map((e: any) => {
  //       return {
  //         ...e,
  //         properties: {
  //           ...e.properties,
  //           id: String(e.properties.id),
  //           time: formatDate(e.properties.time, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
  //           title: formatDate(e.properties.time, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
  //           icon: Icon.dot_w,
  //         },
  //       };
  //     })
  //     .sort((a: any, b: any) =>
  //       a.properties.time.localeCompare(b.properties.time)
  //     );

  //   const source: any = { type: 'FeatureCollection', features: sourceFeatures };

  //   const agentsHistoryPoints = source.features.map((e: any) =>
  //     agentsHistoricalFromAPIMapper(e)
  //   );

  //   const agentsHistoryLines = this.getAgentsLinesHistorical(source).map(
  //     (e: any) => agentsHistoricalFromAPIMapper(e)
  //   );

  //   layer.push({
  //     marker: agentsHistoryPoints,
  //     geometryType: GeometryTypeEnum.HeatMap,
  //     feature: source,
  //     visibility: true,
  //   });

  //   layer.push({
  //     marker: agentsHistoryPoints,
  //     geometryType: GeometryTypeEnum.Point,
  //     feature: source,
  //     visibility: false,
  //   });

  //   layer.push({
  //     marker: agentsHistoryLines,
  //     geometryType: GeometryTypeEnum.LineString,
  //     feature: source,
  //     visibility: false,
  //   });

  //   return layer;
  // }

  // getAgentsLinesHistorical(data: any): any[] {
  //   const agentsIds = [
  //     ...new Set(data.features.map((e: any) => e.properties.id)),
  //   ];

  //   const agentsLineFeatures = [];

  //   for (const item of agentsIds) {
  //     const coordinates = data.features
  //       .filter((e: any) => e.properties.id === item)
  //       .map((e: any) => e.geometry.coordinates);

  //     const properties = data.features.filter(
  //       (e: any) => e.properties.id === item
  //     )[0].properties;

  //     const agentsLineFeature = {
  //       type: 'Feature',
  //       properties: properties,
  //       geometry: {
  //         type: GeometryTypeEnum.LineString,
  //         coordinates: coordinates,
  //       },
  //     };
  //     agentsLineFeatures.push(agentsLineFeature);
  //   }

  //   return agentsLineFeatures;
  // }
}
