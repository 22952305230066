import {Component, Input, OnInit} from "@angular/core";
import {ICardRoadCorridorsModel} from "@types-custom/models/ui/card-road-corridors.model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";

@Component({
  selector: "sit-list-corridors",
  templateUrl: "./sit-list-corridors.component.html",
  styleUrls: ["./sit-list-corridors.component.scss"],
})
export class SitListCorridorsComponent implements OnInit{
  @Input() card:
      | ICardRoadCorridorsModel
      | undefined;

  @Input() number : number

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  avgIncidentsData : IncidentsModel = {
    divId : 'avgIncidents',
    value : 10,
    descriptionLabel : 'Incidentes abiertos',
    labelsClass: undefined,
  }

  ngOnInit() {
    this.avgSpeedProps.value = this.card.speed;
    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
    this.avgIncidentsData.value = this.card.total_incidents;
  }

  openModal(): void {
    this.card?.cardMapLink?.markerDispatcher.next(
        this.card.cardMapLink.markerModel
    );
  }
}
