import { Component, Input, OnInit } from '@angular/core';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { IModalTrafficModel } from '@types-custom/models/ui/modal-traffic-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import {
  adjustSpeedometerRotation,
} from '@ui-core/utils/functions/get-indicator-rotation';
import {IModalTrafficDataWazeModel} from "@types-custom/models/ui/modal-trafficdatawaze-model";

/*
 ** Modal waze
 ** Renders a modal that displays information about the waze font layers.
 */
@Component({
  selector: "sit-modal-waze",
  templateUrl: "./modal-waze.component.html",
  styleUrls: ["./modal-waze.component.scss"],
})
export class ModalWazeComponent implements OnInit{
  @Input() properties: IModalTrafficModel | IModalTrafficDataWazeModel | undefined;
  dataHistory: [] = [];
  dataHistoryTravel: [] = [];

  dataGraphicList: ITimeLineGraphicModel;
  colorScale = [
    { minValue: 0, maxValue: 10, color: '#FF3232' },
    { minValue: 10, maxValue: 20, color: '#FF9901' },
    { minValue: 20, maxValue: 1000, color: '#00C479' },
  ];

  statusIndicator: any = {};

  actMinTimeIndicator = '-';
  actSecTimeIndicator = '-';
  expMinTimeIndicator = '-';
  expSecTimeIndicator = '-';
  concordanceRotation = 0;
  speedometerRotation = 0;
  infoIndicatorMap: { [key: number]: any } = {
    1: {
      now: 'Lento',
      classNow: 'icon-filter-red',
    },
    2: {
      now: 'Medio',
      classNow: 'icon-filter-yellow',
    },
    3: {
      now: 'Bueno',
      classNow: 'icon-filter-green',
    },
  };

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      if ("SpeedRangeDataWazeSource" in this.properties) {
        this.properties.SpeedRangeDataWazeSource
            .getTrafficSpeedRangeWaze(this.properties.id, this.properties.category)
            .subscribe((res) => {
              this.dataHistory = res;
            });
      }
    });
  }

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
    this.setIndicator(this.properties);
    this.setTimeIndicator(this.properties);
    if ("trafficDataWazeSource" in this.properties) {
      this.properties.trafficDataWazeSource
          .getLastMeasurementsWaze(this.properties.id, this.properties.category)
          .subscribe((res) => {
            this.setGraphicData(res);
          });
    }
    
  }

  setIndicator(prop: IModalTrafficModel | IModalTrafficDataWazeModel): void {
    const keyIndicator = this.validationSpeed(+prop.speed);
    this.statusIndicator = this.infoIndicatorMap[keyIndicator];

    const speed = Math.round(+this.properties.speed);

    this.properties.speed =
      speed > 100 ? (100 - speed).toString() : speed.toString();

    this.speedometerRotation = adjustSpeedometerRotation(
      +this.properties.speed
    );
  }

  setTimeIndicator(prop: IModalTrafficModel | IModalTrafficDataWazeModel): void {
    const timeAct = this.convertTime(prop.elapsedTime);
    const timeExp = this.convertTime(prop.expectedValue);

    this.actMinTimeIndicator = timeAct.minute;
    this.actSecTimeIndicator = timeAct.second;
    this.expMinTimeIndicator = timeExp.minute;
    this.expSecTimeIndicator = timeExp.second;
  }

  validationSpeed(speed: number): number {
    if (speed < 10) {
      return 1;
    }

    return speed < 20 ? 2 : 3;
  }

  convertTime(seconds: number): any {
    const hour = Math.floor(seconds / 60);
    const outHour = hour < 10 ? '0' + hour : hour;

    const minute = Math.floor(seconds);
    const outMinute = minute < 10 ? '0' + minute : minute;

    const second = Math.floor((seconds / 60) % 60);
    const outSecond = second < 10 ? '0' + second : second;

    return {
      hour: outHour,
      minute: outMinute,
      second: outSecond,
    };
  }

  setGraphicData(info: any): void {
    const dataGraphic = info
      .map((graph: any) => {
        const originalDate = new Date(graph.time);
        const formattedTime = `${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}`;
        return {
          time: formattedTime,
          value: graph.velocity,
        };
      })
      .sort((x: any, y: any) => x.time - y.time);

    this.dataGraphicList = {
      data: dataGraphic,
      label: 'Velocidad',
      labelX: 'Horas',
      labelY: 'Km / h',
    };
  }
}
