import { LoginComponent } from '@official/pages/login/login.component';
import { NotFoundComponent } from '@official/pages/not-found/not-found.component';
import { Routes } from '@angular/router';

import { LoginGuard } from '@official/guards/login.guard';
import { LogoutGuard } from '@official/guards/logout.guard';

export enum RouteNamesEnum {
  Login = 'inicio-sesion',
  MapViewer = 'visor-mapa',
  IcidentParametersManagement = 'manage-incident-parameters',
  IncidentConfiguration = 'incident-configuration',
  DatexCategories = 'manage-categories',
  UserManagement = 'manage-users',
  ManageCameras = 'manage-cameras',
  ManageGroundResource = 'manage-ground-resource',
  Reports = 'reports',
  Links = 'links',
  Simac = 'simac',
  observatory = 'observatory',
  RolesManagement = 'manage-roles',
  PhysicalResourcesManagement = 'manage-physical-resource',
  Dashboard = 'dashboard',
  ActivitiesSchedule = 'manage-activities',
  ManageIncidentsCoordinator = 'manage-incidents-coordinator',
  FieldsManagement = 'manage-fields',
  NoveltyPhysicalResources = 'manage-novelty-vehicle',
}

export const OFFICIALRoutes: Routes = [
  {
    path: RouteNamesEnum.MapViewer,
    loadChildren: () =>
      import('../pages/map-viewer/map-viewer.module').then(
        (m) => m.MapViewerModule
      ),
  },
  {
    path: RouteNamesEnum.Login,
    component: LoginComponent,
    canActivate: [LogoutGuard],
  },
  {
    path: RouteNamesEnum.UserManagement,
    loadChildren: () =>
      import('../pages/user-management/user-management.module').then(
        (m) => m.UserManagmentModule
      ),
    canActivate: [LoginGuard],
  },
  //recursos en campo
  {
    path: RouteNamesEnum.ManageGroundResource,
    loadChildren: () =>
      import('../pages/management-ground-resource/management-ground-resource.module').then(
        (m) => m.GroundResourceManagmentModule
      ),
    canActivate: [LoginGuard],
  },

  {
    path: RouteNamesEnum.ActivitiesSchedule,
    loadChildren: () =>
      import('../pages/schedule-activities/schedule-activities.module').then(
        (m) => m.ScheduleActivitiesModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.NoveltyPhysicalResources,
    loadChildren: () =>
      import('../pages/manage-novelty-vehicle/manage-novelty-vehicle.module').then(
        (m) => m.ManageNoveltyVehicleModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.RolesManagement,
    loadChildren: () =>
      import('../pages/roles-management/roles-management.module').then(
        (m) => m.RolesManagementModule
      ),
    canActivate: [LoginGuard]
  },
  {
    path: RouteNamesEnum.PhysicalResourcesManagement,
    loadChildren: () =>
      import('../pages/physical-resources-management/physical-resources-management.module').then(
        (m) => m.PyshicalResourcesManagmentModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.IcidentParametersManagement,
    loadChildren: () =>
      import('../pages/incident-parameters-management/incident-parameters-management.module').then(
        (m) => m.IncidentParametersManagementModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.DatexCategories,
    loadChildren: () =>
      import('../pages/manage-datex-categories/manage-datex-categories.module').then(
        (m) => m.ManageDatexCategoriesModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.ManageIncidentsCoordinator,
    loadChildren: () =>
      import('../pages/manage-incidents/manage-incidents.module').then(
        (m) => m.ManageIncidentsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.IncidentConfiguration,
    loadChildren: () =>
      import('../pages/incident-configuration/incident-configuration.module').then(
        (m) => m.IncidentConfigurationModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: RouteNamesEnum.FieldsManagement,
    loadChildren: () =>
      import('../pages/fields-management/fields-management.module').then(
        (m) => m.FieldsManagementModule
      ),
    canActivate: [LoginGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RouteNamesEnum.MapViewer,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
