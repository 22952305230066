import {
  Component,
  Inject,
  Input,
  NgZone,
  PLATFORM_ID,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ISpeedometerModel } from '@types-custom/models/ui/speedometer-model';

/*
** Speedometer
** Render a speedometer that displays speed in traffic.
*/
@Component({
  selector: 'sit-speedometer',
  templateUrl: './speedometer.component.html',
  styleUrls: ['./speedometer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpeedometerComponent {
  @Input() properties: ISpeedometerModel = {
    chartType: 5,
    value: 50,
    unitLabel: 'Km/h',
    descriptionLabel: 'Velocidad',
    divId: 'id',
  };

  creationChartMap: { [key: number]: () => void } = {
    [1]: this.createChartCompliance.bind(this),
    [2]: this.createChartSpeed.bind(this),
    [3]: this.createChartCordance.bind(this),
  };

  private chart!: am4charts.GaugeChart;
  private hand!: am4charts.ClockHand;

  myTimeOut: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.creationChartMap[this.properties.chartType]
      ? this.creationChartMap[this.properties.chartType]()
      : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.value && this.hand) ||
      (changes.value && !changes.value.firstChange)
    ) {
      this.updateChartOnChanges(changes);
    }
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    if (this.chart) {
      this.chart.dispose();
    }
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  updateChartOnChanges(changes: SimpleChanges) {
    if (
      this.properties.chartType === 1 ||
      this.properties.chartType === 2 ||
      this.properties.chartType === 3
    ) {
      clearTimeout(this.myTimeOut);
      this.myTimeOut = setTimeout(() => {
        this.hand.showValue(
          +changes.value.currentValue,
          1000,
          am4core.ease.cubicOut
        );
      }, 1000);
    } else if (this.properties.chartType === 4) {
      setTimeout(() => {
        this.properties.value = this.properties.value * 2.5;
      }, 200);
    } else if (this.properties.chartType === 5) {
      setTimeout(() => {
        this.properties.value = (this.properties.value + 50) * 1.8;
      }, 200);
    }
  }

  createChartCompliance() {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);

      const chart = am4core.create(this.properties.divId, am4charts.GaugeChart);
      // Create axis
      const axis = chart.xAxes.push(
        new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
      );
      axis.min = 0;
      axis.max = 100;
      axis.strictMinMax = true;
      axis.renderer.inside = true;
      axis.renderer.labels.template.fontSize = '0.1em';
      this.chart = chart;

      // Set inner radius
      chart.innerRadius = -3;

      // Add ranges
      const range = axis.axisRanges.create();
      range.value = 0;
      range.endValue = 50;
      range.axisFill.fillOpacity = 1;
      range.axisFill.fill = am4core.color('#DE8F6E');
      range.axisFill.zIndex = -1;

      const range2 = axis.axisRanges.create();
      range2.value = 50;
      range2.endValue = 80;
      range2.axisFill.fillOpacity = 1;
      range2.axisFill.fill = am4core.color('#DBD56E');
      range2.axisFill.zIndex = -1;

      const range3 = axis.axisRanges.create();
      range3.value = 80;
      range3.endValue = 100;
      range3.axisFill.fillOpacity = 1;
      range3.axisFill.fill = am4core.color('#88AB75');
      range3.axisFill.zIndex = -1;

      // Add hand
      this.hand = chart.hands.push(new am4charts.ClockHand());
      this.hand.value = 5;
      this.hand.pin.disabled = true;
      this.hand.fill = am4core.color('#2D93AD');
      this.hand.stroke = am4core.color('#2D93AD');
      this.hand.innerRadius = am4core.percent(10);
      this.hand.radius = am4core.percent(90);
      this.hand.startWidth = 5;
      this.myTimeOut = setTimeout(() => {
        this.hand.showValue(
          +this.properties.value,
          1000,
          am4core.ease.cubicOut
        );
      }, 1000);
    });
  }

  createChartSpeed() {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);

      const chart = am4core.create(this.properties.divId, am4charts.GaugeChart);
      chart.startAngle = -245;
      chart.endAngle = 0;
      // Create axis
      const axis = chart.xAxes.push(
        new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
      );
      axis.min = 0;
      axis.max = 80;
      axis.strictMinMax = true;
      axis.renderer.inside = true;
      axis.renderer.labels.template.fontSize = '0.1em';
      this.chart = chart;

      // Set inner radius
      chart.innerRadius = -3;

      // Add ranges
      const range = axis.axisRanges.create();
      range.value = 0;
      range.endValue = 10;
      range.axisFill.fillOpacity = 1;
      range.axisFill.fill = am4core.color('#DE8F6E');
      range.axisFill.zIndex = -1;

      const range2 = axis.axisRanges.create();
      range2.value = 10;
      range2.endValue = 20;
      range2.axisFill.fillOpacity = 1;
      range2.axisFill.fill = am4core.color('#DBD56E');
      range2.axisFill.zIndex = -1;

      const range3 = axis.axisRanges.create();
      range3.value = 20;
      range3.endValue = 80;
      range3.axisFill.fillOpacity = 1;
      range3.axisFill.fill = am4core.color('#88AB75');
      range3.axisFill.zIndex = -1;

      // Add hand
      this.hand = chart.hands.push(new am4charts.ClockHand());
      this.hand.value = 5;
      this.hand.pin.disabled = true;
      this.hand.fill = am4core.color('#2D93AD');
      this.hand.stroke = am4core.color('#2D93AD');
      this.hand.innerRadius = am4core.percent(10);
      this.hand.radius = am4core.percent(90);
      this.hand.startWidth = 5;
      this.myTimeOut = setTimeout(() => {
        this.hand.showValue(
          +this.properties.value,
          1000,
          am4core.ease.cubicOut
        );
      }, 1000);
    });
  }

  createChartCordance() {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);

      const chart = am4core.create(this.properties.divId, am4charts.GaugeChart);
      // Create axis
      const axis = chart.xAxes.push(
        new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
      );
      axis.min = 0;
      axis.max = 180;
      axis.strictMinMax = true;
      axis.renderer.inside = true;
      axis.renderer.labels.template.fontSize = '0.1em';
      this.chart = chart;

      // Set inner radius
      chart.innerRadius = -3;

      // Add ranges
      const range = axis.axisRanges.create();
      range.value = 0;
      range.endValue = 70;
      range.axisFill.fillOpacity = 1;
      range.axisFill.fill = am4core.color('#DE8F6E');
      range.axisFill.zIndex = -1;

      const range2 = axis.axisRanges.create();
      range2.value = 70;
      range2.endValue = 120;
      range2.axisFill.fillOpacity = 1;
      range2.axisFill.fill = am4core.color('#DBD56E');
      range2.axisFill.zIndex = -1;

      const range3 = axis.axisRanges.create();
      range3.value = 120;
      range3.endValue = 180;
      range3.axisFill.fillOpacity = 1;
      range3.axisFill.fill = am4core.color('#88AB75');
      range3.axisFill.zIndex = -1;

      // Add hand
      this.hand = chart.hands.push(new am4charts.ClockHand());
      this.hand.value = 5;
      this.hand.pin.disabled = true;
      this.hand.fill = am4core.color('#2D93AD');
      this.hand.stroke = am4core.color('#2D93AD');
      this.hand.innerRadius = am4core.percent(10);
      this.hand.radius = am4core.percent(90);
      this.hand.startWidth = 5;
      this.myTimeOut = setTimeout(() => {
        this.hand.showValue(
          +this.properties.value,
          1000,
          am4core.ease.cubicOut
        );
      }, 1000);
    });
  }
}
