import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPmvModel {
  id: string;
  address: string;
  ip: string;
  port: number;
  name: string;
  manufacturer: string;
  protocol: string;
  communeId: number;
  communeName: string;
  stateId: number;
  stateName: string;
  description: string;
  title: string;
  markerPopupText: string;
  icon: unknown;
  updatedDate: string;
}

export class PmvModel extends MarkerModelBase {
  static className = 'PmvModel';
  classProperties: IPmvModel;

  constructor(props: IPmvModel, geometry: IGeometryModel) {
    super(geometry, PmvModel.className);
    this.classProperties = props;
  }
}
