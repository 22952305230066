import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ManagePhysicalResourcesService } from "@official/services/data-grid-service/manage-physical-resource-service/manage-physical-resources.service";
import { SdkDynamicFiltersService } from "@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service";
import { CommonService } from "@shared/services/common-service/common-service.service";
import { ButtonClassesEnum, IButtonModel } from "@types-custom/models/ui/button-model";
import { Icon } from '@types-custom/models/ui/icon-model';
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccesUserComponent } from "@ui-core/components/modal-succes-user/modal-succes-user.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";

@Component({
  selector: "modal-unsuscribe-physical-resources",
  templateUrl: "./modal-unsuscribe-physical-resources.component.html",
  styleUrls: ["./modal-unsuscribe-physical-resources.component.scss"],
})
export class ModalUnsuscribePhysicalResourcesComponent implements OnInit {
  @Input() properties: any | undefined;
  Icon = Icon;

  supportForm!: FormGroup;

  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_resources,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonEditAttributes: IButtonModel = {
    label: 'EDITAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonCancelAttributes: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };
  
  maxWidth = 640;
  modalWidth = 'auto';
  bottom = '70px';
  left = '10px';
  right = '10px';

  constructor(
    private formBuilder: FormBuilder,
    public pyshicalResourcesManagementService: ManagePhysicalResourcesService,
    private modalService: ModalService
  ){
    
  }

  ngOnInit(): void {
    this.render();
    this.createForm();
  }

  render(): void {
    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    const elementStyle = (<HTMLStyleElement>(
      document.querySelector('.modal-box')
    )).style;

    elementStyle.width = this.modalWidth;
    elementStyle.bottom = this.bottom;
    elementStyle.left = this.left;
    elementStyle.right = this.right;
  }

  createForm() {
    this.supportForm = this.formBuilder.group({
      motive: ['',Validators.required]
    });
  }

  changeUserState(){
    //console.log(this.properties);
    let data = {
      id: this.properties.card.id,
      stateCode: this.properties.card.state,
      motive: this.supportForm.get('motive').value
    }

    this.pyshicalResourcesManagementService.updateStateUser(data).subscribe(
      (resp: any) => {
        this.showModalSuccess('El recurso se ha dado de baja');
        this.pyshicalResourcesManagementService.init();
        this.modalService.closeConfirmationModal();
      },
      (err: any) => {
        this.showModalError('Error al dar de baja el recurso físico')
        this.modalService.closeConfirmationModal();
      }
    );
  }

  get motiveErrorMessage(): string {
    const form: FormControl = this.supportForm.get('motive') as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 60'
            : form.hasError('pattern')
                ? 'Caracter no permitido'
                : '';
  }

  validateFormGroup(): void {
    this.supportForm.markAllAsTouched();
  }

  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccesUserComponent,
        data: {
          infoMessage: 'Está seguro que desea cancelar ?',
          icon: Icon.questions_w,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }

  onCancel(): void {
    this.modalService.closeConfirmationModal();
    // this.modalCanelar();
  }

  showModalSuccess(message: string): void {
    // this.modalService.confirmationModal(modal);
    this.modalService.addModal({
      component: ModalSuccessComponent,
      data: {
        properties: {
          message: message,
          customClass: 'modalInfoBulkLoad',
        },
        message: message,
      },
    });
  }
  showModalError(message: string): void {

    // this.modalService.confirmationModal(modal);
    this.modalService.addModal({
      component: ModalErrorComponent,
      data: {
        properties: {
          message: message,
          customClass: 'modalInfoBulkLoad',
        },
        message: message,
      },
    });
  }

}
