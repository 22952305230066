<div class="title-container mb-30">
  <div class="title-area d-flex align-items-center pb-16 pt-16">
    <sit-icon
      [icon]="icon"
      [sizeClass]="'icon-size-33'"
      alt="icono de título de la página"
    ></sit-icon>
    <h2 class="text-size-20 text-weight-700 text-white">{{ name }}</h2>
  </div>
</div>
