import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { ICardTrafficModel } from '@types-custom/models/ui/card-traffic-model';
import { IPanelTrafficModel } from '@types-custom/models/ui/panel-traffic-model';
import { ScrollHandlingEventsEnum } from '@types-custom/models/ui/scroll-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { sortByTitle } from '@ui-core/utils/functions/order-function';
import { ICardTrafficSpeedRangeModel } from '@types-custom/models/ui/card-traffic-speed-range-model';
import { ISpeedometerModel } from '@types-custom/models/ui/speedometer-model';
import * as am4core from '@amcharts/amcharts4/core';
/*
** Panel traffic
** Render a panel that contains traffic cards and a brief description of traffic.
*/
@Component({
  selector: 'panel-traffic',
  templateUrl: './panel-traffic.component.html',
  styleUrls: ['./panel-traffic.component.scss'],
})
export class PanelTrafficComponent implements OnInit {
  @Input() properties: IPanelTrafficModel | undefined;
  Icon = Icon;
  cards: ICardTrafficSpeedRangeModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listspeed: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
    ScrollHandlingEventsEnum | undefined
  >;
  //avgSpeedValue: 0;
  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'MEDIA',
    unitLabel: 'KM/H',
    labelsClass: 'gap-row-10-px',
    value: 0
  }

  avgConcordanceProps: ISpeedometerModel = {
    chartType: 5,
    divId: 'avgConcordance',
    descriptionLabel: 'MEDIA',
    unitLabel: '%',
    labelsClass: 'gap-row-10-px',
    value: 0
  }

  colorsSpeed = [
    { id: 0, color: '#FF3232' },
    { id: 10, color: '#FF9901' },
    { id: 20, color: '#00C479' },
  ];

  constructor() {
    //
  }

  ngOnInit(): void {
    this.setIndicators(this.cards);
    this.setGraphic(this.cards);
  }


  setIndicators(cards: ICardTrafficSpeedRangeModel[]) {
    let sumspeedAvg = 0;
    let totDistance = 0;
    let QtysumconcordanceAvg = 0;
    let sumconcordanceAvg = 0;

    cards.map((cards: ICardTrafficSpeedRangeModel) => {
      cards.speed = (+cards.speed).toFixed(2);
      if(!isNaN(+cards.speed)) {
        sumspeedAvg = sumspeedAvg + ((+cards.speed) * (+cards.distance/1000))
        if (+cards.speed > 0) {
          totDistance = totDistance + (+cards.distance/1000);
        }
      }
  });

  cards.map((cards: ICardTrafficSpeedRangeModel) => {
    if (!isNaN(+cards.concordance)) {
      QtysumconcordanceAvg += 1;
      sumconcordanceAvg = sumconcordanceAvg + (+cards.concordance);
    }
  })

  this.avgSpeedProps.value = Math.round(sumspeedAvg/totDistance);
  this.avgConcordanceProps.value = Math.round(sumconcordanceAvg/QtysumconcordanceAvg);

  }

  setGraphic(cards: ICardTrafficSpeedRangeModel[]) {

    const lookup = {} as any;

    cards.map((card: ICardTrafficSpeedRangeModel) => {
      const speed =  Math.floor(+card.speed/10)*10;
      if (speed in lookup) {
        lookup[speed] = lookup[speed] + 1;
      }
      else {
        lookup[speed] = 1;
        const object: any = new Object();
        object['id'] = speed;
        object['label'] = speed + 'km/h';
        object['qty'] = 1,
        object['color'] = this.colorsSpeed.find(element => element.id == ((speed > 20)? 20: speed )).color;

        this.listspeed.push(object);
        //console.log(this.listspeed);
      }
    });

    this.listspeed.forEach((item) => {
      item.qty = lookup[item.id];
    });
    //console.log(this.listspeed);
  }
}
