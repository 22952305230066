import { Component, Input, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { FieldsManageService } from "@official/services/data-grid-service/fields-manage-service/fields-manage.service";
import { IStateParemter } from "@shared/models/field.model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalConfirmationComponent } from "@ui-core/components/modal-confirmation/modal-confirmation.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";

@Component({
  selector: "sit-state-field-toggle",
  templateUrl: "./state-field-toggle.component.html",
  styleUrls: ["./state-field-toggle.component.scss"],
})
export class StateFieldToggleComponent {
  @Input() stateParameter: IStateParemter;

  constructor(
    private fieldsManageService: FieldsManageService,
    private modalService: ModalService
  ) { }

  changeStatusIncidentcorridor(event: MatSlideToggleChange): void {
    this.stateParameter.enabled = event.checked;
    this.fieldsManageService.updateState(this.stateParameter).subscribe({
      next: () => {
        setTimeout(() => {
          this.showModalSuccess('El estado fue actualizado exitosamente.');
        }, 200);
      }
    });
  }

  showModalConfirm(event: MatSlideToggleChange): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalConfirmationComponent,
        data: {
          titleMessage: `¿Está seguro de ${ this.stateParameter.enabled ? 'inactivar': 'activar' } el ${this.stateParameter.name}?`,
          infoMessage: '',
          icon: Icon.help_blue
        },
      },
      headerBackgroundClass: 'bg-color-background-3',
      leftLineClass: 'bg-color-state-2',
      sheetBottom: true,
      confirm: () => {
        this.changeStatusIncidentcorridor(event);
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        event.source.checked = !event.checked;
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      leftLineClass: 'bg-color-state-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

}
