import { Validators } from "@angular/forms";
import { CodeValidatorNameEnum, FormGroupNamesEnum, IDynamicFormModel, InputTypeEnum, ParametricFormI } from "@types-custom/models/ui/generic-form.model";


export function dynamicFormMapper(field: IDynamicFormModel): ParametricFormI {
    const ret: ParametricFormI = {
        type: field.type as InputTypeEnum,
        formKey: `dynamic_${field.id}`,
        formName: FormGroupNamesEnum.MAIN,
        isDynamicControl: true,
        label: field.name,
        list: field.options.map(field => { return { value: `${field.id}`, ...field } }),
        formControl: {
            value: '',
            options: null,
            validators: (field.type === InputTypeEnum.INPUT ? [
                {
                    validator: Validators.minLength(field.minCharacterSize),
                    validatorCodeName: CodeValidatorNameEnum.MIN_LENGTH_VALIDATOR,
                    errorMessage: `Mínimo (${field.minCharacterSize}) caracteres`,
                },
                {
                    validator: Validators.maxLength(field.maxCharacterSize),
                    validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
                    errorMessage: `Máximo (${field.maxCharacterSize}) caracteres`,
                },
            ] : [])
        },
    }
    return ret;
}
