import { Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal Success
 ** Render a modal that displays a success message.
 */
@Component({
  selector: 'citizen-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent implements OnInit {
  @Input() message = 'La operación se ha realizado con éxito';
  checkCircle = Icon.check_circle_green;

  maxWidth = 640;
  modalWidth = 'auto';
  bottom = '70px';
  left = '10px';
  right = '10px';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    // If the viewport width is greater than maxWidth, no need to update styles.
    if (this.maxWidth > document.documentElement.clientWidth) {
      const modalElement = this.el.nativeElement.querySelector('.modal-box');
      this.renderer.setStyle(modalElement, 'width', this.modalWidth);
      this.renderer.setStyle(modalElement, 'bottom', this.bottom);
      this.renderer.setStyle(modalElement, 'left', this.left);
      this.renderer.setStyle(modalElement, 'right', this.right);
    }
  }
}
