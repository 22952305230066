<div class="modal-co2 w-auto h-auto d-flex fd-col text-weight-600">
  <div class="content-indicadores justify-center">
    <div
      class="content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1"
      (click)="graphicCo2(dataSource)"
    >
      <div>
        <span class="">CO2</span>
      </div>
      <div class="content-icon">
        <sit-icon
          [icon]="Icon.co2_w"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de nube co2"
        ></sit-icon>
        <span class="content-text">{{ properties?.carbonDioxide }}</span>
        <span>ppm</span>
      </div>
      <!-- TODO: Temporarily disabled
            <div class="d-flex justify-center text-weight-400">
            <span>Último registo: 11:00</span>
        </div> -->
    </div>
    <div
      class="content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1"
      (click)="graphicTemperature(dataSource)"
    >
      <div>
        <span class="">Temperatura</span>
      </div>
      <div class="content-icon">
        <sit-icon
          [icon]="Icon.sensor_w"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de termómetro"
        ></sit-icon>
        <span class="content-text">{{ properties?.temperature }}°C</span>
      </div>
      <!-- TODO: Temporarily disabled
            <div class="d-flex justify-center text-weight-400">
            <span>Último registo: 11:00</span>
        </div> -->
    </div>
    <div
      class="content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1"
      (click)="graphicHumidity(dataSource)"
    >
      <div>
        <span class="">Humedad</span>
      </div>
      <div class="content-icon">
        <sit-icon
          [icon]="Icon.gota"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de gota"
        ></sit-icon>
        <span class="content-text">{{ properties?.humidity }}%</span>
      </div>
      <!-- TODO: Temporarily disabled
            <div class="d-flex justify-center text-weight-400">
            <span>Último registo: 11:00</span>
        </div> -->
    </div>
    <div
      class="content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1"
      (click)="graphicPressure(dataSource)"
    >
      <div>
        <span class="">Presión</span>
      </div>
      <div class="content-icon-pressure">
        <sit-icon
          [icon]="Icon.sensor_w"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de flecha hacia abajo"
        ></sit-icon>
        <span class="content-text">{{ properties?.pressure }}</span>
        <span>hPa</span>
      </div>
      <!-- TODO: Temporarily disabled
            <div class="d-flex justify-center text-weight-400">
            <span>Último registo: 11:00</span>
        </div> -->
    </div>
  </div>

  <div class="d-flex fd-row border-b-s-3 border-color-gray mb-12"></div>

  <div
    class="d-flex fd-row bg-color-background-1 cont-co"
    style="height: 200px; width: 700px"
  >
    <sit-chart-line
      [properties]="dataGraphicList"
      [colorScale]="colorScale"
      style="width: 100%"
    >
    </sit-chart-line>
  </div>
</div>
