import { Component, Type } from '@angular/core';
import { CameraModel } from '@shared/models/camera.model';
import { SituationsModel } from '@shared/models/situations.model';
import { TrafficModel } from '@shared/models/traffic.model';
import { Co2Model } from '@shared/models/co2.model';
import { ModalRoadworksComponent } from '@ui-core/components/modal-roadworks/modal-roadworks.component';
import { ModalCameraComponent } from '@ui-core/components/modal-camera/modal-camera.component';
import { ModalTrafficComponent } from '@ui-core/components/modal-traffic/modal-traffic.component';
import { ModalCo2Component } from '@ui-core/components/modal-co2/modal-co2.component';
import { TrafficSpeedRangeModel } from '@shared/models/traffic-speed-range.model';
import { ModalTrafficSpeedrangeComponent } from '@ui-core/components/modal-traffic-speedrange/modal-traffic-speedrange.component';
import { TrafficConcordanceModel } from '@shared/models/traffic-concordance.model';
import { ModalTrafficConcordanceComponent } from '@ui-core/components/modal-traffic-concordance/modal-traffic-concordance.component';
import { ModalBicyclePathComponent } from '@ui-core/components/modal-bicycle-path/modal-bicycle-path.component';
import { ModalCoosComponent } from '@ui-core/components/modal-coos/modal-coos.component';
import { TrafficLightModel } from '@shared/models/traffic-light.model';
import { ModalTrafficLightComponent } from '@ui-core/components/modal-traffic-light/modal-traffic-light.component';
import { ModalAforoComponent } from '@ui-core/components/modal-aforo/modal-aforo.component';
import { AforoModel } from '@shared/models/aforo.model';
import { AgentsModel } from '@shared/models/agents.model';
import { ModalAgentsComponent } from '@ui-core/components/modal-agents/modal-agents.component';
import { ModalAgentsLocationsComponent } from '@ui-core/components/modal-agents-locations/modal-agents-locations.component';
import { ModalCranesLocationsComponent } from '@ui-core/components/modal-cranes-locations/modal-cranes-locations.component';
import { ModalVehiculesComponent } from '@ui-core/components/modal-vehicle/modal-vehicle.component';
import { ModalCranesComponent } from '@ui-core/components/modal-cranes/modal-cranes.component';
import { TrafficManagmentPlansPathModel } from '@shared/models/trafficmanagmentplans-path.model';
import { ModalTrafficManagmentComponent } from '@ui-core/components/modal-traffic-managment/modal-traffic-managment.component';
import { ModalStopBusComponent } from '@ui-core/components/modal-stop-bus/modal-stop-bus.component';
import { StopBusPathModel } from '@shared/models/stopbus-path.model';
import { ZonesPathModel } from '@shared/models/zones-path.model';
import { ModalZonesComponent } from '@ui-core/components/modal-zones/modal-zones.component';
import { ModalRoadRunComponent } from '@ui-core/components/modal-road-run/modal-road-run.component';
import { RoadRunPathModel } from '@shared/models/roadrun-path.model';
import { RuntDatexModel } from '@shared/models/runt-datex.model';
import { ModalRuntDatexComponent } from '@ui-core/components/modal-runt-datex/modal-runt-datex.component';
import { coiModel } from '@shared/models/coi.model';
import {ModalCoiComponent} from '@ui-core/components/modal-coi/modal-coi.component';
import { AgentsNumberModel } from '@shared/models/agents-number.model';
import {RoadCorridorsModel} from "@shared/models/road-corridors.model";
import { VehiclesModel } from '@shared/models/vehicles.model';
import { coosLineModel } from '@shared/models/coosLine-model';
import { ModalCoosLineComponent } from '@ui-core/components/modal-coos-line/modal-coos-line.component';
import { CoosModel } from '@shared/models/coos.model';
import {TrafficJamWazeModel} from "@shared/models/traffic-jams-waze.model";
import {ModalTrafficJamComponent} from "@ui-core/components/modal-traffic-jam/modal-traffic-jam.component";
import { coilineModel } from '@shared/models/coiline.model';
import { ModalCoilineComponent } from '@ui-core/components/modal-coi-line/modal-coi-line.component';
import { ExodusAndReturnWazeModel } from '@shared/models/exodus-and-return-waze.model';
import {
  ModalRoadCorridorsComponentComponent
} from "@ui-core/components/modal-road-corridors-component/modal-road-corridors-component.component";
import {ModalWazeComponent} from "@ui-core/components/modal-waze/modal-waze.component";
import {WazeDataTrafficModel} from "@shared/models/WazeDataTrafficModel";
import {AlertsDuplicatesWazeModel, AlertsWazeModel} from "@shared/models/alerts-waze-model";
import {WazeAlertsModalComponent} from "@ui-core/components/waze-alerts-modal/waze-alerts-modal.component";
import { CranesModel } from '@shared/models/cranes.model';
import { CranesNumberModel } from '@shared/models/cranes-number.model';
import { IncidentsLocationsModel } from '@shared/models/LocationsIncidents.model';
import { ModalIncidentsComponent } from '@ui-core/components/modal-incidents/modal-incidents.component';
import { LastHoursModel } from '@shared/models/LastHoursIncidents.model';
import {FieldResourcesLocationsModel} from "@shared/models/field-resources-model";
import {ModalFieldResourcesComponent} from "@ui-core/components/modal-field-resources/modal-field-resources.component";
import {IncidentsFieldResourcesModel} from "@shared/models/incidents-field-resources-model";
import {
  ModalIncidentsFieldResourcesComponent
} from "@ui-core/components/modal-incidents-field-resources/modal-incidents-field-resources.component";
import { ModalIncidentsResourceComponent } from '@ui-core/components/modal-incidents-resource/modal-incidents-resource.component';
import {BikesModel} from "@shared/models/Bikes.model";
import {ModalBikesComponent} from "@ui-core/components/modal-bikes/modal-bikes.component";
import { WazeAlertsDuplicatesModalComponent } from "@ui-core/components/waze-alerts-duplicates-modal/waze-alerts-duplicates-modal.component";
import { incidentAddResourcesModel } from '@shared/models/incidentAddResources.model';
import { ModalAddResourcesComponent } from '@ui-core/components/modal-add-resources/modal-add-resources.component';
import {AirQualityModel} from "@shared/models/air_quality/AirQuality.model";
import {AirQualityModalComponent} from "@ui-core/components/air-quality-modal/air-quality-modal.component";

const incidentsResourcesFn = (modals: {
  incidentsResourceModal: Type<unknown>,
  fieldResourceModal: Type<unknown>
}) => (model: IncidentsFieldResourcesModel): Type<unknown>  => {  
  if (model?.classProperties?.type === "incident") {
    return modals.incidentsResourceModal;
  } else {
    return modals.fieldResourceModal;
  }  
}

export const markerModalFunctionMap: { [key: string]: (data: unknown) => Type<unknown>  } = {
  [IncidentsFieldResourcesModel.className]: (data: any) => incidentsResourcesFn({
    incidentsResourceModal: ModalIncidentsFieldResourcesComponent,
    fieldResourceModal: ModalIncidentsResourceComponent
  })(data),
};

export const markerModalMap: { [key: string]: Type<unknown> | unknown  } = {  
  [AforoModel.className]: ModalAforoComponent,
  [AgentsModel.className]: ModalAgentsComponent,
  [AgentsNumberModel.className]: ModalAgentsLocationsComponent,
  [AlertsWazeModel.className]: WazeAlertsModalComponent,
  [BikesModel.className]: ModalBikesComponent,
  [CameraModel.className]: ModalCameraComponent,
  [Co2Model.className]: ModalCo2Component,
  [coilineModel.className]: ModalCoilineComponent,
  [coiModel.className]: ModalCoiComponent,
  [coosLineModel.className]: ModalCoosLineComponent,
  [CoosModel.className]: ModalCoosComponent,
  [CranesModel.className]: ModalCranesComponent,
  [CranesNumberModel.className]: ModalCranesLocationsComponent,
  [FieldResourcesLocationsModel.className]: ModalFieldResourcesComponent,
  [incidentAddResourcesModel.className]: ModalAddResourcesComponent,
  [IncidentsFieldResourcesModel.className]: markerModalFunctionMap,
  [IncidentsLocationsModel.className]: ModalIncidentsComponent,
  [LastHoursModel.className]: ModalIncidentsComponent,
  [FieldResourcesLocationsModel.className]: ModalFieldResourcesComponent,
  [IncidentsFieldResourcesModel.className]: markerModalFunctionMap,
  [BikesModel.className]: ModalBikesComponent,
  [AlertsDuplicatesWazeModel.className]: WazeAlertsDuplicatesModalComponent,
  [RoadCorridorsModel.className]: ModalRoadCorridorsComponentComponent,
  [RoadRunPathModel.className]: ModalRoadRunComponent,
  [RuntDatexModel.className]: ModalRuntDatexComponent,
  [SituationsModel.className]: ModalRoadworksComponent,
  [StopBusPathModel.className]: ModalStopBusComponent,
  [TrafficConcordanceModel.className]: ModalTrafficComponent,
  [TrafficJamWazeModel.className]: ModalTrafficJamComponent,
  [TrafficLightModel.className]: ModalTrafficLightComponent,
  [TrafficManagmentPlansPathModel.className]: ModalTrafficManagmentComponent,
  [TrafficModel.className]: ModalTrafficComponent,
  [TrafficSpeedRangeModel.className]: ModalTrafficComponent,
  [VehiclesModel.className]: ModalVehiculesComponent,
  [WazeDataTrafficModel.className]: ModalWazeComponent,
  [ZonesPathModel.className]: ModalZonesComponent,

  // Air Quality
  [AirQualityModel.className]             : AirQualityModalComponent,
};



