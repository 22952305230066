import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsCo2Model {
  id: string;
  title: string;
  markerPopupText: string;
  location: string;
  carbonDioxide: string;
  temperature: string;
  humidity: string;
  pressure: string;
  icon: Icon;
}

export class Co2Model extends MarkerModelBase {
  static className = 'Co2Model';
  classProperties: IPropsCo2Model;

  constructor(props: IPropsCo2Model, geometry: IGeometryModel) {
    super(geometry, Co2Model.className);
    this.classProperties = props;
  }
}
