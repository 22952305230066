import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  accidentSeverity,
  accidentType,
  accidentSector,
  ICardAccidentModel,
} from '@types-custom/models/ui/card-accident.model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';

@Component({
  selector: 'panel-accident',
  templateUrl: './panel-accident.component.html',
  styleUrls: ['./panel-accident.component.scss'],
})
export class PanelAccidentComponent implements OnInit {
  Icon = Icon;
  cards: ICardAccidentModel[] = [];
  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];
  totalAccidents = 0;
  accidentAddress: string;
  accidentSector: string;
  accidents: ICardAccidentModel[];
  @Input() serviceDataSource: any;

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.accidents = this.cards.map((e: any) => {
      return {
        ...e,
        GRAVEDAD: accidentSeverity[e.GRAVEDAD],
        TIPO_ACCIDENTE: accidentType[e.TIPO_ACCIDENTE],
        SECTOR: accidentSector[e.SECTOR],
      };
    });
    this.totalAccidents = this.accidents.length;
    this.accidentAddress = this.accidents[0].DIRECCION;
    this.accidentSector = this.accidents[0].SECTOR;
  }
}
