import { IBaseMenu } from '@types-custom/models/ui/menu.model';

export const filterMenuBasedOnRoles = (
  menu: IBaseMenu[],
  roles: string[]
): IBaseMenu[] => {
  if (!roles) return [];
  return menu.filter((item) => {
    if (!item.requiredRole) {
      return true;
    }

    return item.requiredRole.some((role) => roles.includes(role.toUpperCase()));
  });
};
