<div class="bg-color-background-1 d-flex">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
    <sit-icon alt="Volver" [icon]="Icon.arrow_backward_w" (click)="handleConfirmation()" class="text-blue-1 ml-28"
      [sizeClass]="'icon-size-24'"></sit-icon>
    <h3
      class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0">
      PROGRAMACION MENSAJE
    </h3>
  </div>
</div>



<div class="bodymessage">
  <form [formGroup]="pageForm">
    <div class="d-flex fd-col">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <mat-form-field class="textWhite agroupinput" appearance="outline" [ngClass]="{
            'error-field':
            pageForm.get('date').hasError('required') && pageForm.get('date').touched
          }">
          <mat-label class="text-white">Fecha Inicial</mat-label>
          <input matInput formControlName="date" type="date" />
        </mat-form-field>
        <mat-form-field style="width: 10vw" class="textWhite" appearance="outline" [ngClass]="{
            'error-field':
            pageForm.get('time').hasError('required') &&  pageForm.get('time').touched
          }">
          >
          <mat-label class="text-white">Hora Inicial</mat-label>
          <input matInput formControlName="time" type="time" />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex fd-col" *ngIf="messages">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
            'error-field': pageForm.get('message').hasError('required') && pageForm.get('message').touched
          }">
          <mat-label class="text-white">Mensage a programar</mat-label>
          <mat-select formControlName="message">
            <mat-option *ngFor="let item of messages.data" [value]="item.messageNumber">
              {{ item.messageNumber }}. &nbsp; {{ item.message }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="modal-actions d-flex align-items-end justify-end pt-30">
      <sit-button (click)="handleConfirmation()" [buttonProperties]="cancelButtonProps" class="width-49"></sit-button>
      <sit-button (click)="pageForm.valid ? handleSendForm() : validateFormGroup()" [disabled]="!pageForm.valid"
        [buttonProperties]="sendButtonProps" class="width-49 ml-16"></sit-button>
    </div>
  </form>
</div>