import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsRuntDatexModel {
  id: string;
  address: string;
  icon: Icon;
  title: string;
  markerPopupText: string;
}

export class RuntDatexModel extends MarkerModelBase {
  static className = 'RuntDatexModel';

  classProperties: IPropsRuntDatexModel;

  constructor(props: IPropsRuntDatexModel, geometry: IGeometryModel) {
    super(geometry, RuntDatexModel.className);
    this.classProperties = props;
  }
}
