import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IModalRuntDatexModel,
  IRuntIndicator,
  IRuntModalCameraResponse,
  IRuntPlatesModel,
  getRuntIndicators,
} from '@types-custom/models/ui/modal-runt-datex.model';
@Component({
  selector: 'ui-modal-runt-datex',
  templateUrl: './modal-runt-datex.component.html',
  styleUrls: ['./modal-runt-datex.component.scss'],
})
export class ModalRuntDatexComponent implements OnInit {
  @Input() properties: IModalRuntDatexModel | undefined;
  Icon = Icon;
  runtIndicators: IRuntIndicator[] = [];
  platesGrid: IRuntPlatesModel[] = [];

  ngOnInit(): void {
    this.properties.runtDatexDataSource
      .getModalCameraRunt(this.properties.id)
      .subscribe((res: IRuntModalCameraResponse) => {
        this.runtIndicators = getRuntIndicators(res.indicators);
        this.setPlatesGrid(res.lastPlatesRegister);
      });
  }

  private setPlatesGrid(plates: IRuntPlatesModel[]) {
    this.platesGrid = plates;
  }
}
