import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardAgentsModel } from '@types-custom/models/ui/card-agents-model';
import { ICardCranesModel } from '@types-custom/models/ui/card-cranes-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel, ITimeSeriesGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { ModalAgentsLocationsComponent } from '../modal-agents-locations/modal-agents-locations.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { IMapViewerPropsModel } from '@types-custom/models/ui/map-viewer-model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CranesService } from '@shared/services/layer-service/cranes-service/cranes.service';
import { ModalCranesLocationsComponent } from '../modal-cranes-locations/modal-cranes-locations.component';

@Component({
  selector: "panel-cranes",
  templateUrl: "./panel-cranes.component.html",
  styleUrls: ["./panel-cranes.component.scss"],
})
export class PanelCranesComponent implements OnInit {
  Icon = Icon;
  cards: ICardCranesModel[] = [];
  dataGraphicList:ITimeSeriesGraphicModel ;
  colorScale: any = [{}];
  totalAgents = 0;
  totalAgentsActive = 0;
  totalTransitAgents = 0;
  @Input() serviceDataSource: any;
  locations: any[] = [];
  props: IMapViewerPropsModel<any, any> = {
    containerId: 'map',
    // Mappers
    layersDataMap: {},
    menuRightPanelMap: {},
    markerModalMap: {},
    geoModalMap: {},
    mapEventsMap: {},
    // DS
    dataSource: {
      getAPIList: (listType: any): Observable<any[]> => of([]),
    },
    // LeftMenu
    leftMenuEvents$: new Subject<any | undefined>(),
    iconInfo: [],
    logoText: undefined,
    // Conventions Component
    mapConventionsProps: undefined,
    // Footer
    currentLayer$: new BehaviorSubject<any | undefined>(undefined),
    footerDynamicEvents$: new Subject<any | undefined>(),
    footerStaticEvents$: new Subject<any | undefined>(),
    footerModel: [],
    refreshDispatcher: undefined,
    mapFilter: undefined,
    mapIcons: [],
    markerRightPanelMap: {},
    autoUpdatedLayers: [],
  };

  constructor(private drawerPanelService: DrawerPanelService,private agentsService: AgentsService,
    private cranesService: CranesService,
    private modalService: ModalService) {}

  ngOnInit(): void {
    //console.log(this.cards.length);

    let totalAgent = this.agentsService.getAllAgents();

    this.cranesService.getAllCranes().subscribe((res: any) => {
      //console.log(res);
      this.totalAgents = res.count;
    });

    // this.totalAgents = this.cards.length ?? 0;
    this.totalAgentsActive = this.cards.length ?? 0;
    
    this.insertDataLocations();

    this.cranesService.getMeasurementCranes().subscribe((res: any) => {
      this.setGraphicAgents(res);
    });
  }

  insertDataLocations(): void{
    this.cranesService.getCranesNumberGeoJSON().pipe().subscribe((cranes:any)=>{
      this.cranesService.getAgentsLocation().pipe().subscribe((list:any)=>{
        
        list.features.forEach((item:any) => {
          item.properties.Qty = (cranes.features.filter((e:any) => {
            return e.properties["locality"] == item.properties['LocNombre'];
          })).length  ;
          item.properties.density = Number(item.properties.Qty*1000000 / item.properties.LocArea );
        });

        this.locations = list.features;
        //console.log(this.locations);
      })
    });
  }

  openModal(card: any): void {
    card.title = card.LocNombre;
    card.icon= Icon.icon_crane;
    const toRender: any = {
      component: ModalCranesLocationsComponent,
      data: {properties:card}
    };

    this.modalService.addModal(toRender);
    
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }

  setGraphicAgents(data: any): void {
    //console.log(data);

    this.dataGraphicList = {
        data: data,
        label: ['GANCHO EXTRA PESADO','GANCHO LIVIANO','GANCHO PESADO','PLANCHON','PLANCHON DE MOTOS'],
        labelX: 'Horas',
        labelY: 'Grúas activas', 
        colorLine:['#04ADC4','#4C60C6','#5B379F','#EA5222','#EB9008']
      };
      this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#92FDF6' }];  
  }
}
