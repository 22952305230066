import { Component, Input } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
** Modal Error
** Render an Error Modal that displays an error message.
*/
@Component({
  selector: 'citizen-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
  @Input() errorMessage = 'No se pudo realizar la operación';
  cancelIcon = Icon.alert_circle_red;
}
