import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormControl} from "@angular/forms";
import {Icon} from "@types-custom/models/ui/icon-model";
import {IncidentsService} from "@shared/services/layer-service/incidents-service/incidents.service";
import {GeoApiService} from "@shared/services/geo-api-service/geo-api.service";
import {DatePipe} from "@angular/common";
import {GeoAPITypeEnum} from "@shared/models/geo-api-type.model";
import {GeometryTypeEnum, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {IPredictiveResourcesPanelMapper} from "@shared/utils/mappers/menu-right-panel-map";
import {ResourcesPredictiveModelLocationsModel} from "@shared/models/ResourcesPredictiveModelLocations.model";
import {ICardPredictiveResourcesModel} from "@types-custom/models/ui/card-predictiveresources.model";
import {PredictiveService} from "@shared/services/predictive-service/predictive.service";

@Component({
  selector: "sit-predictive-resource-filter",
  templateUrl: "./predictive-resource-filter.component.html",
  styleUrls: ["./predictive-resource-filter.component.scss"],
})
export class PredictiveResourceFilterComponent implements OnInit{
  @Input() properties: any;

  resourceControl = new FormControl('');
  locationNameControl = new FormControl('');
  daysNameControl = new FormControl('');
  scheduleNameControl = new FormControl('');

  locations : any[] = [];
  days      : any[] = [];
  schedule  : any[] = [];

  ResourcesGroupTypeList : any[] = []

  constructor(
      private predictiveService: PredictiveService,
      private geoApiService: GeoApiService,
      private datePipe:DatePipe,
  ) {}

  ngOnInit() {
    this.init()
  }

  displayFn(alert: any): string {
    return alert && alert ? alert : '';
  }

  displayDay(alert: any): string {
    return alert && alert ? alert.DayName : '';
  }

  displaySch(alert: any): string {
    return alert && alert ? alert : '';
  }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const PredictiveResource = response;
    console.log('predict',PredictiveResource)

    PredictiveResource.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        className : (mark as any).className,
        
      };
      console.log('at predict type',feature )
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    const cards = points.dataFeatures.map((ele: any): ICardPredictiveResourcesModel => {
      return {
        id                : ele.properties.id,
        agentId           : ele.properties.agentId,
        groupName         : ele.properties.groupName,
        day               : ele.properties.day,
        fileName          : ele.properties.fileName,
        groupId           : ele.properties.groupId,
        hash              : ele.properties.hash,
        hour              : ele.properties.hour,
        processDate       : ele.properties.processDate,
        localityId        : ele.properties.localityId,
        localityName      : ele.properties.localityName,
        type              : ele.properties.type,
      };
    })

    //this.predictiveService.setCards(cards)

    this.properties.mapFilterDispatcher$?.next({
      layer: this.properties.layer,
      filter: undefined,
      source: points.dataFeatures
    });

    document.querySelector<HTMLElement>('#closeFilter').click();
    setTimeout(() => {
      document.querySelector<HTMLElement>('#closeFilter').parentElement.classList.add('applied-filter');
      document.querySelector('.marker-active-situations-layer')?.classList.remove('marker-active-situations-layer');
    }, 10);
  }

  applyFilter() {
    const url = this.generateURL();

    this.geoApiService.getPredictiveResourcesLocationsModel(GeoAPITypeEnum.IncidentsHeatMap, true, url)
        .subscribe({
          next: this.reloadData.bind(this),
        });
  }

  private init() {
    this.loadDataLocations()
    this.loadDataDays()
    this.loadDataSchedule()
    this.ResourcesGroupTypeList = [
      {
        name : "Todos",
        value : "ALL"
      },
      {
        name : "Agentes",
        value : "AGENTS"
      },
      {
        name : "Grúas",
        value : "CRANES"
      },
    ]
  }

  private loadDataLocations(){
    this.locations = [
      {
        "LocNombre": "ANTONIO NARIÑO"
      },
      {
        "LocNombre": "BARRIOS UNIDOS"
      },
      {
        "LocNombre": "BOSA"
      },
      {
        "LocNombre": "CANDELARIA"
      },
      {
        "LocNombre": "CHAPINERO"
      },
      {
        "LocNombre": "CIUDAD BOLÍVAR"
      },
      {
        "LocNombre": "ENGATIVÁ"
      },
      {
        "LocNombre": "FONTIBÓN"
      },
      {
        "LocNombre": "KENNEDY"
      },
      {
        "LocNombre": "LOS MÁRTIRES"
      },
      {
        "LocNombre": "PUENTE ARANDA"
      },
      {
        "LocNombre": "RAFAEL URIBE URIBE"
      },
      {
        "LocNombre": "SAN CRISTÓBAL"
      },
      {
        "LocNombre": "SANTA FE"
      },
      {
        "LocNombre": "SUBA"
      },
      {
        "LocNombre": "SUMAPAZ"
      },
      {
        "LocNombre": "TEUSAQUILLO"
      },
      {
        "LocNombre": "TUNJUELITO"
      },
      {
        "LocNombre": "USAQUÉN"
      },

      {
        "LocNombre": "USME"
      }
    ];
  }

  private loadDataDays(){
    this.days = [
      {
        "DayName" : "Domingo",
        "value"   : "sunday"
      },
      {
        "DayName" : "Lunes",
        "value"   : "monday"
      },
      {
        "DayName" : "Martes",
        "value"   : "tuesday"
      },
      {
        "DayName" : "Miercoles",
        "value"   : "wednesday"
      },
      {
        "DayName" : "Jueves",
        "value"   : "thursday"
      },
      {
        "DayName" : "Viernes",
        "value"   : "friday"
      },
      {
        "DayName" : "Sabado",
        "value"   : "saturday"
      },
    ]
  }

  private loadDataSchedule() {
    this.schedule = [
      {
        "hour"  : "00:00-01:00"
      },
      {
        "hour"  : "01:00-02:00"
      },
      {
        "hour"  : "02:00-03:00"
      },
      {
        "hour"  : "03:00-04:00"
      },
      {
        "hour"  : "04:00-05:00"
      },
      {
        "hour"  : "05:00-06:00"
      },
      {
        "hour"  : "06:00-07:00"
      },
      {
        "hour"  : "07:00-08:00"
      },
      {
        "hour"  : "08:00-09:00"
      },
      {
        "hour"  : "09:00-10:00"
      },
      {
        "hour"  : "10:00-11:00"
      },
      {
        "hour"  : "11:00-12:00"
      },
      {
        "hour"  : "12:00-13:00"
      },
      {
        "hour"  : "13:00-14:00"
      },
      {
        "hour"  : "14:00-15:00"
      },
      {
        "hour"  : "15:00-16:00"
      },
      {
        "hour"  : "16:00-17:00"
      },
      {
        "hour"  : "17:00-18:00"
      },
      {
        "hour"  : "18:00-19:00"
      },
      {
        "hour"  : "19:00-20:00"
      },
      {
        "hour"  : "20:00-21:00"
      },
      {
        "hour"  : "21:00-22:00"
      },
      {
        "hour"  : "22:00-23:00"
      },
      {
        "hour"  : "23:00-00:00"
      },
    ]
  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }

  protected readonly Icon = Icon;

  private generateURL() {

    const days  : string[] = ["sunday","monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    const hoursOfDay: string[] = [
      "00:00-01:00", "01:00-02:00", "02:00-03:00", "03:00-04:00",
      "04:00-05:00", "05:00-06:00", "06:00-07:00", "07:00-08:00",
      "08:00-09:00", "09:00-10:00", "10:00-11:00", "11:00-12:00",
      "12:00-13:00", "13:00-14:00", "14:00-15:00", "15:00-16:00",
      "16:00-17:00", "17:00-18:00", "18:00-19:00", "19:00-20:00",
      "20:00-21:00", "21:00-22:00", "22:00-23:00", "23:00-00:00"
    ];

    let url = this.resourceControl.value ? `type=${this.resourceControl.value}` : 'type=ALL';
    url += this.locationNameControl.value? `&localityName=${this.locationNameControl.value}` : ''
    // @ts-ignore
    url += this.daysNameControl.value ? `&day=${this.daysNameControl.value['value']}` : `&day=${days[new Date().getDay()]}`;
    url += this.scheduleNameControl.value ? `&hour=${this.scheduleNameControl.value}` : `&hour=${hoursOfDay[new Date().getHours()]}`;

    return url;

  }
}
