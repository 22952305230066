<ng-container *ngIf="multiple">
    <mat-chip-listbox (change)="onSelectionChange($event)" multiple>
        <ng-container *ngFor="let item of _dataSource$ | async as items">
            <mat-chip-option [value]="item.value">{{ item.label }} </mat-chip-option>
        </ng-container>
    </mat-chip-listbox>
</ng-container>

<ng-container *ngIf="!multiple">
    <mat-chip-listbox (change)="onSelectionChange($event)">
        <ng-container *ngFor="let item of _dataSource$ | async as items">
            <mat-chip-option [value]="item.value">{{ item.label }} </mat-chip-option>
        </ng-container>
    </mat-chip-listbox>
</ng-container>

