import { Component, OnInit, Input } from '@angular/core';
import { ICardAforoModel } from '@types-custom/models/ui/card-aforo-model';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ISpeedometerModel } from '@types-custom/models/ui/speedometer-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';

@Component({
  selector: 'panel-aforo',
  templateUrl: './panel-aforo.component.html',
  styleUrls: ['./panel-aforo.component.scss'],
})
export class PanelAforoComponent implements OnInit {
  @Input() cards: ICardAforoModel[] = [];
  @Input() serviceDataSource: any;

  Icon = Icon;

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    value: 0,
  };

  carsFlowIndicator = 0;
  flowCountdataGraphicList: ITimeLineGraphicModel;
  flowCountcolorScale: any = [{}];
  avgSpeeddataGraphicList: ITimeLineGraphicModel;
  avgSpeedcolorScale: any = [{}];

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.serviceDataSource.getPanelAforoMeasurements().subscribe((res: any) => {
      this.setPanelData(res);
    });
  }

  setPanelData(response: any): void {
    const filteredGrid = response.grid.filter((item: any) => {
      return this.cards.some((card) => card.id === item.cameraId);
    });
    this.setAforoIndicators(filteredGrid);
    this.graphicFlowCountChartLine(response.avgCountAndSpeed);
    this.graphicAvgSpeedChartLine(response.avgCountAndSpeed);
    this.setRuntGrid(response.grid);
  }
  graphicFlowCountChartLine(dataChartLine: any[]): void {
    const dataGraphic = dataChartLine.map((element: any) => {
      return {
        time: element.time,
        value: element.count,
      };
    });
    this.flowCountdataGraphicList = {
      data: dataGraphic,
      label: '',
      labelX: 'Hora',
      labelY: 'Conteo de Vehículos',
    };
    this.flowCountcolorScale = [
      { minValue: 0, maxValue: 10000, color: '#FF9901' },
    ];
  }

  graphicAvgSpeedChartLine(dataChartLine: any[]): void {
    const dataGraphic = dataChartLine.map((element: any) => {
      return {
        time: element.time,
        value: element.speed,
      };
    });
    this.avgSpeeddataGraphicList = {
      data: dataGraphic,
      label: 'Km/h',
      labelX: 'Hora',
      labelY: 'Velocidad media Km/h',
    };
    this.avgSpeedcolorScale = [
      { minValue: 0, maxValue: 10000, color: '#FF9901' },
    ];
  }

  setAforoIndicators(indicators: any) {
    let sumAvgSpeed = 0;
    let sumFlowCount = 0;
    let count = 0;

    for (const indicator of indicators) {
      if (!isNaN(+indicator.totalCount)) {
        sumFlowCount += +indicator.totalCount;
      }

      if (!isNaN(+indicator.avgSpeed) && indicator.avgSpeed !== 0) {
        sumAvgSpeed += +indicator.avgSpeed;
        count++;
      }
    }

    this.avgSpeedProps.value = count ? Math.round(sumAvgSpeed / count) : 0;
    this.carsFlowIndicator = sumFlowCount;
  }

  private setRuntGrid(data: any) {
    this.cards.map((card) => {
      data.map((item: any) => {
        if (card.id === item.cameraId) {
          card.avgSpeed = item.avgSpeed;
          card.flowCount = item.totalCount;
        }
      });
    });
  }

  openModal(card: any): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }
}
