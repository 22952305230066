import { OnInit } from '@angular/core';
import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  PLATFORM_ID,
  Inject,
  NgZone,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/amchartsdark';
import {
  ITimeSeriesGraphicModel,
  IPointOnTimeLine,
} from '@types-custom/models/ui/chart-line-color-model';

import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'sit-chart-series',
  templateUrl: './sit-chart-series.component.html',
  styleUrls: ['./sit-chart-series.component.scss']
})
export class SitChartSeriesComponent
implements OnDestroy, AfterViewInit, OnChanges
{

  @ViewChild('lineColor') lineColor!: ElementRef;
  @Input() properties: ITimeSeriesGraphicModel;
  colorscaleDefault = [
    { minValue: 0, maxValue: 10, color: '#00C479' },
    { minValue: 10, maxValue: 20, color: '#FF9901' },
    { minValue: 20, maxValue: 30, color: '#FF3232' },
  ];
  @Input() colorScale = this.colorscaleDefault;
  private chart!: am4charts.XYChart;

  constructor(
  @Inject(PLATFORM_ID) private platformId: any,
  private zone: NgZone
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.properties.currentValue) {
      this.drawgraphic(this.properties);
    }
  }

  ngAfterViewInit(): void {
    if (this.properties) {
      this.drawgraphic(this.properties);
    }
  }

  drawgraphic(data:any){

    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_kelly);
    const chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.data = data.data;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "hour";
    categoryAxis.title.text = data.labelX;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.title.fill = am4core.color('#ffffff');

    const  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = data.labelY;
    valueAxis.title.fill = am4core.color('#FFFFFF');
    
    for (let i = 0; i < (data.label).length; i++) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = data.label[i];
      series.dataFields.categoryX = "hour";
      series.name = data.label[i];
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.strokeWidth = 2;
      series.hidden = false
      series.stroke = am4core.color(`${data.colorLine[i]}`);
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(`${data.colorLine[i]}`);
    }

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    this.chart = chart;
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
