<div class="form-container pr-16 pl-16 mb-32">

    <!-- <h2 class="text-white"> Crear parámetro</h2> -->
    <form [formGroup]="createFormGroup" class="form-container-body custom-scrollbar pb-1-rem d-flex fd-col justify-space-evenly">
        <div class="mt-2 d-flex fd-col">
            <mat-label class="text-button-clean-filter text-size-20 pb-10 text-weight-600">{{category.name}}</mat-label>
            <ng-container *ngIf="subcategories && subcategories.length > 0; else noItems">
                <mat-chip-listbox formControlName="subcategoryDatexIds" multiple>
                    <mat-chip-option *ngFor="let subcat of subcategories" [value]="subcat.id" selected="{{subcat.active}}">{{ subcat.subcategoryName }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </ng-container>
            <ng-template #noItems>
                <mat-label class="text-white">
                    No hay subcategorías disponibles para la categoría seleccionada.
                </mat-label>
            </ng-template>
        </div>
    </form>
    <div class="d-flex fd-row justify-end pt-10">
        <sit-button (click)="handleCancel()" [buttonProperties]="cancelButtonProps" class="width-49"></sit-button>
        <sit-button (click)="createFormGroup.valid ? handleSendForm() : validateFormGroup()"
            [disabled]="!createFormGroup.valid" [buttonProperties]="sendButtonProps"
            class="width-49 ml-16"></sit-button>
    </div>
</div>