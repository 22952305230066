<div class="container">
    <div class="text-blue-1 text-weight-700 sit-card" *ngFor="let card of cards" (click)="openModal(card)">
        <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-18 text-weight-700">
            <span *ngIf="card.orientation == 'Entrando'"> {{ card?.corridor }}</span>
            <span *ngIf="card.orientation == 'Saliendo'" class="mt-1-em"></span>
        </div>
        <div class="tittleGraphic">
            <span class="text-weight-400 text-size-14 text-md-size-16 text-white align-self-center">{{ card?.orientation}}</span>
        </div>
        <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
            <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
                <div class="container-graphic-speed">
                    <sit-speedometer [properties]="card.avgSpeedProps"></sit-speedometer>
                </div>
            </div>

    
            <div class="d-flex fd-col mt-1-em mb-1 align-items-center">
                <sit-icon
                [icon]="Icon.speed_exodus_return"
                alt="gorra agente"
                [sizeClass]="'icon-size-48'"
              ></sit-icon>
              <span class="text-white text-size-20 text-weight-600">{{ card?.timeMinutes}} min</span>
              <span class="text-white text-size-12 text-weight-400 mt-05-em">Tiempo <br> recorrido</span>
            </div>
          </div>
    </div>
    
    <!-- <div class="text-blue-1 text-weight-700 sit-card" (click)="openModal()">
        <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-18 text-weight-700">
            <span> {{ card?.corridor }}</span>
        </div>
        <div class="tittleGraphic mt-1-em">
            <span class="text-weight-400 text-size-14 text-md-size-16 text-color-white align-self-center">{{ card?.orientation}}</span>
        </div>
        <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
            <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
                <div class="container-graphic-speed">
                    <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
                </div>
            </div>
            
    
            <div class="d-flex fd-col mt-1-em mb-1 align-items-center">
                <sit-icon
                [icon]="Icon.speed_green"
                alt="gorra agente"
                [sizeClass]="'icon-size-48'"
              ></sit-icon>
              <span class="text-white text-size-18 text-weight-600">{{ card?.timeMinutes}} min</span>
              <span class="text-white text-size-12 text-weight-600 mt-05-em">Tiempo recorrido</span>
            </div>
        </div>
    </div> -->
</div>
