import {
    CardMapLinkModel,
    IGeometryLineModel,
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsTrafficJamWazeModel {
    id: string;
    uuid: string;
    tid: string,
    icon: Icon;
    street : string,
    speedkmh: number;
    length0: string;
    delay: string;
    level : string,
    receptiontime: string,
    pubtime : string;
    updpubmilis : string;
}

export class TrafficJamWazeModel extends MarkerModelBase {
    static className = 'TrafficJamWazeModel';
    classProperties: IPropsTrafficJamWazeModel;

    constructor(
        props: IPropsTrafficJamWazeModel,
        geometry: IGeometryLineModel | IGeometryModel,
        lat?: number,
        lng?: number
    ) {
        super(geometry, TrafficJamWazeModel.className, lat, lng);
        this.classProperties = props;
        this.geometry = geometry;
    }
}