import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { generateHeaderModel } from '@official/models/constants/header';
import { IMenuApiModel } from '@official/models/menu-api.model';
import { IHeaderModel } from '@types-custom/models/ui/header.model';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';

@Injectable(
  {
    providedIn: 'root',
  }
)
export class MenuService {
  menuInfo: IMenuApiModel[];
  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private localStorageManagementService: LocalStorageManagementService
  ) { }

  isSideMenuVisible$ = new BehaviorSubject<boolean>(false);

  panel$ = new BehaviorSubject<MapEventTypeEnum>(MapEventTypeEnum.VOID);
  headerMenu$ = new BehaviorSubject<any>(undefined);

  private getMenuItems(): Observable<IMenuApiModel[]> {
    if (this.localStorageManagementService.getUserLocalStorage()) {
      return (
        this.httpClient
          // .get<IMenuApiModel[]>(`assets/mock/menu.json`)
          .get<IMenuApiModel[]>(`${this.environment.authUrl}/menu/`)
          .pipe(
            map((response: IMenuApiModel[]) =>
              response.sort((a: IMenuApiModel, b: IMenuApiModel) => b.order - a.order)
            ),
            tap((response: IMenuApiModel[]) => (this.menuInfo = response))
          )
      );
    } else {
      return of([]);
    }
  }

  public getHeaderModel(
    dispatcher: BehaviorSubject<any | undefined>
  ): Observable<IHeaderModel<any>> {
    let accountTextInline = '';
    this.localStorageManagementService.userInfo$.subscribe(
      (res) => (accountTextInline = res?.names)
    );
    return this.getMenuItems().pipe(
      map((response) =>
        generateHeaderModel(dispatcher, response, accountTextInline)
      )
    );
  }

  public toggleSideMenu(isSideMenuVisible: boolean): void {
    this.isSideMenuVisible$.next(isSideMenuVisible);
  }

  handleMenuPanelActions(value?: MapEventTypeEnum): void {
    this.panel$.next(value);
  }
}
