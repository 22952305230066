
import {
    IGeometryModel,
    MarkerModelBase,
  } from '@types-custom/models/business/marker.model';
import { Icon } from "@types-custom/models/ui/icon-model";
  
 export interface IcoilineProp {
    id:string;
    location:string;
    title:string;
    icon:Icon;
    alt:string;
    observaciones: string;
    contratista: string;
    fini: string;
    ffin: string;
    direccion: string;
    tcoi: string;
    htra: string;
    hcie: string;
    markerPopupText: string;
  }
  
 export class coilineModel extends MarkerModelBase {
    static className = 'Coiline';
    classProperties: IcoilineProp;
  
    constructor(props: IcoilineProp, geometry: IGeometryModel) {
      super(geometry, coilineModel.className);
      this.classProperties = props;
    }
  }
    