import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from "@angular/forms";
import { GeoAPITypeEnum } from "@shared/models/geo-api-type.model";
import { SituationPriorityFilterEnum, SituationTypeFilterEnum } from "@shared/models/situations.model";
import { GeoApiService } from "@shared/services/geo-api-service/geo-api.service";
import { FieldResourcesService } from "@shared/services/layer-service/field-resources/field-resources.service";
import { IncidentsService } from "@shared/services/layer-service/incidents-service/incidents.service";
import { feature } from "@turf/turf";
import { GeometryTypeEnum, MarkerModelBase } from "@types-custom/models/business/marker.model";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { url } from "inspector";
import { Observable, fromEvent, map, startWith } from "rxjs";

@Component({
  selector: "sit-ground-resource-group",
  templateUrl: "./ground-resource-group.component.html",
  styleUrls: ["./ground-resource-group.component.scss"],
})
export class GroundResourceGroupComponent {
  @Input() properties: any;
  situationTypeFilterEnum = SituationTypeFilterEnum;
  situationPriorityFilterEnum = SituationPriorityFilterEnum;

  incidentTypeControl = new FormControl('');
  priorityControl = new FormControl('');
  resourceControl = new FormControl('');

  physicalResourceCode = new FormControl('');
  options: any[] = [];
  filteredOptions: Observable<any[]>;

  ResourcesGroupTypeList: any[] = [
    {
      name: "Grupo Guía",
      value: 17
    },
    {
      name: "Ciempiés",
      value: 20
    },
    {
      name: "Al Colegio En Bici",
      value: 19
    },
    {
      name: "Agentes Civiles",
      value: 18
    }
  ]

  filterCategoryOptions: string[];
  defaultDateStart = (new Date(new Date().setHours(0, 0, 0, 0))).toISOString();
  defaultDateEnd = (new Date().toISOString());

  Icon = Icon;
  incidentsTypes: [] = [];
  incidentForm: FormGroup;

  constructor(
    private incidentsService: IncidentsService,
    private geoApiService: GeoApiService,
    private datePipe: DatePipe,
    private fieldService: FieldResourcesService,
  ) { }

  ngOnInit() {
    this.setData()
    this.filteredOptions = this.physicalResourceCode.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;

        return name
          ? this._filter(name as string)
          : this.options.slice();
      })
    );

  }

  setData() {
    //get data map 
    this.options = this.properties.data[0][0].feature.features.map(
      (item: any) =>
      ({
        value: item.properties.indicative,
        id: item.properties.id,
      })
    );


  }

  // reloadData(response: any) {
  //   console.log('reloadData resposne', response)
  //   this.incidentsService.incidentFilterNotifier$.next(response);
  // }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const heatMapLayer = response[0];

    heatMapLayer.marker.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        type: 'Feature',

      };
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    let arr : any[] = []
    arr.push(heatMapLayer)

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer + '-Heat',
      filter: undefined,
      source: points.dataFeatures,
      visibility: true
    });
}

  private generateURL() {
    let url = 'format-geo-json?recordsPerPage=1000&page=0';

    if (this.physicalResourceCode.value) {
      console.log(this.physicalResourceCode.value)

      let data: any = this.physicalResourceCode.value
      url += `&indicative=${data.value}`;

      console.log(data)
    }

    if (this.resourceControl.value) {
      // @ts-ignore
      let data: any[] = this.resourceControl.value
      data.forEach((item: any) => {
        url += `&groups=${item}`
      });
    }
    url += '&hours=24';
    return url;
  }

  applyFilter() {

    const data: any = this.physicalResourceCode.value;
    const initialFilterValue: { [key: string]: any } = {
      recordsPerPage: 1000,
      page: 0,
      indicative: data?.value ? data.value : ''  ,
    };
    initialFilterValue.groups = this.resourceControl.value; //array
    const url = this.generateURL();

    this.geoApiService.getResourceHistoricalList(GeoAPITypeEnum.GroundResourceLasthours, true, url )
      .subscribe({
        next: this.reloadData.bind(this),
      });
  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }

  displayFn(human: any): string {
    return human && human.value ? human.value : '';
  }

  private _filter(value: string): string[] {
    return this.options.filter((option) =>
      option.feature.toLowerCase().includes(value.toLowerCase())
    );
  }
}
function cloneDeep(filters: any): any {
  throw new Error("Function not implemented.");
}

