<div class="text-blue-1 text-weight-700 sit-card" *ngFor="let card of cards " (click)="openModal(card)">
  <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-16 text-weight-500">
      <span> </span>
  </div>
  <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
      <div class="container-info separator-line-info indicator-container">
          <div class="element">
              <span class="title">
          
          </span>
              <span class="subject">
              Tipo
          </span>
          </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
          <div class="element">
              <span class="title">
      
          </span>
              <span class="subject">
              Subtipo
          </span>
          </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
          <div class="element">
              <span class="title">
         
          </span>
              <span class="subject">
              Fiabilidad
          </span>
          </div>
      </div>
  </div>
  <div class="d-flex footer-box">
          <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white"
                                          [sizeClass]="'icon-size-15'"
                                          alt="icono persona"></sit-icon> </span>

      <span class="d-flex"> <sit-icon [icon]="Icon.time"
                                      [sizeClass]="'icon-size-15'"
                                      alt="icono persona"></sit-icon> </span>

  </div>
</div>