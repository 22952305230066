import {IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {Icon} from "@types-custom/models/ui/icon-model";

export interface IPropsLastHoursModel {
    id: string;
    address: string,
    latitude: number,
    longitude: number,
    location_name: string,
    class_name: string,
    type_name: string,
    priority_name: string,
    source_name: string,
    state_name: string,
    create_date: string,
    gravity_name: string,
    corredor_name: string,
    description: string,
    incident_time: string,
    category_name?: string,
    count_incidents?: string,
    markerPopupText? : string,
    icon? : Icon
}


export class LastHoursModel extends MarkerModelBase {
    static className = 'LastHoursModel';
    classProperties: IPropsLastHoursModel;

    constructor(props: IPropsLastHoursModel,
       geometry: IGeometryModel ,
      lat?: number,
      lng?: number,
      ){
      super(
        geometry, LastHoursModel.className, lat, lng
        );
      this.classProperties = props;
      this.geometry = geometry;
  }
}
