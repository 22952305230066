import { Component, Input, OnInit } from '@angular/core';
import { nameManagePanel } from '@official/pages/map-viewer/models/managament-panel/name-panel';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';

import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { GeoLocationService } from '@shared/services/geo-location/geo-location.service';
import { MessageUnassignSucces } from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { MessageEditSuccess } from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { FieldResourcesService } from '@shared/services/layer-service/field-resources/field-resources.service';
@Component({
  selector: "details-incidents-panel",
  templateUrl: "./details-incidents-panel.component.html",
  styleUrls: ["./details-incidents-panel.component.scss"],
})
export class DetailsIncidentsPanelComponent  implements OnInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() gridRowData: any;
  @Input() properties: any;

  actionDataForm$ = new Subject<any>();

  Icon = Icon;
  titlePanel = '';
  messageSuccess = '';
  listResourcesHuman :any;

  constructor(
    private modalService: ModalService,
    private fieldResourcesService:FieldResourcesService,
  ) { }

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  sendButtonProps: IButtonModel = {
    label: 'CREAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  ngOnInit(): void {
    this.titlePanel = nameManagePanel[this.panelOnManagement];
    this.messageSuccess = MessageUnassignSucces[this.panelOnManagement];
  }

  ngAfterViewInit(): void {
      this.toListhumanresources(this.gridRowData)
  }

  toListhumanresources(idIncident:any){
    this.fieldResourcesService.getHumanResourcesByIncident(idIncident.id).subscribe({
      next: (data) => this.listResourcesHuman = data,
    });
  }

  deleteHumanResource(humanResourceId:any){
    this.fieldResourcesService.removeHumanResources(humanResourceId,this.gridRowData.id ).subscribe({
      next: (data) => this.handleSuccess(),
      error:(error) => console.log(error)
    });
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleSuccess(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: this.messageSuccess },
      },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
    this.serviceDataSource.init();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };

    this.modalService.confirmationModal(modal);
  }

  handleConfirmation(message?: string): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage: `¿Está seguro que desea ${message ?? 'cancelar'}?`,
        },
      },
      headerBackgroundClass: 'bg-color-background-2',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => { },
    };
    this.modalService.confirmationModal(modal);
  }
  
}
