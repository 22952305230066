import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { ModalService } from '@ui-core/services/modal/modal.service';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';

@Component({
  selector: 'modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
})
export class ModalChangePasswordComponent implements OnInit {
  @Input() properties: any;
  buttonAttributes: IButtonModel | undefined = undefined;
  signinButtonProps: IButtonModel | undefined;
  infoIcon = Icon.info;
  Icon = Icon;
  changePasswordFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();
  showCurrentPassword = false;
  showNewPassword = false;
  showNewPasswordConfirm = false;

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button_primary,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  submitButtonProps: IButtonModel = {
    label: 'ACEPTAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  constructor(private modalService: ModalService, private loginAuthService: LoginAuthService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  get currentPasswordErrorMessage(): string {
    const form: FormControl = this.changePasswordFormGroup.get(
      'currentPassword'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('minlength')
      ? 'Longitud mínima requerida (8)'
      : form.hasError('pattern')
      ? 'Contraseña no válida'
      : '';
  }

  get newPasswordErrorMessage(): string {
    const form: FormControl = this.changePasswordFormGroup.get(
      'newPassword'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('minlength')
      ? 'Longitud mínima requerida (8)'
      : form.hasError('pattern')
      ? 'Contraseña no válida'
      : '';
  }
  private initializeForm(): void {
    this.changePasswordFormGroup = this.fb.group(
      {
        currentPassword: [
          { value: '', disabled: false },
          [Validators.required, Validators.minLength(8),  Validators.pattern(/^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).*/)],
        ],
        newPassword: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).*/),
          ],
        ],
        newPasswordConfirm: [
          { value: '', disabled: false },
          [Validators.required, Validators.minLength(8)],
        ],
      },
      { validators: this.passwordMatcher }
    );
  }

  private passwordMatcher: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let errors: any = null;
    if (
      group.get('newPassword').value !== group.get('newPasswordConfirm').value
    ) {
      errors = { notMatch: true };
      group.get('newPasswordConfirm').setErrors(errors);
      return errors;
    }

    return null;
  };

  handleSendChangePassword() {
    const changePasswordParams = {
      currentPassword: this.changePasswordFormGroup.value.currentPassword,
      newPassword: this.changePasswordFormGroup.value.newPassword,
    };

    this.loginAuthService
      .changePassword(changePasswordParams)

      .subscribe(this.handleChangePassword.bind(this));
  }

  handleChangePassword(res: any) {
    if (res.status === 'PASSWORD_UPDATED') {
      this.showSuccessModal();
      return;
    }
    this.showErrorModal();
  }

  handleClose() {
    this.properties.close();
  }
  showSuccessModal() {
    this.handleClose();
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: 'La contraseña se ha modificado con éxito',
        },
      },
    };

    setTimeout(() => {
      this.properties.modalService.confirmationModal(modal);
    }, 200);
  }

  showErrorModal() {
    this.handleClose();
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: { component: ModalErrorComponent },
    };

    setTimeout(() => {
      this.properties.modalService.confirmationModal(modal);
    }, 200);
  }

  validateFormGroup(): void {
    this.changePasswordFormGroup.markAllAsTouched();
  }
  
}
