import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

export class ServiceModal {

    // $emitter = new EventEmitter();

    // emitirEvento() {
    //     this.$emitter.emit();
    // }   

    private resource$ = new Subject<any>();

    addCard(card: any) {
        //console.log(card)
        this.resource$.next(card);
    }

    getModal$(): Observable<any> {
        return this.resource$.asObservable();
    }
}
