import { Icon } from './icon-model';
export interface IButtonModel {
  preIcon?: Icon;
  label?: string;
  postIcon?: Icon;
  classAttributes?: IClassesAttributes;
}
export interface IClassesAttributes {
  buttonClass?: ButtonClassesEnum;
  iconClass?: IconClassesEnum;
  spanClass?: string;
}

export enum ButtonClassesEnum {
  white_background_button = 'white-background-button',
  white_background_b2c_form_button = 'white-background-b2c-form-button',
  blue_button = 'blue-button',
  blue_button_2 = 'blue-button-2',
  blue_button_light = 'blue-button-light',
  white_button_light = 'white_button_light',
  white_background_blue_button = 'white-background-blue-button',
  primary_1_button = 'primary-1-button',
  tertiary_button = 'tertiary-button',
  button_cancel = 'gray-outline-button',
  button_create = 'primary-1-button',
  button_resources = 'primary-2-button',
  tertiary_button_primary = 'tertiary-button-primary',
  tertiary_button_pmv = 'tertiary-button-pmv',
  complentary_2_button = 'complementary-2-button',
}

export enum IconClassesEnum {
  blue_icon = 'blue-icon',
  text_login_button = 'text-login-button',
  text_login_b2c_form_button = 'text-login-b2c-form-button',
  //sit
  text_complementary_2 = 'text-complementary-2 bttn-login font-weight-600',
  text_white = 'text-color-white bttn-citizen-login',
  text_white_primary = 'text-white bttn-forgot-login',
}

