<ng-container *ngIf="dataSource | async as data; else loading">
    <div *ngFor="let item of data; let index = index" class="card-info d-flex fd-row">
        <div class="d-flex fd-col card-left">
            <sit-icon [icon]="item['icon']" class="card-icon" [sizeClass]="'icon-size-32'"></sit-icon>
        </div>
        <div class="d-flex fd-col card-right">
            <ng-container *ngFor="let header of headers">
                <div class="card-info-card fd-col" *ngIf="!header.isArray">
                    <span [classList]="header.headerClass || ''" *ngIf="header.showLabel">{{ header.label }}</span>
                    <span [classList]="header.cellClass || ''">
                        {{ item[header.key] }}
                    </span>
                </div>
            </ng-container>
            <!-- LISTA IMÁGENES -->
            <div class="d-flex fd-row mt-10">
            <ng-container *ngFor="let img of item['images']">
                <img *ngIf="img.url" [attr.src]="img.url" alt="image" class="d-flex fd-col  card-imagen"/>
            </ng-container>
        </div>
        </div>        
    </div>
</ng-container>

<ng-template #loading>
    <sit-spinner />
</ng-template>