import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: "map-filter-cranes",
  templateUrl: "./map-filter-cranes.component.html",
  styleUrls: ["./map-filter-cranes.component.scss"],
})
export class MapFilterCranesComponent implements OnInit {
  @Input() properties: any;
  Icon = Icon;
  options: any[];
  filteredOptions: Observable<any[]>;
  crane = new FormControl<string>('');
  fb = new UntypedFormBuilder();

  ngOnInit() {
    //console.log(this.properties)
    switch (this.properties.layer) {
      case 'Cranes':
        this.options = this.properties.data[0].map((e: any) => {
          return { id: e.classProperties.id, name: e.classProperties.id };
        });
        break;
      case 'CranesHistorical':
        //console.log(this.properties.data[0]);
        this.options = [
          ...new Set(
            this.properties.data[0]
              .filter((e: any) => e.geometryType === GeometryTypeEnum.Point)[0]
              .feature.features.map((e: any) => e.properties.id)
          ),
        ]
          .sort((a: any, b: any) => a.localeCompare(b))
          .map((e: any) => {
            return { id: e, name: e };
          });

          //console.log(this.options);

        break;
    }

    this.filteredOptions = this.crane.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;

        return name
          ? this._filterOptions(name as string)
          : this.options.slice();
      })
    );
  }

  displayFn(agent: any): string {
    return agent && agent.id ? agent.id : '';
  }

  private _filterOptions(crane: string): any[] {
    return this.options.filter((option) =>
      option.name.toLowerCase().includes(crane.toLowerCase())
    );
  }


  filterCrane(crane: any): void {
    let filter: string[] = [];

    //console.log(crane);

    if (crane) {
      filter = ['==', 'id', crane.id];
    }

    //console.log(filter);

    switch (this.properties.layer) {
      case 'Cranes':
        this.properties.mapFilterDispatcher$.next({ filter: filter });
        break;
      case 'CranesHistorical':
        this.filterCranesHistorical({
          layer: this.properties.layer + '-Heat',
          filter: undefined,
          visibility: filter.length > 0 ? false : true,
        });

        this.filterCranesHistorical({
          layer: this.properties.layer,
          filter: filter,
          visibility: filter.length > 0 ? true : false,
        });

        this.filterCranesHistorical({
          layer: this.properties.layer + '-Lines',
          filter: filter,
          visibility: filter.length > 0 ? true : false,
        });
        break;
    }
  }

  filterCranesHistorical(filter: any) {
    this.properties.mapFilterDispatcher$.next(filter);
  }

  resetFilter(){
    let filter: string[] = [];
    this.properties.mapFilterDispatcher$.next({ filter: filter });
    this.crane.reset('');
  }
}
