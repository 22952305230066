import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IDocumentTypeModel,
  IUserEditModel,
  IUserManagmentModel,
  userStateList,
} from '@shared/models/user.model';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import {
  ILocationOption,
  ILocationPoint,
} from '@types-custom/models/business/location.model';
import {
  AbstractSdkDynamicFilterDataSource,
  DynamicFilterTypeEnum,
  IDynamicFilterModel,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import {
  AbstractPaginatorDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { StateIndicatorsClassesEnum } from '@types-custom/models/ui/state-indicator-model';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { IPhysicalResourcesManagmentModel } from '@shared/models/physicalResources.model';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
@Injectable({
  providedIn: 'root'
})
export class ManagePhysicalResourcesService implements
AbstractPaginatorDataSource<any>,
AbstractSdkDynamicFilterDataSource {
  states$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  statesSelected$ = new BehaviorSubject<string | undefined>(undefined);
  state$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  stateSelected$ = new BehaviorSubject<string | undefined>(undefined);
  typeResource$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  typeResourceSelected$ = new BehaviorSubject<string | undefined>(undefined);
  condition$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  conditionSelected$ = new BehaviorSubject<string | undefined>(undefined);
  asigned$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  asignedSelected$ = new BehaviorSubject<string | undefined>(undefined);
  documentType$ = new BehaviorSubject<IDocumentTypeModel[] | undefined>(
    undefined
  );
  documentTypeSelected$ = new BehaviorSubject<string | undefined>(undefined);
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  private dataSubject = new BehaviorSubject<IPhysicalResourcesManagmentModel[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;

  formFiltersValue: any | undefined = undefined;

  urlShared!:string;
  pageShared!:number;
  recordPageShared!:number;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private sdkDynamicFiltersService: SdkDynamicFiltersService
  ) {}

  // Dynamic filters
  filterList: ISdkDynamicFilterModel<any, any>[] = [

    {
      key: 'searchValue',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'Buscar',
      value: new BehaviorSubject(undefined),
      searchChange: true
    },
    {
      key: 'stateName',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Estado',
      value: new BehaviorSubject(undefined),
      options: this.state$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'resourceTypeName',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Tipo de recurso',
      value: new BehaviorSubject(undefined),
      options: this.typeResource$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'conditionName',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Condición',
      value: new BehaviorSubject(undefined),
      options: this.condition$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'assignmentName',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Asignación',
      value: new BehaviorSubject(undefined),
      options: this.asigned$,
      optionsMapper: undefined,
      searchChange: true
    },
  ];

  dataMap: { [key: number]: any } = {
    0: (): string => `pathState`,
    3: (): string => `pathAgencias`,
  };

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const path = this.dataMap[index]?.();
    return this.httpClient.get(path);
  }

  // Dynamic filters

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  buildExcelPdfUrl(fileType: string) {
    let urlBase = '';
    if (fileType === fileTypes.EXCEL) {
      if(this.urlShared != '' && this.urlShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?${this.urlShared}`;
      }else if(this.recordPageShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?recordsPerPage=${this.recordPageShared}&page=${this.pageShared}`;
      }else{
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?recordsPerPage=10&page=0`;
      }
    } else if (fileType === fileTypes.PDF) {
      if(this.urlShared != '' && this.urlShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?${this.urlShared}`;
      }else if(this.recordPageShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?recordsPerPage=${this.recordPageShared}&page=${this.pageShared}`;
      }else{
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?recordsPerPage=10&page=0`;
      }
    }

    return urlBase;
  }

  buildUrl(dataObject: IPaginatorModel): string {
    const data = dataObject.data;
    let url: string;
    if (data) {
      const searchValue = data['searchValue'];
      const stateName = data['stateName'];
      const typeResource = data['resourceTypeName'];
      const condition = data['conditionName'];
      const asigned = data['assignmentName'];
      let statesList = [];
      const enabled = data['enabled'];


      this.recordPageShared = dataObject.pageSize;
      this.pageShared = dataObject.page;

      url = `${this.environment.resourcesUrl}/physical-resources/pagination/data?recordsPerPage=${
        dataObject.pageSize
      }&page=${dataObject.page}${searchValue ? '&searchValue=' + searchValue : ''}${
        stateName ? '&stateIds=' + stateName  : ''
      }${typeResource ? '&resourceTypeIds=' + typeResource  : ''}
       ${condition ? '&conditionIds=' + condition  : ''}
       ${asigned ? '&assignationTypeIds=' + asigned  : ''}`;
       
      this.urlShared = `recordsPerPage=${
        dataObject.pageSize
      }&page=${dataObject.page}${searchValue ? '&searchValue=' + searchValue : ''}${
        stateName ? '&stateIds=' + stateName  : ''
      }${typeResource ? '&resourceTypeIds=' + typeResource  : ''}
       ${condition ? '&conditionIds=' + condition  : ''}
       ${asigned ? '&assignationTypeIds=' + asigned  : ''}`
    } else {
      url = `${this.environment.resourcesUrl}/physical-resources/pagination/data?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
    }
    return url;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value; //TODO: review approach
    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: IPhysicalResourcesManagmentModel[] = response.records.map(
          (item: IPhysicalResourcesManagmentModel) => {
            item.idStateName = {id: item.id,
                               state: item.stateCode};
            return item;
          }
        );

        this.dataSubject.next(data ?? []);
      }),
      tap((response: any) => {
        const data: any[] = this.mapDataToGrid(response.records);
        this.dataSubject.next(data ?? []);
      })
    );
  }

  private mapDataToGrid(data: any[]): any[] {
    return data.map((row) => ({
      ...row,
      stateIndicatorProperties: {
        label: row.enabled == true ? 'Activo' : 'Inactivo',
        stateClass:
          row.enabled == true
            ? StateIndicatorsClassesEnum.active_state
            : StateIndicatorsClassesEnum.inactive_state,
      },
    }));
  }

  generateDynamicFIltersModel(): IDynamicFilterModel {
    const dynamicFiltersModel: IDynamicFilterModel = {
      sdkDynamicFiltersService: this.sdkDynamicFiltersService,
      dataSource: this,
      filtersDispatcher: this.selected$,
      showBttnConventions: false,
      hiddenButtonsFilters: true
    };
    return dynamicFiltersModel;
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
    this.states$.next(userStateList);
  }
  public setStates(states: ILocationOption[]) {
    this.state$.next(states);
  }
  public setTypeResources(states: ILocationOption[]) {
    this.typeResource$.next(states);
  }
  public setCondition(states: ILocationOption[]) {
    this.condition$.next(states);
  }
  public setAsigned(states: ILocationOption[]) {
    this.asigned$.next(states);
  }
  public setDocumentType(documentType: IDocumentTypeModel[]) {
    this.documentType$.next(documentType);
  }

  public getPhysicalResource(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getPhysicalResourceById(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getImageResourceById(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/files`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getHistoricalPhysicalResource(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/log`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public updatePhysicalResource(physicalResource: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.put<any>(url, physicalResource);
  }
  
  public updateStateUser(params:any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/change-state`;
    return this.httpClient.put<any>(`${url}?id=${params.id}&stateCode=${params.stateCode}&motive=${params.motive}`,params);
  }

  public createPhysicalResource(physicalResource: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.post<any>(url, physicalResource);
  }

  public bulkLoadPhysicalResources(file: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/bulk-load/import`;
    return this.httpClient.post<any>(url, file);
  }

  public deleteImagePhysicialResource(id:any) {
    return this.httpClient.delete(`${this.environment.resourcesUrl}/physical-resources/files/${id}`);
  }

  public searhAfterSave(): void {
    this.setSelectedDataSubject(this.selected$);
  }

  public clearData(): void {
    this.filterList.forEach((filter) => {
      filter.value.next(undefined);
    });
  }

  public getUserInfo(params: any): Observable<any> {
    const url = `${this.environment.userUrl}/user/info`;
    return this.httpClient.get<any>(`${url}?email=${params}`);
  }
}
