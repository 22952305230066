<div class="all-container">
  <div class="grid-container-lane text-white text-weight-600">
    <div class="card-lane">
      <sit-icon
        [icon]="Icon.tramo"
        alt="gorra agente"
        class="mb-05 mr-1"
        [sizeClass]="'icon-size-28'"
      ></sit-icon>
      <span class="text-size-16 text-weight-600">Selector de Carril</span>
    </div>
    <div class="card-lane">
      <div class=" d-flex carril_solo" [ngClass]="{'select': orientation == '' }" (click)="ChangeVista(null,'')">
         <svg class="arrow-icon mt-1" title="velocidad" role="img">
          <use href="../../../../assets/icons/icons.svg#icon-square" />
         </svg>
         <span class="txt-titulo_graphica">Todos</span>
      </div>
    </div>
    <br>
    <div class="card-lane">
      <div *ngFor="let orient of orientations" [ngClass]="{'select': orientation == orient }" class=" d-flex carril" (click)="ChangeVista(null,orient)" data-toggle="tooltip" data-placement="top"
           title="{{orient}}" >
         <svg [ngClass]="{ arrow_rotate: (orient.toLowerCase() == 'norte - sur' || orient.toLowerCase() == 'occidente - oriente') }" class="arrow-icon mt-1" role="img"  >
               <use href="../../../../assets/icons/icons.svg#icon-arrowdown" />
         </svg>
      </div>
   </div>
   <div class="card-lane">
    <div *ngFor="let lane of lanes" [ngClass]="{'select': orientation == lane.laneId}" class=" d-flex carril" (click)="ChangeVista(lane,'')" data-toggle="tooltip" data-placement="top"
         title="{{lane.laneId}}" >
       <svg [ngClass]="{ arrow_rotate: (lane.orientation.toLowerCase() == 'norte - sur' || lane.orientation.toLowerCase() == 'occidente - oriente') }" class="arrow-icon mt-1" role="img"  >
             <use href="../../../../assets/icons/icons.svg#icon-arrowdown" />
       </svg>
    </div>
    
 </div>
  </div>
  <div class="grid-container-info text-white text-weight-600"> 
    <div class="card-info">
      <sit-icon
        [icon]="Icon.tacometro"
        alt="reloj"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-64'"
      ></sit-icon>
        <span class="text-size-14 text-weight-500">{{speed}} KM/h</span>
        <span class="text-size-14 mt-1">Velocidad</span>
    </div>
    <div class="card-info">
      <div class="row mb-06 mt-05">
        <svg class="" style="height: 4em; width: 5em; display: inline-block;" [ngStyle]="{'fill': color }"  title="area" role="img">
          <use [attr.href]="'../../../../assets/icons/icons.svg#icon-flujo'" />
        </svg>
      </div>

      <span class="text-size-14 text-weight-500">
        {{flow}}</span>
        <span class="text-size-14 mt-1">Flujo</span>  </div>
    <div class="card-info">
      <!-- <sit-icon
        [icon]="Icon.nivel_a_vehicles"
        alt="gorra agente"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-48'"
      ></sit-icon> -->
      <div class="row circle-blue">
        <svg class=" mt-1" style="height: 3em; width: 4em; display: inline-block;" [ngStyle]="{'fill': strcolor }"  title="area" role="img">
          <use [attr.href]="'../../../../assets/icons/icons.svg#' + strIcon" />
      </svg>
      </div>
      <span class="mt-1 text-size-14 text-weight-600">Nivel de servicio</span>
    </div>
  </div>
</div>      
  
<div class="all-container">
  <div class="grid-container-lane text-white text-weight-600">
    <div class="d-flex" style="width: 80%; flex-direction: column;  padding: 1rem;" >
          <div class="card-lane">
              <sit-icon
                [icon]="Icon.car_blue"
                alt="gorra agente"
                class="mb-05 mr-1"
                [sizeClass]="'icon-size-28'"
              ></sit-icon>
              <span class="text-size-16 text-weight-600">{{strTitle}}</span>
          </div>
    </div>           
  </div>
  <div class="grid-container-buttons text-white text-weight-600">
    
    <sit-button
      (click)="OnFilter('vehiculosFlow')" 
      type="submit"
      [buttonProperties]="fluxButtonProps"
      class="width-14 mr-1"
      [ngClass]="{
        'active-action': isGraphicFlow
      }">
    ></sit-button>

    <sit-button
    (click)="OnFilter('vehiculosSpeed')"
    type="submit"
    [buttonProperties]="speedButtonProps"
    class="width-14 text-white"
    [ngClass]="{
      'active-action': isGraphicSpeed
    }">
  ></sit-button>
  </div>
</div>

<div class="d-flex fd-row chart-line-container" *ngIf="isGraphicFlow">
  <sit-chart-line [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width">
  </sit-chart-line>
</div>

<div class="d-flex fd-row chart-line-container" *ngIf="isGraphicSpeed">
  <sit-chart-line [properties]="dataGraphicListSpeed" [colorScale]="colorScaleSpeed" class="full-width">
  </sit-chart-line>
</div>
  


