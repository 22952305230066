<div class="d-flex fd-col full-height overflow-y-auto custom-scrollbar" tabindex="0">
  <p class="d-flex mb-8 text-size-14 text-gray-3 pt-md-15 text-justify lh-150" style="padding: 1em">
    Permite consultar la información de los vehículos que se desplazan por la
    ciudad. Haga clic en un punto sobre el mapa para consultar la información.
  </p>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="automóviles icono título vehículos (últimas 8 horas)"></sit-icon>
    <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
      VEHÍCULOS (ÚLTIMAS 8 HORAS)
    </h3>
  </div>

  <div class="d-flex fd-row p-20-px gap-5-px">
    <ng-container *ngFor="let indicator of runtIndicators">
      <div
        class="d-flex fd-col justify-space-between flex-basis-0 flex-grow gap-5-px align-items-center text-white"
      >
        <sit-icon
          [icon]="indicator.icon"
          [sizeClass]="'icon-size-58'"
          class="mr-1 ml-16"
          [alt]="indicator.alt"
        ></sit-icon>
        <span>{{ indicator.value }}</span>
        <span class="text-size-14 text-center">{{ indicator.text }}</span>
      </div>
    </ng-container>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="automóvil icono título conteo soat (últimas 8 horas)"></sit-icon>
    <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
      CONTEO SOAT (ÚLTIMAS 8 HORAS)
    </h3>
  </div>

  <div class="chart-line-container d-flex fd-row full-width">
    <sit-chart-line [properties]="soatCountDataGraphicList" [colorScale]="soatCountcolorScale" class="full-width">
    </sit-chart-line>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="herramienta icono título conteo tecnomecáncia (últimas 8 horas)"></sit-icon>
    <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
      CONTEO TECNOMECÁNCIA (ÚLTIMAS 8 HORAS)
    </h3>
  </div>

  <div class="chart-line-container d-flex fd-row full-width">
    <sit-chart-line [properties]="tecnoCountDataGraphicList" [colorScale]="tecnoCountcolorScale" class="full-width">
    </sit-chart-line>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.density_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="herramienta icono título conteo por localización"></sit-icon>
    <h3 class="text-color-white m-0 text-size-18 text-weight-500">
      CONTEO POR LOCALIZACIÓN
    </h3>
  </div>

  <div>
    <table class="table-runt">
      <thead class="text-color-secondary-2 text-size-12">
        <th>Dirección</th>
        <th>Vehículos sin SOAT</th>
        <th>Vehículos sin Tecnomecánica</th>
      </thead>
      <tbody class="text-color-secondary-2 text-size-12">
        <ng-container *ngFor="let item of cards">
          <tr (click)="openModal(item)">
            <td>{{ item.address }}</td>
            <td>{{ item.soatExpired || '-' }}</td>
            <td>{{ item.technoExpired || '-' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>