import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { DrawerPanelService } from "@ui-core/services/drawer-panel/drawer-panel.service";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { Subject } from "rxjs";
import { GroundResourceManageService } from "@official/services/data-grid-service/ground-resource-manage/ground-resource-manage.service";
import { ManageIncidentsCoordinatorService } from "@official/services/data-grid-service/manage-incidents-coordinator/manage-incidents-coordinator.service";
import { ModalViewAssignedResourcesComponent } from "../modal-view-assigned-resources/modal-view-assigned-resources.component";
import { ModalRegisterIncidentManageComponent } from "../modal-register-incident-manage/modal-register-incident-manage.component";
import { ModalSuccesUserComponent } from "@ui-core/components/modal-succes-user/modal-succes-user.component";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";

@Component({
  selector: "sit-actions-incidents-coordination",
  templateUrl: "./actions-incidents-coordination.component.html",
  styleUrls: ["./actions-incidents-coordination.component.scss"],
})
export class ActionsIncidentsCoordinationComponent implements OnInit {
  Icon = Icon;
  @Input() id: any | undefined;
  private resources$ = new Subject<any>();

  // @ViewChild(PhysicalResourcesComponent) hijo: any;

  constructor(
    private incidentesCoordinationService:ManageIncidentsCoordinatorService,
    private modalService: ModalService,
    private drawerPanelService: DrawerPanelService,
    // private service: ServiceModal
    ) {
    // this.pyshicalResourcesManagementService.init();
   
  }

  ngOnInit(): void {
    this.modalService.cleanModals$.asObservable().subscribe({
      next: (response) => {
      }
    });
    this.modalService.currentModal$.asObservable().subscribe({
      next: (response) => {
      }
    });
  }

  public registerManageIncident(id:any): void {

    if(this.modalService.currentModals.length > 0) {
      this.modalService.cleanModals$.next([]);
    }

    var incidentById;
    this.incidentesCoordinationService.getIncidentById(id).subscribe((res:any)=>{
      incidentById = res;

      this.modalService.addModal({
              component: ModalRegisterIncidentManageComponent,
              data: {
                properties: {
                  incidentById,
                  icon: Icon.add_w,
                  title: 'Registro de Gestión del Incidente',
                  customClass: 'modalViewResources',
                },
                id
              },
              cancel: () => {
                // this.modalService.confirmationModal(modal);
                this.modalCanelar();
              },
      });  
    })
  }

  public viewAssignedResourcesIncident(id:any): void {

    if(this.modalService.currentModals.length > 0) {
      this.modalService.cleanModals$.next([]);
    }

    var incidentById;
    this.incidentesCoordinationService.getIncidentById(id).subscribe((res:any)=>{
      incidentById = res;

      this.modalService.addModal({
        component: ModalViewAssignedResourcesComponent,
        data: {
          properties: {
            incidentById,
            icon: Icon.eye_w,
            title: 'Recursos Asignados al Incidente',
            customClass: 'modalViewResources',
          },
          id
        },
      }); 
    })
  }

  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccesUserComponent,
        data: {
          infoMessage: 'Se cerrará la ventana sin guardar la información diligenciada',
          icon: Icon.questions_w,
        },
      },
      headerBackgroundClass: 'bg-color-background-3',
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }
}
