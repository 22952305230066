import {
    CardMapLinkModel,
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface ICardBikesModel {
    id: number;
    name: string;
    locality: string;
    orientation: number;
    speed? : number;
    flow : string,
    levelOfService? : string;
    icon? : Icon;
    cardMapLink?: CardMapLinkModel;
}

export interface IPropsBikesModel {
    id: number;
    name: string;
    locality: string;
    orientation: number;
    flow : string;
    markerPopupText : string;
    icon? : Icon;
}

export class BikesModel extends MarkerModelBase {
    static className = 'BikesModel';
    classProperties: IPropsBikesModel;

    constructor(props: IPropsBikesModel, geometry: IGeometryModel) {
        super(geometry, BikesModel.className);
        this.classProperties = props;
    }
}
