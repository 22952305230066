import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { separateCoordinates } from '@shared/utils/functions/separate-coordinates';
import { AbstractAforoSensorDataSource } from '@types-custom/models/ui/modal-aforo-model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AforoService implements AbstractAforoSensorDataSource {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  public getLayerAforoGeoJson(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.camerasUrl}/camera/format-geo-json?cameraType=AV&state=ACTIVO`
      // `assets/mock/aforo-layer-mock.json`
    );
  }

  //chart-line array by sensor id (for modal)
  getModalAforoMeasurements(sensorId: string): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.environment.camerasUrl}/camera-history/report/last-measurements/vehicle-type/camera/${sensorId}`
      //`assets/mock/aforo-modal.json`
    );
  }

  //chart-line array by sensor id (for panel)
  public getPanelAforoMeasurements(): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.environment.camerasUrl}/camera-history/report/count-and-speed-vehicles-global`
      // `assets/mock/aforo-history.json`
    );
  }

  getAforoMapper(AforoObj: any) {
    return separateCoordinates(AforoObj.geoAforo);
  }
}
