import {Component, Input, OnInit} from "@angular/core";
import {KPIFieldResources} from "@types-custom/models/ui/card-field-resources";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-kpiresources",
  templateUrl: "./kpiresources.component.html",
  styleUrls: ["./kpiresources.component.scss"],
})
export class KPIResourcesComponent implements OnInit{
  @Input() KPIData  : KPIFieldResources | undefined;
  @Input() CardWith : string | undefined;

  protected readonly Math = Math;
  protected readonly Icon = Icon;

  ngOnInit() {
  }

}
