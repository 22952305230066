import {
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsAirQualityModel {
    id              : string,
    location        : string,
    temperature     : string,
    humidity        : string,
    pressure        : string
    title           : string;
    keepAlive       : string;
    markerPopupText : string;
    icon            : unknown;
}

export class AirQualityModel extends MarkerModelBase {
    static className = 'AirQualityModel';
    classProperties: IPropsAirQualityModel;

    constructor(props: IPropsAirQualityModel, geometry: IGeometryModel) {
        super(geometry, AirQualityModel.className);
        this.classProperties = props;
    }
}
