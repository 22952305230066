import { Component, Input } from '@angular/core';
import { IModalCranesModel } from '@types-custom/models/ui/modal-cranes-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal Cranes
 ** Render a modal that displays information about cranes.
 */

@Component({
  selector: "modal-cranes",
  templateUrl: "./modal-cranes.component.html",
  styleUrls: ["./modal-cranes.component.scss"],
})
export class ModalCranesComponent {
  @Input() properties: IModalCranesModel | undefined;
  Icon = Icon;
}
