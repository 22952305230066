export enum PanelManageActionsEnum {
  GRID = 'grid',
  CREATE = 'create',
  SEARCH = 'search',
  EDIT = 'edit',
  EDITSCHEDLUE = 'editschedlue',
  DETAIL = 'deatil',
}


export enum CardActionEnum {
  EDIT = 'edit',
  DETAIL = 'deatil',
  DELETE = 'delete',
}

export enum CardSocialMediaActionEnum {
  WAZE_TEXT = 'wazeText',
  WHATSAPP_TEXT = 'whatsAppText',
  TWITTER_TEXT = 'twitterText'
}