import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RouteNamesEnum } from '@official/routes/routes';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard implements CanActivate {
  constructor(
    private localStorageManagementService: LocalStorageManagementService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token =
      this.localStorageManagementService.getUserLocalStorage();
    if (token) {
      this.router.navigate([RouteNamesEnum.MapViewer]);
      return false;
    }
    return true;
  }
}
