import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import { GeometryTypeEnum, ILayerModel } from '@types-custom/models/business/marker.model';
import { formatDate } from '@angular/common';
import { Icon } from '@types-custom/models/ui/icon-model';
import { GrouendResourceHistoryAPIMapper, agentsHistoricalFromAPIMapper, groundNumberFromAPIMapper } from '@shared/utils/mappers/geo-api-map';

@Injectable({
  providedIn: 'root'
})
export class FieldResourcesService {
  getNumberGroundMapper(response: any): any {
    throw new Error('Method not implemented.');
  }

  filter$ = new BehaviorSubject<any>(undefined);
  constructor(
      private httpClient: HttpClient,
      @Inject('environment') private environment: any
  ) {
  }

  getIncidentsResourcesFromGeoJSON(incidentBeginDate : Date, queryParams? : string): Observable<any> {
    let url = ''
    if(queryParams){
      url = `${this.environment.incidentUrl}/incidents/geo-json/incidents-and-resources?${queryParams}`
    } else {
      url = `${this.environment.incidentUrl}/incidents/geo-json/incidents-and-resources?incidentBeginDate=${incidentBeginDate.toISOString().replace('Z', '')}&resourceHours=1`
    }//let url = `assets/mock/IncidentsFieldResources.json`; //TODO mocked data for cammeras
    return this.httpClient.get<any>(
        url
    );
  }

  getResourcesFromGeoJSON(): Observable<any> {
    let url = `${this.environment.resourcesUrl}/human-resources/format-geo-json?groups=17&groups=19&groups=20&hours=24&valid=true'`
    //let url = `assets/mock/FieldResources.json`; //TODO mocked data for cammeras
    return this.httpClient.get<any>(
        url
    );
  }

  getIndicatorsByGroup() {
    let url = `${this.environment.resourcesUrl}/scheduled-activity/report/by-group`
    return this.httpClient.get<any>(
        url
    );
  }

  getIndicatorsByLocality() {
    let url = `${this.environment.resourcesUrl}/scheduled-activity/report/by-locality`
    return this.httpClient.get<any>(
        url
    );
  }

  getResourcesGraph() {
    let url = `${this.environment.resourcesUrl}/human-resource-locations/graph`
    return this.httpClient.get<any>(
        url
    );
  }

  getDataOfResource(id : string){
    let url = `${this.environment.resourcesUrl}/human-resources/report/executionPercentage/${id}`
    return this.httpClient.get<any>(
        url
    );
  }

  //layer history Field resource 
  getHistoryResourcesFromGeoJSON(queryFilters ='recordsPerPage=1000&page=0&hours=24' ): Observable<any> {
    // const filters = {
    //   recordsPerPage: 1000,
    //   page: 0,
    //   hours: 24,
    //   ...queryFilters,
    // };  
    // console.log('at getHistoryResourcesFromGeoJSON:', filters)
    
    let url = `${this.environment.resourcesUrl}/human-resources/format-geo-json?${queryFilters}`
    // let url = `assets/mock/resourceHistory.json`;
    return this.httpClient.get<any>(url);
  }

  getNumberResourcesFromGeoJSON(queryFilters: any ): Observable<any>{
    const filters = {
      recordsPerPage: 1001,
      page: 0,
      ...queryFilters,
    };  

    let url = `${this.environment.resourcesUrl}/human-resources/format-geo-json`
          return this.httpClient.get<any>( url, {
        params: filters
      });
  }

    
  getHistoryGroundMapper(HumanHistorical: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const sourceFeatures = HumanHistorical.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.id),
            time: formatDate(e.properties.updateLocationDate, 'YYYY-MM-dd HH:mm:ss', 'en-US')  ,
            title: formatDate(e.properties.updateLocationDate, 'YYYY-MM-dd HH:mm:ss', 'en-US') ,
            icon: Icon.dot_w,
          },
        };
      })
      .sort((a: any, b: any) =>
        a.properties.time.localeCompare(b.properties.time)
      );

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };

    const GroundHistoryPoints = source.features.map((e: any) =>
      GrouendResourceHistoryAPIMapper(e)
    );

    const agentsHistoryLines = this.getAgentsLinesHistorical(source).map(
      (e: any) => GrouendResourceHistoryAPIMapper(e)
    );


    layer.push({
      marker: GroundHistoryPoints,
      geometryType: GeometryTypeEnum.HeatMap, 
      feature: source,
      visibility: true,
    });

    layer.push({
      marker: GroundHistoryPoints,
      geometryType: GeometryTypeEnum.Point,
      feature: source,
      visibility: false,
    });

    layer.push({
      marker: agentsHistoryLines,
      geometryType: GeometryTypeEnum.LineString,
      feature: source,
      visibility: false,
    });

    return layer;
  }

  getAgentsLinesHistorical(data: any): any[] {
    const agentsIds = [
      ...new Set(data.features.map((e: any) => e.properties.id)),
    ];

    const agentsLineFeatures = [];

    for (const item of agentsIds) {
      const coordinates = data.features
        .filter((e: any) => e.properties.id === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.features.filter(
        (e: any) => e.properties.id === item
      )[0].properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      agentsLineFeatures.push(agentsLineFeature);
    }

    return agentsLineFeatures;
  }

  getGroundNumberMapper(groundNumber: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const sourceFeatures = groundNumber.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.LocCodigo),
            markerPopupText: `<img src="../../../../assets/icons/agents_green.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
            <span class="mr-3-em" style="margin-left:2.1rem">${e.properties.Qty}</span> <span style="margin-left:2.9rem">${e.properties.density.toFixed(2)}</span> <br> 
            <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
            <span style="display:flex;justify-content:center">${e?.properties?.LocNombre}</span>`,
            icon: Icon.dot_w,
          },
        };
      })

      return layer;
  }
  
  getHumanResourcesByIncident(incidentId: number): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-assign-user/human-resources/${incidentId}`
    return this.httpClient.get<any>(
      url
    );
  }

  addHumanResourceIncident(incidentId: number, humanResourceId: number): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-assign-user/human-resource`

    const body = {
      incidentId: incidentId,
      humanResourceId: humanResourceId
    };

    return this.httpClient.post<any>(
      url,
      body
    );
  }

  removeHumanResources(humanResourceId: number, incidentId: number): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-assign-user/human-resources/${humanResourceId}?incidentId=${incidentId}`
    return this.httpClient.delete<any>(
      url
    );
  }

  listHumanResourceByIncident(incidentId: number, value?: string): Observable<any> {

    let searchValue = '';
    if (value) {
      searchValue = `&searchValue=${value}`;
    }

    const url = `${this.environment.incidentUrl}/incident-assign-user/search/human-resources-by-incident?incidentId=${incidentId}${searchValue}`
    return this.httpClient.get<any>(
      url
    );
  }  

  listScheduledActivity(Id:number){
    const url = `${this.environment.resource}/scheduled-activity/human-resource/${Id}`
    return this.httpClient.get<any>(
      url
    );
  }

  listUnscheduledActivity(Id:number){
    const url = `${this.environment.incidentUrl}/incidents/human-resource/${Id}`
    return this.httpClient.get<any>(
      url
    );
  }
  
  listIncidentsWithoutAssign(){
    const url = `${this.environment.incidentUrl}/incidents/without-assign`
    return this.httpClient.get<any>(
      url
    );
  }

  public setFilter(filter: any): void {
    this.filter$.next(filter);
  }

  public getFilter() {
    return this.filter$;
  }

}
