export enum AuthActionEnum {
  Login = 'token',
  Logout = 'logout',
  UserInfo ='userinfo'
}

export enum SocialLoginPathEnum {
  Google = 'google',
  Microsoft = 'microsoft',
}
