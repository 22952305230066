import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsCranesHistoricalModel {
  id: string;
  name?: string;
  updatedDate?: string;
  agentType?: string;
  title: string;
  markerPopupText: string;
  time: string;
  icon: unknown;
  localidad?: string;
  dev_id?: string;
}

export class CranesHistoricalModel extends MarkerModelBase {
  static className = 'CranesHistoricalModel';
  classProperties: IPropsCranesHistoricalModel;

  constructor(props: IPropsCranesHistoricalModel, geometry: IGeometryModel) {
    super(geometry, CranesHistoricalModel.className);
    this.classProperties = props;
  }
}
