<!-- <modal></modal> -->
<!-- <page-title [icon]="icon" [name]="'Gestión de Usuarios'"></page-title> -->

<<div class="page-incident-parameters box-container-resource text-color-white">
  <div class="d-flex fd-row justify-space-between">
    <div
      class="text-color-white d-flex gap-16-px fd-row align-items-center letter-spacing-1 text-size-32 text-weight-600">
      <sit-icon class="col-sm d-flex" [icon]="icon.rudder_blue" [sizeClass]="'icon-size-40'"
        alt="icono de usuario"></sit-icon>
      <span class="d-flex justify-space-between align-items-center text-color-primary-1">
        Inconsistencias y novedades de asignación de vehículos a recursos en campo.
        <sit-icon [icon]="icon.info" [sizeClass]="'icon-size-22'"
          [tooltip]="'En esta funcionalidad podrá crear, activar e inactivar las diferentes opciones para los parámetros usados en el módulo de incidentes.'"></sit-icon>
      </span>
    </div>
  </div>
  <hr class="mb-1 line-separator" />
  <p class="">En este módulo podrá gestionar, registrar y ver detalles de los reportes e Inconsistencias y novedades de
    asignación de vehículos que realizaron los recursos en campo.</p>
  <div class="box-container-filter mb-2">
    <div class="full-width mt-1 mb-05 text-weight-600 text-white letter-spacing-1 text-size-14">
      <dynamic-filter [properties]="dynamicFiltersModel"></dynamic-filter>
    </div>
    <span class="text-color-white">(Módulo de Incidentes)</span>

    <div class="box-container-table overflow-auto">
      <grid-page [properties]="gridPageProps" (gridAction)="handleDownloadGridAction($event)"></grid-page>
    </div>
  </div>