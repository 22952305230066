import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { DrawerPanelService } from "@ui-core/services/drawer-panel/drawer-panel.service";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { Subject } from "rxjs";
import { GroundResourceComponent } from "../ground-resource/ground-resource.component";
import { EditGroundResourceComponent } from "../edit-ground-resource/edit-ground-resource.component";
import { GroundResourceManageService } from "@official/services/data-grid-service/ground-resource-manage/ground-resource-manage.service";
import { AddPhysicalResourcesComponent } from "../add-physical-resources/add-physical-resources.component";

@Component({
  selector: "cdm-manizales-actions-grounds-resource",
  templateUrl: "./actions-grounds-resource.component.html",
  styleUrls: ["./actions-grounds-resource.component.scss"],
})
export class ActionsGroundsResourceComponent implements OnInit {
  Icon = Icon;
  @Input() idEditresource: any | undefined;
  @Input() jobs: any | undefined;
  private resources$ = new Subject<any>();

  // @ViewChild(PhysicalResourcesComponent) hijo: any;

  constructor(
    private serviceGround:GroundResourceManageService,
    private modalService: ModalService,
    private drawerPanelService: DrawerPanelService,
    // private service: ServiceModal
    ) {
    // this.pyshicalResourcesManagementService.init();
   
  }

  ngOnInit(): void {
    this.modalService.cleanModals$.asObservable().subscribe({
      next: (response) => {
      }
    });
    this.modalService.currentModal$.asObservable().subscribe({
      next: (response) => {
      }
    });
  }

  editGroundResource(id:any):any{

    if(this.modalService.currentModals.length > 0) {
      this.modalService.cleanModals$.next([]);
    }
    this.serviceGround.getgroundResourceById(id).subscribe((res:any )=> { 
    var jobName= this.serviceGround.jobName$
    var groupName= this.serviceGround.groupName$
    var groundResourceEdit=res

      this.modalService.addModal({
        component: EditGroundResourceComponent,
        data: {
          properties: {
            icon: Icon.edit_w,
            title: 'Editar Recurso en Campo',
            customClass: 'modalFielGround',
          },
          groundResourceEdit,
          jobName,
          groupName
        },
      });
    })
  }

  addFisicalRecursos(id:any):any {
    if(this.modalService.currentModals.length > 0) {
      this.modalService.cleanModals$.next([]);
    }
    this.serviceGround.getgroundResourceById(id).subscribe((res:any )=> { 
      var groundResourceEdit=res
      
      this.modalService.addModal({
        component: AddPhysicalResourcesComponent,
        data: {
          properties: {
            icon: Icon.add_w,
            title: 'Vincular recursos físicos',
            customClass: 'modalAddResource',
          },
          groundResourceEdit,
        },
      });
    })
  }

}
