import { Component } from '@angular/core';

/*
** Spinner
** Render a loading spinner
*/
@Component({
  selector: 'sit-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {}
