import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IncidentsManageService } from "@official/services/data-grid-service/incidents-manage-service/incidents-manage.service";
import { IIncidentParameter, parameterListTypeOptions } from "@shared/models/incident-parameter.model";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { remapObjectToOneLevel } from "@ui-core/utils/functions/remap-object";
// import {
//   AbstractControl,
//   AsyncValidatorFn,
//   FormControl,
//   UntypedFormBuilder,
//   UntypedFormGroup, ValidationErrors,
//   Validators
// } from "@angular/forms";

@Component({
  selector: "sit-modal-create-parameter",
  templateUrl: "./modal-create-parameter.component.html",
  styleUrls: ["./modal-create-parameter.component.scss"],
})
export class ModalCreateParameterComponent implements OnInit {
  @Input() onComplete: () => void;

  constructor(
    private serviceDataSource: IncidentsManageService,
    private modalService: ModalService
  ) { }

  fb = new UntypedFormBuilder();
  createFormGroup = this.fb.group({});

  ngOnInit(): void {
    this.initializeForm();

  }

  get parameters() {
    return parameterListTypeOptions;
  }

  sendButtonProps: IButtonModel = {
    label: 'CREAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  handleCancel() {
    this.modalService.closeConfirmationModal();
  }
  handleSendForm() {
    const dataForm = remapObjectToOneLevel(this.createFormGroup.getRawValue());
    this.serviceDataSource.submitCreateParameter(dataForm).subscribe({
      next: (v: IIncidentParameter) => {
        this.modalService.closeConfirmationModal();
        setTimeout(() =>
          this.handleSuccess(v.name)
        );
      },
      error: () => this.validateFormGroup(),
      complete: () => this.onComplete(),
    });

  }
  validateFormGroup() {
    this.createFormGroup.markAllAsTouched();
  }

  _isAValidComponent(formName: string, formKey: string) {
    return this.createFormGroup.get(formName).get(formKey).status === 'INVALID'
      && this.createFormGroup.get(formName).get(formKey).touched
  }

  initializeForm() {
    const formControls: { [key: string]: any } = {
      incidentTypeCode: [
        { value: '', disabled: false },
        [
          Validators.required
        ],
      ],
      name: [
        { value: '', disabled: false },
        [
          Validators.required,
          // Validators.maxLength(200),
          // Validators.minLength(3),
          // Validators.pattern(
          //   /^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/
          // ),
        ],
      ],
    }

    this.createFormGroup = this.fb.group(formControls);
  }

  handleSuccess(name?: string) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: `Éxito al actualizar ${name}` },
      },
      headerBackgroundClass: 'bg-color-background-1',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };
    this.modalService.confirmationModal(modal);
  }
}
