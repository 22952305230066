import { Component, Input } from '@angular/core';
import { IModalCoosModel } from '@types-custom/models/ui/modal-coos-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal coos
 ** Render a modal that displays information about the Coos.
 */

@Component({
  selector: 'citizen-modal-coos',
  templateUrl: './modal-coos.component.html',
  styleUrls: ['./modal-coos.component.scss'],
})
export class ModalCoosComponent {
  @Input() properties: IModalCoosModel | undefined;
  Icon = Icon;
  
}
