<div class="card {{CardWith}}">
    <div class="card-title"> {{KPIData.name}} </div>
    <div class="card-contet d-flex gap-10-px">
        <sit-icon [icon]="KPIData.icon" [sizeClass]="'icon-size-48'"></sit-icon>
        <div class="d-grid">
            <div class="stacks">
                {{KPIData.enabled}}/{{KPIData.disabled}}
            </div>
            <span class="subtitle">
                Activos
            </span>
        </div>
    </div>
    <div class="percentage">
        <span class="percentage_label">{{((this.KPIData.executionPercentage) * 100).toFixed(0)}}%</span>
        <div class="loader">
            <div class="bar" id="progressBar" [style.width.%]="this.KPIData.executionPercentage * 100"></div>
        </div>
    </div>
</div>
