import { Injectable } from '@angular/core';
import {
  AbstractDynamicFiltersService,
  AbstractSdkDynamicFilterDataSource,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import { map, skip, take, tap } from 'rxjs';

@Injectable()
export class SdkDynamicFiltersService
  implements AbstractDynamicFiltersService {
  private filters!: ISdkDynamicFilterModel[];
  private dataSource!: AbstractSdkDynamicFilterDataSource;
  private initialIndex!: number | number[];

  public setFilters(
    dataSource: AbstractSdkDynamicFilterDataSource,
    startWithIndex: number | number[] = -1
  ): void {
    this.dataSource = dataSource;
    this.filters = dataSource.filterList;
    this.initialIndex = startWithIndex;
    this.setSubscriptions();
    if (typeof startWithIndex === 'number') {
      if (startWithIndex > -1) {
        this.fetchData(startWithIndex);
      }
    } else if (startWithIndex && startWithIndex.length) {
      startWithIndex.forEach((index) => this.fetchData(index));
    }
  }

  public getModel(): any {
    const model = this.filters.reduce(
      (pre: any, cur: ISdkDynamicFilterModel) => {
        pre[`${cur.key}`] = cur.value.value;
        return pre;
      },
      {}
    );

    return model;
  }

  public cleanFilters(): void {
    this.filters.forEach((filter) => filter.value.next(undefined));
  }

  private fetchData(index: number): void {
    const filterToFetch = this.filters[index];
    if (!filterToFetch) return;
    this.dataSource
      .fetchData(undefined, filterToFetch, index)
      .pipe(
        take(1),
        map((response) => {
          return filterToFetch?.optionsMapper?.(response) ?? response;
        })
      )
      .subscribe((response) => filterToFetch?.options?.next(response));
  }

  private setSubscriptions(): void {
    this.filters.forEach(
      (filter: ISdkDynamicFilterModel, index: number, elements) => {
        const nextIndex = index + 1;
        if (!this.isInitialIndex(nextIndex)) {
          const next =
            index < elements.length - 1 ? elements[index + 1] : undefined;
          if (filter.options && next?.options) {
            filter.value.pipe(skip(1)).subscribe((value) => {
              this.handleSelectedValue(value, next, index + 1);
            });
          }
        }
      }
    );
  }

  private handleSelectedValue(
    value: any,
    next: ISdkDynamicFilterModel,
    index: number
  ): void {
    if (!value) return;
    //console.log(value)
    this.dataSource
      .fetchData(value, next, index)
      .pipe(
        take(1),
        map((response) => next.optionsMapper?.(response) ?? response)
      )
      .subscribe((response) => next.options?.next(response));
  }

  private isInitialIndex(index: number): boolean {
    const { initialIndex } = this;
    if (typeof initialIndex === 'number') {
      if (initialIndex > -1) {
        return index === initialIndex;
      }
    } else if (initialIndex && initialIndex.length) {
      return initialIndex.some((idx) => idx === index);
    }
    return false;
  }
}
