<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
  <ng-container *ngIf="panelAction$ | async as panelAction">
    <p class="work-sans-font text-size-14 line-height-2 m-1 text-white">
      {{ textPanel }}
    </p>
    <div
      class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
      <div class="left-title-block bg-color-primary-1"></div>
      <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
        alt="icono panel mensajería variable"></sit-icon>
      <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
        ID: {{ datacameraSelect.id }} &nbsp; {{ datacameraSelect.address }}
      </h3>
    </div>

    <div class="row">
      <div class="row d-flex justify-end full-width">
        <div class="col-4">
          <sit-button [buttonProperties]="resetButtonProps" class="" (click)="resetpmv()" [ngClass]="{
            'active-action': panelAction === panelManageActionsEnum.CREATE
          }"></sit-button>
        </div>
      </div>

      <div
        class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
        <div class="left-title-block bg-color-primary-1"></div>
        <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
          alt="icono panel mensajería variable"></sit-icon>
        <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
          CONFIGURACION DE MENSAJES
        </h3>
      </div>

      <div class="d-flex fd-row justify-space-evenly">
        <sit-button [buttonProperties]="searchButtonProps" class="width-49"
          (click)="onPanelManageAction(panelManageActionsEnum.SEARCH)" [ngClass]="{
          'active-action': panelAction === panelManageActionsEnum.SEARCH
        }"></sit-button>
        <sit-button [buttonProperties]="createButtonProps" class="width-49"
          (click)="onPanelManageAction(panelManageActionsEnum.CREATE)" [ngClass]="{
          'active-action': panelAction === panelManageActionsEnum.CREATE
        }"></sit-button>

      </div>

      <ng-container *ngIf="panelAction === panelManageActionsEnum.GRID">
        <div
          class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
          <div class="left-title-block bg-color-primary-1"></div>
          <sit-icon [icon]="Icon.last_hours_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
            alt="reloj icono título lista de mensajes"></sit-icon>
          <h2 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
            Lista de Mensajes
          </h2>
        </div>
        <div>
          <grid-page [properties]="gridPageProps" (gridAction)="handleDownloadGridAction($event)"></grid-page>
        </div>
      </ng-container>
      <!-- CREATE -->
      <ng-container *ngIf="panelAction === panelManageActionsEnum.EDIT">
        <div>
          <create-message-pmv [dataselect]="dataselect" [ip]="datacameraSelect"
            [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
            [panelAction$]="panelAction$"></create-message-pmv>
        </div>
      </ng-container>

      <!-- LIST -->
      <ng-container *ngIf="panelAction === panelManageActionsEnum.SEARCH">
        <div>
          <list-message-pmv [dataselect]="dataselect" [ipforsearch]="datacameraSelect"
            [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
            [panelAction$]="panelAction$"></list-message-pmv>
        </div>
      </ng-container>

      <ng-container *ngIf="panelAction === panelManageActionsEnum.CREATE">
        <div>
          <program-message-pmv [dataselect]="dataselect" [ip]="datacameraSelect"
            [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
            [panelAction$]="panelAction$"></program-message-pmv>
        </div>
      </ng-container>

      <ng-container *ngIf="panelAction === panelManageActionsEnum.EDITSCHEDLUE">
        <div>
          <edit-program-message-pmv [dataselect]="dataselect" [ip]="datacameraSelect"
            [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
            [panelAction$]="panelAction$"></edit-program-message-pmv>
        </div>
      </ng-container>
    </div>

  </ng-container>
</div>
