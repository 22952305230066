<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
  <p class="work-sans-font text-size-14 line-height-2 m-1 text-white">
    {{ textPanel }}
  </p>
  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="icono panel mensajería variable"></sit-icon>
    <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
      ID: {{ datacameraSelect.id }} &nbsp; {{ datacameraSelect.address }}
    </h3>
  </div>

  <div class="d-flex fd-col" style="margin-left: 1vw">
    <div class="d-flex fd-row text-color-secondary-2 m-0 text-size-18 text-weight-500">
      <label>Estado:</label>
      <span class="ml-8">{{ datacameraSelect.stateName }}</span>
    </div>
    <div class="d-flex fd-row text-color-secondary-2 m-0 text-size-18 text-weight-500">
      <label>Mensaje Actual:</label>
      <span class="ml-8">{{
        dataStatusforcamera?.currentMessage.message
        }}</span>
    </div>
  </div>

  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.last_hours_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="reloj icono título programación"></sit-icon>
    <h3 class="text-color-secondary-2 m-0 text-size-18 text-weight-500">
      PROGRAMACIÓN
    </h3>
  </div>
  <div>
    <div class="bodymessage">
      <grid-page [properties]="gridPageProps" (gridAction)="handleDownloadGridAction($event)"></grid-page>
    </div>
  </div>
</div>