export enum pmvPanelActionsEnum {
  MAIN_MESSAGE_GRID = 'MAIN_MESSAGE_GRID',
  MAIN_MESSAGE_CREATE = 'MAIN_MESSAGE_CREATE',
  GRID_PROGRAM_MESSAGE = 'GRID_PROGRAM_MESSAGE',
  CREATE_PROGRAM_MESSAGE = 'CREATE_PROGRAM_MESSAGE',
  EDIT_PROGRAM_MESSAGE = 'EDIT_PROGRAM_MESSAGE',
}

export enum notificationCodeMessage {
  NAME_ALREDY_EXIST = 'El nombre ya existe',
  ERROR_WRONG_DATA = 'Datos erróneos',
  ERROR_INVALID_MODEL = 'Los datos no son validos',
  ERROR_EMAIL_ALREADY_EXIST = 'El correo electrónico ya existe',
  INAVLID_LOGIN_ATTEMPT = 'Inicio de sesión invalido',
  NETWORK_EXCEPTION = 'Error de red',
  NO_RESPONSE_RECEIVED_FROM_SNMP_AGENT = 'No hay respuesta del panel',
  ERROR_IN_SNMP_REPLY = 'Error en la respuesta del panel',
  WRONG_MESSAGE_ID = 'Incorrecto id del mensaje',
  WRONG_FONT_ID = 'Incorrecto id de la fuente',
  WRONG_GRAPHIC_ID = 'Incorrecto id del grafico',
  WRONG_GRAPHIC_PAGE = 'Incorrecta página del grafico',
  WRONG_MESSAGE_PAGE = 'Incorrecta página del mensaje',
  MESSAGE_ERROR_OTHER = 'Error en el mensaje',
  MESSAGE_ERROR_BEACONS = 'Error en el mensaje, baliza',
  MESSAGE_ERROR_PIXEL_SERVICE = 'Error en el mensaje, servicio de pixel',
  MESSAGE_ERROR_SYNTAXMULTI_OTHER = 'Error en el mensaje, sintaxis',
  MESSAGE_ERROR_SYNTAXMULTI_UNSUPPORTED_TAG = 'Error en el mensaje, sintaxis, tag no soportado',
  MESSAGE_ERROR_SYNTAXMULTI_UNSUPPORTED_TAG_VALUE = 'Error en el mensaje, sintaxis, valor del tag',
  MESSAGE_ERROR_SYNTAXMULTI_TEXT_TOO_BIG = 'Error en el mensaje, texto muy grande',
  MESSAGE_ERROR_SYNTAXMULTI_FONT_NOT_DEFINED = 'Error en el mensaje, sintaxis, fuente no definida',
  MESSAGE_ERROR_SYNTAXMULTI_CHARACTER_NOT_DEFINED = 'Error en el mensaje, sintaxis, carácter no definido',
  MESSAGE_ERROR_SYNTAXMULTI_FIELD_DEVICE_NOT_EXIST = 'Error en el mensaje, sintaxis,',
  MESSAGE_ERROR_SYNTAXMULTI_FIELD_DEVICE_ERROR = 'Error en el mensaje, sintaxis, campo del dispositivo',
  MESSAGE_ERROR_SYNTAXMULTI_FLASH_REGION_ERROR = 'Error en el mensaje, sintaxis, región',
  MESSAGE_ERROR_SYNTAXMULTI_TAG_CONFLICT = 'Error en el mensaje, sintaxis, tag en conflicto',
  MESSAGE_ERROR_SYNTAXMULTI_TOO_MANY_PAGES = 'Error en el mensaje, sintaxis, demasiadas paginas',
  MESSAGE_ERROR_SYNTAXMULTI_FONT_VERSION_ID = 'Error en el mensaje, sintaxis, id de la fuente',
  MESSAGE_ERROR_SYNTAXMULTI_GRAPHIC_ID = 'Error en el mensaje, sintaxis, id del grafico',
  MESSAGE_ERROR_SYNTAXMULTI_GRAPHIC_NOT_DEFINED = 'Error en el mensaje, sintaxis, grafico no definido',
  WRONG_DATE_TIME = 'Fecha incorrecta',
  WRONG_SCHEDULE_ID = 'Id del mensaje programado incorrecto',
  LIMIT_EXCEEDED_SCHEDULE_ITEMS = 'Se excedió el limite de mensajes programados',
  EXCEPTION = 'Error en el servicio',
}
