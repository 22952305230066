<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar px-24-px py-05-rem" tabindex="0">
    <div class="d-grid indicators">
        <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
            <div class="container-graphic">
                <sit-general-card [properties]="this.levelOfService" [size]="'icon-size-80'"></sit-general-card>
            </div>
        </div>
        <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
            <div class="container-graphic">
                <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
            </div>
        </div>
        <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center sit-card">
            <div class="container-graphic">
                <sit-general-card [properties]="this.flow" [size]="'icon-size-34'"></sit-general-card>
            </div>
        </div>
    </div>

    <div class="bg-color-background-1 d-flex">
        <div class="left-subtitle-block bg-color-primary-1"></div>
        <div class="d-flex full-width py-05">
            <sit-icon [icon]="Icon.speed_range_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título rango de velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-24 lh-32-px ml-16 text-md-size-18 text-white align-self-center">
                Rango de velocidad
            </h3>
        </div>
    </div>

    <div class="full-height full-width">

    </div>

    <div class="bg-color-background-1 d-flex">
        <div class="left-subtitle-block bg-color-primary-1"></div>
        <div class="d-flex full-width py-05">
            <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="camino icono título rutas principales"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-24 ml-16 text-md-size-18 lh-32-px text-white align-self-center">
                Rutas principales
            </h3>
        </div>
    </div>

    <!-- <div class="table-title d-flex fd-row text-white text-weight-400 justify-space-around bg-color-secondary-4 py-10-px">
      <span>VELOCIDAD</span>
      <span>TIEMPO</span>
      <span>RANGO</span>
      <span>CONCORDANCIA</span>
    </div> -->

    <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher" *ngIf="cards.length > 1">
        <ng-container *ngFor="let card of cards; let i = index" class="pb-1-rem">
            <sit-list-bikes [card]="card" class="pb-1-rem"></sit-list-bikes>
        </ng-container>
    </div>

</div>
