<div class="pr-38 pl-38 pt-10 bg-color-background-3">
  <div class="container-form d-flex fd-col mt-16 text-weight-500 text-size-14">
    <ng-container *ngIf="currentAction === forgotPassAction.emailForm">
      <span class="text-white mb-16">
        Para restablecer su contraseña por favor ingrese su correo electrónico
      </span>
      <form [formGroup]="emailFormGroup">
        <div class="mat-input d-flex fd-row align-items-center mb-32">
          <div class="mr-8 icon">
            <sit-icon
              [icon]="Icon.email_w"
              alt="icono carta email"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
          </div>
          <mat-form-field
            class="textWhite"
            appearance="outline"
            [ngClass]="{
              'error-field':
                (emailFormGroup.controls.email.hasError('required') ||
                  emailFormGroup.controls.email.hasError('email')) &&
                emailFormGroup.controls.email.touched
            }"
          >
            <mat-label class="text-white">Correo electrónico</mat-label>
            <input matInput formControlName="email" />
            <mat-error>
              <label *ngIf="emailFormGroup.controls.email.hasError('required')"
                >Campo obligatorio</label
              >
              <label *ngIf="emailFormGroup.controls.email.hasError('email')"
                >Dirección de correo inválida</label
              >
            </mat-error>
          </mat-form-field>
        </div>
      </form>
      <div class="d-flex fd-row justify-end mt-32 mb-16">
        <div class="d-flex fd-row">
          <sit-button
            (click)="handleCancel()"
            [buttonProperties]="cancelbuttonProps"
            class="width-49 mr-16 buttons_align"
          ></sit-button>
        </div>
        <div class="d-flex fd-row">
          <sit-button
            (click)="
              emailFormGroup.valid
                ? handleSendEmailForm()
                : validateFormGroup(emailFormGroup)
            "
            [disabled]="!emailFormGroup.valid"
            [buttonProperties]="sendButtonProps"
            class="width-49 buttons_align"
          ></sit-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentAction === forgotPassAction.bannerConfirmation">
      <div class="text-white">
        <span class="mb-16">Estimado usuario:</span>
        <p class="text-justify">
          Hemos remitido un correo a la cuenta registrada. Por favor ingrese a
          su correo electrónico para restablecer su contraseña y acceder al
          portal de Gestión de Movilidad de Manizales.
        </p>
      </div>
      <div class="d-flex fd-row justify-end mt-8 mb-16">
        <div class="d-flex fd-row">
          <sit-button
            (click)="handleBannerAccept()"
            [buttonProperties]="sendButtonProps"
            class="width-49 buttons_align"
          ></sit-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentAction === forgotPassAction.verificationForm">
      <div class="d-flex fd-col align-items-center mb-16">
        <span class="text-white mb-16">
          Para restablecer su contraseña por favor ingrese el código de
          verificación que hemos enviado al correo:
        </span>
        <span class="text-white text-size-16 text-weight-600">{{ Email }}</span>
      </div>
      <form [formGroup]="verificationFormGroup" class="login-form mt-16">
        <div class="mat-input d-flex fd-row align-items-center mb-32">
          <div class="mr-8 icon">
            <sit-icon
              [icon]="Icon.check_w"
              alt="icono simbolo número"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
          </div>
          <mat-form-field
            class="textWhite"
            appearance="outline"
            [ngClass]="{
              'error-field':
                verificationFormGroup.controls.code.invalid &&
                verificationFormGroup.controls.code.touched
            }"
          >
            <mat-label class="text-white">Código de verificación</mat-label>
            <input matInput formControlName="code" />
            <mat-error>
              <label
                *ngIf="verificationFormGroup.controls.code.hasError('required')"
                >Campo obligatorio</label
              >
            </mat-error>
            <mat-error
              *ngIf="verificationFormGroup.controls.code.hasError('pattern')"
            >
              Solo se permiten números
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mat-input d-flex fd-row align-items-center mb-32">
          <div class="mr-8 icon">
            <sit-icon
              [icon]="Icon.lock_w"
              alt="icono candado"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
          </div>
          <mat-form-field
            class="textWhite"
            appearance="outline"
            [ngClass]="{
              'error-field':
                verificationFormGroup.controls.newPassword?.invalid &&
                verificationFormGroup.controls.newPassword.touched
            }"
          >
            <mat-label class="text-white">Nueva contraseña</mat-label>
            <input
              matInput
              formControlName="newPassword"
              matTooltipPosition="below"
              matTooltipClass="custom-tooltip"
              matTooltip="Debe contener mínimo una letra mayúscula, un número y un carácter especial + *"
              [type]="showPass ? 'text' : 'password'"
            />
            <sit-icon
              matSuffix
              (click)="showPass = !showPass"
              class="cursor-pointer"
              [icon]="showPass ? Icon.visibility_off : Icon.visibility_on"
              alt="icono ojo"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
            <mat-error>{{ newPasswordErrorMessage }}</mat-error>
          </mat-form-field>
        </div>

        <div class="mat-input d-flex fd-row align-items-center mb-32">
          <div class="mr-8 icon">
            <sit-icon
              [icon]="Icon.lock_w"
              alt="icono candado"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
          </div>
          <mat-form-field
            class="textWhite"
            appearance="outline"
            [ngClass]="{
              'error-field':
                verificationFormGroup.controls.confirmNewPassword.invalid &&
                verificationFormGroup.controls.confirmNewPassword.touched
            }"
          >
            <mat-label class="text-white">Confirmar nueva contraseña</mat-label>
            <input
              matInput
              matTooltipPosition="below"
              matTooltipClass="custom-tooltip"
              matTooltip="Debe contener mínimo una letra mayúscula, un número y un carácter especial + *"
              formControlName="confirmNewPassword"
              [type]="showPass ? 'text' : 'password'"
            />
            <sit-icon
              matSuffix
              (click)="showPass = !showPass"
              class="cursor-pointer"
              [icon]="showPass ? Icon.visibility_off : Icon.visibility_on"
              alt="icono ojo"
              [sizeClass]="'icon-size-28'"
            ></sit-icon>
            <mat-error>
              <label
                *ngIf="
                  verificationFormGroup.controls.confirmNewPassword.hasError(
                    'required'
                  )
                "
                >Campo obligatorio</label
              >
              <label
                *ngIf="
                  verificationFormGroup.controls.confirmNewPassword.hasError(
                    'sameValueValidator'
                  )
                "
                >Las contraseñas no coinciden</label
              >
            </mat-error>
          </mat-form-field>
        </div>
      </form>
      <div class="d-flex fd-row justify-end mt-32 mb-16">
        <div class="d-flex fd-row">
          <sit-button
            (click)="handleCancel()"
            [buttonProperties]="cancelbuttonProps"
            class="width-49 mr-16 buttons_align"
          ></sit-button>
        </div>
        <div class="d-flex fd-row">
          <sit-button
            (click)="
              verificationFormGroup.valid
                ? handleSendVerificationForm()
                : validateFormGroup(verificationFormGroup)
            "
            [disabled]="!verificationFormGroup.valid"
            [buttonProperties]="sendButtonProps"
            class="width-49 buttons_align"
          ></sit-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
