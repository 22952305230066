<div class="card">
    <div class="title">
        <span class="color-primnary fw-bold">
            {{Data.locality_Name}}
        </span>
    </div>
    <div class="gap-8-px justify-center align-items-center content mt-1-em">
        <sit-dynamic-kpi [type]="4" [data]="KPIAgents"></sit-dynamic-kpi>
        <sit-dynamic-kpi [type]="4" [data]="KPICranes"></sit-dynamic-kpi>
    </div>
</div>