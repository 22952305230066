export const vehiclesNivelJsonMockData = [
    {
        "nivelid": 1,
        "letter": "A",
        "color": "#CCFFCE",
        "start_range": 0,
        "end_range": 0.2
    },
    {
        "nivelid": 2,
        "letter": "B",
        "color": "#00C479",
        "start_range": 0.2,
        "end_range": 0.4
    },
    {
        "nivelid": 3,
        "letter": "C",
        "color": "#FDFF9E",
        "start_range": 0.4,
        "end_range": 0.7
    },
    {
        "nivelid": 4,
        "letter": "D",
        "color": "#FECC9B",
        "start_range": 0.7,
        "end_range": 0.8
    },
    {
        "nivelid": 5,
        "letter": "E",
        "color": "#D78313",
        "start_range": 0.8,
        "end_range": 1
    },
    {
        "nivelid": 6,
        "letter": "F",
        "color": "#E45024",
        "start_range": 1,
        "end_range": 10
    }
]