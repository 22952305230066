import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: "grid-page-physical-resource",
  templateUrl: "./grid-page-physical-resource.component.html",
  styleUrls: ["./grid-page-physical-resource.component.scss"],
})
export class GridPagePhysicalResourceComponent {
 readonly GridPageActions = GridPageActions;

  icon = Icon;

  @Input() properties!: IGridPageModel;
  @Output() gridAction = new EventEmitter<GridPageActions>();
  exportExcelButtonProps: IButtonModel | undefined = undefined;
  exportPDFButtonProps: IButtonModel | undefined = undefined;
  ngOnInit(): void {
    this.initializePanel();
    //console.log(this.properties.dataSource);
  }
  private initializePanel(): void {
    this.exportExcelButtonProps = {
      label: 'EXPORTAR A EXCEL',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.exportPDFButtonProps = {
      label: 'EXPORTAR A PDF',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };
  }
  dispatchAction(action: GridPageActions): void {
    this.gridAction.emit(action);
  }}
