import { CameraModel, IPropsCameraModel } from '@shared/models/camera.model';
import {
  IPropsSituationsModel,
  SituationsModel,
} from '@shared/models/situations.model';
import { IPropsCo2Model, Co2Model } from '@shared/models/co2.model';
import { IPropsTrafficModel, TrafficModel } from '@shared/models/traffic.model';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import {
  IPropsTrafficSpeedRangeModel,
  TrafficSpeedRangeModel,
} from '@shared/models/traffic-speed-range.model';
import {
  IPropsTrafficConcordanceModel,
  TrafficConcordanceModel,
} from '@shared/models/traffic-concordance.model';
import {
  IPropsRoadCorridorsModel,
  RoadCorridorsModel,
} from '@shared/models/road-corridors.model';

import {
  IPropsBicyclePathModel,
  BicyclePathModel,
} from '@shared/models/bicycle-path.model';
import {
  IPropsTrafficLightModel,
  TrafficLightModel,
} from '@shared/models/traffic-light.model';
import { AforoModel, IPropsAforoModel } from '@shared/models/aforo.model';
import { AgentsModel, IPropsAgentsModel } from '@shared/models/agents.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { iconClassIncidentsMap, iconClassSituationsMap } from './geo-marker-map';
import {
  AgentsHistoricalModel,
  IPropsAgentsHistoricalModel,
} from '@shared/models/agents-historical.model';
import {
  IncidentsHeatMapModel,
  IPropsIncidentsHeatMapModel,
} from '@shared/models/incidents-heat-map.model';
import {
  TrafficManagmentPlansPathModel,
  IPropsTrafficManagmentPathModel,
} from '@shared/models/trafficmanagmentplans-path.model';
import {
  StopBusPathModel,
  IPropsStopBusPathModel,
} from '@shared/models/stopbus-path.model';
import {
  ZonesPathModel,
  IPropsZonesPathModel,
} from '@shared/models/zones-path.model';
import {
  RoadRunPathModel,
  IPropsRoadRunPathModel,
} from '@shared/models/roadrun-path.model';
import {
  AccidentModel,
  IPropsAccidentModel,
} from '@shared/models/accident.model';
import { IPmvModel, PmvModel } from '@shared/models/pmv.model';
import {
  IPropsRuntDatexModel,
  RuntDatexModel,
} from '@shared/models/runt-datex.model';
import { IcoiProp, coiModel } from '@shared/models/coi.model';
import { AgentsNumberModel, IPropsAgentsNumberModel } from '@shared/models/agents-number.model';
import { geo } from "@amcharts/amcharts4/maps";
import { IPropsVehiclesModel, VehiclesModel } from '@shared/models/vehicles.model';
import { ICoosLineModel, coosLineModel } from '@shared/models/coosLine-model';
import { CoosModel, IPropsCoosModel } from '@shared/models/coos.model';
import { IPropsTrafficJamWazeModel, TrafficJamWazeModel } from "@shared/models/traffic-jams-waze.model";
import { IcoilineProp, coilineModel } from '@shared/models/coiline.model';
import { ExodusAndReturnWazeModel, IPropsExodusAndReturnWazeModel } from '@shared/models/exodus-and-return-waze.model';
import { IPropsWazeDataTrafficModel, WazeDataTrafficModel } from "@shared/models/WazeDataTrafficModel";
import { AlertsDuplicatesWazeModel, AlertsWazeModel, IPropsTAlertsWazeModel } from "@shared/models/alerts-waze-model";
import { GeoAPITypeEnum } from "@shared/models/geo-api-type.model";
import { GeoApiService } from "@shared/services/geo-api-service/geo-api.service";
import { CranesModel, IPropsCranesModel } from '@shared/models/cranes.model';
import { CranesNumberModel, IPropsCranesNumberModel } from '@shared/models/cranes-number.model';
import { CranesHistoricalModel, IPropsCranesHistoricalModel } from '@shared/models/cranes-historical.model';
import { WazeTrasnslate } from '@types-custom/models/ui/waze-enum.model';
import { IPropsLocationsModel, IncidentsLocationsModel, incidentsTypeEnum } from "@shared/models/LocationsIncidents.model";
import { FieldResourcesLocationsModel, IPropsFieldResourcesLocationsModel } from "@shared/models/field-resources-model";
import {
  IncidentsFieldResourcesModel,
  IPropsIncidentsFieldResourcesModel
} from "@shared/models/incidents-field-resources-model";
import { HistoryGroundResourceModel, IPropsHistoryGroundResourceModel } from '@shared/models/HistoryGroundResource.model';
import { NumberGroundResourceModel, IPropsNumberGroundResourceModel } from '@shared/models/numberGroundResource.model';
import {
  IPropsResourcesPredictiveModelLocationsModel,
  ResourcesPredictiveModelLocationsModel
} from "@shared/models/ResourcesPredictiveModelLocations.model";
import { BikesModel, IPropsBikesModel } from "@shared/models/Bikes.model";
import { incidentAddResourcesModel } from '@shared/models/incidentAddResources.model';
import {AirQualityModel, IPropsAirQualityModel} from "@shared/models/air_quality/AirQuality.model";

export const co2FromAPIMapper = (apiObj: any): Co2Model => {
  const propsCo2: IPropsCo2Model = {
    id: apiObj?.properties?.id,
    title: apiObj?.properties?.address,
    markerPopupText: apiObj?.properties?.address,
    location: apiObj?.sensors?.address,
    carbonDioxide: apiObj?.sensors?.co2,
    temperature: apiObj?.sensors?.temperature,
    humidity: apiObj?.sensors?.humidity,
    pressure: apiObj?.sensors?.pressure,
    icon: Icon.sensor_blue,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };
  return new Co2Model(propsCo2, geometry);
};

export const CoiFromAPIMapper = (apiObj: any): coiModel => {
  const propsCoi: IcoiProp = {
    id: apiObj?.attributes?.OBJETCID,
    title: `ID #${apiObj?.attributes?.CID} - ${apiObj?.attributes?.TCOI} - ${apiObj?.attributes?.DINI}`,
    location: apiObj?.attributes?.LOCA,
    icon: Icon.coiMap,
    observaciones: apiObj?.attributes?.OBSE,
    contratista: apiObj?.attributes?.CONT,
    fini: apiObj?.attributes.FINI,
    ffin: apiObj?.attributes.FFIN,
    direccion: apiObj?.attributes.DINI,
    tcoi: apiObj?.attributes.TCOI,
    htra: apiObj?.attributes.HTRA,
    hcie: apiObj?.attributes.HCIE,
    alt: apiObj?.alt,
    markerPopupText: apiObj?.attributes.DINI,
  };

  const geometry = {
    coordinates: [apiObj?.geometry?.x, apiObj?.geometry?.y],
    type: GeometryTypeEnum.Point
  };

  return new coiModel(propsCoi, geometry);
};

export const CoilineFromAPIMapper = (apiObj: any): coilineModel => {

  const propsCoiline: IcoilineProp = {
    id: apiObj?.attributes?.OBJETCID,
    title: `ID #${apiObj?.attributes?.CID} - ${apiObj?.attributes?.TCOI} - ${apiObj?.attributes?.DINI}`,
    location: apiObj?.attributes?.LOCA,
    icon: Icon.coiMap,
    observaciones: apiObj?.attributes?.OBSE,
    contratista: apiObj?.attributes?.CONT,
    fini: apiObj?.attributes.FINI,
    ffin: apiObj?.attributes.FFIN,
    direccion: apiObj?.attributes.DINI,
    tcoi: apiObj?.attributes.TCOI,
    htra: apiObj?.attributes.HTRA,
    hcie: apiObj?.attributes.HCIE,
    alt: apiObj?.alt,
    markerPopupText: apiObj?.attributes.DINI,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.paths[0],
    type: GeometryTypeEnum.LineString
  };

  return new coilineModel(propsCoiline, geometry);
};

export const aforoFromAPIMapper = (apiObj: any): AforoModel => {
  const propsAforo: IPropsAforoModel = {
    id: apiObj?.properties.id,
    address: apiObj?.properties.address,
    title: apiObj?.properties.address,
    name: apiObj?.properties.name,
    cameraType: apiObj?.properties.cameraType,
    status: apiObj?.properties.status,
    description: apiObj?.properties.description,
    markerPopupText: apiObj?.properties.address,
    icon: Icon.density_w,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AforoModel(propsAforo, geometry);
};

export const situationsFromAPIMapper = (apiObj: any): SituationsModel => {
  const propsSituations: IPropsSituationsModel = {
    id: apiObj?.properties?.id,
    situationType: apiObj?.properties?.incidentType,
    creationDate: apiObj?.properties?.creationDate,
    eventDate: apiObj?.properties?.eventDate,
    status: apiObj?.properties?.status,
    title: `ID ${apiObj?.properties?.id} - ${apiObj?.properties?.location}`,
    markerPopupText: `ID ${apiObj?.properties?.id} - ${apiObj?.properties?.location}`,
    icon: iconClassSituationsMap[apiObj?.properties?.incidentType],
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new SituationsModel(propsSituations, geometry);
};

export const trafficFromAPIMapper = (apiObj: any): TrafficModel => {
  const propsTraffic: IPropsTrafficModel = {
    id: apiObj?.properties?.id,
    nameFrom: apiObj?.properties?.nameFrom,
    nameTo: apiObj?.properties?.nameTo,
    distance: apiObj?.properties?.distance,
    levelOfService: apiObj?.properties?.levelOfService,
    concordance: apiObj?.properties?.concordanceIndex,
    speed: apiObj?.properties?.speed,
    elapsedTime: apiObj?.properties?.elapsedTime,
    expectedValue: apiObj?.properties?.expectedValue,
    readings: apiObj?.properties?.readings,
    readTime: apiObj?.properties?.readTime,
    time: apiObj?.properties?.time,
    speedRange: apiObj?.speedRange,
    geometry: apiObj?.geometry,
    apiObj,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new TrafficModel(propsTraffic, geometry);

  // let model: TrafficModel;

  // if (apiObj?.geometry?.type === GeometryTypeEnum.Point) {
  //   const geometry = {
  //     type: apiObj?.geometry?.type,
  //     lat: apiObj?.geometry?.coordinates?.[1],
  //     lng: apiObj?.geometry?.coordinates?.[0],
  //   };
  //   model = new TrafficModel(propsTraffic, geometry);
  // } else {
  //   const geometry = {
  //     type: apiObj?.geometry?.type,
  //     coordinates: apiObj?.geometry?.coordinates,
  //   };
  //   const lat =
  //     geometry.type === GeometryTypeEnum.LineString
  //       ? geometry.coordinates[0][1]
  //       : geometry.coordinates[0][0][1];
  //   const lng =
  //     geometry.type === GeometryTypeEnum.LineString
  //       ? geometry.coordinates[0][0]
  //       : geometry.coordinates[0][0][0];

  //   model = new TrafficModel(propsTraffic, geometry, lat, lng);
  // }
};

export const cameraFromAPIMapper = (apiObj: any): CameraModel => {
  const propsCamera: IPropsCameraModel = {
    id: apiObj?.properties?.id,
    icon: Icon.map_cameras,
    address: apiObj?.properties?.address,
    name: apiObj?.properties?.name,
    markerPopupText: `${apiObj?.properties?.address}`,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new CameraModel(propsCamera, geometry);
};

export const IncidentsFromAPIMapper = (apiObj: any): IncidentsLocationsModel => {
  const union: any = `${apiObj?.properties?.priorityName < 4 && apiObj?.properties?.classesName ? apiObj?.properties?.classesName + apiObj?.properties?.priorityName : "default"}`

  const propsLocationsIncidents: IPropsLocationsModel = {
    id: apiObj?.properties?.id,
    address: apiObj?.properties?.address,
    latitude: apiObj?.properties?.latitude,
    longitude: apiObj?.properties?.longitude,
    location_name: apiObj?.properties?.locationName,
    class_name: apiObj?.properties?.classesName,
    type_name: apiObj?.properties?.typeName,
    priority_name: apiObj?.properties?.priorityName,
    source_name: apiObj?.properties?.sourceName,
    state_name: apiObj?.properties?.stateName,
    create_date: apiObj?.properties?.incidentTime,
    gravity_name: apiObj?.properties?.gravityName,
    corredor_name: apiObj?.properties?.brokerName,
    description: apiObj?.properties?.observations,
    incident_time: apiObj?.properties?.incidentTime,
    markerPopupText: `${apiObj?.properties?.address}`,
    icon: iconClassIncidentsMap[union],  //Siniestro ,Varado, Semaforización Transporte masivo ,masivo,Incidente en vía
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type
  };

  return new IncidentsLocationsModel(propsLocationsIncidents, geometry);
}




export const trafficSpeedRangeFromAPIMapper = (
  apiObj: any
): TrafficSpeedRangeModel => {
  const propsTrafficSpeedRange: IPropsTrafficSpeedRangeModel = {
    id: apiObj?.properties?.id,
    nameFrom: apiObj?.properties?.nameFrom,
    nameTo: apiObj?.properties?.nameTo,
    distance: apiObj?.properties?.distance,
    levelOfService: apiObj?.properties?.levelOfService,
    composed_name: apiObj?.properties?.composed_name,
    concordance: apiObj?.properties?.concordanceIndex,
    speed: apiObj?.properties?.speed,
    elapsedTime: apiObj?.properties?.elapsedTime || apiObj?.properties?.readTime,
    expectedValue: apiObj?.properties?.expectedValue || apiObj?.properties?.expectValue,
    readings: apiObj?.properties?.readings,
    readTime: apiObj?.properties?.readTime,
    time: apiObj?.properties?.time,
    icon: Icon.road_speed_w,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new TrafficSpeedRangeModel(propsTrafficSpeedRange, geometry);
};

export const exodusAndReturnWazeFromAPIMapper = (
  apiObj: any
): ExodusAndReturnWazeModel => {
  const propsTrafficSpeedRange: IPropsExodusAndReturnWazeModel = {
    id: apiObj?.properties?.id,
    nameFrom: apiObj?.properties?.nameFrom,
    nameTo: apiObj?.properties?.nameTo,
    distance: apiObj?.properties?.distance,
    levelOfService: apiObj?.properties?.levelOfService,
    concordance: apiObj?.properties?.concordanceIndex,
    speed: apiObj?.properties?.speed,
    elapsedTime: apiObj?.properties?.elapsedTime,
    expectedValue: apiObj?.properties?.expectedValue,
    readings: apiObj?.properties?.readings,
    readTime: apiObj?.properties?.readTime,
    time: apiObj?.properties?.time,
    speedRange: apiObj?.speedRange,
    icon: Icon.road_speed_w,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new ExodusAndReturnWazeModel(propsTrafficSpeedRange, geometry);
};

export const trafficConcordanceFromAPIMapper = (
  apiObj: any
): TrafficConcordanceModel => {
  const propsTrafficConcordance: IPropsTrafficConcordanceModel = {
    id: apiObj?.properties?.id,
    nameFrom: apiObj?.properties?.nameFrom,
    nameTo: apiObj?.properties?.nameTo,
    distance: apiObj?.properties?.distance,
    levelOfService: apiObj?.properties?.levelOfService,
    concordance: apiObj?.properties?.concordanceIndex,
    composed_name: apiObj?.properties?.composedName,
    speed: apiObj?.properties?.speed,
    elapsedTime: apiObj?.properties?.elapsedTime,
    expectedValue: apiObj?.properties?.expectedValue || apiObj?.properties?.expectValue,
    readings: apiObj?.properties?.readings,
    readTime: apiObj?.properties?.readTime,
    time: apiObj?.properties?.time,
    icon: Icon.map_traffic_light,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };
  return new TrafficSpeedRangeModel(propsTrafficConcordance, geometry);
};

export const RoadCorridorsFromAPIMapper = (apiObj: any, listType: GeoAPITypeEnum): RoadCorridorsModel => {
  const propsRoadCorridors: IPropsRoadCorridorsModel = {
    tid: apiObj.properties.id,
    id: apiObj.properties.id,
    composed_name: apiObj.properties.composedName,
    name_from: apiObj.properties.nameFrom,
    name_to: apiObj.properties.nameTo,
    orient: apiObj.properties.orient,
    distance: apiObj.properties.distance,
    read_time: apiObj.properties.readTime,
    speed: apiObj.properties.speed,
    time: apiObj.properties.time,
    total_incidents: apiObj.properties.totalIncidents,
    icon: Icon.map_traffic_light,
    nameFrom: apiObj?.properties?.nameFrom,
    nameTo: apiObj?.properties?.nameTo,
    levelOfService: apiObj?.properties?.levelOfService,
    concordance: apiObj?.properties?.concordanceIndex,
    elapsedTime: apiObj?.properties?.elapsedTime,
    expectedValue: apiObj?.properties?.expectedValue || apiObj?.properties?.expectValue,
    readings: apiObj?.properties?.readings,
    readTime: apiObj?.properties?.readTime,
    speedRange: apiObj?.speedRange,
    geometry: apiObj?.geometry,
    isCorridor: (GeoAPITypeEnum.TrafficConcordanceCorridors === listType || GeoAPITypeEnum.RoadCorridors === listType),
    apiObj,
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new RoadCorridorsModel(propsRoadCorridors, geometry);
};

export const TrafficJamWazeFromAPIMapper = (apiObj: any): TrafficJamWazeModel => {
  const propTrafficJamWaze: IPropsTrafficJamWazeModel = {
    id: apiObj?.properties.uuid,
    tid: apiObj?.properties.uuid,
    uuid: apiObj?.properties.uuid,
    street: apiObj?.properties.street,
    speedkmh: apiObj?.properties.speedKmH,
    length0: apiObj?.properties.length,
    delay: apiObj?.properties.delay,
    level: apiObj?.properties.level,
    receptiontime: apiObj?.properties.receptionTime,
    pubtime: apiObj?.properties.pubTime,
    updpubmilis: apiObj?.properties.updpubmilis,
    icon: Icon.map_traffic_light,
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new TrafficJamWazeModel(propTrafficJamWaze, geometry);
};

export const AlertsWazeFromAPIMapper = (apiObj: any): AlertsWazeModel => {
  const propAlertsWaze: IPropsTAlertsWazeModel = {
    reliability: apiObj?.properties.reliability,
    type: apiObj?.properties.type,
    subType: apiObj?.properties.subType,
    pubTime: apiObj?.properties.pubTime,
    receptionTime: apiObj?.properties.receptionTime,
    description: apiObj?.properties.description,
    street: apiObj?.properties.street,
    locality: apiObj?.properties.locality,
    localityId: apiObj?.properties.localityId,
    timeAlerts: apiObj?.properties.timeAlertMin,
    markerPopupText: `<div style="display:flex;justify-content: center;margin-top:0.3rem;">
                    ${apiObj.properties.street} <br>
                    </div>
                   ${(WazeTrasnslate[apiObj.properties.subType as keyof typeof WazeTrasnslate]) || '<br/>'} 
                     <div style="display: flex;justify-content: center;">
                    Duracion: ${apiObj?.properties.timeAlertMin} min
                    </div>`,
    icon: (apiObj?.properties.type == 'WEATHERHAZARD') ? Icon.map_climate :
      (apiObj?.properties.type == 'ACCIDENT') ? Icon.map_accident : Icon.map_roadClosed
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AlertsWazeModel(propAlertsWaze, geometry);
};

export const WazeTrafficDataFromAPIMapper = (apiObj: any): WazeDataTrafficModel => {
  const propsWazeDataTraffic: IPropsWazeDataTrafficModel = {
    id: apiObj.properties.id,
    uuid: apiObj?.properties.id,
    tid: apiObj?.properties.id,
    velocity: apiObj.properties.velocity,
    length: apiObj.properties.length,
    receptionDate: apiObj.properties.receptionDate,
    name: apiObj?.properties?.name,
    markerPopupText: `<div style="display: flex;justify-content: center;">
    ${(apiObj.properties.corridor ?? apiObj.properties.name)}<br>
    </div>
    <div style="display: flex;justify-content: center;margin-top:0.3rem">
    ${apiObj?.properties?.velocity.toFixed(2)} Km/h
    </div>`,
    publicationDate: apiObj.properties.publicationDate,
    historyTimeMinutes: apiObj.properties.historyTimeMinutes,
    timeMinutes: apiObj.properties.timeMinutes,
    updateDate: apiObj.properties.updateDate,
    category: apiObj.properties.category,
    orientation: apiObj.properties.orientation,
    corridor: apiObj.properties.corridor,
    speedRange: apiObj?.speedRange,
    icon: Icon.waze,
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };


  return new WazeDataTrafficModel(propsWazeDataTraffic, geometry);
};

export const bicyclePathFromAPIMapper = (apiObj: any): BicyclePathModel => {
  const propsBicycle: IPropsBicyclePathModel = {
    id: apiObj?.properties?.id,
    type: apiObj?.properties?.TIPO,
    distance: apiObj?.properties?.DISTANCIA,
    width: apiObj?.properties?.ANCHO,
    shapeLength: apiObj?.properties?.Shape_Leng,
    editor: apiObj?.properties?.EDITOR,
    date: apiObj?.properties?.FECHA,
    status: apiObj?.properties?.ESTADO,
    speed: apiObj?.properties?.VELOCIDAD,
    slope: apiObj?.properties?.PENDIENTE,
    title: `CICLORRUTA ID ${apiObj?.properties?.id}`,
    markerPopupText: `Ruta - ${apiObj?.properties?.NOMBREVIA}`,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new BicyclePathModel(propsBicycle, geometry);
};

export const CoosLineFromAPIMapper = (apiObj: any): coosLineModel => {
  const propsCoosLineProp: ICoosLineModel = {
    id: apiObj?.attributes?.id,
    location: apiObj?.attributes?.LOCA,
    hourClose: apiObj?.attributes?.HCIE,
    hourWork: apiObj?.attributes?.HTRA,
    contractor: apiObj?.attributes?.CONT,
    observations: apiObj?.attributes?.OBSE,
    typeCoos: apiObj?.attributes?.TCOO,
    typeInt: apiObj?.attributes?.ECON,
    fechaIni: apiObj?.attributes?.FINI,
    fechaFin: apiObj?.attributes?.FFIN,
    shapeLength: apiObj?.attributes?.Shape_Leng,
    title: apiObj?.attributes?.DINI,
    markerPopupText: apiObj?.attributes?.DINI,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.paths[0],
    type: GeometryTypeEnum.LineString,
  };

  return new coosLineModel(propsCoosLineProp, geometry);
};

export const CoosFromAPIMapper = (apiObj: any): CoosModel => {
  const propsCoos: IPropsCoosModel = {
    id: apiObj?.attributes?.OBJECTID,
    location: apiObj?.attributes?.LOCA,
    hourClose: apiObj?.attributes?.HCIE,
    hourWork: apiObj?.attributes?.HTRA,
    contractor: apiObj?.attributes?.CONT,
    observations: apiObj?.attributes?.OBSE,
    typeCoos: apiObj?.attributes?.TCOO,
    typeInt: apiObj?.attributes?.ECON,
    fechaIni: apiObj?.attributes?.FINI,
    fechaFin: apiObj?.attributes?.FFIN,
    title: `ID:${apiObj?.attributes?.OBJECTID} - ${apiObj?.attributes?.TCOO} - ${apiObj?.attributes?.DINI}`,
    markerPopupText: apiObj?.attributes?.DINI,
    icon: Icon.map_Coos,
  };

  const geometry = {
    coordinates: [apiObj?.geometry?.x, apiObj?.geometry?.y],
    type: GeometryTypeEnum.Point,
  };

  return new CoosModel(propsCoos, geometry);
};

export const trafficLightFromAPIMapper = (apiObj: any): TrafficLightModel => {
  const propsTrafficLight: IPropsTrafficLightModel = {
    direccion: apiObj?.properties?.DIRECCION,
    localidad: apiObj?.properties?.LOCALIDAD,
    operacion: apiObj?.properties?.OPERACION,
    tipo_inter: apiObj?.properties?.TIPO_INTER,
    infra_cicl: apiObj?.properties?.INFRA_CICL,
    codigo: apiObj?.properties?.COD_ID_INT,
    title: apiObj?.properties?.DIRECCION,
    markerPopupText: apiObj?.properties?.DIRECCION,
    icon: Icon.map_traffic_light,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new TrafficLightModel(propsTrafficLight, geometry);
};

export const agentsFromAPIMapper = (apiObj: any): AgentsModel => {

  const propsAgents: IPropsAgentsModel = {
    id: String(apiObj?.properties?.devId),
    name: apiObj?.properties?.devName,
    agentType:
      apiObj?.properties?.deviceGroupId === 25
        ? 'transit'
        : 'police',
    updatedDate: apiObj?.properties?.readTime,
    title: `ID # ${apiObj?.properties?.devName}`,
    grpName: apiObj?.properties?.grpName,
    devId: apiObj?.properties?.devId,
    speed: apiObj?.properties?.speed,
    locName: apiObj?.properties?.locName,
    markerPopupText: `
    <div style="display: flex;justify-content: center;">
     ID <br> 
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.devName}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.locName}</span>
    </div>
    `,
    icon:
      apiObj?.properties?.deviceGroupId === 25
        ? Icon.map_transit_update
        : Icon.map_police_update
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AgentsModel(propsAgents, geometry);
};

export const agentsNumberFromAPIMapper = (apiObj: any): AgentsNumberModel => {
  const propsAgents: IPropsAgentsNumberModel = {
    id: String(apiObj?.properties?.LocCodigo),
    name: apiObj?.properties?.LocNombre,
    agentType: 'transit',
    updatedDate: '',
    Qty: apiObj.properties.Qty,
    density: apiObj.properties.density,
    LocNombre: apiObj.properties.LocNombre,
    title: `${apiObj?.properties?.LocNombre}`,
    markerPopupText: `<img src="../../../../assets/icons/agents_green.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
    <span class="mr-3-em" style="margin-left:2.1rem">${apiObj.properties.Qty}</span> <span style="margin-left:2.9rem">${apiObj.properties.density.toFixed(2)}</span> <br> 
    <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
    <span style="display:flex;justify-content:center">${apiObj?.properties?.LocNombre}</span>`,
    icon: Icon.agents_blue
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type
  };

  return new AgentsNumberModel(propsAgents, geometry);

};

export const groundNumberFromAPIMapper = (apiObj: any): NumberGroundResourceModel => {
  const propsResources: IPropsNumberGroundResourceModel = {
    id: String(apiObj?.properties?.LocCodigo),
    name: apiObj?.properties?.LocNombre,
    groupName: 'all-resources',
    updatedDate: '',
    Qty: apiObj.properties.Qty,
    density: apiObj.properties.density,
    LocNombre: apiObj.properties.LocNombre,
    title: `${apiObj?.properties?.LocNombre}`,
    markerPopupText: `<img src="../../../../assets/icons/FieldResourceWhite.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
      <span class="mr-3-em" style="margin-left:2.1rem">${apiObj.properties.Qty}</span> <span style="margin-left:2.9rem">${apiObj.properties.density.toFixed(2)}</span> <br> 
      <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
      <span style="display:flex;justify-content:center">${apiObj?.properties?.LocNombre}</span>`,
    icon: Icon.ResourcesWhite
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type
  };

  return new NumberGroundResourceModel(propsResources, geometry);

};


export const FieldResourcesAPIMapper = (apiObj: any): FieldResourcesLocationsModel => {
  const propsResources: IPropsFieldResourcesLocationsModel = {
    id: String(apiObj?.properties?.id),
    firstName: apiObj?.properties?.firstName,
    lastName: apiObj?.properties?.lastName,
    documentTypeId: apiObj?.properties?.documentTypeId,
    documentTypeName: apiObj?.properties?.documentTypeName,
    document: apiObj?.properties?.document,
    email: apiObj?.properties?.email,
    phone: apiObj?.properties?.phone,
    stateId: apiObj?.properties?.stateId,
    stateName: apiObj?.properties?.stateName,
    groupId: apiObj?.properties?.groupId,
    groupName: apiObj?.properties?.groupName,
    indicative: apiObj?.properties?.indicative,
    jobId: apiObj?.properties?.jobId,
    jobName: apiObj?.properties?.jobName,
    updateLocationDate: apiObj?.properties?.updateLocationDate,
    localityName: apiObj?.properties?.localityName,
    markerPopupText: `
    <div style="display: flex;justify-content: center;">
     ID <br> 
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.id}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.localityName}</span>
    </div>
    `,
    icon:
      apiObj?.properties?.groupId === 17
        ? Icon.HelmetIconMap
        : apiObj?.properties?.groupId === 20
          ? Icon.CentipedeIconMap
          : Icon.CycleSchoolIconMap
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };


  return new FieldResourcesLocationsModel(propsResources, geometry);
}

function getIconType(properties: any) {
  if (properties.type === 'incident') {

    if (
      properties?.incidentClassName?.trim() !== 'Siniestro' &&
      properties?.incidentClassName?.trim() !== 'Varado' &&
      properties?.incidentClassName?.trim() !== 'Manifestación' &&
      properties?.incidentClassName?.trim() !== 'Incidente en vía' &&
      properties?.incidentClassName?.trim() !== 'Masivo' &&
      properties?.incidentClassName?.trim() !== 'Semaforización'
    ) {

      return iconClassIncidentsMap[properties?.incidentClassName?.trim()]
    }

    const union: any = `${properties?.incidentPriorityName < 4 && properties?.incidentClassName ? properties?.incidentClassName + properties?.incidentPriorityName : "default"}`

    return iconClassIncidentsMap[union]
  }

  return properties.resourceGroupId === 17 ? Icon.HelmetIconMap : properties.resourceGroupId === 19 ? Icon.CentipedeIconMap : Icon.CycleSchoolIconMap;
}

export const BikeFromAPIMapper = (apiObj: any): BikesModel => {
  const propsBikes: IPropsBikesModel = {
    id: apiObj?.properties?.id,
    name: apiObj?.properties?.name,
    markerPopupText: apiObj?.properties?.name,
    locality: apiObj?.properties?.locality,
    flow: apiObj?.properties?.flow,
    orientation: apiObj?.properties?.orientation,
    icon: Icon.BikeDisabled,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: GeometryTypeEnum.Point,
  };

  return new BikesModel(propsBikes, geometry);
};


export const ResourcePredictiveModelAPIMapper = (apiObj: any): ResourcesPredictiveModelLocationsModel => {
  const propsResources: IPropsResourcesPredictiveModelLocationsModel = {
    id: apiObj.properties?.id,
    agentId: apiObj.properties?.agentId,
    day: apiObj.properties?.day,
    fileName: apiObj.properties?.fileName,
    groupId: apiObj.properties?.groupId,
    groupName: apiObj.properties?.groupName,
    hash: apiObj.properties?.hash || null,
    hour: apiObj.properties?.hour,
    processDate: apiObj.properties?.processDate,
    localityId: apiObj.properties?.localityId,
    localityName: apiObj.properties?.localityName,
    type: apiObj.properties?.type,
    markerPopupText:
      `
      <div style="display: flex;justify-content: center;">
      <span style="color: white;">Indicativo&nbsp;${apiObj?.properties?.agentId}</span> <br>
      </div>
      <div style="display: flex;justify-content: center;">
      <span style="color: white;">Localidad&nbsp;${apiObj?.properties?.localityName}</span>
      </div>
    `,
    icon: (apiObj.properties?.type === "agents" ? Icon.PredictiveAgents : Icon.PredictiveCrane)
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates || [-74.05778682148076,
      4.6958921607502475],
    type: apiObj?.geometry?.type,
  };

  return new ResourcesPredictiveModelLocationsModel(propsResources, geometry);
}


export const GrouendResourceHistoryAPIMapper = (apiObj: any): HistoryGroundResourceModel => {

  const propsResources: IPropsHistoryGroundResourceModel = {
    id: String(apiObj?.properties?.id),
    firstName: apiObj?.properties?.firstName,
    lastName: apiObj?.properties?.lastName,
    documentTypeId: apiObj?.properties?.documentTypeId,
    documentTypeName: apiObj?.properties?.documentTypeName,
    document: apiObj?.properties?.document,
    email: apiObj?.properties?.email,
    phone: apiObj?.properties?.phone,
    stateId: apiObj?.properties?.stateId,
    stateName: apiObj?.properties?.stateName,
    groupId: apiObj?.properties?.groupId,
    groupName: apiObj?.properties?.groupName,
    indicative: apiObj?.properties?.indicative,
    jobId: apiObj?.properties?.jobId,
    jobName: apiObj?.properties?.jobName,
    updatedDate: apiObj?.properties?.updateLocationDate,
    localityName: apiObj?.properties?.localityName,
    time: apiObj?.properties?.time,
    markerPopupText: apiObj?.properties?.firstName,
    icon: Icon.dot_w,
    updateLocationDate: apiObj?.properties?.updateLocationDate,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };


  return new HistoryGroundResourceModel(propsResources, geometry);
}

export const HumanResourceListAPIMapper = (apiObj: any): incidentAddResourcesModel => {
  const GROUPBIKE = 19 ;
  const GROUPGUIDE = 17 ;

  const propsResources: IPropsHistoryGroundResourceModel = {
    id: String(apiObj?.properties?.id),
    firstName: apiObj?.properties?.firstName,
    lastName: apiObj?.properties?.lastName,
    documentTypeId: apiObj?.properties?.documentTypeId,
    documentTypeName: apiObj?.properties?.documentTypeName,
    document: apiObj?.properties?.document,
    email: apiObj?.properties?.email,
    phone: apiObj?.properties?.phone,
    stateId: apiObj?.properties?.stateId,
    stateName: apiObj?.properties?.stateName,
    groupId: apiObj?.properties?.groupId,
    groupName: apiObj?.properties?.groupName,
    indicative: apiObj?.properties?.indicative,
    jobId: apiObj?.properties?.jobId,
    jobName: apiObj?.properties?.jobName,
    updatedDate: apiObj?.properties?.updateLocationDate,
    updateLocationDate: apiObj?.properties?.updateLocationDate,
    localityName: apiObj?.properties?.localityName,
    markerPopupText: `
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.indicative}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.jobName}</span>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.groupName}</span> <br>
    </div>
    `,
    icon: apiObj?.properties?.groupId === GROUPGUIDE ?
              Icon.HelmetIconMap : apiObj?.properties?.groupId === GROUPBIKE ?
                          Icon.CycleSchoolIconMap : Icon.CentipedeIconMap,
    time: apiObj?.properties?.updateLocationDate,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };


  return new incidentAddResourcesModel(propsResources, geometry);
}

export const IncidentsFieldResourcesAPIMapper = (apiObj: any): IncidentsFieldResourcesModel => {
  const propsResources: IPropsIncidentsFieldResourcesModel = {
    type: apiObj?.properties?.type,
    incidentId: apiObj?.properties?.incidentId,
    incidentClassId: apiObj?.properties?.incidentClassId,
    incidentClassName: apiObj?.properties?.incidentClassName,
    incidentTypeId: apiObj?.properties?.incidentTypeId,
    incidentTypeName: apiObj?.properties?.incidentTypeName,
    incidentPriorityId: apiObj?.properties?.incidentPriorityId,
    incidentPriorityName: apiObj?.properties?.incidentPriorityName,
    incidentAddress: apiObj?.properties?.incidentAddress,
    incidentStatusId: apiObj?.properties?.incidentStatusId,
    incidentStatusName: apiObj?.properties?.incidentStatusName,
    incidentTime: apiObj?.properties?.incidentTime,
    incidentSourceId: apiObj?.properties?.incidentSourceId,
    incidentSourceName: apiObj?.properties?.incidentSourceName,
    incidentLocalityName: apiObj?.properties?.incidentLocalityName,
    incidentCountsGroup: apiObj?.properties?.incidentCountsGroup,
    resourceId: apiObj?.properties?.resourceId,
    resourceFirstName: apiObj?.properties?.resourceFirstName,
    resourceLastName: apiObj?.properties?.resourceLastName,
    resourceDocumentTypeId: apiObj?.properties?.resourceDocumentTypeId,
    resourceDocumentTypeName: apiObj?.properties?.resourceDocumentTypeName,
    resourceDocument: apiObj?.properties?.resourceDocument,
    resourceEmail: apiObj?.properties?.resourceEmail,
    resourcePhone: apiObj?.properties?.resourcePhone,
    resourceStateId: apiObj?.properties?.resourceStateId,
    resourceStateName: apiObj?.properties?.resourceStateName,
    resourceGroupId: apiObj?.properties?.resourceGroupId,
    resourceGroupName: apiObj?.properties?.resourceGroupName,
    resourceIndicative: apiObj?.properties?.resourceIndicative,
    resourceJobId: apiObj?.properties?.resourceJobId,
    resourceJobName: apiObj?.properties?.resourceJobName,
    resourceUpdateLocationDate: apiObj?.properties?.resourceUpdateLocationDate,
    resourceLocalityName: apiObj?.properties?.resourceLocalityName,
    markerPopupText:
      apiObj?.properties?.type === 'incident' ?
        `
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.incidentAddress}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.incidentTypeName}</span>
    </div>
    `
        :
        `
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">ID&nbsp;${apiObj?.properties?.resourceId}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.resourceGroupName}</span>
    </div>
    `,
    icon: getIconType(apiObj?.properties)
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates || [-74.05778682148076,
      4.6958921607502475],
    type: apiObj?.geometry?.type,
  };

  return new IncidentsFieldResourcesModel(propsResources, geometry);
}

export const vehiclesFromAPIMapper = (apiObj: any): VehiclesModel => {

  const propsVehicles: IPropsVehiclesModel = {
    id: String(apiObj?.properties?.siteid),
    siteId: String(apiObj?.properties?.siteId),
    address: apiObj?.properties?.address,
    title: `${apiObj?.properties?.address}`,
    markerPopupText: `${apiObj?.properties?.address}`,
    icon: Icon.map_vehicle
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type
  };

  return new VehiclesModel(propsVehicles, geometry);
};

export const cranesFromAPIMapper = (apiObj: any): CranesModel => {
  const propsAgents: IPropsCranesModel = {
    id: String(apiObj?.properties?.plate),
    speed: apiObj?.properties?.speed,
    location: apiObj?.properties?.locality,
    time: apiObj?.properties?.readTime,
    status: apiObj?.properties?.stateName,
    inactive: apiObj?.properties?.state,
    typeField: apiObj?.properties?.craneType,
    title: `ID # ${apiObj?.properties?.plate} - ${apiObj?.properties?.stateName}`,
    markerPopupText: `
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.craneType}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.stateName}</span> <br>
    </div>
    <div style="display: flex;justify-content: center;">
    <span style="color: white;">${apiObj?.properties?.plate}</span>
    </div>
    `,
    icon:
      apiObj?.properties?.stateName == 'Libre'
        ? Icon.map_crane_available
        : Icon.map_crane_laden
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new CranesModel(propsAgents, geometry);
};

export const trafficManagmentPlansFromAPIMapper = (
  apiObj: any
): TrafficManagmentPlansPathModel => {
  const propsTrafficManagment: IPropsTrafficManagmentPathModel = {
    id: apiObj?.properties?.objectid,
    globalid: apiObj?.properties?.globalid,
    objectid: apiObj?.properties?.OBJECTID_1,
    contratista: apiObj?.properties?.contratist,
    direccion: apiObj?.properties?.direccion,
    empresacon: apiObj?.properties?.empresacon,
    fechaampli: apiObj?.properties?.fechaampli,
    fechaaprob: apiObj?.properties?.fechaaprob,
    fechafin: apiObj?.properties?.fechafin,
    fechainici: apiObj?.properties?.fechainici_1,
    fechasegui: apiObj?.properties?.fechasegui,
    interven: apiObj?.properties?.interven_1,
    intervento: apiObj?.properties?.intervento,
    lastedite: apiObj?.properties?.lastedite,
    numerocelu: apiObj?.properties?.numerocelu,
    numeropmt: apiObj?.properties?.numeropmt,
    observacion: apiObj?.properties?.observacio,
    type: apiObj?.properties?.tipo_cierr,
    vigencia: apiObj?.properties?.vigencia,
    apiObj,
    geometry: apiObj?.geometry,
    title: `ID ${apiObj?.properties?.objectid}`,
    markerPopupText: `ID ${apiObj?.properties?.objectid}`,
    icon: Icon.map,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new TrafficManagmentPlansPathModel(propsTrafficManagment, geometry);
};

export const StopBusFromAPIMapper = (apiObj: any): StopBusPathModel => {
  const propsStopBus: IPropsStopBusPathModel = {
    id: apiObj?.attributes?.OBJECTID,
    paradaNombre: apiObj?.attributes?.NTRNOMBRE,
    paradaDireccion: apiObj?.attributes?.NTRDIRECCION,
    paradaTipo: apiObj?.attributes?.NTRMTRANSP,
    paradaCodigo: apiObj?.attributes?.NTRCODIGO,
    apiObj,
    geometry: apiObj?.geometry,
    title: apiObj?.attributes?.NTRNOMBRE,
    markerPopupText: `${apiObj?.attributes?.NTRNOMBRE}    ${apiObj?.attributes?.NTRDIRECCION}`,
    icon: Icon.map_bus_stop,
  };

  const geometry = {
    coordinates: [apiObj?.geometry?.x, apiObj?.geometry?.y],
    type: GeometryTypeEnum.Point
  };

  return new StopBusPathModel(propsStopBus, geometry);
};

export const agentsHistoricalFromAPIMapper = (apiObj: any): AgentsHistoricalModel => {
  const propsAgentsHistorical: IPropsAgentsHistoricalModel = {
    id: apiObj?.properties?.name,
    name: apiObj?.properties?.name,
    agentType: apiObj?.properties?.agentType,
    updatedDate: apiObj?.properties?.updatedDate,
    time: apiObj?.properties?.time,
    title: apiObj?.properties?.time,
    localidad: apiObj?.properties?.localidad,
    dev_id: apiObj?.properties?.name,
    markerPopupText: apiObj?.properties?.time,
    icon: Icon.dot_w,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };
  return new AgentsHistoricalModel(propsAgentsHistorical, geometry);
};

export const incidentsHeatMapModelFromAPIMapper = (apiObj: any): IncidentsHeatMapModel => {
  const propsIncidentsHeatMap: IPropsIncidentsHeatMapModel = {
    totalIncidents: apiObj?.properties?.totalIncidents,
    relativeWeight: apiObj?.properties?.relativeWeight,
    totalIncidentsOnHexagon: apiObj?.properties?.totalIncidentsOnHexagon,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new IncidentsHeatMapModel(propsIncidentsHeatMap, geometry);
};

export const agentsNumbersFromAPIMapper = (apiObj: any): AgentsNumberModel => {
  const propsAgentsHistorical: IPropsAgentsNumberModel = {
    id: String(apiObj?.properties?.LocCodigo),
    name: apiObj?.properties?.LocNombre,
    agentType: 'transit',
    updatedDate: '',
    Qty: apiObj.properties.Qty,
    LocNombre: apiObj.properties.LocNombre,
    density: apiObj.properties.density,
    title: `${apiObj?.properties?.LocNombre}`,
    markerPopupText: `<img src="../../../../assets/icons/agents_green.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
    <span class="mr-3-em" style="margin-left:2.1rem">${apiObj.properties.Qty}</span> <span style="margin-left:2.9rem">${apiObj.properties.density.toFixed(2)}</span> <br> 
    <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
    <span style="display:flex;justify-content:center">${apiObj?.properties?.LocNombre}</span>`,
    icon: Icon.check_black
  };


  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: GeometryTypeEnum.Point
  };


  return new AgentsNumberModel(propsAgentsHistorical, geometry);
};

export const cranesNumbersFromAPIMapper = (apiObj: any): CranesNumberModel => {
  const propsAgentsHistorical: IPropsCranesNumberModel = {
    id: String(apiObj?.properties?.LocCodigo),
    name: apiObj?.properties?.LocNombre,
    agentType: 'transit',
    updatedDate: '',
    Qty: apiObj.properties.Qty,
    LocNombre: apiObj.properties.LocNombre,
    density: apiObj.properties.density,
    title: `${apiObj?.properties?.LocNombre}`,
    markerPopupText: `<img src="../../../../assets/icons/crane_w.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
    <span class="mr-3-em" style="margin-left:2.1rem">${apiObj.properties.Qty}</span> <span style="margin-left:2.9rem">${apiObj.properties.density.toFixed(2)}</span> <br> 
    <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
    <span style="display:flex;justify-content:center">${apiObj?.properties?.LocNombre}</span>`,
    icon: Icon.check_black
  };


  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: GeometryTypeEnum.Point
  };


  return new CranesNumberModel(propsAgentsHistorical, geometry);
};

export const cranesHistoricalFromAPIMapper = (apiObj: any): CranesHistoricalModel => {
  const propsCranesHistorical: IPropsCranesHistoricalModel = {
    id: apiObj?.properties?.id,
    name: apiObj?.properties?.plate,
    updatedDate: apiObj?.properties?.coordinateDate,
    time: apiObj?.properties?.time,
    title: apiObj?.properties?.time,
    localidad: apiObj?.properties?.locality,
    markerPopupText: apiObj?.properties?.time,
    icon: Icon.dot_w,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new CranesHistoricalModel(propsCranesHistorical, geometry);
};


export const ZonesFromAPIMapper = (apiObj: any): ZonesPathModel => {
  const propsZones: IPropsZonesPathModel = {
    id: apiObj?.properties?.id,
    ancho: apiObj?.properties?.ANCHO,
    capacidad: apiObj?.properties?.CAPACIDAD,
    direccion: apiObj?.properties?.DIRECCION,
    editor: apiObj?.properties?.EDITOR,
    estado: apiObj?.properties?.ESTADO,
    fechaEstado: apiObj?.properties?.FECH_ESTAD,
    horaFin: apiObj?.properties?.HOR_FIN,
    horaInicio: apiObj?.properties?.HOR_INIC,
    largo: apiObj?.properties?.LARGO,
    objectId: apiObj?.properties?.OBJECTID,
    reglamento: apiObj?.properties?.REGLAMENT,
    tarifa: apiObj?.properties?.TARIFA,
    tarifaAuto: apiObj?.properties?.TARIFA_AUT,
    tarifaMoto: apiObj?.properties?.TARIFA_MOT,
    tipo: apiObj?.properties?.TIPO,
    apiObj,
    geometry: apiObj?.geometry,
    title: `ID ${apiObj?.properties?.DIRECCION}`,
    markerPopupText: `ID ${apiObj?.properties?.DIRECCION}`,
    icon:
      apiObj?.properties?.TIPO === 'CARGUE Y DESCARGUE'
        ? Icon.check_black
        : apiObj?.properties?.TIPO === 'ZONA AMARILLA'
          ? Icon.check_blue
          : apiObj?.properties?.TIPO === 'ZONA NARANJA'
            ? Icon.check_black : Icon.check_blue,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new ZonesPathModel(propsZones, geometry);
};

export const RoadRunFromAPIMapper = (apiObj: any): RoadRunPathModel => {
  const propsRoadRun: IPropsRoadRunPathModel = {
    id: apiObj?.properties?.ID,
    nombreVia: apiObj?.properties?.NOMVIAL,
    ancho: apiObj?.properties?.UN_ANCHO_C,
    capacidad: apiObj?.properties?.CAPACIDAD_,
    largo: apiObj?.properties?.LENGTH,
    tipo: apiObj?.properties?.TIPOVIAPOT,
    sentido: apiObj?.properties?.DIR,
    velocidad: apiObj?.properties?.VELOCIDAD1,
    title: `VIA ${apiObj?.properties?.NOMVIAL}`,
    markerPopupText: `VIA ${apiObj?.properties?.NOMVIAL}`,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new RoadRunPathModel(propsRoadRun, geometry);
};

export const accidentFromApiMapper = (apiObj: any): AccidentModel => {
  const propsAccident: IPropsAccidentModel[] = apiObj.properties;

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AccidentModel(propsAccident, geometry);
};

export const pmvAPIMapper = (apiObj: any): PmvModel => {
  const propsAgents: IPmvModel = {
    id: apiObj?.properties?.id,
    address: apiObj?.properties?.address,
    ip: apiObj?.properties?.ip,
    port: apiObj?.properties?.port,
    name: apiObj?.properties?.name,
    manufacturer: apiObj?.properties?.manufacturer,
    protocol: apiObj?.properties?.protocol,
    communeId: apiObj?.properties?.communeId,
    communeName: apiObj?.properties?.communeName,
    stateId: apiObj?.properties?.stateId,
    stateName: apiObj?.properties?.stateName,
    description: apiObj?.properties?.description,
    updatedDate: apiObj?.properties?.updatedDate,
    title: `pmv ID ${apiObj?.properties?.title}`,
    markerPopupText: `${apiObj?.properties?.address}`,
    icon: Icon.map,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new PmvModel(propsAgents, geometry);
};

export const runtFromAPIMapper = (apiObj: any): RuntDatexModel => {
  const propsRuntDatex: IPropsRuntDatexModel = {
    id: apiObj?.properties?.id,
    address: apiObj?.properties?.address,
    icon: Icon.camera_play_w,
    title: apiObj?.properties?.address,
    markerPopupText: apiObj?.properties?.address,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new RuntDatexModel(propsRuntDatex, geometry);
};

export const AirQualityPointsAPIMapper = (apiObj: any): AirQualityModel => {
  const propsAirQuality: IPropsAirQualityModel = {
    id                  : apiObj?.properties?.id,
    location            : apiObj?.properties?.location,
    temperature         : apiObj?.properties?.temperature,
    humidity            : apiObj?.properties?.humidity,
    pressure            : apiObj?.properties?.pressure,
    icon                : Icon.StationPoint,
    title               : apiObj?.properties?.address,
    keepAlive           : apiObj?.properties?.keepAlive,
    markerPopupText:
        `
          <div style="justify-content: center; align-items: center; display: grid; text-align: center">
              <div class="title">
                <span>
                ${apiObj?.properties?.id} 
                </span>
              </div>
              <div class="subtitle">
                <span>
                ${apiObj?.properties?.location} 
                </span>
              </div>
          </div>
        `,
  };

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AirQualityModel(propsAirQuality, geometry);
};

export const AlertsDuplicatesWazeFromAPIMapper = (apiObj: any): AlertsDuplicatesWazeModel => {
  const propAlertsWaze: IPropsTAlertsWazeModel = {
    id: apiObj?.properties.wazeAlertDuplicateReference.id,
    reliability: apiObj?.properties.wazeAlertDuplicateReference.reliability,
    type: apiObj?.properties.wazeAlertDuplicateReference.type,
    subType: apiObj?.properties.wazeAlertDuplicateReference.subType,
    pubTime: apiObj?.properties.wazeAlertDuplicateReference.pubTime,
    receptionTime: null,
    description: apiObj?.properties.wazeAlertDuplicateReference.description,
    street: apiObj?.properties.wazeAlertDuplicateReference.localityName, // street
    locality: apiObj?.properties.wazeAlertDuplicateReference.localityName,
    localityId: null,
    timeAlerts: null,
    longitude: apiObj?.geometry?.coordinates[0],
    latitude: apiObj?.geometry?.coordinates[1],
    markerPopupText: `<div style="display:flex;justify-content: center;margin-top:0.3rem;">
                    ${apiObj.properties.wazeAlertDuplicateReference.localityName} <br>
                    </div>
                    ${(WazeTrasnslate[apiObj.properties.wazeAlertDuplicateReference.subType as keyof typeof WazeTrasnslate]) || '<br/>'} 
                     <div style="display: flex;justify-content: center;">
                      ${apiObj?.properties.wazeAlertDuplicateReference.timeAlertMin} min
                    </div>`,
    icon: (apiObj?.properties.wazeAlertDuplicateReference.type == 'WEATHERHAZARD') ? Icon.map_climate :
      (apiObj?.properties.wazeAlertDuplicateReference.type == 'ACCIDENT') ? Icon.map_accident : Icon.map_roadClosed,

    // Mapear los posibles duplciados
    duplicates: apiObj?.properties.wazeAlertsDuplicates
    .filter((item: any) => item)
      .map(
        (item: any) =>
        ({
          id: item.id,
          reliability: item.reliability,
          type: item.type,
          subType: item.subType,
          pubTime: item.pubTime,
          receptionTime: null,
          description: item.description,
          street: item.localityName, // street
          locality: item.localityName,
          localityId: null,
          timeAlerts: null,
          markerPopupText: null,
          icon: (item.type == 'WEATHERHAZARD') ? Icon.map_climate :
            (item.type == 'ACCIDENT') ? Icon.map_accident : Icon.map_roadClosed,
        } as IPropsTAlertsWazeModel)
      )
  }

  const geometry = {
    coordinates: apiObj?.geometry?.coordinates,
    type: apiObj?.geometry?.type,
  };

  return new AlertsDuplicatesWazeModel(propAlertsWaze, geometry);
};
