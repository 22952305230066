import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { IAction, IActionHandler } from '../action.service';

@Injectable()
export class RouterActionHandler implements IActionHandler {
  constructor(
    private router: Router,
    private loginAuthService: LoginAuthService,
    @Inject('environment') private environment: any
  ) {}

  canHandle(action: IAction): boolean {
    return action.event.actionType === ActionType.Route;
  }
  handle(action: IAction): void {
    const event = action.event;
    const actionHandler =
      this.getActionHandler(event.action) ||
      this.getDefaultActionHandler(event.action);
    actionHandler();
  }

  private getActionHandler(action: string): any {
    const actions: any = {
      simac: () => this.openNewWindow(this.environment.simacUrl),
      observatory: () => this.openNewWindow(this.environment.observatoryUrl),
      reports: () => this.openNewWindow(this.environment.reportsUrl),
      dashboard: () => this.openNewWindow(this.environment.dashboardUrl),
      'inicio-sesion': () => this.logout(),
      close_session: () => this.logout(),
    };
    return actions[action];
  }

  private getDefaultActionHandler(route: string): any {
    return () => this.navigateToRoute(route);
  }

  openNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  logout(): void {
    this.loginAuthService.logout();
  }

  navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }
}
