import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsAgentsNumberModel {
  Qty: number;
  density: number;
  LocNombre: string;
  id: string;
  name: string;
  updatedDate: string;
  agentType: string;
  title: string;
  markerPopupText: string;
  icon: unknown;
}

export class AgentsNumberModel extends MarkerModelBase {
  static className = 'AgentsNumberModel';
  classProperties: IPropsAgentsNumberModel;

  constructor(props: IPropsAgentsNumberModel, geometry: IGeometryModel) {
    super(geometry, AgentsNumberModel.className);
    this.classProperties = props;
  }
}
