export const vehiclesSensoresJsonMockData = [
    {
        "id": 1,
        "siteid": 1,
        "laneid": "C3_NS_01",
        "orientation": "Norte - Sur",
        "access_point": "apeg3515"
    },
    {
        "id": 2,
        "siteid": 1,
        "laneid": "C3_NS_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg3515"
    },
    {
        "id": 3,
        "siteid": 1,
        "laneid": "C3_NS_03",
        "orientation": "Norte - Sur",
        "access_point": "apeg3515"
    },
    {
        "id": 4,
        "siteid": 1,
        "laneid": "C3_SN_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg3514"
    },
    {
        "id": 5,
        "siteid": 1,
        "laneid": "C3_SN_02",
        "orientation": "Sur - Norte",
        "access_point": "apeg3514"
    },
    {
        "id": 6,
        "siteid": 1,
        "laneid": "C3_SN_03",
        "orientation": "Sur - Norte",
        "access_point": "apeg3514"
    },
    {
        "id": 7,
        "siteid": 2,
        "laneid": "C7_NS_CL_01",
        "orientation": "Norte - Sur",
        "access_point": "apeg5082"
    },
    {
        "id": 8,
        "siteid": 2,
        "laneid": "C7_NS_CL_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg5082"
    },
    {
        "id": 9,
        "siteid": 2,
        "laneid": "C7_NS_CL_03",
        "orientation": "Norte - Sur",
        "access_point": "apeg5082"
    },
    {
        "id": 10,
        "siteid": 2,
        "laneid": "C6_SN_CL_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg5077"
    },
    {
        "id": 11,
        "siteid": 2,
        "laneid": "C6_SN_CL_02",
        "orientation": "Sur - Norte",
        "access_point": "apeg5077"
    },
    {
        "id": 12,
        "siteid": 2,
        "laneid": "C6_SN_CL_03",
        "orientation": "Sur - Norte",
        "access_point": "apeg5077"
    },
    {
        "id": 13,
        "siteid": 2,
        "laneid": "C6_SN_CL_04",
        "orientation": "Sur - Norte",
        "access_point": "apeg5077"
    },
    {
        "id": 14,
        "siteid": 4,
        "laneid": "E2_NS_C1",
        "orientation": "Norte - Sur",
        "access_point": "apeg1121"
    },
    {
        "id": 15,
        "siteid": 4,
        "laneid": "E2_NS_C2",
        "orientation": "Norte - Sur",
        "access_point": "apeg1121"
    },
    {
        "id": 16,
        "siteid": 4,
        "laneid": "E2_NS_C3",
        "orientation": "Norte - Sur",
        "access_point": "apeg1121"
    },
    {
        "id": 17,
        "siteid": 4,
        "laneid": "E2_SN_C1",
        "orientation": "Sur - Norte",
        "access_point": "apeg1121"
    },
    {
        "id": 18,
        "siteid": 4,
        "laneid": "E2_SN_C2",
        "orientation": "Sur - Norte",
        "access_point": "apeg1121"
    },
    {
        "id": 19,
        "siteid": 4,
        "laneid": "E2_SN_C3",
        "orientation": "Sur - Norte",
        "access_point": "apeg1121"
    },
    {
        "id": 20,
        "siteid": 3,
        "laneid": "C1_NS_01",
        "orientation": "Norte - Sur",
        "access_point": "apeg2455"
    },
    {
        "id": 21,
        "siteid": 3,
        "laneid": "C1_NS_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg2455"
    },
    {
        "id": 22,
        "siteid": 3,
        "laneid": "C1_NS_03",
        "orientation": "Norte - Sur",
        "access_point": "apeg2455"
    },
    {
        "id": 23,
        "siteid": 3,
        "laneid": "C1_SN_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg2454"
    },
    {
        "id": 24,
        "siteid": 3,
        "laneid": "C1_SN_02",
        "orientation": "Sur - Norte",
        "access_point": "apeg2454"
    },
    {
        "id": 25,
        "siteid": 3,
        "laneid": "C1_SN_03",
        "orientation": "Sur - Norte",
        "access_point": "apeg2454"
    },
    {
        "id": 26,
        "siteid": 3,
        "laneid": "C1_SN_04",
        "orientation": "Sur - Norte",
        "access_point": "apeg2454"
    },
    {
        "id": 27,
        "siteid": 5,
        "laneid": "D1_OE_CL_01",
        "orientation": "Occidente - Oriente",
        "access_point": "apeg5078"
    },
    {
        "id": 28,
        "siteid": 5,
        "laneid": "D1_OE_CL_02",
        "orientation": "Occidente - Oriente",
        "access_point": "apeg5078"
    },
    {
        "id": 29,
        "siteid": 5,
        "laneid": "D1_OE_CL_03",
        "orientation": "Occidente - Oriente",
        "access_point": "apeg5078"
    },
    {
        "id": 30,
        "siteid": 5,
        "laneid": "D1_OE_CR_01",
        "orientation": "Occidente - Oriente",
        "access_point": "apeg5078"
    },
    {
        "id": 31,
        "siteid": 5,
        "laneid": "D1_OE_CR_02",
        "orientation": "Occidente - Oriente",
        "access_point": "apeg5078"
    },
    {
        "id": 32,
        "siteid": 5,
        "laneid": "D2_EO_CL_01",
        "orientation": "Oriente - Occidente",
        "access_point": "apeg5081"
    },
    {
        "id": 33,
        "siteid": 5,
        "laneid": "D2_EO_CL_02",
        "orientation": "Oriente - Occidente",
        "access_point": "apeg5081"
    },
    {
        "id": 34,
        "siteid": 5,
        "laneid": "D2_EO_CL_03",
        "orientation": "Oriente - Occidente",
        "access_point": "apeg5081"
    },
    {
        "id": 35,
        "siteid": 5,
        "laneid": "D2_EO_CR_01",
        "orientation": "Oriente - Occidente",
        "access_point": "apeg5081"
    },
    {
        "id": 36,
        "siteid": 5,
        "laneid": "D2_EO_CR_02",
        "orientation": "Oriente - Occidente",
        "access_point": "apeg5081"
    },
    {
        "id": 37,
        "siteid": 6,
        "laneid": "C8_NS_01",
        "orientation": "Norte - Sur",
        "access_point": "apeg5079"
    },
    {
        "id": 38,
        "siteid": 6,
        "laneid": "C8_NS_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg5079"
    },
    {
        "id": 39,
        "siteid": 6,
        "laneid": "C9_SN_02",
        "orientation": "Sur - Norte",
        "access_point": "apeg5080"
    },
    {
        "id": 40,
        "siteid": 6,
        "laneid": "C9_SN_CL_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg5080"
    },
    {
        "id": 45,
        "siteid": 8,
        "laneid": "E7_EO_C1L",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1551"
    },
    {
        "id": 46,
        "siteid": 8,
        "laneid": "E7_EO_C1R",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1551"
    },
    {
        "id": 47,
        "siteid": 8,
        "laneid": "E7_EO_C2L",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1551"
    },
    {
        "id": 48,
        "siteid": 8,
        "laneid": "E7_EO_C2R",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1551"
    },
    {
        "id": 49,
        "siteid": 8,
        "laneid": "E7_OE_C1L",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1551"
    },
    {
        "id": 50,
        "siteid": 8,
        "laneid": "E7_OE_C1R",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1551"
    },
    {
        "id": 51,
        "siteid": 8,
        "laneid": "E7_OE_C2L",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1551"
    },
    {
        "id": 52,
        "siteid": 8,
        "laneid": "E7_OE_C2R",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1551"
    },
    {
        "id": 41,
        "siteid": 7,
        "laneid": "E8_SN_C1L",
        "orientation": "Sur - Norte",
        "access_point": "apeg1552"
    },
    {
        "id": 42,
        "siteid": 7,
        "laneid": "E8_SN_C1R",
        "orientation": "Sur - Norte",
        "access_point": "apeg1552"
    },
    {
        "id": 43,
        "siteid": 7,
        "laneid": "E8_SN_C2L",
        "orientation": "Sur - Norte",
        "access_point": "apeg1552"
    },
    {
        "id": 44,
        "siteid": 7,
        "laneid": "E8_SN_C2R",
        "orientation": "Sur - Norte",
        "access_point": "apeg1552"
    },
    {
        "id": 62,
        "siteid": 9,
        "laneid": "E6_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1550"
    },
    {
        "id": 63,
        "siteid": 9,
        "laneid": "E6_EO_C3",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1550"
    },
    {
        "id": 64,
        "siteid": 9,
        "laneid": "E6_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1550"
    },
    {
        "id": 65,
        "siteid": 9,
        "laneid": "E6_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1550"
    },
    {
        "id": 66,
        "siteid": 9,
        "laneid": "E6_OE_C3",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1550"
    },
    {
        "id": 67,
        "siteid": 10,
        "laneid": "E2_EO_C1",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1122"
    },
    {
        "id": 68,
        "siteid": 10,
        "laneid": "E2_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1122"
    },
    {
        "id": 69,
        "siteid": 10,
        "laneid": "E2_EO_C3",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1122"
    },
    {
        "id": 70,
        "siteid": 10,
        "laneid": "E2_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1122"
    },
    {
        "id": 71,
        "siteid": 10,
        "laneid": "E2_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1122"
    },
    {
        "id": 72,
        "siteid": 10,
        "laneid": "E2_OE_C3",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1122"
    },
    {
        "id": 73,
        "siteid": 11,
        "laneid": "E5_EO_C1",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1549"
    },
    {
        "id": 74,
        "siteid": 11,
        "laneid": "E5_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1549"
    },
    {
        "id": 75,
        "siteid": 11,
        "laneid": "E5_EO_C3",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1549"
    },
    {
        "id": 76,
        "siteid": 11,
        "laneid": "E5_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1549"
    },
    {
        "id": 77,
        "siteid": 11,
        "laneid": "E5_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1549"
    },
    {
        "id": 78,
        "siteid": 11,
        "laneid": "E5_OE_C3",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1549"
    },
    {
        "id": 79,
        "siteid": 12,
        "laneid": "E1_EO_C1",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1119"
    },
    {
        "id": 80,
        "siteid": 12,
        "laneid": "E1_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1119"
    },
    {
        "id": 81,
        "siteid": 12,
        "laneid": "E1_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1120"
    },
    {
        "id": 82,
        "siteid": 12,
        "laneid": "E1_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1120"
    },
    {
        "id": 83,
        "siteid": 12,
        "laneid": "E1_OE_C3",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1120"
    },
    {
        "id": 84,
        "siteid": 13,
        "laneid": "E4_EO_C1",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1127"
    },
    {
        "id": 85,
        "siteid": 13,
        "laneid": "E4_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1127"
    },
    {
        "id": 86,
        "siteid": 13,
        "laneid": "E4_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1128"
    },
    {
        "id": 87,
        "siteid": 13,
        "laneid": "E4_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1128"
    },
    {
        "id": 88,
        "siteid": 14,
        "laneid": "C2_EO_01",
        "orientation": "Oriente - occidente",
        "access_point": "apeg3516"
    },
    {
        "id": 89,
        "siteid": 14,
        "laneid": "C2_EO_02",
        "orientation": "Oriente - occidente",
        "access_point": "apeg3516"
    },
    {
        "id": 90,
        "siteid": 14,
        "laneid": "C2_EO_03",
        "orientation": "Oriente - occidente",
        "access_point": "apeg3516"
    },
    {
        "id": 91,
        "siteid": 14,
        "laneid": "C2_OE_01",
        "orientation": "Occidente - oriente",
        "access_point": "apeg3517"
    },
    {
        "id": 92,
        "siteid": 14,
        "laneid": "C2_OE_02",
        "orientation": "Occidente - oriente",
        "access_point": "apeg3517"
    },
    {
        "id": 93,
        "siteid": 14,
        "laneid": "C2_OE_03",
        "orientation": "Occidente - oriente",
        "access_point": "apeg3517"
    },
    {
        "id": 94,
        "siteid": 15,
        "laneid": "E3_EO_C1",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1124"
    },
    {
        "id": 95,
        "siteid": 15,
        "laneid": "E3_EO_C2",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1124"
    },
    {
        "id": 96,
        "siteid": 15,
        "laneid": "E3_EO_C3",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1124"
    },
    {
        "id": 97,
        "siteid": 15,
        "laneid": "E3_EO_C4",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1124"
    },
    {
        "id": 98,
        "siteid": 15,
        "laneid": "E3_OE_C1",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1124"
    },
    {
        "id": 99,
        "siteid": 15,
        "laneid": "E3_OE_C2",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1124"
    },
    {
        "id": 100,
        "siteid": 15,
        "laneid": "E3_OE_C3",
        "orientation": "Occidente - oriente",
        "access_point": "apeg1124"
    },
    {
        "id": 101,
        "siteid": 16,
        "laneid": "E1_NS_C1",
        "orientation": "Norte - Sur",
        "access_point": "apeg1117"
    },
    {
        "id": 102,
        "siteid": 16,
        "laneid": "E1_NS_C2",
        "orientation": "Norte - Sur",
        "access_point": "apeg1117"
    },
    {
        "id": 103,
        "siteid": 16,
        "laneid": "E1_SN_C1",
        "orientation": "Sur - Norte",
        "access_point": "apeg1118"
    },
    {
        "id": 104,
        "siteid": 16,
        "laneid": "E1_SN_C2",
        "orientation": "Sur - Norte",
        "access_point": "apeg1118"
    },
    {
        "id": 105,
        "siteid": 17,
        "laneid": "E3_NS_C1L",
        "orientation": "Norte - Sur",
        "access_point": "apeg1123"
    },
    {
        "id": 106,
        "siteid": 17,
        "laneid": "E3_NS_C1R",
        "orientation": "Norte - Sur",
        "access_point": "apeg1123"
    },
    {
        "id": 107,
        "siteid": 17,
        "laneid": "E3_NS_C2L",
        "orientation": "Norte - Sur",
        "access_point": "apeg1123"
    },
    {
        "id": 108,
        "siteid": 17,
        "laneid": "E3_NS_C2R",
        "orientation": "Norte - Sur",
        "access_point": "apeg1123"
    },
    {
        "id": 109,
        "siteid": 17,
        "laneid": "E3_SN_C1L",
        "orientation": "Sur - Norte",
        "access_point": "apeg1123"
    },
    {
        "id": 110,
        "siteid": 17,
        "laneid": "E3_SN_C1R",
        "orientation": "Sur - Norte",
        "access_point": "apeg1123"
    },
    {
        "id": 111,
        "siteid": 17,
        "laneid": "E3_SN_C2L",
        "orientation": "Sur - Norte",
        "access_point": "apeg1123"
    },
    {
        "id": 112,
        "siteid": 17,
        "laneid": "E3_SN_C2R",
        "orientation": "Sur - Norte",
        "access_point": "apeg1123"
    },
    {
        "id": 113,
        "siteid": 18,
        "laneid": "E4_NS_C1",
        "orientation": "Norte - Sur",
        "access_point": "apeg1125"
    },
    {
        "id": 114,
        "siteid": 18,
        "laneid": "E4_NS_C2",
        "orientation": "Norte - Sur",
        "access_point": "apeg1125"
    },
    {
        "id": 115,
        "siteid": 18,
        "laneid": "E4_NS_C3",
        "orientation": "Norte - Sur",
        "access_point": "apeg1125"
    },
    {
        "id": 116,
        "siteid": 18,
        "laneid": "E4_NS_C4",
        "orientation": "Norte - Sur",
        "access_point": "apeg1125"
    },
    {
        "id": 117,
        "siteid": 18,
        "laneid": "E4_SN_C1",
        "orientation": "Sur - Norte",
        "access_point": "apeg1126"
    },
    {
        "id": 118,
        "siteid": 18,
        "laneid": "E4_SN_C2",
        "orientation": "Sur - Norte",
        "access_point": "apeg1126"
    },
    {
        "id": 119,
        "siteid": 18,
        "laneid": "E4_SN_C3",
        "orientation": "Sur - Norte",
        "access_point": "apeg1126"
    },
    {
        "id": 120,
        "siteid": 19,
        "laneid": "C5_NS_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg4880"
    },
    {
        "id": 121,
        "siteid": 19,
        "laneid": "C5_SN_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg4880"
    },
    {
        "id": 122,
        "siteid": 20,
        "laneid": "C4_NS_02",
        "orientation": "Norte - Sur",
        "access_point": "apeg4879"
    },
    {
        "id": 123,
        "siteid": 20,
        "laneid": "C4_SN_01",
        "orientation": "Sur - Norte",
        "access_point": "apeg4879"
    },
    {
        "id": 61,
        "siteid": 9,
        "laneid": "E6_EO_01",
        "orientation": "Oriente - occidente",
        "access_point": "apeg1550"
    }
]