import { Component, Input, OnInit } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ManageIncidentsCoordinatorService } from "@official/services/data-grid-service/manage-incidents-coordinator/manage-incidents-coordinator.service";
import { CommonService } from "@shared/services/common-service/common-service.service";
import { ButtonClassesEnum, IButtonModel } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";

@Component({
  selector: "sit-modal-register-incident-manage",
  templateUrl: "./modal-register-incident-manage.component.html",
  styleUrls: ["./modal-register-incident-manage.component.scss"],
})
export class ModalRegisterIncidentManageComponent implements OnInit {
  @Input() properties: any | undefined;
  @Input() id: any | undefined;
  Icon = Icon;

  entity!: any;

  registerFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();

  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_resources,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };

  constructor(
    private incidentsCoordination: ManageIncidentsCoordinatorService,
    private modalService: ModalService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    // this.rolesList = this.roles.map((e) => e.name);
    this.initializeForm();
  }

  private initializeForm(): void {
    const formControls: { [key: string]: any } = {
      observation: [
        { value: '', disabled: false },
        [
          Validators.minLength(3),
          Validators.maxLength(1000),
          Validators.pattern(
            /^\S(?:.*\S)?$/
          ),
          Validators.required,
        ],
      ],
      closeManagement: [
        { value: false, disabled: false },
      ],
    };

    // this.rolesList.forEach((item: string) => {
    //   formControls[item] = [''];
    // });

    this.registerFormGroup = this.fb.group(formControls);

  }

  get observationsErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('observation') as FormControl;
    return form.hasError('required')
        ? 'El campo Registro de Gestión es obligatorio por favor diligéncielo'
        :  form.hasError('minlength')
        ? 'Este campo debe tener una longitud mínima de 3'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 1000'
            : form.hasError('pattern')
                ? 'No se permiten solo espacios en blanco'
                : '';
  }

  validateFormGroup(): void {
    this.registerFormGroup.markAllAsTouched();
  }

  validateUserSubmit(): void {
    this.onSubmit();
  }

  onSubmit() {
    this.entity = {};
    this.entity = this.registerFormGroup.value;
    this.entity.incidentId = this.id;

    this.incidentsCoordination.createManageIncident(this.entity).subscribe({
      next: (response: any) => {
        this.modalService.cleanModals$.next([]);
        setTimeout(() => {
          this.showModalSuccess('El incidente fue gestionado exitosamente');
          this.incidentsCoordination.init();
          this.init();
        }, 200);
      },
      error: (error: any) => this.showModalError(error.error.errors[0]),
    });
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

  showModalError(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }
}
