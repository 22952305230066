import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import {
  IHorizontalMenuModel,
  IMenuItem,
} from '@types-custom/models/ui/horizontal-menu.model';
import { IMenuEvent } from '@types-custom/models/ui/menu.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionService } from '@shared/services/action-service/action.service';

/*
 ** Horizontal menu
 ** Render a horizontal menu.
 */
@Component({
  selector: 'horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
})
export class HorizontalMenuComponent {
  @Input() properties: IHorizontalMenuModel | undefined = undefined;
  @Input() isCollapsed = true;
  Icon = Icon;
  isPressed=false;

  constructor(private actionService: ActionService) {}
  dispatchAction(
    event: IMenuEvent,
    dispatcher: Subject<IMenuEvent | undefined>,
    item: IMenuItem
  ): void {
    item.isSelected = !item.isSelected;
    this.actionService.dispatch(event, dispatcher);
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  
  toggleColor() {
    this.isPressed = !this.isPressed;
  }
}
