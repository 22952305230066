<div class="modal-video bottom-sheet-directive">
  <!-- Error message display -->
  <div class="video-player-error-message my-05">
    <span class="text-red text-size-16">{{ errorMessage }}</span>
  </div>
  <!-- Video player container -->
  <div #videoPlayer class="video-player bg-color-background-2 border-radius-5">
    <video id="videoPlayerStreaming" controlsList="nodownload noplaybackrate" class="full-width" autoplay error>
      <div style="color: white;">
        Tu navegador no soporta la reproducción de video.
      </div>
    </video>
  </div>
</div>