import { Component, Input } from '@angular/core';
import { IModalCoosLineModel } from '@types-custom/models/ui/modal-coos-line-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal Coos line
 ** Render a modal that displays information about Coos Line
 */

@Component({
  selector: 'citizen-modal-coos-line-path',
  templateUrl: './modal-coos-line.component.html',
  styleUrls: ['./modal-coos-line.component.scss'],
})
export class ModalCoosLineComponent {
  @Input() properties: IModalCoosLineModel | undefined;
  Icon = Icon;
}
