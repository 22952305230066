import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Bound, IAddressModel } from '@types-custom/models/business/google-api.model';
import {
  IMapEvent,
  MapEventTypeEnum,
} from '@types-custom/models/ui/map-viewer-model';
import { Subject, Subscription, skip } from 'rxjs';
import { IGooglePlacesModel } from '@types-custom/models/business/google-places.model';
import { ILocationControlModel } from '@types-custom/models/ui/location-control.model';
import { MatFormFieldControl } from '@angular/material/form-field';
import { InputTypeEnum } from '@types-custom/models/ui/generic-form.model';
import { IncidentStatusCodeEnum } from '@types-custom/models/business/manage-incident-databody.model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';
import { IncidentFormEnum } from '@types-custom/models/ui/incidents-model';
declare let google: any;

@Component({
  selector: 'location-control-from-api',
  templateUrl: './location-control-from-api.component.html',
  styleUrls: ['./location-control-from-api.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: LocationControlFromApiComponent,
    },
  ],
})
export class LocationControlFromApiComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() properties: ILocationControlModel;
  private unsubscribe = new Subject();

  private unsb: Subscription;
  private autocompleteListener: any; // To store Google's event listener

  ngOnInit(): void {
    this.initFormProps();
  }

  initFormProps() {
    this.properties.formGroup.addControl('latlng', new FormControl(''));
    this.properties.formGroup.addControl('latlng2', new FormControl(''));

    this.unsb = this.properties.geoLocationService.pointerData$
      .pipe(skip(1))
      .subscribe(this.handleMapClickLocation.bind(this));
  }

  handleMapClickLocation(pointerData: IMapEvent) {
    if (pointerData.lat && pointerData.lng) {
      this.properties.geoLocationService
        .getAddress2(pointerData)
        .subscribe((locationFromGoogle: IAddressModel) => {
          const form = this.properties.formGroup
            .get(this.properties.formName);
          form
            .get(InputTypeEnum.LOCATION_INPUT)
            ?.patchValue(locationFromGoogle.addressStr); //Patch the address
          form
            .get(IncidentFormEnum.latitude)
            ?.patchValue(locationFromGoogle.lat);
          form
            .get(IncidentFormEnum.longitude)
            ?.patchValue(locationFromGoogle.lng);
          const normalizer = this.properties.geoLocationService.normalizeName;
          form
            .get(InputTypeEnum.LOCALITY)
            ?.patchValue(
              this.properties.cachedLocationList?.get(IncidentsListEnum.LOCALIDAD)?.find(element => locationFromGoogle.localityDescriptor?.includes(normalizer(element.name)))?.value);

          form.get(IncidentFormEnum.brokerId)
            ?.patchValue(this.properties.cachedLocationList?.get(IncidentsListEnum.CORREDOR)?.find(element => element.name === locationFromGoogle.closestFeature?.properties.composed_name)?.value ?? locationFromGoogle.closestFeature?.properties.composed_name);

          form.get(IncidentFormEnum.orientId)
            ?.patchValue(
              this.properties.cachedLocationList?.get(IncidentsListEnum.ORIENTACION)
                ?.find(element => normalizer(this.properties.geoLocationService.mapOrientation(locationFromGoogle.closestFeature?.properties.composed_name.substr(-2))) === normalizer(element.name))?.value ?? '');
          this.setGeometryPointField(locationFromGoogle);
        });
    }
  }



  ngAfterViewInit() {
    this.setListeners();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.unsb.unsubscribe();

    if (this.autocompleteListener) {
      google.maps.event.removeListener(this.autocompleteListener);
    }
  }

  private setListeners(): void {
    this.properties.locationControlViewChild$.subscribe(
      this.initGoogleInputField.bind(this)
    );

    this.properties.formGroup
      .get('latlng')
      ?.valueChanges.subscribe(this.handleLatLngChanges.bind(this));
  }

  private handleLatLngChanges(data: any): void {
    const event: IMapEvent = {
      type: MapEventTypeEnum.CLICK,
      lng: data.lng,
      lat: data.lat,
    };

    this.handleMapClickLocation(event);
    if (data.lng && data.lat) {
      this.properties.geoLocationService.clickEventsDispatcher.next(event);
    }
  }

  private initGoogleInputField(locationControlViewChild: any): void {
    let elem = locationControlViewChild;
    if (!elem)
      elem = document.querySelector('#location-controller-element');
    else
      elem = elem.nativeElement
    if (!elem) return
    const bounds: Bound = this.properties.geoLocationService.getBounds();
    const bogotaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bounds.swlat, bounds.swlng), // Coordenadas del punto suroeste
      new google.maps.LatLng(bounds.nelat, bounds.nelng),  // Coordenadas del punto noreste
      // new google.maps.LatLng(4.475, -74.217), // Coordenadas del punto suroeste
      // new google.maps.LatLng(4.840190450915529, -73.99434174671173),  // Coordenadas del punto noreste
    );
    const autocomplete = new google.maps.places.Autocomplete(
      elem,
      {
        componentRestrictions: { country: 'CO' },
        types: ['address'],
        bounds: bogotaBounds,
        strictBounds: true
      }
    );
    // Removing the listener (unsubscriber at autocompleteListener) when the component is destroyed.
    this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.setNewMarkerPoint(place);
    });
  }

  private setNewMarkerPoint(place: IGooglePlacesModel): void {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    this.properties.formGroup.get('latlng')?.patchValue({
      lat,
      lng,
    });
  }

  private setGeometryPointField(locationFromGoogle: IAddressModel): void {
    const lat = locationFromGoogle.lat;
    const lng = locationFromGoogle.lng;
    this.properties.formGroup
      .get(this.properties.formName)
      .get('point')
      ?.patchValue([lat, lng]);

    this.properties.formGroup.get('latlng2').patchValue([lat, lng]);

    this.disableFormOnEditClosed();
  }

  disableFormOnEditClosed() {
    if (
      this.properties.panelAction &&
      this.properties.statusId === parseInt(IncidentStatusCodeEnum.CERRADO)
    ) {
      this.properties.formGroup.setErrors({ closed: true });
    }
  }
}
