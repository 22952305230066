import { Component, Input } from '@angular/core';
import { IGridTableCellDispatcher, IGridTableHeaderModel } from '@types-custom/models/ui/grid-table-model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'sit-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss'],
})
export class ListCardsComponent {
  @Input() headers: IGridTableHeaderModel[] = [];
  @Input() dataSource: Observable<[]> | undefined;

  dataCellType = {
    isNumber: (data: any) => typeof data === 'number',
  };

  hasDispatcher = (): boolean => this.headers.some(h => h.dispatcher);

  handleCardAction(item: any, index: number): void {
    const actionDispatcher = this.headers.find(header => header.dispatcher)?.dispatcher;
    if (actionDispatcher) {
      const actionData = { row: item, index };
      actionDispatcher.next(actionData);
    }
  };

  handleClick(
    dispatcher: Subject<IGridTableCellDispatcher> | undefined,
    row: any,
    key: string | undefined,
    index: number
  ): void {
    if (dispatcher) {
      const data = {
        row,
        index,
        key,
      };

      dispatcher.next(data);
    }
  }

  resolveRenderData(
    toRender: IRendererModel,
    cellData: any,
    key: string,
    index: number,
    row: any
  ): IRendererModel {
    const { component, data } = toRender;
    const renderModel = {
      component,
      data: data ? {
        ...data, props: {
          index: index,
          key: key,
          row: row,
        }, [key]: cellData
      } : { [key]: cellData },
    };
    return renderModel;
  }
}
