import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoreTheme } from '@types-custom/models/ui/core-themes';

@Injectable({
  providedIn: 'root',
})
export class CoreThemingService {
  theme$ = new BehaviorSubject<CoreTheme>(CoreTheme.default);

  setTheme(theme: CoreTheme): void {
    this.theme$.next(theme);
  }
}
