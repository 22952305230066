import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreThemingService } from '@ui-core/services/core-theming.service';
import { IHeaderModel } from '@types-custom/models/ui/header.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IndicatorModel,
  IndicatorProps,
} from '@types-custom/models/ui/indicators.model';
import {
  BehaviorSubject,
  interval,
  map,
  mergeMap,
  Observable,
  startWith,
} from 'rxjs';
import { AnyAaaaRecord } from 'dns';

/*
 ** Header
 ** Render a header section
 */

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() properties: IHeaderModel<any> = {};
  @Input() visibleSideMenu: boolean | undefined = undefined;
  @Output() flagSideMenu: EventEmitter<any> = new EventEmitter();
  @Input() indicators = new BehaviorSubject<IndicatorModel[] | undefined>(
    undefined
  );
  @Input() indicatorProps: IndicatorProps;
  theme$ = this.coreThemingService.theme$;
  Icon = Icon;
  hourBoard = '';
  dateBoard = '';
  refreshIndicators = interval(180000);
  constructor(private coreThemingService: CoreThemingService) {
    // TODO: Improve approach, is causing performance issues
    this.dateOnBoard();
    setInterval(() => this.dateOnBoard(), 6000);
  }
  ngOnInit(): void {
    this.setIndicators();
  }

  setIndicators() {
    this.refreshIndicators
      .pipe(
        startWith(-1),
        mergeMap(() => this.indicatorProps.indicatorDataSource.getIndicators()),
        map((response: any) => ({
          ...response,
          speedAvg: parseInt(response.speedAvg),
        }))
      )
      .subscribe((e) =>
        this.indicators.next(this.indicatorProps.generateIndicatorsModel(e))
      );
  }

  toggleSideMenu() {
    this.visibleSideMenu = !this.visibleSideMenu;
    this.flagSideMenu.emit(this.visibleSideMenu);
  }

  dateOnBoard() {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Bogota',
    };
    this.hourBoard = date.toLocaleString('en-US', options);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      timeZone: 'America/Bogota',
    };
    this.dateBoard = date.toLocaleString('es-ES', optionsDate);
  }
}
