<div
  class="
    d-flex
    fd-col
    text-weight-600 text-size-14
    mt-16
    fx-card-hover
    cursor-pointer
  "
  (click)="openModal()"
>
  <div class="modal-title d-flex fd-row mb-16 align-items-center">
    <sit-icon
      class="text-blue-2"
      [icon]="card?.icon"
      [sizeClass]="'icon-size-22'"
      [alt]="'icono de camaras'"
    ></sit-icon>
    <div class="title-text d-flex fd-col ml-8">
      <h2 class="m-0 text-gray text-size-16 text-weight-700">
        Cámara {{ card?.title }}
      </h2>
    </div>
  </div>
  <div class="border-b-s-1 border-color-blue"></div>
</div>
