import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsAforoModel {
  id: string;
  address: string;
  name: string;
  cameraType: string;
  status: string;
  description: string;
  title: string;
  icon: Icon;
  markerPopupText: string;
}

export class AforoModel extends MarkerModelBase {
  static className = 'AforoModel';
  classProperties: IPropsAforoModel;

  constructor(props: IPropsAforoModel, geometry: IGeometryModel) {
    super(geometry, AforoModel.className);
    this.classProperties = props;
  }
}
