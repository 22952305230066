import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractRuntDatexDataSource } from '@types-custom/models/ui/modal-runt-datex.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RuntDatexService implements AbstractRuntDatexDataSource  {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  public getModalCameraRunt(cameraId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.camerasUrl}/camera/${cameraId}/runt`
    );
  }

  public getPanelRunt(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.camerasUrl}/camera/runt`
    );
  }
}
