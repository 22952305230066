<div
  class="modal-box d-flex fd-row f-wrap flex-sm-nowrap align-items-center justify-center bg-color-background-3"
>
  <div>
    <sit-icon
      [icon]="infoIcon"
      alt="icono con signo de exclamación"
      [sizeClass]="'icon-size-58 icon-sm-size-46'"
      class="text-color-secondary-2"
    ></sit-icon>
  </div>
  <div class="text-center pl-16">
    <span
      class="text-color-white text-size-24 text-sm-size-14 text-weight-700"
      >{{ infoMessage }}</span
    >
  </div>
</div>
