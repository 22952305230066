<div
  DragDrop
  (fileDropped)="onDroppedFile($event)"
  class="drag-drop-box full-width d-flex align-items-center justify-center mb-8"
>
  <label for="files" class="text-white" 
    >Arrastre aquí su(s) archivo(s) o haga click para añadir</label
  >
  <input
    #filesInput
    id="files"
    type="file"
    [attr.accept]="getFileTypesAllowed"
    (change)="onBrowseFile($event)"
    class="full-width full-height"
  />
</div>
<div class="file-list d-flex fd-col">
  <div *ngFor="let file of files; let i = index" class="d-flex fd-row">
    <span class="text-white">{{ i + 1 }}. {{ formatBytes(file.size) }}</span>
    <span class="ml-16 text-white">{{ file.name }}</span>
    <sit-icon
      [icon]="icon"
      [sizeClass]="'icon-size-20'"
      (click)="deleteFile(i)"
      class="cursor-pointer"
    ></sit-icon>
  </div>
</div>
