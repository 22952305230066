<div class="card" [ngClass]="this.active ? 'active' : ''" (click)="this.active ? '' : SelectIndicator(this.properties)">
    <div class="content">
        <div class="title">
            <div
                class="color"
                [ngStyle]="{'border-radius': '1000px',
                'background-color': this.properties.has_color ? DefineColor(properties.value) : 'transparent',
                'height'          : '.5rem',
                'width'           : '.5rem'}"
            ></div>
            <span class="title"> {{this.properties.name}} </span>
        </div>

        <div class="content">
        <span>
            {{this.properties.value}}
        </span>
        </div>

        <div class="Footer">
        <span>
            {{this.properties.unit}}
        </span>
        </div>
    </div>

</div>