import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-traffic-concordance',
  templateUrl: './modal-traffic-concordance.component.html',
  styleUrls: ['./modal-traffic-concordance.component.scss'],
})
export class ModalTrafficConcordanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
