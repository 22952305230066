<div class="text-blue-1 text-weight-700 sit-card" (click)="openModal()">
    <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-16 text-weight-500">
        <span> Tramo {{number + 1}} - {{ card?.composed_name }}</span>
    </div>
    <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
        <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
            <div class="container-graphic-speed">
                <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
            </div>
        </div>

        <!--
        <div class="d-flex fd-col mt-1 mb-1 justify-center">
            <div class="d-flex fd-col text-white time-gap-10">
                <div class="d-flex fd-col">
                    <label class="text-color-primary-1 text-size-18 text-weight-500">{{ card.time.split('.')[0] }}m {{ card.time.split('.')[1] }}s</label>
                    <label class="text-size-14 text-weight-400">Tiempo Real</label>
                </div>
            </div>
        </div>
        -->

        <div class="d-flex fd-col mt-1 mb-1 align-items-center">
            <div class="container-graphic-speed">
                <sit-incidents [properties]="avgIncidentsData"></sit-incidents>
            </div>
        </div>
    </div>
</div>