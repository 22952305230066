import { Component, Input } from '@angular/core';
import { IGridPageModel } from '@types-custom/models/ui/grid-page-model';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: "list-cards-report-page",
  templateUrl: "./list-cards-report-page.component.html",
  styleUrls: ["./list-cards-report-page.component.scss"],
})
export class ListCardsReportPageComponent {
  icon = Icon;
  @Input() properties!: IGridPageModel;
}
