import {
  SituationsModel,
  SituationTypeEnum,
} from '@shared/models/situations.model';
import { TrafficModel } from '@shared/models/traffic.model';
import {
  getIconClass,
  Icon,
  getIconAsset,
} from '@types-custom/models/ui/icon-model';
import { CameraModel } from '@shared/models/camera.model';
import { Co2Model } from '@shared/models/co2.model';
import { CoosModel } from '@shared/models/coos.model';
import { TrafficLightModel } from '@shared/models/traffic-light.model';
import { AforoModel } from '@shared/models/aforo.model';
import { IMapIconModel } from '@types-custom/models/ui/map-icons.model';
import { PmvModel } from '@shared/models/pmv.model';
import { coiModel } from '@shared/models/coi.model';
import { incidentsTypeEnum } from '@shared/models/LocationsIncidents.model';

export const mapIcons: IMapIconModel[] = [
  {
    id: Icon.general_road_w,
    path: getIconAsset(Icon.general_road_w),
  },
  {
    id: Icon.check_blue,
    path: getIconAsset(Icon.check_blue),
  },
  {
    id: Icon.sensor_w,
    path: getIconAsset(Icon.sensor_w),
  },
  {
    id: Icon.density_blue,
    path: getIconAsset(Icon.density_blue),
  },
  {
    id: Icon.map,
    path: getIconAsset(Icon.map),
  },
  {
    id: Icon.camera_play_w,
    path: getIconAsset(Icon.camera_play_w),
  },
  {
    id: Icon.map_cameras,
    path: getIconAsset(Icon.map_cameras),
  },
  {
    id: Icon.check_w,
    path: getIconAsset(Icon.check_w),
    size: '24',
  },
  {
    id: Icon.dot_w,
    path: getIconAsset(Icon.dot_w),
    size: '14',
  },
  {
    id: Icon.coiMap,
    path: getIconAsset(Icon.coiMap),
  },
  {
    id: Icon.AccidentWaze,
    path: getIconAsset(Icon.AccidentWaze),
  },
  {
    id: Icon.ClimateWaze,
    path: getIconAsset(Icon.ClimateWaze),
  },
  {
    id: Icon.CloseWaze,
    path: getIconAsset(Icon.CloseWaze),
  },
  {
    id: Icon.map_accident,
    path: getIconAsset(Icon.map_accident),
  },
  {
    id: Icon.map_climate,
    path: getIconAsset(Icon.map_climate),
  },
  {
    id: Icon.map_roadClosed,
    path: getIconAsset(Icon.map_roadClosed),
  },
  {
    id: Icon.map_agents_blue,
    path: getIconAsset(Icon.map_agents_blue),
  },
  {
    id: Icon.map_agents_green,
    path: getIconAsset(Icon.map_agents_green),
  },
  {
    id: Icon.agente_map_azul,
    path: getIconAsset(Icon.agente_map_azul),
  },
  {
    id: Icon.pointer_location,
    path: getIconAsset(Icon.pointer_location),
  },
  {
    id: Icon.agente_map_verde,
    path: getIconAsset(Icon.agente_map_verde),
  },
  {
    id: Icon.map_policy,
    path: getIconAsset(Icon.map_policy),
  },
  {
    id: Icon.map_transit_update,
    path: getIconAsset(Icon.map_transit_update),
  },
  {
    id: Icon.map_police_update,
    path: getIconAsset(Icon.map_police_update),
  },
  {
    id: Icon.map_transit,
    path: getIconAsset(Icon.map_transit),
  },
  {
    id: Icon.car_blue,
    path: getIconAsset(Icon.car_blue),
  },
  {
    id: Icon.calendar_blue,
    path: getIconAsset(Icon.calendar_blue),
  },
  {
    id: Icon.map_vehicle,
    path: getIconAsset(Icon.map_vehicle),
  },
  {
    id: Icon.map_crane_laden,
    path: getIconAsset(Icon.map_crane_laden),
  },
  {
    id: Icon.map_crane_available,
    path: getIconAsset(Icon.map_crane_available),
  },
  {
    id: Icon.agents_green,
    path: getIconAsset(Icon.agents_green),
  },
  {
    id: Icon.agents_blue,
    path: getIconAsset(Icon.agents_blue),
  },
  {
    id: Icon.camera_header,
    path: getIconAsset(Icon.camera_header),
  },
  {
    id: Icon.bicycle_header,
    path: getIconAsset(Icon.bicycle_header),
  },
  {
    id: Icon.crane_header,
    path: getIconAsset(Icon.crane_header),
  },
  {
    id: Icon.map_bus_stop,
    path: getIconAsset(Icon.map_bus_stop),
    size: '24',
  },
  {
    id: Icon.map_Coos,
    path: getIconAsset(Icon.map_Coos),
    size: '24',
  },
  {
    id: Icon.map_traffic_light,
    path: getIconAsset(Icon.map_traffic_light),
    size: '32',
  },
  {
    id: Icon.alert_map,
    path: getIconAsset(Icon.alert_map),
  },
  {
    id: Icon.sinister_level0,
    path: getIconAsset(Icon.sinister_level0),
  },
  {
    id: Icon.sinister_level1,
    path: getIconAsset(Icon.sinister_level1),
  },
  {
    id: Icon.sinister_level2,
    path: getIconAsset(Icon.sinister_level2),
  },
  {
    id: Icon.stranded_level0,
    path: getIconAsset(Icon.stranded_level0),
  },
  {
    id: Icon.stranded_level1,
    path: getIconAsset(Icon.stranded_level1),
  },
  {
    id: Icon.stranded_level2,
    path: getIconAsset(Icon.stranded_level2),
  },
  {
    id: Icon.semaforizacion_level0,
    path: getIconAsset(Icon.semaforizacion_level0),
  },
  {
    id: Icon.semaforizacion_level1,
    path: getIconAsset(Icon.semaforizacion_level1),
  },
  {
    id: Icon.semaforizacion_level2,
    path: getIconAsset(Icon.semaforizacion_level2),
  },
  {
    id: Icon.massive_level0,
    path: getIconAsset(Icon.massive_level0),
  },
  {
    id: Icon.massive_level1,
    path: getIconAsset(Icon.massive_level1),
  },
  {
    id: Icon.massive_level2,
    path: getIconAsset(Icon.massive_level2),
  },
  {
    id: Icon.massive_level3,
    path: getIconAsset(Icon.massive_level3),
  },
  {
    id: Icon.manifestation_level0,
    path: getIconAsset(Icon.manifestation_level0),
  },
  {
    id: Icon.manifestation_level1,
    path: getIconAsset(Icon.manifestation_level1),
  },
  {
    id: Icon.manifestation_level2,
    path: getIconAsset(Icon.manifestation_level2),
  },
  {
    id: Icon.accident_level0,
    path: getIconAsset(Icon.accident_level0),
  },
  {
    id: Icon.accident_level1,
    path: getIconAsset(Icon.accident_level1),
  },
  {
    id: Icon.accident_level2,
    path: getIconAsset(Icon.accident_level2),
  },
  {
    id: Icon.accident_level3,
    path: getIconAsset(Icon.accident_level3),
  },
  {
    id: Icon.sinister_level3,
    path: getIconAsset(Icon.sinister_level3),
  },
  {
    id: Icon.stranded_level3,
    path: getIconAsset(Icon.stranded_level3),
  },
  {
    id: Icon.manifestation_level3,
    path: getIconAsset(Icon.manifestation_level3),
  },
  {
    id: Icon.semaforizacion_level3,
    path: getIconAsset(Icon.semaforizacion_level3),
  },
  {
    id: Icon.HelmetIconMap,
    path: getIconAsset(Icon.HelmetIconMap),
  },
  {
    id: Icon.CentipedeIconMap,
    path: getIconAsset(Icon.CentipedeIconMap),
  },
  {
    id: Icon.CycleSchoolIconMap,
    path: getIconAsset(Icon.CycleSchoolIconMap),
  },
  {
    id: Icon.BikeDisabled,
    path: getIconAsset(Icon.BikeDisabled),
  },
  {
    id: Icon.BikeEnabled,
    path: getIconAsset(Icon.BikeEnabled),
  },
  {
    id: Icon.PredictiveAgents,
    path: getIconAsset(Icon.PredictiveAgents),
  },
  {
    id: Icon.PredictivePolice,
    path: getIconAsset(Icon.PredictivePolice),
  },
  {
    id: Icon.PredictiveCrane,
    path: getIconAsset(Icon.PredictiveCrane),
  },
  {
    id: Icon.accident_datex,
    path: getIconAsset(Icon.accident_datex),
  },
  {
    id: Icon.vehicular_obstruction_datex,
    path: getIconAsset(Icon.vehicular_obstruction_datex),
  },
  {
    id: Icon.environmental_obstruction_datex,
    path: getIconAsset(Icon.environmental_obstruction_datex),
  },
  {
    id: Icon.damaged_infrastructure_datex,
    path: getIconAsset(Icon.damaged_infrastructure_datex),
  },
  {
    id: Icon.animal_obstruction_datex,
    path: getIconAsset(Icon.animal_obstruction_datex),
  },
  {
    id: Icon.maintenance_datex,
    path: getIconAsset(Icon.maintenance_datex),
  },
  {
    id: Icon.general_obstruction_datex,
    path: getIconAsset(Icon.general_obstruction_datex),
  },
  {
    id: Icon.abnormal_traffic_datex,
    path: getIconAsset(Icon.abnormal_traffic_datex),
  },
  {
    id: Icon.road_not_weather_datex,
    path: getIconAsset(Icon.road_not_weather_datex),
  },
  {
    id: Icon.bad_conditions_datex,
    path: getIconAsset(Icon.bad_conditions_datex),
  },
  {
    id: Icon.condition_datex,
    path: getIconAsset(Icon.condition_datex),
  },
  {
    id: Icon.network_management_datex,
    path: getIconAsset(Icon.network_management_datex),
  },
  {
    id: Icon.construction_work_datex,
    path: getIconAsset(Icon.construction_work_datex),
  },
  {
    id: Icon.road_management_datex,
    path: getIconAsset(Icon.road_management_datex),
  },
  {
    id: Icon.surface_condition_datex,
    path: getIconAsset(Icon.surface_condition_datex),
  },
  {
    id: Icon.StationPoint,
    path: getIconAsset(Icon.StationPoint),
  }
];

export const iconClassSituationsMap = {
  [SituationTypeEnum.crane]: Icon.crane_w,
  [SituationTypeEnum.inmobilizations]: Icon.car_w,
  [SituationTypeEnum.road]: Icon.street_blue,
  [SituationTypeEnum.schedule]: Icon.schedule_w,
  [SituationTypeEnum.signal]: Icon.general_road_w,
  [SituationTypeEnum.traffic]: Icon.traffic_arrows_w,
};

export const iconClassIncidentsMap = {
  [incidentsTypeEnum.default]: Icon.alert_map,
  [incidentsTypeEnum.siniestro0]: Icon.sinister_level0,
  [incidentsTypeEnum.siniestro1]: Icon.sinister_level1,
  [incidentsTypeEnum.siniestro2]: Icon.sinister_level2,
  [incidentsTypeEnum.siniestro3]: Icon.sinister_level3,
  [incidentsTypeEnum.varado0]: Icon.stranded_level0,
  [incidentsTypeEnum.varado1]: Icon.stranded_level1,
  [incidentsTypeEnum.varado2]: Icon.stranded_level2,
  [incidentsTypeEnum.varado3]: Icon.stranded_level3,
  [incidentsTypeEnum.manifestacion0]: Icon.manifestation_level0,
  [incidentsTypeEnum.manifestacion1]: Icon.manifestation_level1,
  [incidentsTypeEnum.manifestacion2]: Icon.manifestation_level2,
  [incidentsTypeEnum.manifestacion3]: Icon.manifestation_level3,
  [incidentsTypeEnum.accidentes0]: Icon.accident_level0,
  [incidentsTypeEnum.accidentes1]: Icon.accident_level1,
  [incidentsTypeEnum.accidentes2]: Icon.accident_level2,
  [incidentsTypeEnum.accidentes3]: Icon.accident_level3,
  [incidentsTypeEnum.masivo0]: Icon.massive_level0,
  [incidentsTypeEnum.masivo1]: Icon.massive_level1,
  [incidentsTypeEnum.masivo2]: Icon.massive_level2,
  [incidentsTypeEnum.masivo3]: Icon.massive_level3,
  [incidentsTypeEnum.semaforizacion0]: Icon.semaforizacion_level0,
  [incidentsTypeEnum.semaforizacion1]: Icon.semaforizacion_level1,
  [incidentsTypeEnum.semaforizacion2]: Icon.semaforizacion_level2,
  [incidentsTypeEnum.semaforizacion3]: Icon.semaforizacion_level3,
  [incidentsTypeEnum.accidente]: Icon.accident_datex,
  [incidentsTypeEnum.obstruccionVehicular]: Icon.vehicular_obstruction_datex,
  [incidentsTypeEnum.obstruccionAmbiental]: Icon.environmental_obstruction_datex,
  [incidentsTypeEnum.infraestructuraDanada]: Icon.damaged_infrastructure_datex,
  [incidentsTypeEnum.obstruccionAnimal]: Icon.animal_obstruction_datex,
  [incidentsTypeEnum.mantenimiento]: Icon.maintenance_datex,
  [incidentsTypeEnum.obstruccionGeneral]: Icon.general_obstruction_datex,
  [incidentsTypeEnum.traficoAnormal]: Icon.abnormal_traffic_datex,
  [incidentsTypeEnum.carreteraNoClima]: Icon.road_not_weather_datex,
  [incidentsTypeEnum.condicionAmbiental]: Icon.bad_conditions_datex,
  [incidentsTypeEnum.condicionesSituacion]: Icon.condition_datex,
  [incidentsTypeEnum.situacionGestionRed]: Icon.network_management_datex,
  [incidentsTypeEnum.situacionObrasConstruccion]: Icon.construction_work_datex,
  [incidentsTypeEnum.situacionCarretera]: Icon.road_management_datex,
  [incidentsTypeEnum.situacionGestionVelocidad]: Icon.surface_condition_datex,
};
