<div class="grid-container text-white">
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Id Incidente</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.id}}</span>
    </div>
    <div class="card">
        <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
        <h5 class="text-button-clean-filter">Categoria</h5>
        <span class="text-size-16 text-weight-600 mt-1-em">
          {{properties.incidentById?.classesName}}</span>
    </div>
    <div class="card">
        <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
        <h5 class="text-button-clean-filter">Subcategoria</h5>
        <span class="text-size-16 text-weight-600 mt-1-em">
          {{properties.incidentById?.typeName}}</span>
    </div>
</div>

<div class="grid-container text-white">
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Zona</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.zoneName ? properties.incidentById?.zoneName : 'No registrado'}}</span>
    </div>
    <div class="card">
        <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
        <h5 class="text-button-clean-filter">Corredor</h5>
        <span class="text-size-16 text-weight-600 mt-1-em">
          {{properties.incidentById?.brokerName ? properties.incidentById?.brokerName : 'No registrado'}}</span>
    </div>
    <div class="card">
        <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
        <h5 class="text-button-clean-filter">Fuente</h5>
        <span class="text-size-16 text-weight-600 mt-1-em">
          {{properties.incidentById?.sourceName ? properties.incidentById?.sourceName : 'No registrado'}}</span>
    </div>
</div>

<div class="grid-container text-white">
  <div class="card">
    <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
    <h5 class="text-button-clean-filter">Gravedad</h5>
    <span class="text-size-16 text-weight-600 mt-1-em">
      {{properties.incidentById?.gravityName ? properties.incidentById?.gravityName : 'No registrado'}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Prioridad</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.priorityName ? properties.incidentById?.priorityName : 'No registrado'}}</span>
  </div>
</div>

<div class="form-container pr-24 pl-24 mb-32 mt-10">
  
  <form [formGroup]="registerFormGroup" class="login-form">
    <div class="mat-input d-flex fd-row align-items-center mb-32 mt-15">
      <mat-form-field
              class="textWhite"
              appearance="outline"
              [ngClass]="{
          'error-field':
            registerFormGroup.controls.observation.invalid &&
            registerFormGroup.controls.observation.touched
        }"
      >
        <mat-label class="text-white">Registro de Gestión</mat-label>
        <textarea [rows]="4" matInput formControlName="observation"></textarea>
        <mat-error>
          {{ observationsErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex" style="justify-content: space-between;margin-top: 7rem;">

      <ng-container>
        <mat-checkbox class="example-margin" formControlName="closeManagement">Finalizar Gestión</mat-checkbox>
      </ng-container>

      <ng-container>
        <sit-button
                  (click)="
                  registerFormGroup.valid ? validateUserSubmit() : validateFormGroup()
                  "
                [buttonProperties]="buttonSaveAttributes"
        ></sit-button>
      </ng-container>
    </div>
  </form>

</div>
