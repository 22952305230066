
<!-- Type Card -->

<ng-container *ngIf="KPIData">
<div class="card">
    <div [ngClass]="KPIData.icon ? 'd-flex' : ''">
        <sit-icon *ngIf="KPIData.icon" [icon]="KPIData.icon" [sizeClass]="'icon-size-48'"></sit-icon>
        <span [ngClass]="KPIData.icon ? 'sicon' : 'nicon'">{{KPIData.count || KPIData.text || 0}}</span>
    </div>
    <div class="card-footer" [ngClass]="KPIData.icon ? 'fs-12' : 'fs-8'">
        <span>{{KPIData.title}}</span>
    </div>
</div>
</ng-container>

<!-- Type List -->

<ng-container *ngIf="DataItem">
    <div class="card" (click)="openModal(DataItem)">
        <div class="title border-special mb-1-em d-flex">
            <span class="blue_label">{{DataItem.incidentAddress}}</span>
            <span class="normal_label">{{DataItem.incidentStatusName}}</span>
        </div>
        <div class="content d-flex gap-10-px align-items-center">
            <sit-incidents-kpi [KPIData]="stacks.classIncident"></sit-incidents-kpi>
            <sit-incidents-kpi [KPIData]="stacks.typeIncident"></sit-incidents-kpi>
            <sit-incidents-kpi [KPIData]="stacks.priorityIncident"></sit-incidents-kpi>
        </div>
        <div class="mt-1-em d-flex">
            <span class="fw-bold" *ngIf="this.DataItem?.incidentCountsGroup?.length === 0 || !this.DataItem?.incidentCountsGroup"> No hay recursos asignados </span>

            <br>
            <div class="icons" *ngIf="this.DataItem?.incidentCountsGroup?.length > 0 && this.DataItem?.incidentCountsGroup">
                <div class="d-flex gap-5-px mr-1-em w-100 icon" *ngFor="let item of this.DataItem.incidentCountsGroup">
                    <sit-icon
                            [icon]="item.name === 'Al Colegio en Bici' ? Icon.CycleSchoolIconMap :
                                item.name === 'Ciempiés' ? Icon.CentipedeIconMap :
                                item.name === 'Agentes Civiles' ? Icon.agente_map_azul : Icon.HelmetIconMap">
                    </sit-icon>
                    <span class="text-white">{{item.count}}</span>
                </div>
            </div>

        </div>
        <div class="d-flex mt-1-em big-group gap-10-px">
            <div class="d-flex gap-10-px group">
                <sit-icon [icon]="Icon.calendar_w" [sizeClass]="'icon-size-18'"></sit-icon>
                <span>{{convertDateFormat(DataItem.incidentTime)}}</span>
            </div>

            <div class="d-flex gap-10-px group">
                <sit-icon [icon]="Icon.schedule_w" [sizeClass]="'icon-size-18'"></sit-icon>
                <span>{{convertTimeFormat(DataItem.incidentTime)}}</span>
            </div>
        </div>
    </div>
</ng-container>
