<div
  class="main-container ml-20-em ml-md-0 ml-sm-0"
  [ngClass]="{
    'top-62': !mapConventionsProperties?.showOwnButton
  }"
>
  <ng-container *ngIf="mapConventionsProperties?.showOwnButton">
    <div
      (click)="onShowPanel()"
      class="bttn d-flex fd-row align-items-center borders bg-white mb-8 cursor-pointer btn-panel"
    >
      <sit-icon
        [alt]="'Abrir panel de convenciones del mapa'"
        [icon]="Icon.info_w"
        [sizeClass]="'icon-size-28'"
      ></sit-icon>
      <label class="text-size-16 ml-8 text-weight-500 cursor-pointer"
        >Convenciones</label
      >
    </div>
  </ng-container>
  <div
    *ngIf="mapConventionsProperties?.showPanel$ | async"
    class="container-1 borders bg-white"
  >
    <div
      (click)="closePanel()"
      class="d-flex text-color-white justify-end cursor-pointer"
    >
      <sit-icon
        [alt]="'Cerrar'"
        [icon]="Icon.close_w"
        [sizeClass]="'icon-size-28'"
      ></sit-icon>
    </div>
    <div class="container-2">
      <div
        class="grid1-col-3"
        [ngClass]="{
          'grid1-citizen-col-3': !mapConventionsProperties?.showOwnButton
        }"
      ></div>
      <div class="grid2-col-2">
        <ng-container
          *ngFor="
            let item of mapConventionsProperties?.properties
              ?.situationsConventions
          "
        >
          <div class="d-flex fd-row align-items-center">
            <sit-icon
              [icon]="item.icon"
              [sizeClass]="'icon-size-28'"
              [alt]="item.alt"
            ></sit-icon>
            <label class="text-size-16 ml-8">{{ item.label }}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
