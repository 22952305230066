import { IncidentPanelService } from "@shared/services/incident-panel/incident-panel.service";
import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IModelincidentsModel } from "@types-custom/models/ui/modal-incidents.model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { BehaviorSubject, Observable } from "rxjs";
import { ModalSuccessComponent } from "../modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { IButtonModel } from "@types-custom/models/ui/button-model";
 

@Component({
  selector: "sit-modal-add-resources",
  templateUrl: "./modal-add-resources.component.html",
  styleUrls: ["./modal-add-resources.component.scss"],
})
export class ModalAddResourcesComponent implements OnInit {
  @Input() modalDispatcher: BehaviorSubject<any> = new BehaviorSubject({});

  @Input() properties : any | undefined;
  
    Icon = Icon;
    items = new Array(10);
  
    listScheduledActivities: any[];
    listUnScheduledActivities: any[];
    listIncidentsWithoutAssign:any[];
  
  
    props:{
      listScheduledActivity: (Id: number, value?: string) => Observable<any>,
      listUnscheduledActivity:(Id: number, value?: string) => Observable<any>,
      listIncidentsWithoutAssign :() => Observable<any>
    }

  actionButtonProps: IButtonModel = {
    label: 'Asignar',
    // classAttributes:,
  }
  datosPanel: string;


  constructor(
    private incidentPanelService:IncidentPanelService,
    private modalService: ModalService
    ){ }


  ngOnInit(): void {
    this.props = this.properties.resourcesDataSource;
    this.init()

    this.incidentPanelService.data$.subscribe(data => {
      this.datosPanel = data;
    });
  }

  init(): void {
    this.props.listScheduledActivity(this.properties?.id).subscribe({
        next: (response) => { 
          this.listScheduledActivities = response;
        
        },
        error: (error: any) => console.log(error)
      });

      this.props.listUnscheduledActivity(this.properties?.id).subscribe({
        next: (response) => { 
          this.listUnScheduledActivities = response;
        
        },
        error: (error: any) => console.log(error)
      });

      this.props.listIncidentsWithoutAssign().subscribe({
        next: (response) => { 
          this.listIncidentsWithoutAssign = response;
        },
        error: (error: any) => console.log(error)
      });

    
  }

  onClick() {
   this.incidentPanelService.setIncidentAddHumanResource(this.properties.id)
   this.incidentPanelService.clickModalLDispatcher$.subscribe(
    (resultado) => {
      if (resultado) {
        this.showModalSuccess('El recurso fue asignado exitosamente')
      } else {
        console.warn('Hubo un problema en la petición.');
      }
    }
  );

  }

   showModalSuccess(message: string): void {
     const modal: IUniqueModalModel = {
       headerBackgroundClass: 'bg-color-background-3',
       toRender: {
         component: ModalSuccessComponent,
         data: {
           message: message,
         },
       },
     };
    this.modalService.confirmationModal(modal);
   }

  private handlingError(error: any): void {
    console.error({ error });
  }
}
