<form [formGroup]="properties.formGroup" class="d-flex fd-col login-form" [ngClass]="{
    'pr-16 pl-16': properties.actionPanel$.value === 'edit'
  }">
  <ng-container *ngFor="let controlGroup of formModelArray">
    <div class="d-flex control-group mb-4" [ngClass]="
        controlGroup.length > 1 ? 'fd-row pt-field-8-px' : 'fd-col'
      ">
      <ng-container *ngFor="let control of controlGroup">
        <div formGroupName="{{ control.formName }}" class="mat-input d-flex fd-row align-items-center"
          *ngIf="!control.hiddenControl || handleHideFromParent(control)" [ngClass]="{
            'observation-last-control': control.formKey === 'observation',
            'height-auto': _isAValidComponent(control.formName, control.formKey)
          }">
          <ng-container
            *ngIf="control.type !== inputTypeEnum.BUTTON_RADIO && control.type !== inputTypeEnum.BUTTON_CHECKBOX && control.type !== inputTypeEnum.BINARY">

            <mat-form-field class="textWhite height-auto" appearance="outline" [ngClass]="{
              'error-field': _isAValidComponent(control.formName, control.formKey),
              'disabled-control': control.formControl.options?.disabled
            }">
              <mat-label class="text-white main-label">{{ control.label }}</mat-label>
              <ng-container *ngIf="control.type">
                <!-- Defines the input control based on its type -->
                <ng-container [ngSwitch]="control.type">
                  <!-- Input and Location Input -->
                  <ng-container *ngSwitchCase="inputTypeEnum.INPUT">
                    <ng-container *ngIf="control.formKey === inputTypeEnum.LOCATION_INPUT; else defaultInput">
                      <input #addresstext id="location-controller-element" matInput formControlName="{{ control.formKey }}" />
                      <location-control-from-api [properties]="locationControlModel"></location-control-from-api>
                    </ng-container>

                    <ng-template #defaultInput>
                      <input matInput formControlName="{{ control.formKey }}" />
                    </ng-template>
                  </ng-container>
                  <!-- Select -->
                  <mat-select *ngSwitchCase="inputTypeEnum.SELECT" formControlName="{{ control.formKey }}">
                    <mat-option *ngFor="let item of control.list" [value]="item.value">{{ item.name }}</mat-option>
                  </mat-select>
                  <!-- List -->
                  <mat-select *ngSwitchCase="inputTypeEnum.LIST" formControlName="{{ control.formKey }}">
                    <mat-option *ngFor="let item of control.list" [value]="item.value">{{ item.name }}</mat-option>
                  </mat-select>
                  <!-- Autocomplete Input -->
                  <autocomplete-control *ngSwitchCase="inputTypeEnum.AUTOCOMPLETE_INPUT"
                    [formGroupInstance]="properties.formGroup.get(control.formName)"
                    [formControlInstance]="getControl(control)" [controlModel]="control"
                    [controlList]="control.list"></autocomplete-control>
                  <!-- Number Input -->
                  <input *ngSwitchCase="inputTypeEnum.INPUT_NUMBER" type="number" matInput
                    formControlName="{{ control.formKey }}" />
                  <!-- Text Area Input -->
                  <textarea *ngSwitchCase="inputTypeEnum.TEXT" matInput formControlName="{{ control.formKey }}"
                    (focus)="control.formControl.options.focus ? onFocus($event, control) : null"></textarea>
                  <!-- Date/Time input -->
                  <input *ngSwitchCase="inputTypeEnum.DATE" id="incidentForm-{{ control.formKey }}" type="date" matInput
                    formControlName="{{ control.formKey }}" />
                  <input *ngSwitchCase="inputTypeEnum.TIME" id="incidentForm-{{ control.formKey }}" type="time" matInput
                    formControlName="{{ control.formKey }}" />
                  <input *ngSwitchCase="inputTypeEnum.DATETIME" id="incidentForm-{{ control.formKey }}"
                    type="datetime-local" matInput formControlName="{{ control.formKey }}" max="{{currentDate}}" />
                  <!-- <ng-container *ngIf="control.type === inputTypeEnum.DATETIME"> -->
                  <!-- <div *ngSwitchCase="inputTypeEnum.DATETIME" class="date">
                    <input matInput [ngxMatDatetimePicker]="picker" id="incidentForm-{{ control.formKey }}"
                      formControlName="{{ control.formKey }}">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSeconds]="true">
                    </ngx-mat-datetime-picker>
                  </div> -->
                  <!-- </ng-container> -->
                  <!-- Toggle switch input -->
                  <div *ngSwitchCase="inputTypeEnum.TOOGLESWITCH" class="d-flex fd-col">
                    <input class="hidden-control" matInput formControlName="{{ control.formKey }}" />
                    <mat-label class="text-white">{{ control.label }}</mat-label>
                    <state-toogle [formControlInstance]="getControl(control)" />
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="control.formControl.validators" ngProjectAs="mat-error">
                <ng-container *ngFor="let validator of control.formControl.validators">
                  <mat-error *ngIf="getControl(control).hasError(validator.validatorCodeName) 
                      && getControl(control).touched 
                      && object.keys(getControl(control).errors)[0] === validator.validatorCodeName">
                    {{ validator.errorMessage }}
                  </mat-error>
                </ng-container>
              </ng-container>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="control.type === inputTypeEnum.BUTTON_RADIO">
            <div class="d-flex fd-col gap-10-px mb-12 px-16-px">

              <mat-label class="text-weight-500 text-size-18"
                [ngClass]="_isAValidComponent(control.formName, control.formKey) ? 'error-field mandatory-field' : 'text-white'">{{
                control.label }}</mat-label>
              <div class="d-inline-flex">
                <ng-container *ngFor="let item of control.list; let i = index">

                  <div class="button-radio">
                    <input formControlName="{{ control.formKey }}" id="btn-{{control.formKey}}-{{i}}"
                      class="hidden-radio" type="radio" [value]="item.value" />
                    <label for="btn-{{control.formKey}}-{{i}}" class="button-label text-size-16">{{ item.name }}</label>
                  </div>

                </ng-container>
                <ng-container *ngIf="control.formControl.validators" ngProjectAs="mat-error">
                  <ng-container *ngFor="let validator of control.formControl.validators">
                    <mat-error *ngIf="getControl(control).hasError(validator.validatorCodeName) 
                        && getControl(control).touched 
                        && object.keys(getControl(control).errors)[0] === validator.validatorCodeName">
                      {{ validator.errorMessage }}
                    </mat-error>
                  </ng-container>
                </ng-container>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="control.type === inputTypeEnum.BUTTON_CHECKBOX">
            <div class="d-flex fd-col gap-10-px mb-12 px-16-px">

              <select multiple class="hidden-control" formControlName="{{ control.formKey }}">
                <ng-container *ngFor="let item of control.list; let i = index">
                  <option [value]="item.value">{{ item.name }}</option>
                </ng-container>
              </select>

              <mat-label class="text-white text-weight-500 text-size-18">{{ control.label }}</mat-label>
              <div class="d-inline-flex">

                <ng-container *ngFor="let item of control.list; let i = index">
                  <div class="button-checkbox">
                    <input id="btn-{{control.formKey}}-{{i}}" class="hidden-checkbox" type="checkbox"
                      [value]="item.value" (change)="updateSelection(control, $event, item)"
                      [checked]="selectedCheck(control, item)" />
                    <label for="btn-{{control.formKey}}-{{i}}" class="button-label text-size-16">{{ item.name }}</label>
                  </div>
                </ng-container>

              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="control.type === inputTypeEnum.BINARY">
            <div class="d-flex text-white gap-10-px mb-12 px-16-px">
              {{control.label}}
              <input type="checkbox" formControlName="{{ control.formKey }}">
            </div>
            <!-- <label for="btn-{{control.formKey}}-{{i}}" class="button-label text-size-16">{{ item.name }}</label> -->
          </ng-container>


        </div>
      </ng-container>
    </div>
  </ng-container>
</form>

<ng-container *ngIf="properties.actionPanel$ | async as action">
  <div *ngIf=" action === 'edit' && properties.panelOnManagement === mapEventTypeEnum.INCIDENTS ">
    <div class="bg-color-background-1 d-flex mt-2 ml-16">
      <div class="left-subtitle-block bg-color-primary-1"></div>
      <!-- <sit-icon class="" [icon]="Icon.edit_w" [sizeClass]="'icon-size-16'" alt="icono lapiz de edicion"></sit-icon> -->
      <div class="d-flex full-width py-05">
        <h2 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0">
          BITÁCORA
        </h2>
      </div>
    </div>
    <div [formGroup]="filterFormGroup" class="d-flex fd-col gap-10-px mb-12 px-16-px">

      <select multiple class="hidden-control">
        <ng-container *ngFor="let item of filterRoleOption; let i = index">
          <option [value]="item.value">{{ item.value }}</option>
        </ng-container>
      </select>
      <div class="d-inline-flex">
        <ng-container *ngFor="let item of filterRoleOption; let i = index">
          <div class="button-checkbox">
            <input id="btn-filter-role-option-{{i}}" class="hidden-checkbox" type="checkbox" [value]="item.value"
              (change)="updateFilterSelection()" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" />
            <label for="btn-filter-role-option-{{i}}" class="button-label text-size-16">
              <sit-icon [icon]="item.icon" [sizeClass]="'icon-size-26'" alt="icono filtro usuario"></sit-icon>
              {{ item.value }}</label>
          </div>
        </ng-container>

      </div>
    </div>

    <div class="bitacora-observation overflow-y-auto custom-scrollbar" tabindex="0">
      <!-- <ng-container *ngFor="let item of observationsList"> -->
      <ng-container *ngFor="let item of filteredObservationsList">
        <div class="textWhite observation">
          <div *ngIf="roleIconMapper(item.type) !== null" class="icon icon-user-container" [style.--bg]="roleIconMapper(item.type).background">
            <sit-icon [icon]="roleIconMapper(item.type).icon" [sizeClass]="'icon-size-26'"
              alt="icono usuario"></sit-icon>
          </div>
          <label class="username">{{item.authorName ?? 'No especificado'}}</label>
          <em class="incident-date">{{ formatDate(item?.date) }} - {{ formatTime(item?.date) }}</em>
          <p class="message">
            {{ item.observation }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>