import { Injectable } from '@angular/core';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { IAction, IActionHandler } from '../action.service';
import { ModalService } from '@ui-core/services/modal/modal.service';

@Injectable()
export class ModalActionHandler implements IActionHandler {
  private modalService: ModalService;

  public setService(service: any) {
    this.modalService = service;
  }

  canHandle(action: IAction): boolean {
    return action.event.actionType === ActionType.Modal;
  }
  handle(action: IAction): void {
    const { data: modal } = action.event;

    modal.toRender.data.properties = {
      modalService: this.modalService,
      close: () => this.modalService.closeConfirmationModal(),
    };

    this.modalService.confirmationModal(modal);
  }
}
