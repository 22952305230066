import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { LoginAuthService } from '@shared/services/login-auth/login-auth.service';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { RouteNamesEnum } from '@official/routes/routes';
import { Router } from '@angular/router';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { CryptoService } from '@shared/services/crypto-service/crypto.service';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() showModals = new EventEmitter();

  Icon = Icon;
  showPass = false;
  fb = new UntypedFormBuilder();
  mensaje: string;

  signinButtonProps: IButtonModel = {
    label: 'INGRESAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  loginFormGroup: UntypedFormGroup;

  constructor(
    private loginAuthService: LoginAuthService,
    private localStorageManagementService: LocalStorageManagementService,
    private router: Router,
    private modalService: ModalService,
    private cryptoService: CryptoService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.loginFormGroup = this.fb.group({
      username: [{ value: '', disabled: false }, [Validators.required]],
      password: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  handleLogin() {
    if (this.loginFormGroup.invalid) {
      this.mensaje = 'Todos los campos son obligatorios';
    } else {
      this.mensaje = '';
      const loginBody = {
        username: this.loginFormGroup.value.username,
        password: this.loginFormGroup.value.password,
      };
      this.loginAuthService.login(loginBody).subscribe({
        next: (response) => {
          const encryptedTokenString = this.cryptoService.encryptToken(
            response.token
          );
          response.token = encryptedTokenString;
          this.localStorageManagementService.setUserLocalStorage(response);

          // TODO: Temporarily disabled.
          this.loginAuthService.setSingleSession().subscribe({
            next: () => {
              this.handleRouteLogin();
            }
          });
          // this.handleRouteLogin();
        },
        error: (err) => {
          console.error('handle error', err);
          this.invalidUser();
        },
      });
    }
  }

  private handleRouteLogin(): void {
    this.router.navigate([RouteNamesEnum.MapViewer]);
  }

  showModalTerms(notice: string): void {
    this.showModals.emit(notice);
  }

  showModalForgot(): void {
    this.showModals.emit('FORGOT PASS');
  }

  invalidUser(): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: 'Lo sentimos, su correo electrónico o contraseña son incorrectos. Por favor intente nuevamente',
        },
      },
    };
    this.modalService.confirmationModal(modal);
  }
}
