<div class="container-modal d-flex fd-col">
  <div class="ind-modal">

    <div class="card bd-r-white">
      <ng-container *ngIf="properties.class_name.includes('Manifestación')">
        <sit-icon
        [icon]="Icon.manifestacion_blue"
         alt="peaton"
         sizeClass="icon-size-40"
         ngClass="icon-bg"
         >
        </sit-icon>
    </ng-container>
    <ng-container *ngIf="properties.class_name.includes('Siniestro')">
        <sit-icon
        [icon]="Icon.atropello_blue"
         alt="Bicicleta_azul"
         sizeClass="icon-size-40"
         ngClass="icon-bg">
        </sit-icon>
    </ng-container>
    <ng-container *ngIf="properties.class_name.includes('Incidente en vía')">
        <sit-icon
        [icon]="Icon.atropello_blue"
         alt="auto_verde"
         sizeClass="icon-size-40"
         ngClass="icon-bg">
          </sit-icon>
    </ng-container>
    <ng-container *ngIf="properties.class_name.includes('Semaforización')">
      <sit-icon
        [icon]="Icon.semaforo_blue"
        alt="auto_verde"
        sizeClass="icon-size-40"
        ngClass="icon-bg">
      </sit-icon>
    </ng-container>
    <ng-container *ngIf="properties.class_name.includes('Varado')">
      <sit-icon
        [icon]="Icon.herido_blue"
        alt="auto_verde"
        sizeClass="icon-size-40"
        ngClass="icon-bg">
      </sit-icon>
    </ng-container>
    <ng-container *ngIf="properties.class_name.includes('Transporte masivo')">
      <sit-icon
        [icon]="Icon.traffic_jams_w"
        alt="auto_verde"
        sizeClass="icon-size-40"
        ngClass="icon-bg">
      </sit-icon>
    </ng-container>
        <span class="text-color-primary-1"> Clase</span>
        <span class="text-center"> {{properties?.class_name}}</span>

    </div>

    <div class="card mid">
      <sit-icon
      [icon]="Icon.alert_red"
      alt="Icono coi amarilla"
      sizeClass="icon-size-40"
      class="icon-bg"
      >
      </sit-icon>
        <span class="text-color-primary-1" >Tipo</span>
        <span class="text-center">{{ properties?.type_name }}</span>
    </div>

    <div class="card bd-l-white">  
      <span class="icon-bg" >
        <ng-container *ngIf="properties.priority_name.includes('0') ">
          <span class="pr0">0</span>    
        </ng-container>
        <ng-container  *ngIf="properties.priority_name.includes('1') ">
          <span class="pr1">1</span>    
        </ng-container>
        <ng-container *ngIf="properties.priority_name.includes('2')  ">
          <span class="pr2">2</span>    
        </ng-container>
        <ng-container *ngIf="properties.priority_name.includes('3')  ">
          <span class="pr3">3</span>    
        </ng-container>
      </span>
      <span class="text-color-primary-1">Prioridad</span>
    </div>

    <div class="card bd-l-white">  
      <sit-icon
      [icon]="Icon.zoom_blue"
      alt="Icono zoom "
      sizeClass="icon-size-40"
      class="icon-bg"
      >
      </sit-icon>
      <span class="text-color-primary-1">Localidad</span>
      <span class="text-center"> {{ properties?.location_name }} </span>
    </div>
  </div>

  <div class="titles-modal">

    <div class="tit-2">
      <sit-icon
      [icon]="Icon.text_box"
      alt="Icono de caja con texto"
      [sizeClass]="'icon-size-40'"
      class="icon-footer"
      >
      </sit-icon>
      <span>Descripcion</span>
    </div>
  </div>

  <div class="text-modal">
    <div class="text-cont">
      <p>
      {{ properties?.description}}
      </p>
    </div>
  
  </div>

  <div class="footer-modal d-flex ">
    <div class="card-footer">
      <label class="card-tit">
        <sit-icon
        alt="icon calendario"
        [icon]="Icon.calendar_w"
        [sizeClass]="'icon-size-24'"
        class="icon-footer"
        >
        </sit-icon>
        <span>Fecha de suceso:</span>
      </label>
     <span class="text-white"><strong>{{ properties?.create_date | date: 'M/d/yy, h:mm a' }}</strong></span>
    </div>

    <div class="card-footer">
      <label class="card-tit">
        <sit-icon
        alt="icon calendario"
        [icon]="Icon.calendar_w"
        [sizeClass]="'icon-size-24'"
        class="icon-footer"
        >
        </sit-icon>
        <span>Fecha de creación:</span>
      </label>
      <span class="text-white"><strong>{{ properties?.create_date | date: 'M/d/yy, h:mm a' }}</strong></span>
    </div>

    <div class="card-footer">
      <label class="card-tit">
        <sit-icon
        alt="icon ojo"
        [icon]="Icon.eye_w"
        [sizeClass]="'icon-size-24'"
        class="icon-footer"
        >
        </sit-icon>
        <span>FUENTE</span>
      </label>
      <span class="text-white"><strong> {{ properties?.source_name }} </strong></span>
    </div>

  </div>
</div>